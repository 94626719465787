import React, { useEffect, useState, useRef } from "react";
import MUIDataTable from "mui-datatables";
import { toast } from "react-toastify";
import { FormSelect } from "react-bootstrap";
import { DateRangePicker } from "../../common/date-range-picker";
import SessionService from "../../../service/SessionService";
import PaymentService from "../../../service/report/PaymentService";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { NumericFormat } from "react-number-format";
import { Box, Typography } from "@mui/material";

const theme = createTheme({
	components: {
		MuiPopover: {
			styleOverrides: {
				paper: {
					padding: "1rem",
					maxWidth: "500px", // Set the width of the dialog window
					maxHeight: "500px", // Set the max height of the dialog window
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontWeight: "600",
					textTransform: "none",
					whiteSpace: "nowrap",
				},
			},
		},
	},
});

const PaymentsTable = ({ loading, setLoading }) => {
	const [users, setUsers] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [rowCount, setRowCount] = useState(10);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [filterList, setFilterList] = useState({});
	const [sortList, setSortList] = useState("payment_date:desc");
	const [tableState, setTableState] = useState({});
	const [dropdowns, setDropdowns] = useState({});
	const [searchText, setSearchText] = useState("");
	const [lastAction, setLastAction] = useState("");
	const [filters, setFilters] = useState({});
	const tableRef = useRef(null);
	const [isIsoColumnVisible, setIsIsoColumnVisible] = useState(true);
	const [isMasterAgentVisible, setIsMasterAgentVisible] = useState(true);
	const [isOrganizationVisible, setIsOrganizationVisible] = useState(true);
	const [isCompanyNameVisible, setIsCompanyNameVisible] = useState(true);
	const [isStoreNameVisible, setIsStoreNameVisible] = useState(true);
	const [isAddressVisible, setIsAddressVisible] = useState(false);
	const [isCityVisible, setIsCityVisible] = useState(false);
	const [isStateVisible, setIsStateVisible] = useState(false);
	const [isZipVisible, setIsZipVisible] = useState(false);

	const userRoutes = SessionService.getUserRoutes() || {};
	const redirectUrl = userRoutes["contract/view"] || "view"; // Fallback to default if not defined

	const [searchOpen, setSearchOpen] = useState(false);
	const searchOptions = {
		onSearchOpen: () => setSearchOpen(true),
		onSearchClose: () => setSearchOpen(false),
		// other options...
	};

	const getCellProps = (value, tableMeta, updateValue) => ({
		style: {
			whiteSpace: "nowrap",
		},
	});

	const customNameRender = (value, tableMeta, updateValue) => {
		if (tableMeta.rowIndex === users.length - 1) {
			return <strong>{value}</strong>;
		}
		return value;
	};

	const getRowProps = (row, dataIndex, rowIndex) => {
		// Check if it's the last row
		if (rowIndex === users.length - 1) {
			return {
				style: { backgroundColor: "#f2f2f2" },
			};
		}
		return {};
	};

	// Function to format the date in MM/DD/YYYY format
	const formatDate = (date) => {
		let day = date.getDate();
		let month = date.getMonth() + 1; // Months are 0-indexed
		let year = date.getFullYear();

		return `${month}/${day}/${year}`;
	};

	// Calculate the first day of the current month and today's date
	const today = new Date();
	const firstDayCurrentMonth = new Date(
		today.getFullYear(),
		today.getMonth(),
		1
	);

	// Format the date range string for the current month so far
	const initialDateRange = `${formatDate(
		firstDayCurrentMonth
	)} - ${formatDate(today)}`;

	// Initialize the dateRangeCreated state with the calculated date range
	const [dateRangeCreated, setDateRangeCreated] = useState(initialDateRange);

	const PaymentView = {
		page: currentPage, // <-- Set the default page
		limit: rowCount, // <-- Set the default row count
		payment_date: dateRangeCreated,
		sort: sortList,
		filter: {
			payment_date: dateRangeCreated,
		},
	};

	function isEmpty(obj) {
		return Object.keys(obj).length === 0 && obj.constructor === Object;
	}

	const addSummaryObject = (data, totalCount) => {
		const summaryObject = {
			id: "",
			payment_date: "",
			contract_number: "",
			customer_name: "",
			customer_address: "",
			customer_city: "",
			customer_state: "",
			customer_zip: "",
			payment_amount: totalCount?.sums?.payment_amount?.toLocaleString(),
			dealer_fee: totalCount?.sums?.dealer_fee?.toLocaleString(),
			dealer_fee_share: "",
			payment_tax: totalCount?.sums?.payment_tax?.toLocaleString(),
			payment_total: "",
			payment_type_id: "",
			payment_type_name: "",
			account_last_4: null,
			payment_status_name: "",
			resp_text: "Totals",
			total: totalCount?.sums?.total?.toLocaleString(),
			auth_total: totalCount?.sums?.auth_total?.toLocaleString(),
			ret_ref: null,
			batch_id: null,
			void_user_name: null,
			processed_user_name: "",
			struct_iso_id: "",
			struct_iso_name: "",
			struct_iso_status_name: "",
			struct_master_agent_id: "",
			struct_master_agent_name: "",
			struct_master_agent_status_name: "",
			struct_organization_id: "",
			struct_organization_name: "",
			struct_organization_status_name: "",
			struct_company_id: "",
			struct_company_name: "",
			struct_company_status_name: "",
			struct_store_id: "",
			struct_store_name: "",
			struct_store_status_name: "",
		};
		// Create a new array with original data plus the summary object
		return [...data, summaryObject];
	};

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const response = await PaymentService.view(PaymentView);

				// Display an error message if needed
				if (response.status !== 200) {
					// console.log("response", response);
					toast.error(`Error fetching Leads: ${response.message}`, {
						position: "top-center",
						autoClose: 1000,
					});
				}

				const users = response.data.data.query;
				const rowCount = parseInt(response.data.data.query.length);
				const totalCount = response?.data?.total;
				const currentPage = response.data.page;
				const dropdowns = response.data.dropdowns;

				setUsers(addSummaryObject(users, totalCount));
				setTotalCount(totalCount);
				setCurrentPage(currentPage);
				setTotalPages(totalPages);
				setRowCount(rowCount);
				setRowsPerPage(rowsPerPage);
				setDropdowns(dropdowns);
			} catch (error) {
				setLoading(false);
				console.error("Error fetching users:", error);
			}
			setLoading(false);
		};

		fetchData();
	}, []);

	const sendRequest = async (filterList, isFilterChange, dateRangeStr) => {
		try {
			const PaymentView = {
				page: tableState.page + 1,
				limit: tableState.rowsPerPage,
				payment_date: dateRangeCreated,
				filter: {},
				sort: sortList,
			};

			if (tableState.sortOrder.hasOwnProperty("name")) {
				let sortOrderDirection = tableState.sortOrder.direction
					? tableState.sortOrder.direction
					: "asc";
				PaymentView.sort = `${tableState.sortOrder.name}:${sortOrderDirection}`;
			} else {
				PaymentView.sort = "payment_date:desc";
			}
			setSortList(PaymentView.sort);

			if (tableState.searchText) {
				PaymentView.filter = {
					...PaymentView.filter,
					all: tableState.searchText,
					payment_date: dateRangeStr,
				};
			}

			if (isFilterChange) {
				let newFilterList = [];

				if (filterList) {
					newFilterList = filterList;
				} else {
					newFilterList = tableState.filterList;
				}

				//Filter
				if (newFilterList) {
					// Ensure both columns and tableState are provided and valid
					if (!columns || !tableState || !newFilterList) {
						console.error("Invalid columns or tableState");
					} else {
						// Loop through each column and set the filter in PaymentView
						columns.forEach((column, index) => {
							const filterValue = newFilterList[index][0];
							if (filterValue) {
								// console.log(column.name);
								if (dateRangeStr) {
									if (column.name === "payment_date") {
										PaymentView.filter = {
											...PaymentView.filter,
											[column.name]: dateRangeStr,
										};
									} else {
										PaymentView.filter = {
											...PaymentView.filter,
											[column.name]: filterValue,
										};
									}
								} else {
									PaymentView.filter = {
										...PaymentView.filter,
										[column.name]: filterValue,
									};
								}
							} else if (dateRangeStr) {
								if (column.name === "payment_date") {
									PaymentView.filter = {
										...PaymentView.filter,
										[column.name]: dateRangeStr,
									};
								}
							}
						});
					}

					setFilterList(PaymentView.filter);
				} else if (dateRangeStr) {
					columns.forEach((column, index) => {
						// const filterValue = newFilterList[index][0];
						// console.log(column.name);
						if (dateRangeStr) {
							if (column.name === "payment_date") {
								PaymentView.filter = {
									...PaymentView.filter,
									[column.name]: dateRangeStr,
								};
							}
						}
					});
				}
			}

			if (isFilterChange === false) {
				if (tableRef.current) {
					if (isEmpty(filters) === false) {
						tableRef.current.resetFilters();
					}
				}
			}

			setLoading(true);
			const response = await PaymentService.view(PaymentView);
			const users = response?.data?.data?.query
				? response?.data?.data?.query
				: [];
			const rowCount = parseInt(response?.data?.data?.query?.length);
			const totalCount = response.data.total;
			const currentPage = parseInt(response.data.page);
			const dropdowns = response.data.dropdowns;

			// Set void user name dropdown if null
			dropdowns.void_user_name = dropdowns?.void_user_name
				? dropdowns?.void_user_name
				: [];

			setUsers(addSummaryObject(users, totalCount));
			setTotalCount(totalCount);
			setCurrentPage(currentPage);
			setTotalPages(totalPages);
			setRowCount(rowCount);
			setRowsPerPage(tableState.rowsPerPage);
			setDropdowns(dropdowns);
			setLoading(false);
		} catch (error) {
			console.error("Error fetching users:", error);
		}
	};

	useEffect(() => {
		const handleClickOutside = async (event) => {
			if (lastAction === "search") {
				// sendRequest(null, false, dateRangeCreated);
				sendRequest(tableState.filterList, true, dateRangeCreated);
				setLastAction("");
				// setFilters({});
			}
		};

		const handleEnterRelease = async (event) => {
			if (event.key === "Enter" && lastAction === "search") {
				// sendRequest(null, false, dateRangeCreated);
				sendRequest(tableState.filterList, true, dateRangeCreated);
				setLastAction("");
				// setFilters({});
			}
			if (event.key === "Tab" && lastAction === "search") {
				// sendRequest(null, false, dateRangeCreated);
				sendRequest(tableState.filterList, true, dateRangeCreated);
				setLastAction("");
				// setFilters({});
			}
		};

		// Add event listener
		document.addEventListener("click", handleClickOutside);
		document.addEventListener("keyup", handleEnterRelease);
		// Clean up
		return () => {
			document.removeEventListener("click", handleClickOutside);
			document.removeEventListener("keyup", handleEnterRelease);
		};
	}, [searchText, lastAction]);

	useEffect(() => {
		const handleClickOutside = async (event) => {
			if (lastAction === "filterChange") {
				// sendRequest(null, true, dateRangeCreated);
				sendRequest(tableState.filterList, true, dateRangeCreated);
				setLastAction("");
			}
		};

		const handleEnterRelease = async (event) => {
			if (event.key === "Enter" && lastAction === "filterChange") {
				// sendRequest(null, true, dateRangeCreated);
				sendRequest(tableState.filterList, true, dateRangeCreated);
				setLastAction("");
			}
			if (event.key === "Tab" && lastAction === "filterChange") {
				// sendRequest(null, true, dateRangeCreated);
				sendRequest(tableState.filterList, true, dateRangeCreated);
				setLastAction("");
			}
		};

		// Add event listener
		document.addEventListener("click", handleClickOutside);
		document.addEventListener("keyup", handleEnterRelease);
		// Clean up
		return () => {
			document.removeEventListener("click", handleClickOutside);
			document.removeEventListener("keyup", handleEnterRelease);
		};
	}, [filters, lastAction]);

	// let userHierarchy = SessionService.getUserHierarchy();
	// let isos = userHierarchy.iso_list ? userHierarchy.iso_list : {};
	// let masterAgents = userHierarchy.mas_list ? userHierarchy.mas_list : {};
	// let companies = userHierarchy.com_list ? userHierarchy.com_list : {};
	// let organizations = userHierarchy.org_list ? userHierarchy.org_list : {};
	// let stores = userHierarchy.sto_list ? userHierarchy.sto_list : {};

	//console.log("isos", isos);

	const handleTableChange = async (action, newTableState) => {
		if (JSON.stringify(tableState) !== JSON.stringify(newTableState)) {
			setTableState(newTableState);
		}

		if (action === "search") {
			setSearchText(newTableState.searchText);
			setLastAction(action);
		}

		if (action === "filterChange") {
			// Assuming filters are structured as an object with column names as keys
			setFilters(
				newTableState.filterList.reduce((acc, filter, index) => {
					acc[newTableState.columns[index].name] = filter;
					return acc;
				}, {})
			);
			setLastAction(action);
		}

		if (
			action === "changePage" ||
			action === "changeRowsPerPage" ||
			action === "sort" ||
			action === "resetFilters"
		) {
			try {
				const PaymentView = {
					page: newTableState.page + 1,
					limit: newTableState.rowsPerPage,
					payment_date: dateRangeCreated,
					filter: {},
					sort: sortList,
				};

				if (newTableState.sortOrder.hasOwnProperty("name")) {
					let sortOrderDirection = newTableState.sortOrder.direction
						? newTableState.sortOrder.direction
						: "asc";
					PaymentView.sort = `${newTableState.sortOrder.name}:${sortOrderDirection}`;
				} else {
					PaymentView.sort = "id:desc";
				}
				setSortList(PaymentView.sort);

				if (newTableState.searchText) {
					if (newTableState.searchText.length > 2) {
						PaymentView.filter = {
							...PaymentView.filter,
							all: newTableState.searchText,
							// payment_date: dateRangeCreated,
						};
					} else {
						return false;
					}
				}

				//Filter
				if (newTableState.filterList) {
					// Ensure both columns and tableState are provided and valid
					if (
						!columns ||
						!newTableState ||
						!newTableState.filterList
					) {
						console.error("Invalid columns or newTableState");
					} else {
						// Loop through each column and set the filter in PaymentView
						columns.forEach((column, index) => {
							const filterValue =
								newTableState.filterList[index][0];

							if (filterValue) {
								// console.log(column.name);
								if (dateRangeCreated) {
									if (column.name === "payment_date") {
										PaymentView.filter = {
											...PaymentView.filter,
											[column.name]: dateRangeCreated,
										};
									} else {
										PaymentView.filter = {
											...PaymentView.filter,
											[column.name]: filterValue,
										};
									}
								} else {
									PaymentView.filter = {
										...PaymentView.filter,
										[column.name]: filterValue,
									};
								}
							} else if (dateRangeCreated) {
								if (column.name === "payment_date") {
									PaymentView.filter = {
										...PaymentView.filter,
										[column.name]: dateRangeCreated,
									};
								}
							}
						});
					}

					setFilterList(PaymentView.filter);
				} else if (dateRangeCreated) {
					columns.forEach((column, index) => {
						// const filterValue = newFilterList[index][0];
						// console.log(column.name);
						if (dateRangeCreated) {
							if (column.name === "payment_date") {
								PaymentView.filter = {
									...PaymentView.filter,
									[column.name]: dateRangeCreated,
								};
							}
						}
					});
				}
				setLoading(true);
				const response = await PaymentService.view(PaymentView);

				const users = response?.data?.data?.query
					? response?.data?.data?.query
					: [];
				const rowCount = parseInt(response?.data?.data?.query?.length);
				const totalCount = response.data.total;
				const currentPage = parseInt(response.data.page);
				const dropdowns = response.data.dropdowns;

				// Set void user name dropdown if null
				dropdowns.void_user_name = dropdowns?.void_user_name
					? dropdowns?.void_user_name
					: [];

				setUsers(addSummaryObject(users, totalCount));
				setTotalCount(totalCount);
				setCurrentPage(currentPage);
				setTotalPages(totalPages);
				setRowCount(rowCount);
				setRowsPerPage(newTableState.rowsPerPage);
				setDropdowns(dropdowns);
				setLoading(false);
			} catch (error) {
				console.error("Error fetching users:", error);
			}
		}
	};

	let allowIsoAdmin =
		SessionService.canSystemAdmin() ||
		SessionService.canRtoAdmin() ||
		SessionService.canIsoAdmin();
	let allowMasterAgentAdmin =
		allowIsoAdmin || SessionService.canMasterAgentAdmin();
	let allowOrganizationAdmin =
		allowMasterAgentAdmin || SessionService.canOrganizationAdmin();
	let allowCompanyAdmin =
		allowOrganizationAdmin || SessionService.canCompanyAdmin();
	let allowStoreAdmin = allowCompanyAdmin || SessionService.canStoreAdmin();

	const columns = [
		{
			name: "struct_iso_name",
			label: "ISO",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: allowIsoAdmin === true ? true : false,
				display:
					allowIsoAdmin === true
						? isIsoColumnVisible
							? true
							: false
						: "excluded",
				filterType: "custom",
				filterOptions: {
					names: [], // You can provide filter options here if needed
					display: (filterList, onChange, index, column) => (
						<>
							<label
								className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 font-sans mb-1"
								style={{ fontWeight: 500 }}
							>
								ISO
							</label>
							<FormSelect
								name="struct_iso_id"
								onChange={(event) => {
									if (event.target.value === "") {
										// Clear the filter for this column if the default option is selected
										filterList[index] = [];
									} else {
										// Set the filter value to the selected option
										filterList[index][0] =
											event.target.value;
									}
									onChange(filterList[index], index, column);
								}}
								className="form-select form-select-sm font-sans"
								value={filterList[index][0] || ""}
							>
								<option value="">All</option>
								{dropdowns?.struct_iso_name?.map((dd) => (
									<option value={dd.name} key={dd.id}>
										{dd.name}
									</option>
								))}
							</FormSelect>
						</>
					),
				},
				customBodyRender: (value) => {
					return (
						<div className="whitespace-nowrap">
							{value ? value : ""}
						</div>
					);
				},
			},
		},
		{
			name: "struct_master_agent_name",
			label: "Master Agent",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: allowMasterAgentAdmin === true ? true : false,
				display:
					allowMasterAgentAdmin === true
						? isMasterAgentVisible
							? true
							: false
						: "excluded",
				filterType: "custom",
				filterOptions: {
					names: [], // You can provide filter options here if needed
					display: (filterList, onChange, index, column) => (
						<>
							<label
								className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 font-sans mb-1"
								style={{ fontWeight: 500 }}
							>
								Master Agent
							</label>
							<FormSelect
								name="struct_master_agent_name"
								onChange={(event) => {
									if (event.target.value === "") {
										// Clear the filter for this column if the default option is selected
										filterList[index] = [];
									} else {
										// Set the filter value to the selected option
										filterList[index][0] =
											event.target.value;
									}
									onChange(filterList[index], index, column);
								}}
								className="form-select form-select-sm font-sans"
								value={filterList[index][0] || ""}
							>
								<option value="">All</option>
								{dropdowns?.struct_master_agent_name?.map(
									(dd) => (
										<option value={dd.name} key={dd.id}>
											{dd.name}
										</option>
									)
								)}
							</FormSelect>
						</>
					),
				},
				customBodyRender: (value) => {
					return (
						<div className="whitespace-nowrap">
							{value ? value : ""}
						</div>
					);
				},
			},
		},
		{
			name: "struct_organization_name",
			label: "Organization",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: allowOrganizationAdmin === true ? true : false,
				display:
					allowOrganizationAdmin === true
						? isOrganizationVisible
							? true
							: false
						: "excluded",
				filterType: "custom",
				filterOptions: {
					names: [], // You can provide filter options here if needed
					display: (filterList, onChange, index, column) => (
						<>
							<label
								className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 font-sans mb-1"
								style={{ fontWeight: 500 }}
							>
								Organization
							</label>
							<FormSelect
								name="struct_organization_name"
								onChange={(event) => {
									if (event.target.value === "") {
										// Clear the filter for this column if the default option is selected
										filterList[index] = [];
									} else {
										// Set the filter value to the selected option
										filterList[index][0] =
											event.target.value;
									}
									onChange(filterList[index], index, column);
								}}
								className="form-select form-select-sm font-sans"
								value={filterList[index][0] || ""}
							>
								<option value="">All</option>
								{dropdowns?.struct_organization_name?.map(
									(dd) => (
										<option value={dd.name} key={dd.id}>
											{dd.name}
										</option>
									)
								)}
							</FormSelect>
						</>
					),
				},
				customBodyRender: (value) => {
					return (
						<div className="whitespace-nowrap">
							{value ? value : ""}
						</div>
					);
				},
			},
		},
		{
			name: "struct_company_name",
			label: "Company",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: allowCompanyAdmin === true ? true : false,
				display:
					allowCompanyAdmin === true
						? isCompanyNameVisible
							? true
							: false
						: "excluded",
				filterType: "custom",
				filterOptions: {
					names: [], // You can provide filter options here if needed
					display: (filterList, onChange, index, column) => (
						<>
							<label
								className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 font-sans mb-1"
								style={{ fontWeight: 500 }}
							>
								Company
							</label>
							<FormSelect
								name="struct_company_name"
								onChange={(event) => {
									if (event.target.value === "") {
										// Clear the filter for this column if the default option is selected
										filterList[index] = [];
									} else {
										// Set the filter value to the selected option
										filterList[index][0] =
											event.target.value;
									}
									onChange(filterList[index], index, column);
								}}
								className="form-select form-select-sm font-sans"
								value={filterList[index][0] || ""}
							>
								<option value="">All</option>
								{dropdowns?.struct_company_name?.map((dd) => (
									<option value={dd.name} key={dd.id}>
										{dd.name}
									</option>
								))}
							</FormSelect>
						</>
					),
				},
				customBodyRender: (value) => {
					return (
						<div className="whitespace-nowrap">
							{value ? value : ""}
						</div>
					);
				},
			},
		},
		{
			name: "struct_store_name",
			label: "Store",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: allowStoreAdmin === true ? true : false,
				display:
					allowStoreAdmin === true
						? isStoreNameVisible
							? true
							: false
						: "excluded",
				filterType: "custom",
				filterOptions: {
					names: [], // You can provide filter options here if needed
					display: (filterList, onChange, index, column) => (
						<>
							<label
								className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 font-sans mb-1"
								style={{ fontWeight: 500 }}
							>
								Store
							</label>
							<FormSelect
								name="struct_store_name"
								onChange={(event) => {
									if (event.target.value === "") {
										// Clear the filter for this column if the default option is selected
										filterList[index] = [];
									} else {
										// Set the filter value to the selected option
										filterList[index][0] =
											event.target.value;
									}
									onChange(filterList[index], index, column);
								}}
								className="form-select form-select-sm font-sans"
								value={filterList[index][0] || ""}
							>
								<option value="">All</option>
								{dropdowns?.struct_store_name?.map((dd) => (
									<option value={dd.name} key={dd.id}>
										{dd.name}
									</option>
								))}
							</FormSelect>
						</>
					),
				},
				customBodyRender: (value) => {
					return (
						<div className="whitespace-nowrap">
							{value ? value : ""}
						</div>
					);
				},
			},
		},
		{
			name: "contract_number",
			label: "Contract Number",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: true,
				customBodyRender: (value, tableMeta) => {
					return (
						<a
							href={`/contracts/${redirectUrl}/${tableMeta.rowData[5]}`}
						>
							{value}
						</a>
					);
				},
			},
		},
		{
			name: "payment_date",
			label: "Time Stamp",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: false, // Enable filtering for this column
				filterType: "custom", // Set filter type as custom
				filterOptions: {
					names: [], // You can provide filter options here if needed
					logic(payment_date, filters) {
						if (typeof filters !== "string") return false;
						let [startDate, endDate] = filters.split(" - ");
						if (!endDate) endDate = startDate;
						const createdDate = new Date(payment_date);
						startDate = new Date(startDate);
						endDate = new Date(endDate);
						return (
							createdDate >= startDate && createdDate <= endDate
						);
					},
					display: (filterList, onChange, index, column) => (
						<DateRangePicker
							onUpdate={(values) => {
								const formatDate = (date) => {
									if (!date) return ""; // return empty string if date is not valid
									const d = new Date(date);
									const month = d.getMonth() + 1; // getMonth() is zero-based
									const day = d.getDate();
									const year = d.getFullYear();
									return `${month}/${day}/${year}`;
								};

								const startDate = formatDate(values.range.from);
								const endDate = formatDate(values.range.to);
								const dateRangeStr = !endDate
									? startDate // if endDate is empty, just use startDate
									: startDate === endDate
									? startDate // if they are the same, use startDate
									: startDate + " - " + endDate; // otherwise, use both
								setDateRangeCreated(dateRangeStr);
								filterList[index][0] = dateRangeStr;
								onChange(filterList[index], index, column);
							}}
							label="Payment date"
							initialDateFrom={
								filterList[index][0]
									? filterList[index][0].split(" - ")[0]
									: ""
							}
							initialDateTo={
								filterList[index][0]
									? filterList[index][0].split(" - ")[1]
									: ""
							}
							align="end"
							locale="en-GB"
							showCompare={false}
						/>
					),
				},
				customBodyRender: (value) => {
					const date = new Date(value);
					const formattedDate = date.toLocaleDateString("en-US", {
						year: "numeric",
						month: "numeric",
						day: "numeric",
					});
					return (
						<div className="whitespace-nowrap">
							{value ? formattedDate : ""}
						</div>
					);
				},
			},
		},
		{
			name: "customer_name",
			label: "Customer Name",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				setCellProps: getCellProps,
				filter: true,
			},
		},
		{
			name: "customer_address",
			label: "Address",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				setCellProps: getCellProps,
				filter: true,
				display: isAddressVisible ? true : false,
			},
		},
		{
			name: "customer_city",
			label: "City",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: true,
				display: isCityVisible,
			},
		},
		{
			name: "customer_state",
			label: "State",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: true,
				display: isStateVisible,
			},
		},
		{
			name: "customer_zip",
			label: "Zip",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: true,
				display: isZipVisible,
			},
		},
		{
			name: "payment_type_name",
			label: "Payment Type",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: true,
				filterType: "custom",
				filterOptions: {
					names: [], // You can provide filter options here if needed
					display: (filterList, onChange, index, column) => (
						<>
							<label
								className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 font-sans mb-1"
								style={{ fontWeight: 500 }}
							>
								Payment Type
							</label>
							<FormSelect
								name="struct_store_name"
								onChange={(event) => {
									if (event.target.value === "") {
										// Clear the filter for this column if the default option is selected
										filterList[index] = [];
									} else {
										// Set the filter value to the selected option
										filterList[index][0] =
											event.target.value;
									}
									onChange(filterList[index], index, column);
								}}
								className="form-select form-select-sm font-sans"
								value={filterList[index][0] || ""}
							>
								<option value="">All</option>
								{dropdowns?.payment_type_name?.map((dd) => (
									<option value={dd.name} key={dd.id}>
										{dd.name}
									</option>
								))}
							</FormSelect>
						</>
					),
				},
				customBodyRender: (value) => {
					return (
						<div className="whitespace-nowrap">
							{value ? value : ""}
						</div>
					);
				},
			},
		},
		{
			name: "account_last_4",
			label: "Last 4 of Credit Card",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: true,
			},
		},
		{
			name: "payment_status_name",
			label: "Status",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: true,
				filterType: "custom",
				filterOptions: {
					names: [], // You can provide filter options here if needed
					display: (filterList, onChange, index, column) => (
						<>
							<label
								className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 font-sans mb-1"
								style={{ fontWeight: 500 }}
							>
								Payment Status
							</label>
							<FormSelect
								name="payment_status_name"
								onChange={(event) => {
									if (event.target.value === "") {
										// Clear the filter for this column if the default option is selected
										filterList[index] = [];
									} else {
										// Set the filter value to the selected option
										filterList[index][0] =
											event.target.value;
									}
									onChange(filterList[index], index, column);
								}}
								className="form-select form-select-sm font-sans"
								value={filterList[index][0] || ""}
							>
								<option value="">All</option>
								{dropdowns?.payment_status_name?.map((dd) => (
									<option value={dd.name} key={dd.id}>
										{dd.name}
									</option>
								))}
							</FormSelect>
						</>
					),
				},
				customBodyRender: (value) => {
					return (
						<div className="whitespace-nowrap">
							{value ? value : ""}
						</div>
					);
				},
			},
		},
		{
			name: "resp_text",
			label: "Reason Desc",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: true,
				customBodyRender: customNameRender,
			},
		},
		{
			name: "payment_amount",
			label: "Amount",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: true,
				customBodyRender: (value, tableMeta) => {
					if (tableMeta.rowIndex === users.length - 1) {
						return (
							<strong>
								<div
									style={{
										display: "flex",
										justifyContent: "right",
									}}
								>
									<NumericFormat
										value={value}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
										decimalScale={2}
										fixedDecimalScale={true}
									/>
								</div>
							</strong>
						);
					}
					return (
						<div
							style={{ display: "flex", justifyContent: "right" }}
						>
							<NumericFormat
								value={value}
								displayType={"text"}
								thousandSeparator={true}
								prefix={"$"}
								decimalScale={2}
								fixedDecimalScale={true}
							/>
						</div>
					);
				},
			},
		},
		{
			name: "payment_tax",
			label: "Sales Tax",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: true,
				customBodyRender: (value, tableMeta) => {
					if (tableMeta.rowIndex === users.length - 1) {
						return (
							<strong>
								<div
									style={{
										display: "flex",
										justifyContent: "right",
									}}
								>
									<NumericFormat
										value={value}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
										decimalScale={2}
										fixedDecimalScale={true}
									/>
								</div>
							</strong>
						);
					}
					return (
						<div
							style={{ display: "flex", justifyContent: "right" }}
						>
							<NumericFormat
								value={value}
								displayType={"text"}
								thousandSeparator={true}
								prefix={"$"}
								decimalScale={2}
								fixedDecimalScale={true}
							/>
						</div>
					);
				},
			},
		},
		{
			name: "dealer_fee",
			label: "Fee",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: true,
				customBodyRender: (value, tableMeta) => {
					if (tableMeta.rowIndex === users.length - 1) {
						return (
							<strong>
								<div
									style={{
										display: "flex",
										justifyContent: "right",
									}}
								>
									<NumericFormat
										value={value}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
										decimalScale={2}
										fixedDecimalScale={true}
									/>
								</div>
							</strong>
						);
					}
					return (
						<div
							style={{ display: "flex", justifyContent: "right" }}
						>
							<NumericFormat
								value={value}
								displayType={"text"}
								thousandSeparator={true}
								prefix={"$"}
								decimalScale={2}
								fixedDecimalScale={true}
							/>
						</div>
					);
				},
			},
		},
		{
			name: "auth_total",
			label: "Total Payment Auth",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: true,
				customBodyRender: (value, tableMeta) => {
					if (tableMeta.rowIndex === users.length - 1) {
						return (
							<strong>
								<div
									style={{
										display: "flex",
										justifyContent: "right",
									}}
								>
									<NumericFormat
										value={value}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
										decimalScale={2}
										fixedDecimalScale={true}
									/>
								</div>
							</strong>
						);
					}
					return (
						<div
							style={{ display: "flex", justifyContent: "right" }}
						>
							<NumericFormat
								value={value}
								displayType={"text"}
								thousandSeparator={true}
								prefix={"$"}
								decimalScale={2}
								fixedDecimalScale={true}
							/>
						</div>
					);
				},
			},
		},
		{
			name: "total",
			label: "Total Payment Received",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: true,
				customBodyRender: (value, tableMeta) => {
					if (tableMeta.rowIndex === users.length - 1) {
						return (
							<strong>
								<div
									style={{
										display: "flex",
										justifyContent: "right",
									}}
								>
									<NumericFormat
										value={value}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
										decimalScale={2}
										fixedDecimalScale={true}
									/>
								</div>
							</strong>
						);
					}
					return (
						<div
							style={{ display: "flex", justifyContent: "right" }}
						>
							<NumericFormat
								value={value}
								displayType={"text"}
								thousandSeparator={true}
								prefix={"$"}
								decimalScale={2}
								fixedDecimalScale={true}
							/>
						</div>
					);
				},
			},
		},
		{
			name: "ret_ref",
			label: "RetRef",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: true,
			},
		},
		{
			name: "batch_id",
			label: "CardConnect Batch ID",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: true,
			},
		},
		{
			name: "processed_user_name",
			label: "Processed By",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: true,
				filterType: "custom",
				filterOptions: {
					names: [], // You can provide filter options here if needed
					display: (filterList, onChange, index, column) => (
						<>
							<label
								className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 font-sans mb-1"
								style={{ fontWeight: 500 }}
							>
								Processed By
							</label>
							<FormSelect
								name="processed_user_name"
								onChange={(event) => {
									if (event.target.value === "") {
										// Clear the filter for this column if the default option is selected
										filterList[index] = [];
									} else {
										// Set the filter value to the selected option
										filterList[index][0] =
											event.target.value;
									}
									onChange(filterList[index], index, column);
								}}
								className="form-select form-select-sm font-sans"
								value={filterList[index][0] || ""}
							>
								<option value="">All</option>
								{dropdowns?.processed_user_name?.map((dd) => (
									<option value={dd.name} key={dd.id}>
										{dd.name}
									</option>
								))}
							</FormSelect>
						</>
					),
				},
				customBodyRender: (value) => {
					return (
						<div className="whitespace-nowrap">
							{value ? value : ""}
						</div>
					);
				},
			},
		},
		{
			name: "void_user_name",
			label: "Voided By",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: true,
				filterType: "custom",
				filterOptions: {
					names: [], // You can provide filter options here if needed
					display: (filterList, onChange, index, column) => (
						<>
							<label
								className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 font-sans mb-1"
								style={{ fontWeight: 500 }}
							>
								Voided By
							</label>
							<FormSelect
								name="void_user_name"
								onChange={(event) => {
									if (event.target.value === "") {
										// Clear the filter for this column if the default option is selected
										filterList[index] = [];
									} else {
										// Set the filter value to the selected option
										filterList[index][0] =
											event.target.value;
									}
									onChange(filterList[index], index, column);
								}}
								className="form-select form-select-sm font-sans"
								value={filterList[index][0] || ""}
							>
								<option value="">All</option>
								{dropdowns?.void_user_name?.map((dd) => (
									<option value={dd.name} key={dd.id}>
										{dd.name}
									</option>
								))}
							</FormSelect>
						</>
					),
				},
				customBodyRender: (value) => {
					return (
						<div className="whitespace-nowrap">
							{value ? value : ""}
						</div>
					);
				},
			},
		},
	];

	const options = {
		customToolbar: () => {
			<p>I'm a toolbar</p>;
		},
		filter: true,
		filterType: "textField",
		searchText: searchText,
		onFilterChipClose: (removedFilterIndex, value, filterList) => {
			sendRequest(filterList, true, dateRangeCreated);
			setLastAction("");
		},
		onSearchOpen: () => setSearchOpen(true),
		onSearchClose: () => setSearchOpen(false),
		setRowProps: getRowProps,
		onViewColumnsChange: (changedColumn, action) => {
			if (changedColumn === "struct_iso_name") {
				setIsIsoColumnVisible(action === "add");
			} else if (changedColumn === "struct_master_agent_name") {
				setIsMasterAgentVisible(action === "add");
			} else if (changedColumn === "struct_organization_name") {
				setIsOrganizationVisible(action === "add");
			} else if (changedColumn === "struct_company_name") {
				setIsCompanyNameVisible(action === "add");
			} else if (changedColumn === "struct_store_name") {
				setIsStoreNameVisible(action === "add");
			} else if (changedColumn === "customer_zip") {
				setIsZipVisible(action === "add");
			} else if (changedColumn === "customer_state") {
				setIsStateVisible(action === "add");
			} else if (changedColumn === "customer_city") {
				setIsCityVisible(action === "add");
			} else if (changedColumn === "customer_address") {
				setIsAddressVisible(action === "add");
			}
		},
		serverSide: true,
		count: totalCount?.count,
		page: currentPage - 1,
		rowsPerPage: rowsPerPage,
		rowsPerPageOptions: [
			10,
			25,
			50,
			100,
			{ label: "All", value: totalCount?.count },
		],
		elevation: 0,
		responsive: "standard",
		fixedHeader: true,
		fixedFooter: true,
		// tableBodyHeight: "calc(100vh - 150px)",
		// customTableStyle: {
		//     maxHeight: "calc(100vh - 150px)",
		// },
		selectableRows: "none",
		onTableChange: handleTableChange,
		onDownload: (buildHead, buildBody, columns, data) => {
			// Fetch all data that matches the applied filters
			const fetchAllData = async () => {
				// Use the same parameters as in the handleTableChange function
				const PaymentView = {
					page: 1,
					limit: totalCount?.count,
					payment_date: dateRangeCreated,
					filter: filterList,
					sort: sortList,
				};

				// Add filters based on the current table state
				if (tableState.filterList) {
					// Ensure both columns and tableState are provided and valid
					if (!columns || !tableState || !tableState.filterList) {
						console.error("Invalid columns or newTableState");
					} else {
						// Loop through each column and set the filter in PaymentView
						columns.forEach((column, index) => {
							const filterValue = tableState.filterList[index][0];

							if (filterValue) {
								PaymentView.filter[column.name] = filterValue;
							}
						});
					}
				}

				const response = await PaymentService.view(PaymentView);
				return response.data.data.query;
			};

			fetchAllData().then((allData) => {
				// Check if allData is defined
				if (!allData) {
					console.error("allData is undefined");
					return false;
				}

				// get checked columns
				const checkedColumns = tableState.columns.filter(
					(column) => column.display === "true"
				);

				// Transform the checked columns to the format required by buildHead
				const transformedColumns = checkedColumns.map((column) => ({
					...column,
				}));

				// remove first column, null empty
				// transformedColumns.shift();

				// Check if there are no columns selected
				if (transformedColumns.length === 0) {
					// Optionally display a message to the user
					toast.error("No columns selected for download", {
						position: "top-center",
						autoClose: 2000,
					});
					return false;
				}

				// Transform data to the required format
				const transformedData = allData
					.map((item, index) => {
						if (item) {
							return {
								index: index,
								data: transformedColumns.map(
									(column) => item[column.name]
								),
							};
						}
						return null;
					})
					.filter((item) => item !== null);

				// Generate CSV content
				const csvContent =
					buildHead(transformedColumns) + buildBody(transformedData);

				var fileDownload = require("js-file-download");
				fileDownload(csvContent, "export.csv", "text/csv");
			});

			// Return false to prevent the default CSV export
			return false;
		},
	};

	const CustomTitle = () => (
		<Box style={{ marginBottom: "10px" }}>
			<Typography variant="h6">Payments Report</Typography>
			<Typography
				variant="subtitle1"
				style={{
					marginTop: "5px",
					fontWeight: "500",
					fontSize: "14px",
					fontFamily:
						'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
				}}
			>
				Payment Date
			</Typography>
			<DateRangePicker
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "start",
					alignItems: "start",
				}}
				onUpdate={(values) => {
					const formatDate = (date) => {
						if (!date) return ""; // return empty string if date is not valid
						const d = new Date(date);
						const month = d.getMonth() + 1; // getMonth() is zero-based
						const day = d.getDate();
						const year = d.getFullYear();
						return `${month}/${day}/${year}`;
					};

					const startDate = formatDate(values.range.from);
					const endDate = formatDate(values.range.to);
					const dateRangeStr = !endDate
						? startDate // if endDate is empty, just use startDate
						: startDate === endDate
						? startDate // if they are the same, use startDate
						: startDate + " - " + endDate; // otherwise, use both

					setDateRangeCreated(dateRangeStr);

					sendRequest(null, true, dateRangeStr);

					const currentFilters = tableRef.current.state.filterList;
					const updatedFilters = [...currentFilters];
					updatedFilters[5] = [];
					tableRef.current.setState({
						...tableRef.current.state,
						filterList: updatedFilters,
					});

					// filterList[index][0] = dateRangeStr;
					// onChange(filterList[index], index, column);
				}}
				// label="Payment Date Range"
				// align="end"
				initialDateFrom={
					dateRangeCreated ? dateRangeCreated.split(" - ")[0] : ""
				}
				initialDateTo={
					dateRangeCreated ? dateRangeCreated.split(" - ")[1] : ""
				}
				locale="en-GB"
				showCompare={false}
			/>
		</Box>
	);

	return (
		<div>
			<ThemeProvider theme={theme}>
				{searchOpen && <CustomTitle />}
				<MUIDataTable
					// title={<CustomTitle />}
					title={!searchOpen ? <CustomTitle /> : ""}
					data={users}
					columns={columns}
					options={options}
					ref={tableRef}
				/>
			</ThemeProvider>
		</div>
	);
};

export default PaymentsTable;
