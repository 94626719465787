import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Row, Col } from "react-bootstrap";
import { TextField } from "@mui/material";
// import ReCAPTCHA from "react-google-recaptcha";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import LoginService from "../../service/auth/LoginService";
import SessionService from "../../service/SessionService";

const schema = z.object({
	username: z
		.string()
		.trim()
		.nonempty({ message: "Username cannot be blank!" })
		.max(100, {
			message: "Username cannot be longer than 100 characters!",
		}),
	password: z
		.string()
		.trim()
		.nonempty({ message: "Password cannot be blank!" })
		.max(100, {
			message: "Password cannot be longer than 100 characters!",
		}),
	// recaptcha: z
	//     .string({
	//         invalid_type_error: "Please complete the ReCAPTCHA!",
	//     })
	//     .trim()
	//     .nonempty({ message: "Please complete the ReCAPTCHA!" }),
});

function LoginForm({ loading, setLoading }) {
	//const navigate = useNavigate();

	const {
		register,
		//control,
		handleSubmit,
		formState: { errors },
		//getValues,
		//setValue,
		setError,
		//clearErrors,
		watch,
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			username: "",
			password: "",
			// recaptcha: "",
		},
		resolver: zodResolver(schema),
	});

	const { username, password } = watch(["username", "password"]);

	// useEffect(() => {
	//     // clear the api error when the user updates the form
	//     console.log("clearing api error");
	//     clearErrors("api");
	// }, [username, password, clearErrors]);
	useEffect(() => {
		//console.log("username:", username);
		//console.log("password:", password);
		// ...
	}, [username, password]);

	//const recaptchaRef = useRef();

	const onSubmit = async (data) => {
		// Set loading state
		setLoading(true);

		const loginData = {
			username: data.username,
			password: data.password,
			// recaptcha: data.recaptcha,
		};

		/* const response = await axios
            .post( process.env.REACT_APP_API_BASE_URL + "/login", loginData, {
                headers: {
                    "Content-Type": "application/json",
                },
            }) */
		// const response = LoginService.login(loginData)
		LoginService.login(loginData)
			.then((response) => {
				if (response.status === 200 && response.data.isOk === true) {
					SessionService.setJWTToken(
						response.headers.get("X-Jwt-Token")
					);
					SessionService.setUserInfo(response.data.user);
					SessionService.setUserRoles(response.data.user);
					SessionService.setUserRoutes(response.data.userRoutes);
					SessionService.setUserHierarchy(
						response.data.userHierarchy
					);
					SessionService.setUserStore(response.data.user.store.id);
					SessionService.setStoreReportFlags(
						response.data.storeReportFlags
					);
					//console.log(response.data.storeReportFlags);
					// sessionStorage.setItem(
					//     "token",
					//     response.headers.get("X-Jwt-Token")
					// );
					// sessionStorage.setItem(
					//     "userInfo",
					//     JSON.stringify(response.data.user)
					// );
					//navigate("/dashboard");
					let referralUrl = localStorage.getItem("referralUrl");
					localStorage.removeItem("referralUrl");
					// navigate(referralUrl ? referralUrl : "/dashboard");
					window.location.href = referralUrl
						? referralUrl
						: "/dashboard";
				} else {
					// console.log("failed");
					// console.log(response.data.message);
					setError("api", {
						type: "server",
						message: response.data.message,
					});
					setError("username", {
						type: "server",
						message: "",
					});
					setError("password", {
						type: "server",
						message: "",
					});
				}

				// Set loading state
				setLoading(false);
			})
			.catch((error) => {
				// console.log("error");
				// console.log(error);
				setError("api", {
					type: "server",
					message: error.message,
				});
				setError("username", {
					type: "server",
					message: "",
				});
				setError("password", {
					type: "server",
					message: "",
				});
			});
	};

	return (
		<>
			<form
				id="login_form"
				name="login-form"
				onSubmit={handleSubmit(onSubmit)}
			>
				<Row>
					<Col lg style={{ marginBottom: "2rem" }}>
						<TextField
							type="text"
							variant="standard"
							label="Username / Email address"
							id="username"
							name="username"
							fullWidth
							InputLabelProps={{
								shrink: true,
								className:
									"Mui-form-label Mui-form-label-required",
							}}
							InputProps={{
								className: "Mui-form-input",
							}}
							inputProps={{
								maxLength: 100,
							}}
							{...register("username")}
							error={!!errors.username}
							helperText={errors.username?.message}
							autoFocus
						/>
					</Col>
				</Row>
				<Row>
					<Col lg style={{ marginBottom: "2rem" }}>
						<TextField
							type="password"
							variant="standard"
							label="Password"
							id="password"
							name="password"
							fullWidth
							InputLabelProps={{
								shrink: true,
								className:
									"Mui-form-label Mui-form-label-required",
							}}
							InputProps={{
								className: "Mui-form-input",
							}}
							inputProps={{
								maxLength: 100,
							}}
							{...register("password")}
							error={!!errors.password}
							helperText={errors.password?.message}
						/>
						{(!errors.username || !errors.username.message) &&
							(!errors.password || !errors.password.message) &&
							errors.api && (
								<p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium Mui-required">
									{errors.api.message}
								</p>
							)}
					</Col>
				</Row>
				{/* <Row>
                    <Col lg style={{ marginBottom: "2rem" }}>
                        <Controller
                            name="recaptcha"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "Please complete the ReCAPTCHA!",
                            }}
                            render={({ field }) => (
                                <ReCAPTCHA
                                    {...field}
                                    ref={recaptchaRef}
                                    sitekey={
                                        process.env
                                            .REACT_APP_G_RECAPTCHA_SITEKEY
									}
                                    id="recaptcha"
                                    onChange={(value) => {
                                        field.onChange(value); // updates react-hook-form
                                    }}
                                    className="g-recaptcha"
                                />
                            )}
                        />
                        {errors.recaptcha && (
                            <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium Mui-required">
                                {errors.recaptcha.message}
                            </p>
                        )}
                    </Col>
                </Row> */}
				<div className="rto_login-button-div">
					<button
						type="submit"
						className="rto_login-bttn rto_half-bttn w-button"
						name="login-button"
						disabled={loading}
					>
						Login
					</button>{" "}
					<div className="rto_forgot-pass">
						<div>
							<Link
								to="/forgot-password"
								className="rto_blue-link"
							>
								Forgot your password?
							</Link>
						</div>
					</div>
				</div>
			</form>
			{/* <DevTool control={control} /> */}
		</>
	);
}

export default LoginForm;
