import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html
 */

const IsoService = {
    /**
     *
     * @param {IsoView} IsoView
     * @returns
     */
    view: (IsoView) => {
        return ApiService.post("/iso/index", IsoView).catch((error) => {
            console.log(error);
            return error;
        });
    },

    /**
     * ISO data filter by ID
     * @param {int} id
     * @returns
     */
    fetchById: (id) => {
        return ApiService.get("/iso/fetch-by-id", { id: id }).catch((error) => {
            console.log(error);
        });
    },

    /**
     *
     * @param {IsoCreate} IsoCreate
     * @returns
     */
    create: (IsoCreate) => {
        return ApiService.post("/iso/create", IsoCreate).catch((error) => {
            console.log(error);
        });
    },

    /**
     * Update service
     * @param json data {isoId : 1, name: 'xxxx', address_1: 'ADD1' ...}
     * @returns
     */
    update: (data) => {
        return ApiService.post("/iso/update", data).catch((error) => {
            console.log(error);
        });
    },

    /**
     *
     * @param {Integer} isoId
     * @returns
     */
    delete: (isoId) => {
        return ApiService.post("/iso/delete", { isoId: isoId }).catch(
            (error) => {
                console.log(error);
            }
        );
    },
};

export default IsoService;
