import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import ApiService from "../../../../service/ApiService";

/**
 * "routing_number" => [
						"type" => "bank-name-lookup",
						"label" => "Routing Number",
						"value" => "",
						"required" => true,
						"readOnly" => false,
						"style" => [
							"textTransform" => "uppercase"
						]
					],
 */
const CustomBankNameLookupComponent = ({
	name,
	label,
	type,
	value,
	onChange,
	onBlur,
	className,
	targetBankName,
	...otherProps
}) => {
	const [internalValue, setInternalValue] = useState(value);
	const [isValid, setIsValid] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [isTouched, setIsTouched] = useState(false);
	const [bankName, setBankName] = useState("");

	useEffect(() => {
		//validate();
		setInternalValue(value);
	}, [value, internalValue, isTouched]);

	const handleChange = (newValue) => {
		// Filter out non-numeric characters
		const numericValue = newValue.replace(/[^0-9]/g, "");
		setInternalValue(numericValue);
		onChange(name, numericValue, isValid);
	};

	const handleBlur = () => {
		setIsTouched(true);
		validate();
	};

	const validate = async () => {
		const fieldInfo = getFieldInfo();
		let trimmedValue = internalValue.trim();

		// check readonly
		if (fieldInfo.readOnly) {
			setIsValid(true);
			setErrorMessage("");
			onChange(name, trimmedValue, true);

			if (targetBankName) {
				onChange(targetBankName, "", true);
			}
			return;
		}

		// Check required
		if (fieldInfo.required && trimmedValue === "") {
			setIsValid(false);
			setErrorMessage("This field is required.");
			onChange(name, trimmedValue, false);

			if (targetBankName) {
				onChange(targetBankName, "", true);
			}
			return;
		}

		let checksum =
			(3 *
				(parseInt(trimmedValue.charAt(0), 10) +
					parseInt(trimmedValue.charAt(3), 10) +
					parseInt(trimmedValue.charAt(6), 10)) +
				7 *
					(parseInt(trimmedValue.charAt(1), 10) +
						parseInt(trimmedValue.charAt(4), 10) +
						parseInt(trimmedValue.charAt(7), 10)) +
				1 *
					(parseInt(trimmedValue.charAt(2), 10) +
						parseInt(trimmedValue.charAt(5), 10) +
						parseInt(trimmedValue.charAt(8), 10))) %
			10;

		// Routing number validation
		if (fieldInfo.required && trimmedValue.length !== 9 && checksum !== 0) {
			setIsValid(false);
			setErrorMessage("Invalid Routing Number.");
			onChange(name, trimmedValue, false);

			if (targetBankName) {
				onChange(targetBankName, "", true);
			}
			return;
		}

		if (!fieldInfo.readOnly && trimmedValue.length === 9 && isTouched) {
			try {
				const bankInfo = await getBankInfo(trimmedValue);
				if (bankInfo.isValid) {
					setBankName(bankInfo.bankName);
					console.log("Bank Name:", bankName);

					if (targetBankName) {
						onChange(targetBankName, bankInfo.bankName, true);
					}
				} else {
					setIsValid(false);
					setErrorMessage("Invalid Routing Number.");

					if (targetBankName) {
						onChange(targetBankName, "", true);
					}
					onChange(name, trimmedValue, false);
					return;
				}
			} catch (error) {
				console.error("Error validating Routing Number:", error);
			}
		} else if (!fieldInfo.readOnly) {
			if (targetBankName) {
				onChange(targetBankName, "", true);
			}
		}
		setIsValid(true);
		setErrorMessage("");
		onChange(name, trimmedValue, true);
	};

	const getBankInfo = async (routingNumber) => {
		try {
			const response = await ApiService.get(
				"/third-party/bank-routing-number-lookup",
				{
					routingNumber: routingNumber,
				}
			);

			if (!response.data.isOk) {
				return { isValid: false, bankName: "" };
			}

			if (response.data && response.data?.data?.customer_name) {
				return {
					isValid: true,
					bankName: response.data.data.customer_name,
				};
			} else {
				return { isValid: false, bankName: "" };
			}
		} catch (error) {
			console.error("Error validating Routing Number:", error);
			return { isValid: false, bankName: "" };
		}
	};

	const handleFocus = () => {
		setIsTouched(true);
		validate();
	};

	const getFieldInfo = () => {
		return {
			required: Boolean(type && type.required),
			readOnly: type && type.readOnly,
			upperCase: type && type.upperCase,
		};
	};

	return (
		<div className={`${className}`}>
			<TextField
				id={name}
				label={`${label}`}
				variant="standard"
				size="small"
				fullWidth
				type={type && type.type}
				value={internalValue}
				onChange={(e) => handleChange(e.target.value)}
				onBlur={handleBlur}
				onFocus={handleFocus}
				error={!isValid && isTouched}
				helperText={!isValid && isTouched && errorMessage}
				InputProps={{
					readOnly: getFieldInfo().readOnly,
				}}
				{...otherProps}
			/>
		</div>
	);
};

export default CustomBankNameLookupComponent;
