import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Box } from "@mui/material";
// import VendorManufacturersTable from "../../components/backend/vendorManufacturers/VendorManufacturersTable";
import VendorManufacturersService from "../../service/vendor/VendorManufacturersService";
import IndexTable from "../../components/common/IndexTable";

function VendorManufacturers({ loading, setLoading }) {
	const pageTitle = "Vendor Manufacturers | RTO Mobile";

	return (
		<>
			<Helmet>
				<title>{pageTitle}</title>
				<meta name="robots" content="noindex,nofollow"></meta>
				<meta property="og:title" content={pageTitle} />
				<meta name="twitter:title" content={pageTitle} />
			</Helmet>
			<div className="rto_form-sections">
				<div className="rto_content-div" style={{ maxWidth: "1600px" }}>
					<Box
						display="flex"
						justifyContent="flex-end"
						backgroundColor="#f5f5f5"
						p={(0.5, 1)}
						borderRadius={1}
					>
						<Breadcrumbs aria-label="breadcrumb">
							<Link to="/dashboard" className="rto_link-2">
								Dashboard
							</Link>
							<Link to="/admin" className="rto_link-2">
								Admin
							</Link>
							<Typography>Vendor Manufacturers</Typography>
						</Breadcrumbs>
					</Box>
					<div className="rto_title-div">
						<h1 className="rto_page-title">Vendor Manufacturers</h1>
					</div>
					<p className="text-center">
						<a
							href="/admin/vendors-manufacturers/create"
							className="btn btn-primary"
						>
							Create
						</a>
					</p>
					<div
						className="card card-outline card-primary"
						style={{
							borderTop: "3px solid #00aaff",
							boxShadow:
								"0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
						}}
					>
						<div className="card-body">
							{/* <VendorManufacturersTable
								loading={loading}
								setLoading={setLoading}
							/> */}
							<IndexTable
								loading={loading}
								setLoading={setLoading}
								service={VendorManufacturersService}
								title="Vendor Manufacturers"
								actionButtons={{
									viewLink: {
										path: "/vendors-manufacturers",
									},
									updateLink: {
										path: "/vendors-manufacturers/update",
									},
									delete: true,
								}}
								labels={[
									{
										id: "id",
										label: "ID",
									},
									{
										id: "name",
										label: "Name",
									},
									{
										id: "created_at",
										label: "Created At",
									},
									{
										id: "user_name",
										label: "Created By",
									},
								]}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default VendorManufacturers;
