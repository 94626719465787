import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html
 */

const ContactService = {
	/**
	 * View
	 * @param array data pass serach filter values if needed
	 * @see /docs/api/index.html#jump-Contact-Fetch
	 * @returns
	 */
	view: (data) => {
		return ApiService.post("/contact/fetch", data).catch((error) => {
			console.log(error);
			return error;
		});
	},
	/**
	 * @deprecated
	 * @param int modelId
	 * @param string modelName
	 * @see /docs/api/index.html#jump-Contact-FetchByModel
	 * @returns JSON
	 */
	/*fetchByIdModel: (data) => {
        return ApiService.post("/contact/fetch-by-model", data).catch((error) => {
            console.log(error);
        });
    },*/

	/**
	 * filter by ID
	 * @param {int} id
	 * @see /docs/api/index.html#jump-Contact-Fetch
	 * @returns
	 */
	fetchById: (id) => {
		return ApiService.get("/contact/fetch-by-id", { id: id }).catch(
			(error) => {
				console.log(error);
			}
		);
	},

	/**
	 *
	 * @param {ContactCreate} ContactCreate
	 * @see /docs/api/index.html#jump-Contact-Create
	 * @returns
	 */
	create: (ContactCreate) => {
		return ApiService.post("/contact/create", ContactCreate).catch(
			(error) => {
				console.log(error);
			}
		);
	},

	/**
	 * Update service
	 * @param json data {contactId : 1, first_name: 'FName' ...}
	 * @see /docs/api/index.html#jump-Contact-Update
	 * @returns
	 */
	update: (data) => {
		return ApiService.post("/contact/update", data).catch((error) => {
			console.log(error);
		});
	},

	/**
	 * 
	 * @param {*} data
	 * @see /docs/api/index.html#jump-Contact-Delete
	 * @returns
	 */
	delete: (data) => {
		return ApiService.post("/contact/delete", {data : {id : data}}).catch((error) => {
			console.log(error);
		});
	},

	/**
	 * Get list of points of contact
	 * @see /docs/api/index.html#jump-Contact-PointsOfContact
	 * @returns JSON
	 */
	getPointsOfContact: () => {
		return ApiService.get("/contact/points-of-contact").catch((error) => {
			console.log(error);
		});
	},

	/**
	 * Get POCs associated with contacts table
	 * @see /docs/api/index.html#jump-Contact-PointsOfContact
	 * @returns JSON
	 */
	getAssignedPointsOfContact: (modelId, modelName) => {
		return ApiService.get("/contact/get-assigned-points-of-contact", {
			modelId: modelId,
			modelName: modelName,
		}).catch((error) => {
			console.log(error);
		});
	},
};

export default ContactService;
