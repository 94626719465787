import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import ChangePasswordService from "../../service/auth/ChangePassword";
// import { useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";

const passwordSchema = z
	.object({
		oldPassword: z.string().nonempty("Old Password cannot be blank."),
		newPassword: z.string().nonempty("New Password cannot be blank."),
		repeatNewPassword: z
			.string()
			.nonempty("Repeat New Password cannot be blank."),
	})
	.refine((data) => data.newPassword === data.repeatNewPassword, {
		message: "New Passwords don't match.",
		path: ["repeatNewPassword"], // field that will show the error
	});

const ChangePasswordForm = ({ loading, setLoading }) => {
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [alertContent, setAlertContent] = useState(
		"Failed to change password!"
	);
	// const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
	} = useForm({
		resolver: zodResolver(passwordSchema),
	});

	function getMessage(obj) {
		let messages = [];

		// Check if 'message' is directly available and is a string
		if (typeof obj.message === "string") {
			messages.push(obj.message);
		}
		// Check if 'message' is an object
		else if (typeof obj.message === "object") {
			for (const key in obj.message) {
				const value = obj.message[key];
				// If the value is an array, add each element to messages
				if (Array.isArray(value)) {
					value.forEach((item) => messages.push(item));
				}
				// If the value is a string, add it to messages
				else if (typeof value === "string") {
					messages.push(value);
				}
			}
		}

		// Join all messages with a newline and return the result
		return messages.join("\n");
	}

	const onSubmit = async (data) => {
		// console.log(data);

		// // Get the token value from the URL
		// const urlParams = new URLSearchParams(window.location.search);
		// const token = urlParams.get("token");
		setLoading(true);
		const changeData = {
			PasswordUpdateForm: {
				// token: token,
				old_password_hash: data.oldPassword,
				new_password_hash: data.newPassword,
				new_password_repeat_hash: data.repeatNewPassword,
				// reCaptcha: data.recaptcha,
			},
		};

		// console.log(changeData);

		const response = ChangePasswordService.reset(changeData)
			.then((response) => {
				setLoading(false);
				// console.log(response);
				if (response.status === 200 && response.data.isOk === true) {
					//   console.log("Success!");
					setShowAlert(false);
					setFormSubmitted(true);
				} else {
					setAlertContent(getMessage(response.data));
					setShowAlert(true);
					setError("api", {
						type: "server",
						message: response.data.error,
					});
					setError("username", {
						type: "server",
						message: "",
					});
					setError("password", {
						type: "server",
						message: "",
					});
				}
			})
			.catch((error) => {
				setLoading(false);
				setShowAlert(true);
				// console.log(error);
				setError("api", {
					type: "server",
					message: error.response.data.error,
				});
				setError("username", {
					type: "server",
					message: "",
				});
				setError("password", {
					type: "server",
					message: "",
				});
			});
	};

	useEffect(() => {
		let timer;
		if (showAlert) {
			// Set a timeout to hide the alert after 5 seconds (5000 milliseconds)
			timer = setTimeout(() => {
				setShowAlert(false);
			}, 5000);
		}

		// Clear the timeout if the component is unmounted
		// or if the alert is manually closed before 5 seconds
		return () => clearTimeout(timer);
	}, [showAlert]);
	return (
		<>
			{showAlert && (
				<Alert
					variant="danger"
					className="alert-slide-down"
					onClose={() => setShowAlert(false)}
					dismissible
				>
					<Alert.Heading>Error</Alert.Heading>
					<p>{alertContent}</p>
				</Alert>
			)}
			{formSubmitted ? (
				<div data-w-id="e1bc4a34-b07a-fec3-2d1d-9735d080f1d0">
					<div
						style={{
							width: "100%",
							textAlign: "center",
							display: "flex",
							justifyContent: "center",
						}}
					>
						<div className="contact-container">
							<div className="center alert alert-success">
								Success!
							</div>
							<p>Your password has been successfully updated!</p>
							<a className="rto_blue-link" href="/dashboard">
								Back to dashboard
							</a>
						</div>
					</div>
				</div>
			) : (
				<Form
					onSubmit={handleSubmit(onSubmit)}
					className="container mt-4"
					style={{ maxWidth: "500px" }}
				>
					<p
						className="change-password_text"
						style={{ display: "flex" }}
					>
						Please fill out the following fields to change password:
					</p>
					<Form.Group className="mb-3" controlId="oldPassword">
						<Form.Label className="form-label-custom">
							Old Password
						</Form.Label>
						<Form.Control
							type="password"
							isInvalid={!!errors.oldPassword}
							{...register("oldPassword")}
						/>
						<Form.Control.Feedback type="invalid">
							{errors.oldPassword?.message}
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group className="mb-3" controlId="newPassword">
						<Form.Label className="form-label-custom">
							New Password
						</Form.Label>
						<Form.Control
							type="password"
							isInvalid={!!errors.newPassword}
							{...register("newPassword")}
						/>
						<Form.Control.Feedback type="invalid">
							{errors.newPassword?.message}
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group className="mb-3" controlId="repeatNewPassword">
						<Form.Label className="form-label-custom">
							Repeat New Password
						</Form.Label>
						<Form.Control
							type="password"
							isInvalid={!!errors.repeatNewPassword}
							{...register("repeatNewPassword")}
						/>
						<Form.Control.Feedback type="invalid">
							{errors.repeatNewPassword?.message}
						</Form.Control.Feedback>
					</Form.Group>

					<Button variant="primary" type="submit">
						Change
					</Button>
				</Form>
			)}
		</>
	);
};

export default ChangePasswordForm;
