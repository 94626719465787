import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import RefferalForm from "../../components/forms/ReferralForm";

function ReferralProgram({ loading, setLoading }) {
  return (
    <>
      <div className="rto_form-sections">
        <div className="rto_content-div rto_900">
          <Breadcrumb listProps={{ className: "w-100 justify-content-end" }}>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/dashboard" }}>
              Dashboard
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Referral Program</Breadcrumb.Item>
          </Breadcrumb>
          <div className="rto_title-div">
            <h1 className="rto_page-title">Referral Program</h1>
            <p className="referral-text">REFER A MERCHANT AND EARN $100!</p>
            <p className="referral-text">
              Under the RTO Mobile Referral Program, you earn $100 when that
              merchant you refer becomes active on the RTO Mobile platform.
              Certain rules apply, so please talk with your RTO Mobile
              representative, and ask about Referral Program details.
            </p>
          </div>
          <RefferalForm
            loading={loading}
            setLoading={setLoading}
            sendEmail={true}
          />
        </div>
      </div>
    </>
  );
}

export default ReferralProgram;
