import React, { useEffect, useState } from "react";
import CustomSelectComponent from "./CustomSelectComponent";
import HierarchyService from "../../../../service/hierarchy/HierarchyService";

const CustomSelectHierarchyComponent = ({
	name,
	label,
	type,
	value,
	onChange,
	onBlur,
	className,
	formState,
	targetSelectComponent,
	targetModel,
	...otherProps
}) => {
	const [internalValue, setInternalValue] = useState(
		value !== null && value !== undefined ? value : ""
	);
	const [isValid, setIsValid] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [isTouched, setIsTouched] = useState(false);

	useEffect(() => {
		setInternalValue(value !== null && value !== undefined ? value : "");
	}, [value]);

	useEffect(() => {
		validate();
	}, [internalValue]);

	const handleDefaultChange = async (name, value) => {
		console.log("value", value);
		if (parseInt(value) > 0) {
			console.log("newValue", value);
			console.log("targetModel", targetModel);
			// Fetch data from hierarchy service
			const response = await HierarchyService.get(targetModel, value);
			console.log("response", response?.data?.data?.select);
		}
	};

	const handleChange = (newValue) => {
		console.log("newValue", newValue);
		setInternalValue(newValue);
		onChange(name, newValue, isValid);
	};

	const validate = () => {
		const fieldInfo = getFieldInfo();

		let trimmedValue = internalValue;

		if (trimmedValue.length === 0) {
			trimmedValue = ""; //handle 0 value
		}

		setInternalValue(trimmedValue);

		if (fieldInfo.readOnly) {
			setIsValid(true);
			setErrorMessage("");
			onChange(name, trimmedValue, true);
			return;
		}

		// Check required
		if (fieldInfo.required && trimmedValue === "") {
			setIsValid(false);
			setErrorMessage("This field is required.");
			onChange(name, trimmedValue, false);
			return;
		}

		setIsValid(true);
		setErrorMessage("");
		onChange(name, trimmedValue, true);
	};

	const handleFocus = () => {
		setIsTouched(true);
		validate();
	};

	const getFieldInfo = () => {
		return {
			required: Boolean(type && type.required),
			readOnly: type && type.readOnly,
			upperCase: type && type.upperCase,
			options: type && type.options,
		};
	};

	return (
		<CustomSelectComponent
			name={name}
			label={label}
			value={internalValue}
			onChange={handleDefaultChange}
			onBlur={handleFocus}
			errorMessage={errorMessage}
			isValid={isValid}
			isTouched={isTouched}
			className={className}
			type={type}
			{...otherProps}
		/>
	);
};

export default CustomSelectHierarchyComponent;
