import ApiService from "../ApiService";

const DashboardService = {
	/**
	 * Calculate Simple PaymentTerms
	 * @see /docs/api/index.html#jump-Dashboard-CalculateSimplePaymentTerms
	 * @returns {Promise<AxiosResponse<any>>}
	*/
    getCalculator: (PaymentCalculator) => {
        return ApiService.post("/dashboard/calculate-simple-payment-terms", PaymentCalculator).catch((error) => {
            console.log(error);
            return error;
        });
    },

	/**
	 * @see /docs/api/index.html#jump-Dashboard-ContractsSearch
	 * @param string searchData
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	contractsSearch: (searchData) => {
		return ApiService.post("/dashboard/contracts-search",
			{ searchString: searchData }
			).catch((error) => {
            console.log(error);
            return error;
        });
	},
};

export default DashboardService;
