import * as React from "react";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

export default function TopModal({
	title,
	body,
	footer,
	isVisible,
	onClose,
	loading,
}) {
	const handleClose = () => {
		onClose();
	};

	return (
		<React.Fragment>
			<Dialog
				fullWidth={true}
				maxWidth={"md"}
				open={isVisible}
				TransitionComponent={Transition}
				keepMounted
				onClose={(event, reason) => {
					if (reason !== "backdropClick") {
						handleClose();
					}
				}}
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
					{title}{" "}
				</DialogTitle>
				{loading ? (
					<></>
				) : (
					<IconButton
						aria-label="close"
						onClick={handleClose}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				)}
				<DialogContent dividers style={{ textAlign: "left" }}>
					{body}
				</DialogContent>
				<DialogActions>{footer}</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}
