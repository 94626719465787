import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import Feedback from "react-bootstrap/Feedback";
import { DevTool } from "@hookform/devtools";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button, FormSelect } from "react-bootstrap";
import ImeiService from "../../service/system/ImeiService";

import { toast } from "react-toastify";

const schema = z.object({
	imei: z
		.string()
		.trim()
		.nonempty({ message: "IMEI cannot be blank!" })
		.length(15, {
			message: "IMEI must contain 15 characters!",
		})
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return !isNaN(intValue);
			},
			{
				message: "IMEI must be a number!",
			}
		)
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return intValue >= 0;
			},
			{
				message: "IMEI cannot be negative!",
			}
		)
		.refine(
			(value) => {
				return /^[0-9]+$/.test(value);
			},
			{
				message: "IMEI must be a valid integer!",
			}
		),
	manufacturer: z
		.string()
		.trim()
		.nonempty({ message: "Manufacturer cannot be blank!" })
		.max(255, {
			message: "Manufacturer cannot be longer than 255 characters!",
		}),
	model: z
		.string()
		.trim()
		.nonempty({ message: "Model cannot be blank!" })
		.max(255, {
			message: "Model cannot be longer than 255 characters!",
		}),
	code: z
		.string()
		.trim()
		.nonempty({ message: "Code cannot be blank!" })
		.max(25, {
			message: "Code cannot be longer than 25 characters!",
		}),
	integration_id: z
		.string()
		.trim()
		.nonempty({ message: "Integration cannot be blank!" })
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return !isNaN(intValue);
			},
			{
				message: "Integration must be a number!",
			}
		)
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return intValue >= 0;
			},
			{
				message: "Integration cannot be negative!",
			}
		)
		.refine(
			(value) => {
				return /^[0-9]+$/.test(value);
			},
			{
				message: "Integration must be a valid integer!",
			}
		),
});

function AddImeiForm({ imeiData, onUpdateCallback, loading, setLoading }) {
	const [manufacturers, setManufacturers] = useState([]);
	const [integrations, setIntegrations] = useState([]);

	const {
		register,
		control,
		handleSubmit,
		formState: {
			errors,
			touchedFields,
			isSubmitting,
			isSubmitted,
			isValid,
			isDirty,
		},
		// getValues,
		setValue,
		// watch,
		setError,
		reset,
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			imei: "",
			manufacturer: "",
			model: "",
			code: "",
			integration_id: "",
		},
		resolver: zodResolver(schema),
	});

	const fetchManufacturers = async () => {
		try {
			const response = await ImeiService.getManufacturerList();
			setManufacturers(response.data);
		} catch (error) {
			console.error("Error fetching manufacturers:", error);
		}
	};

	const fetchIntegrations = async () => {
		try {
			const response = await ImeiService.getIntegrationList();
			setIntegrations(response.data);
		} catch (error) {
			console.error("Error fetching integrations:", error);
		}
	};

	const isFirstRender = useRef(true);
	const renderCount = useRef(0);

	useEffect(() => {
		fetchManufacturers();
		fetchIntegrations();
		renderCount.current = renderCount.current + 1;

		if (isFirstRender.current) {
			isFirstRender.current = false;
			return;
		}
	}, []);

	useEffect(() => {
		if (imeiData) {
			setValue("imei", imeiData.imei?.toString() ?? "");
			setValue("manufacturer", imeiData.manufacturer ?? "");
			setValue("model", imeiData.model ?? "");
			setValue("code", imeiData.code ?? "");
			setValue(
				"integration_id",
				imeiData.integration_id?.toString() ?? ""
			);
		}
	}, [imeiData, setValue]);

	// const navigate = useNavigate();

	const isEditMode = !!imeiData;

	const [formSubmitted, setFormSubmitted] = useState(false);

	const onSubmit = async (data) => {
		// Set loading state
		setLoading(true);

		const imeiData = {
			ImeiCreate: {
				imei: data.imei,
				manufacturer: data.manufacturer,
				model: data.model.toUpperCase(),
				code: data.code,
				integration_id: data.integration_id,
			},
		};

		const response = await ImeiService.create(imeiData)
			.then((response) => {
				if (response.status === 200 && response.data.isOk === true) {
					// show success message
					// console.log("Success!");
					toast.success(
						`IMEI ${imeiData.ImeiCreate.imei} created successfully!`,
						{
							position: "top-center",
							autoClose: 1000,
						}
					);
					// setFormSubmitted(true);
					// navigate("/isos");
					reset();
				} else {
					// console.log("Error!");
					// show error messages
					const errorMappings = {
						imei: "imei",
						manufacturer: "manufacturer",
						model: "model",
						code: "code",
						integration_id: "integration_id",
					};

					setError("api", {
						type: "server",
						message: response.data.message,
					});

					Object.entries(response.data.message).forEach(
						([field, message]) => {
							const errorType = errorMappings[field];
							if (errorType) {
								setError(errorType, {
									type: "server",
									message,
								});
							}
						}
					);
				}
			})
			.catch((error) => {
				// console.log(error);
				setError("api", {
					type: "server",
					message: error.response.data.error,
				});
			});

		// Set loading state
		setLoading(false);
	};

	const onUpdate = async (data) => {
		// Set loading state
		setLoading(true);

		const imeiUpdateData = {
			imeiId: imeiData.id,
			id: imeiData.id,
			imei: data.imei,
			manufacturer: data.manufacturer,
			model: data.model,
			code: data.code,
			integration_id: data.integration_id,
		};

		// console.log("imeiUpdateData", imeiUpdateData);

		const response = await ImeiService.update(imeiUpdateData)
			.then((response) => {
				// console.log(response);
				if (response.status === 200 && response.data.isOk === true) {
					// show success message
					// console.log("Success!");

					toast.info(
						`IMEI ${imeiUpdateData.imei} updated successfully!`,
						{
							position: "top-center",
							autoClose: 1000,
						}
					);
					// setFormSubmitted(true);
					onUpdateCallback(imeiUpdateData);
					reset();
					renderCount.current = 0;
				} else {
					// console.log("Error!");
					// show error messages
					const errorMappings = {
						imei: "imei",
						manufacturer: "manufacturer",
						model: "model",
						code: "code",
						integration_id: "integration_id",
					};

					Object.entries(response.data.message).forEach(
						([field, message]) => {
							const errorType = errorMappings[field];
							if (errorType) {
								setError(errorType, {
									type: "server",
									message,
								});
							}
						}
					);
				}
			})
			.catch((error) => {
				// console.log(error);
				setError("api", {
					type: "server",
					message: error.response.data.error,
				});
			});

		// Set loading state
		setLoading(false);
	};

	return (
		<>
			{formSubmitted ? (
				<p className="paragraph-2">
					<strong>SUCCESS! YOUR MESSAGE HAS BEEN RECEIVED.</strong>
					<br />
					WE WILL GET IN CONTACT WITH YOU SHORTLY.
				</p>
			) : (
				<form
					id="imei_form"
					name="imei-form"
					onSubmit={handleSubmit(onSubmit)}
					// onSubmit={handleSubmit(
					//     console.log("Validation errors:", errors)
					// )}
					className="form-vertical"
				>
					<Row style={{ marginBottom: "0" }}>
						<Col lg="6" className="offset-lg-3">
							<FormGroup className="form-group">
								<FormLabel htmlFor="name">
									IMEI <div className="has-star">*</div>
								</FormLabel>
								<FormControl
									autoFocus
									type="text"
									maxLength="15"
									name="imei"
									id="imei"
									{...register("imei")}
									isInvalid={
										(touchedFields.imei &&
											!!errors["imei"]) ||
										(isSubmitted && !!errors["imei"])
									}
									isValid={
										touchedFields.imei && !errors["imei"]
									}
								/>
								<Feedback type="invalid">
									{errors["imei"] && errors["imei"].message}
								</Feedback>
							</FormGroup>

							<FormGroup className="form-group">
								<FormLabel htmlFor="manufacturer">
									Manufacturer{" "}
									<div className="has-star">*</div>
								</FormLabel>
								<FormSelect
									name="manufacturer"
									id="manufacturer"
									{...register("manufacturer")}
									isInvalid={
										(touchedFields.manufacturer &&
											!!errors["manufacturer"]) ||
										(isSubmitted &&
											!!errors["manufacturer"])
									}
									isValid={
										touchedFields.manufacturer &&
										!errors["manufacturer"]
									}
								>
									<option value="" selected disabled>
										Select a Manufacturer...
									</option>
									{manufacturers.data?.length > 0 &&
										manufacturers.data.map(
											(manufacturer) => (
												<option
													value={
														manufacturer.manufacturer
													}
													key={
														manufacturer.manufacturer
													}
												>
													{manufacturer.manufacturer}
												</option>
											)
										)}
								</FormSelect>
								<Feedback type="invalid">
									{errors["manufacturer"] &&
										errors["manufacturer"].message}
								</Feedback>
							</FormGroup>

							<FormGroup className="form-group">
								<FormLabel htmlFor="model">
									Model <div className="has-star">*</div>
								</FormLabel>
								<FormControl
									type="text"
									maxLength="100"
									name="model"
									id="model"
									{...register("model")}
									isInvalid={
										(touchedFields.model &&
											!!errors["model"]) ||
										(isSubmitted && !!errors["model"])
									}
									isValid={
										touchedFields.model && !errors["model"]
									}
									style={{ textTransform: "uppercase" }}
								/>
								<Feedback type="invalid">
									{errors["model"] && errors["model"].message}
								</Feedback>
							</FormGroup>

							<FormGroup className="form-group">
								<FormLabel htmlFor="code">
									Code <div className="has-star">*</div>
								</FormLabel>
								<FormControl
									type="text"
									maxLength="100"
									name="code"
									id="code"
									{...register("code")}
									isInvalid={
										(touchedFields.code &&
											!!errors["code"]) ||
										(isSubmitted && !!errors["code"])
									}
									isValid={
										touchedFields.code && !errors["code"]
									}
								/>
								<Feedback type="invalid">
									{errors["code"] && errors["code"].message}
								</Feedback>
							</FormGroup>

							<FormGroup className="form-group">
								<FormLabel htmlFor="integration_id">
									Integration{" "}
									<div className="has-star">*</div>
								</FormLabel>
								<FormSelect
									name="integration_id"
									id="integration_id"
									{...register("integration_id")}
									isInvalid={
										(touchedFields.integration_id &&
											!!errors["integration_id"]) ||
										(isSubmitted &&
											!!errors["integration_id"])
									}
									isValid={
										touchedFields.integration_id &&
										!errors["integration_id"]
									}
								>
									<option value="" selected disabled>
										Select an Integration...
									</option>
									{integrations.data?.length > 0 &&
										integrations.data.map((integration) => (
											<option
												value={integration.id}
												key={integration.id}
											>
												{integration.name}
											</option>
										))}
								</FormSelect>
								<Feedback type="invalid">
									{errors["integration_id"] &&
										errors["integration_id"].message}
								</Feedback>
							</FormGroup>
						</Col>
					</Row>
					{errors.api && (
						<Row>
							<Col
								lg={6}
								className="offset-lg-3"
								style={{ marginBottom: "2rem" }}
							>
								<p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium Mui-required">
									{errors.api.message}
								</p>
							</Col>
						</Row>
					)}
					<Row>
						<Col
							lg={6}
							className="offset-lg-3 d-flex justify-content-end align-items-center"
						>
							{isEditMode ? (
								// If in "edit" mode, show "Update" button
								<Button
									type="button"
									variant="primary"
									disabled={
										isSubmitting || !isValid || !isDirty
									}
									onClick={handleSubmit(onUpdate)}
									// onClick={handleSubmit(
									//     console.log(
									//         "Validation errors:",
									//         errors
									//     )
									// )}
								>
									{isSubmitting ? (
										<>
											<span
												className="spinner-border spinner-border-sm mr-1"
												role="status"
												aria-hidden="true"
											></span>
										</>
									) : (
										<></>
									)}
									Save
								</Button>
							) : (
								// If in "create" mode, show "Submit" button
								<Button
									type="submit"
									variant="success"
									disabled={isSubmitting || !isValid}
								>
									{isSubmitting ? (
										<>
											<span
												className="spinner-border spinner-border-sm mr-1"
												role="status"
												aria-hidden="true"
											></span>
										</>
									) : (
										<></>
									)}
									Submit
								</Button>
							)}
						</Col>
					</Row>
				</form>
			)}
			<DevTool control={control} />
		</>
	);
}

export default AddImeiForm;
