import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";

/**
 * @doc when we need to copy the content to another textbox
 * [
 *	'type' => 'ein',
 *	'name' => 'ein',
 *	'class' => 'col-md-12',
 * ],
 * PHP firld array
 * "ein" => FormElementsHelper::generateEINComponent('EIN'),
 * @enddoc
 */
const CustomInputEINComponent = ({
	name,
	label,
	type,
	value,
	onChange,
	onBlur,
	className,
	...otherProps
}) => {
	const [internalValue, setInternalValue] = useState(value);
	const [isValid, setIsValid] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [isTouched, setIsTouched] = useState(false);

	useEffect(() => {
		validate();
	}, [value, internalValue, isTouched]);

	const handleChange = (newValue) => {
		setInternalValue(newValue);
		onChange(name, newValue, isValid);
	};

	const handleBlur = () => {
		setIsTouched(true);
		validate();
	};

	const validate = () => {
		const fieldInfo = getFieldInfo();
		let trimmedValue = internalValue.trim();

		setInternalValue(trimmedValue);

		if (fieldInfo.readOnly) {
			setIsValid(true);
			setErrorMessage("");
			onChange(name, trimmedValue, true);
			return;
		}

		if (fieldInfo.required && trimmedValue === "") {
			setIsValid(false);
			setErrorMessage("EIN is required.");
			onChange(name, trimmedValue, false);
			return;
		}

		if (fieldInfo.required && !isValidEIN(trimmedValue)) {
			setIsValid(false);
			setErrorMessage("Invalid EIN.");
			onChange(name, trimmedValue, false);
			return;
		}

		setIsValid(true);
		setErrorMessage("");
		onChange(name, trimmedValue, true);
	};

	const handleFocus = () => {
		setIsTouched(true);
		validate();
	};

	const getFieldInfo = () => {
		return {
			required: Boolean(type && type.required),
		};
	};

	const isValidEIN = (value) => {
		const einRegex = /^\d{2}-\d{7}$/;
		return einRegex.test(value);
	};

	return (
		<div className={`${className}`}>
			<InputMask
				mask="99-9999999"
				value={internalValue}
				onChange={(e) => handleChange(e.target.value)}
				onFocus={handleFocus}
			>
				{() => (
					<TextField
						id={name}
						label={`${label}`}
						variant="standard"
						size="small"
						fullWidth
						error={!isValid && isTouched}
						helperText={!isValid && isTouched && errorMessage}
						{...otherProps}
					/>
				)}
			</InputMask>
		</div>
	);
};

export default CustomInputEINComponent;
