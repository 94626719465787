import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";

function AdminTools() {
	const pageTitle = "Admin Tools | RTO Mobile";

	return (
		<>
			<Helmet>
				<title>{pageTitle}</title>
				<meta name="robots" content="noindex,nofollow"></meta>
				<meta property="og:title" content={pageTitle} />
				<meta name="twitter:title" content={pageTitle} />
			</Helmet>
			<div className="rto_form-sections">
				<div className="rto_content-div rto_900">
					<Breadcrumb
						listProps={{ className: "w-100 justify-content-end" }}
					>
						<Breadcrumb.Item
							linkAs={Link}
							linkProps={{ to: "/dashboard" }}
						>
							Dashboard
						</Breadcrumb.Item>
						<Breadcrumb.Item active>Admin Tools</Breadcrumb.Item>
					</Breadcrumb>
					<div className="rto_title-div">
						<h1 className="rto_page-title">Admin Tools</h1>
					</div>
					<div>
						<div className="rto_results-container">
							<div className="rto_div-block-27">
								<div
									className="rto_result-contract card border-bottom-0 border-right-0 border-left-0 card-primary"
									style={{
										borderTop: "3px solid #00aaff",
										// boxShadow:
										//     "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
									}}
								>
									<h3
										className="rto_contract-h3-preview-title d-flex align-items-center"
										style={{
											fontWeight: 400,
										}}
									>
										<b>
											<span
												className="fas fa-chart-line"
												style={{
													color: "#00aaff",
												}}
											></span>
										</b>
										&nbsp;Add IMEI
									</h3>
									<ul>
										<li>
											<Link
												to="/admintools/add-imei"
												className="rto_link-2"
											>
												View Report
											</Link>
										</li>
									</ul>
								</div>

								<div
									className="rto_result-contract card border-bottom-0 border-right-0 border-left-0 card-primary"
									style={{
										borderTop: "3px solid #00aaff",
										// boxShadow:
										//     "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
									}}
								>
									<h3
										className="rto_contract-h3-preview-title d-flex align-items-center"
										style={{
											fontWeight: 400,
										}}
									>
										<b>
											<span
												className="fas fa-chart-line"
												style={{
													color: "#00aaff",
												}}
											></span>
										</b>
										&nbsp;Simple Payment
									</h3>
									<ul>
										<li>
											<Link
												to="/admintools/simple-payment"
												className="rto_link-2"
											>
												View Report
											</Link>
										</li>
									</ul>
								</div>

								<div
									className="rto_result-contract card border-bottom-0 border-right-0 border-left-0 card-primary"
									style={{
										borderTop: "3px solid #00aaff",
										// boxShadow:
										//     "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
									}}
								>
									<h3
										className="rto_contract-h3-preview-title d-flex align-items-center"
										style={{
											fontWeight: 400,
										}}
									>
										<b>
											<span
												className="fas fa-chart-line"
												style={{
													color: "#00aaff",
												}}
											></span>
										</b>
										&nbsp;Contract Origination By Store
									</h3>
									<ul>
										<li>
											<a
												href="/admintools/rto-contracts-originated-by-store"
												className="rto_link-2"
											>
												View Report
											</a>
										</li>
									</ul>
								</div>

								<div
									className="rto_result-contract card border-bottom-0 border-right-0 border-left-0 card-primary"
									style={{
										borderTop: "3px solid #00aaff",
										// boxShadow:
										//     "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
									}}
								>
									<h3
										className="rto_contract-h3-preview-title d-flex align-items-center"
										style={{
											fontWeight: 400,
										}}
									>
										<b>
											<span
												className="fas fa-chart-line"
												style={{
													color: "#00aaff",
												}}
											></span>
										</b>
										&nbsp;RTO Commission Report
									</h3>
									<ul>
										<li>
											<a
												href="/admintools/rto-commissions"
												className="rto_link-2"
											>
												View Report
											</a>
										</li>
									</ul>
								</div>

								<div
									className="rto_result-contract card border-bottom-0 border-right-0 border-left-0 card-primary"
									style={{
										borderTop: "3px solid #00aaff",
										// boxShadow:
										//     "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
									}}
								>
									<h3
										className="rto_contract-h3-preview-title d-flex align-items-center"
										style={{
											fontWeight: 400,
										}}
									>
										<b>
											<span
												className="fas fa-chart-line"
												style={{
													color: "#00aaff",
												}}
											></span>
										</b>
										&nbsp;Device Codes
									</h3>
									<ul>
										<li>
											<a
												href="/admintools/device-codes"
												className="rto_link-2"
											>
												View Report
											</a>
										</li>
									</ul>
								</div>

								<div
									className="rto_result-contract card border-bottom-0 border-right-0 border-left-0 card-primary"
									style={{
										borderTop: "3px solid #00aaff",
										// boxShadow:
										//     "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
									}}
								>
									<h3
										className="rto_contract-h3-preview-title d-flex align-items-center"
										style={{
											fontWeight: 400,
										}}
									>
										<b>
											<span
												className="fas fa-chart-line"
												style={{
													color: "#00aaff",
												}}
											></span>
										</b>
										&nbsp;Device Info & Control
									</h3>
									<ul>
										<li>
											<a
												href="/admintools/device-control"
												className="rto_link-2"
											>
												View Report
											</a>
										</li>
									</ul>
								</div>

								<div
									className="rto_result-contract card border-bottom-0 border-right-0 border-left-0 card-primary"
									style={{
										borderTop: "3px solid #00aaff",
										// boxShadow:
										//     "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
									}}
								>
									<h3
										className="rto_contract-h3-preview-title d-flex align-items-center"
										style={{
											fontWeight: 400,
										}}
									>
										<b>
											<span
												className="fas fa-chart-line"
												style={{
													color: "#00aaff",
												}}
											></span>
										</b>
										&nbsp;Inventory - Index Admin
									</h3>
									<ul>
										<li>
											<a
												href="/admintools/inventory/index-admin"
												className="rto_link-2"
											>
												View Report
											</a>
										</li>
									</ul>
								</div>

								<div
									className="rto_result-contract card border-bottom-0 border-right-0 border-left-0 card-primary"
									style={{
										borderTop: "3px solid #00aaff",
										// boxShadow:
										//     "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
									}}
								>
									<h3
										className="rto_contract-h3-preview-title d-flex align-items-center"
										style={{
											fontWeight: 400,
										}}
									>
										<b>
											<span
												className="fas fa-chart-line"
												style={{
													color: "#00aaff",
												}}
											></span>
										</b>
										&nbsp;Inventory - Store Inventory
									</h3>
									<ul>
										<li>
											<a
												href="/admintools/inventory"
												className="rto_link-2"
											>
												View Report
											</a>
										</li>
									</ul>
								</div>

								<div
									className="rto_result-contract card border-bottom-0 border-right-0 border-left-0 card-primary"
									style={{
										borderTop: "3px solid #00aaff",
										// boxShadow:
										//     "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
									}}
								>
									<h3
										className="rto_contract-h3-preview-title d-flex align-items-center"
										style={{
											fontWeight: 400,
										}}
									>
										<b>
											<span
												className="fas fa-chart-line"
												style={{
													color: "#00aaff",
												}}
											></span>
										</b>
										&nbsp;Payments Due Report
									</h3>
									<ul>
										<li>
											<a
												href="/admintools/payments-due-report"
												className="rto_link-2"
											>
												View Report
											</a>
										</li>
									</ul>
								</div>

								<div
									className="rto_result-contract card border-bottom-0 border-right-0 border-left-0 card-primary"
									style={{
										borderTop: "3px solid #00aaff",
										// boxShadow:
										//     "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
									}}
								>
									<h3
										className="rto_contract-h3-preview-title d-flex align-items-center"
										style={{
											fontWeight: 400,
										}}
									>
										<b>
											<span
												className="fas fa-chart-line"
												style={{
													color: "#00aaff",
												}}
											></span>
										</b>
										&nbsp;Proget All Devices
									</h3>
									<ul>
										<li>
											<a
												href="/admintools/proget-all-devices"
												className="rto_link-2"
											>
												View Report
											</a>
										</li>
									</ul>
								</div>

								<div
									className="rto_result-contract card border-bottom-0 border-right-0 border-left-0 card-primary"
									style={{
										borderTop: "3px solid #00aaff",
										// boxShadow:
										//     "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
									}}
								>
									<h3
										className="rto_contract-h3-preview-title d-flex align-items-center"
										style={{
											fontWeight: 400,
										}}
									>
										<b>
											<span
												className="fas fa-chart-line"
												style={{
													color: "#00aaff",
												}}
											></span>
										</b>
										&nbsp;Proget Queue
									</h3>
									<ul>
										<li>
											<a
												href="/admintools/proget-queue"
												className="rto_link-2"
											>
												View Report
											</a>
										</li>
									</ul>
								</div>

								<div
									className="rto_result-contract card border-bottom-0 border-right-0 border-left-0 card-primary"
									style={{
										borderTop: "3px solid #00aaff",
										// boxShadow:
										//     "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
									}}
								>
									<h3
										className="rto_contract-h3-preview-title d-flex align-items-center"
										style={{
											fontWeight: 400,
										}}
									>
										<b>
											<span
												className="fas fa-chart-line"
												style={{
													color: "#00aaff",
												}}
											></span>
										</b>
										&nbsp;Reconciliation
									</h3>
									<ul>
										<li>
											<a
												href="/admintools/reconciliation"
												className="rto_link-2"
											>
												View Report
											</a>
										</li>
									</ul>
								</div>
								<div
									className="rto_result-contract card border-bottom-0 border-right-0 border-left-0 card-primary"
									style={{
										borderTop: "3px solid #00aaff",
										// boxShadow:
										//     "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
									}}
								>
									<h3
										className="rto_contract-h3-preview-title d-flex align-items-center"
										style={{
											fontWeight: 400,
										}}
									>
										<b>
											<span
												className="fas fa-chart-line"
												style={{
													color: "#00aaff",
												}}
											></span>
										</b>
										&nbsp;RTO Company Payments
									</h3>
									<ul>
										<li>
											<Link
												to="/admintools/rto-company-payments"
												className="rto_link-2"
											>
												View Report
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default AdminTools;
