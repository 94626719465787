import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Typography } from "@mui/material";

/**
 * @doc when we need to copy the content to another textbox
 * 	[
 		"type" => "incremental",
		"value" => "1",
		"label" => "Incremental",
		"minValue" => "1",
		"maxValue" => "10",
		"incrementer" => "1",
		"style" => [
			"textTransform" => "uppercase",
			"width" => "100px"
	],
 *
 * @param {string} name
 * @param {string} label - Text shows near the	input field
 * @param {string} value - The value of the input field
 * @param {number} minValue - The minimum value that the input field can have
 * @param {number} maxValue - The maximum value that the input field can have
 * @param {number} incrementer - The value by which the input field will be incremented
 * @param {string} className - The class of the input field
 * @param {object} style - The style of the input field
 */

const CustomIncrementalComponent = ({
	name,
	label,
	value,
	minValue,
	maxValue,
	incrementer,
	className,
	style,
	onChange,
	...otherProps
}) => {
	const [internalValue, setInternalValue] = useState(value);

	const handleIncrement = () => {
		onChange(
			name,
			Math.min(maxValue, parseInt(internalValue) + parseInt(incrementer)),
			true
		);
		setInternalValue((prevValue) =>
			Math.min(maxValue, parseInt(prevValue) + parseInt(incrementer))
		); // Increment by 1
	};

	const handleDecrement = () => {
		onChange(
			name,
			Math.max(minValue, parseInt(internalValue) - parseInt(incrementer)),
			true
		);
		setInternalValue((prevValue) =>
			Math.max(minValue, parseInt(prevValue) - parseInt(incrementer))
		); // Decrement by 1, ensuring the value doesn't go below 0
	};

	const handleChange = (e) => {
		const inputValue = parseInt(e.target.value);
		onChange(name, parseInt(e.target.value), true);
		setInternalValue(isNaN(inputValue) ? 0 : inputValue);
		onChange(name, internalValue, true);
	};

	return (
		<div style={{ display: "flex", alignItems: "center" }}>
			<Typography variant="body1" style={{ marginRight: "10px" }}>
				{label}
			</Typography>
			<IconButton onClick={handleDecrement} aria-label="Decrement">
				<RemoveIcon />
			</IconButton>
			<TextField
				label=""
				type="number"
				value={internalValue}
				onChange={handleChange}
				style={style}
				{...otherProps}
			/>
			<IconButton onClick={handleIncrement} aria-label="Increment">
				<AddIcon />
			</IconButton>
		</div>
	);
};

export default CustomIncrementalComponent;
