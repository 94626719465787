import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html#Report
 */

const StoreEndOfDayService = {
  /**
   * View DefaultedContracts report data
   * @param array
   * combineAllPaymentTypeFlag : 0 | 1 ( 0 = false, 1 = true )
   * data pass serach filter values if needed
   * @see /docs/api/index.html#jump-Report-StoreEndOfDay
   * @returns
   */
  view: (data, param, signal) => {
    return ApiService.post(
      "/report/store-end-of-day/index",
      data,
      param,
      signal
    ).catch((error) => {
      console.log(error);
      return error;
    });
  },
};

export default StoreEndOfDayService;
