import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html#Contract
 */

const ContractService = {
	/**
	 * View
	 * @param array data pass serach filter values if needed
	 * @see /docs/api/index.html#jump-Contract-Index
	 * @returns
	 */
	view: (data, param, signal) => {
		return ApiService.post("/contract/index", data, param, signal).catch(
			(error) => {
				console.log(error);
				return error;
			}
		);
	},

	/**
	 * filter by ID
	 * @param {int} id
	 * @see /docs/api/index.html#jump-Contract-FetchById
	 * @returns
	 */
	fetchById: (id) => {
		return ApiService.post("/contract/get-by-id", { id: id }).catch(
			(error) => {
				console.log(error);
			}
		);
	},

	update: (data) => {
		return ApiService.post("/contract/update", data).catch((error) => {
			console.log(error);
		});
	},

	getImages: (data) => {
		return ApiService.post("/contract/images-get", data).catch((error) => {
			console.log(error);
		});
	},

	/**
	 * Add new device to contract
	 * @see /docs/api/index.html#jump-Contract-AddDevice
	 * @see https://rowbusiness.atlassian.net/browse/RTOAPP-423
	 * @returns
	 */
	addDevice: (data) => {
		return ApiService.post("/contract/add-device", data).catch((error) => {
			console.log(error);
		});
	},

	/**
	 * Change device to contract
	 * @see https://rowbusiness.atlassian.net/browse/RTOAPP-424
	 * @see /docs/api/index.html#jump-Contract-ChangeDevice
	 * @returns
	 */
	changeDevice: (data) => {
		return ApiService.post("/contract/change-device", data).catch(
			(error) => {
				console.log(error);
			}
		);
	},

	/**
	 * Remove device from contract
	 * @see https://rowbusiness.atlassian.net/browse/RTOAPP-425
	 * @see /docs/api/index.html#jump-Contract-RemoveDevice
	 * @returns
	 */
	removeDevice: (data) => {
		return ApiService.post("/contract/remove-device", data).catch(
			(error) => {
				console.log(error);
			}
		);
	},

	/**
	 * Change billing status
	 * @param {object} data {contractId:8086, status: 2}
	 * @see /docs/api/index.html#jump-Contract-ChangeBillingStatus
	 * @returns
	 */
	changeBillingStatus: (data) => {
		return ApiService.post("/contract/change-billing-status", data).catch(
			(error) => {
				console.log(error);
			}
		);
	},

	/**
	 * Device add lock
	 * @see https://rowbusiness.atlassian.net/browse/RTOAPP-441
	 * @see /docs/api/index.html#jump-Contract-DeviceAddLock
	 * @returns
	 */
	deviceAddLock: (data) => {
		return ApiService.post("/contract/device-add-lock", data).catch(
			(error) => {
				console.log(error);
			}
		);
	},

	/**
	 * Device change lock
	 * @see https://rowbusiness.atlassian.net/browse/RTOAPP-448
	 * @see /docs/api/index.html#jump-Contract-DeviceChangeLock
	 * @returns
	 */
	deviceChangeLock: (data) => {
		return ApiService.post("/contract/device-change-lock", data).catch(
			(error) => {
				console.log(error);
			}
		);
	},

	/**
	 * Device change code
	 * @see https://rowbusiness.atlassian.net/browse/RTOAPP-449
	 * @see /docs/api/index.html#jump-Contract-DeviceChangeCode
	 * @returns
	 */
	deviceChangeCode: (data) => {
		return ApiService.post("/contract/device-change-code", data).catch(
			(error) => {
				console.log(error);
			}
		);
	},
	getCancelReasons: (data) => {
		return ApiService.post("/contract/cancel", data).catch((error) => {
			console.log(error);
		});
	},

	rescheduleContract: (data) => {
		return ApiService.post("/contract/reschedule", data).catch((error) => {
			console.log(error);
		});
	},

	/**
	 * ABP get
	 * @param {*} data
	 * @see /docs/api/index.html#jump-Contract-AbpGet
	 * @returns
	 */
	contractGetAbp: (contractId) => {
		return ApiService.post("/contract/abp-get", {
			contract_id: contractId,
		}).catch((error) => {
			console.log(error);
		});
	},

	deviceConditions: () => {
		return ApiService.get("/contract/device-conditions").catch((error) => {
			console.log(error);
		});
	},
};

export default ContractService;
