import React from "react";
import TopModal from "../../../common/TopModal";
import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";
// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";
import { Button as MuiButton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CustomSelectComponent from "../../../formComponents/rtoDynamicForm/fields/CustomSelectComponent";

const ContractBillingStatusModal = ({
	show,
	setBillingStatus,
	setShowBillingModal,
	modalLoading,
	contractDetail,
	statusId,
	setStatusId,
}) => {
	const handleClose = () => {
		setShowBillingModal(false);
	};

	return (
		<TopModal
			isVisible={show}
			body={
				<>
					<div className="billing-status-select">
						<FormControl
							// variant="filled"
							sx={{
								m: 1,
								// minWidth: 80,
								width: "100%",
								// ".MuiSvgIcon-root": {
								// 	// Targeting the dropdown arrow (SVG Icon)
								// 	color: "white !important", // Changing the arrow color to white
								// },
							}}
						>
							{/* <InputLabel
								id="demo-simple-select-autowidth-label"
								// sx={{
								// 	color: "white !important",
								// }}
							>
								Billing Status
							</InputLabel> */}
							{/* <Select
								labelId="demo-simple-select-autowidth-label"
								id="demo-simple-select-autowidth"
								value={statusId}
								onChange={(event) => {
									setStatusId(event.target.value);
								}}
								sx={
									{
										// color: "white",
										// ".MuiSelect-select": {
										// 	color: "white !important",
										// },
										// ".MuiOutlinedInput-notchedOutline": {
										// 	borderColor: "white !important", // Setting the border color for the Select component
										// },
									}
								}
								autoWidth
								label="Billing Status"
							>
								{contractDetail?.dropdowns?.billing_status_name?.map(
									(option) => (
										<MenuItem
											key={option.id}
											value={option.id}
											sx={{
												color: "black !important",
											}}
										>
											{option.name}
										</MenuItem>
									)
								)}
							</Select> */}

							<CustomSelectComponent
								name={"select-label"}
								label={"Billing Status"}
								value={statusId}
								type={{
									options:
										contractDetail?.dropdowns
											?.billing_status_name,
								}}
								onChange={(fieldName, value, pIsValid) =>
									setStatusId(value)
								}
								className={""}
								required={true}

								// style={fieldInfo?.style}
							/>
						</FormControl>
					</div>

					{modalLoading && (
						<div className="loading-backdrop">
							<CircularProgress />
						</div>
					)}
				</>
			}
			title={`Change Billing Status`}
			footer={
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						width: "100%",
					}}
				>
					<MuiButton
						variant="contained"
						color="error"
						onClick={handleClose}
						disabled={modalLoading}
					>
						Cancel
					</MuiButton>

					<MuiButton
						variant="contained"
						color="success"
						// variant="success"
						onClick={() => {
							setBillingStatus(statusId);
						}}
						disabled={modalLoading}
					>
						Change
					</MuiButton>
				</div>
			}
			onClose={handleClose}
			loading={modalLoading}
		></TopModal>
	);
};

export default ContractBillingStatusModal;
