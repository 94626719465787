import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import SessionService from "../../service/SessionService";

function Settings() {
    const pageTitle = "Settings | RTO Mobile";

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="robots" content="noindex,nofollow"></meta>
                <meta property="og:title" content={pageTitle} />
                <meta name="twitter:title" content={pageTitle} />
            </Helmet>
            <div className="rto_form-sections">
                <div className="rto_content-div rto_900">
                    <Breadcrumb
                        listProps={{ className: "w-100 justify-content-end" }}
                    >
                        <Breadcrumb.Item
                            linkAs={Link}
                            linkProps={{ to: "/dashboard" }}
                        >
                            Dashboard
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Settings</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="rto_title-div">
                        <h1 className="rto_page-title">Settings</h1>
                    </div>
                    <div>
                        <div className="rto_results-container">
                            {SessionService.canStoreAdmin() && (
                                <div className="rto_div-block-27">
                                    <div
                                        className="rto_result-contract card border-bottom-0 border-right-0 border-left-0 card-primary"
                                        style={{
                                            borderTop: "3px solid #00aaff",
                                            // boxShadow:
                                            //     "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
                                        }}
                                    >
                                        <h3
                                            className="rto_contract-h3-preview-title"
                                            style={{
                                                fontWeight: 400,
                                            }}
                                        >
                                            <b>
                                                <span
                                                    className="fas fa-cogs"
                                                    style={{
                                                        color: "#00aaff",
                                                    }}
                                                ></span>
                                            </b>
                                            &nbsp;Administration
                                        </h3>
                                        <ul>
                                            <li>
                                                <a
                                                    href="/settings/users"
                                                    className="rto_link-2"
                                                >
                                                    Users
                                                </a>
                                            </li>
                                            {SessionService.canCompanyAdmin() && (
                                                <li>
                                                    <a
                                                        href="/settings/stores"
                                                        className="rto_link-2"
                                                    >
                                                        Stores
                                                    </a>
                                                </li>
                                            )}
                                            {SessionService.canOrganizationAdmin() && (
                                                <li>
                                                    <Link
                                                        to="/settings/companies"
                                                        className="rto_link-2"
                                                    >
                                                        Companies
                                                    </Link>
                                                </li>
                                            )}
                                            {SessionService.canMasterAgentAdmin() && (
                                                <li>
                                                    <Link
                                                        to="/settings/organizations"
                                                        className="rto_link-2"
                                                    >
                                                        Organizations
                                                    </Link>
                                                </li>
                                            )}
                                            {SessionService.canIsoAdmin() && (
                                                <li>
                                                    <Link
                                                        to="/settings/master-agents"
                                                        className="rto_link-2"
                                                    >
                                                        Master Agents
                                                    </Link>
                                                </li>
                                            )}
                                            {SessionService.canRtoAdmin() && (
                                                <li>
                                                    <Link
                                                        to="/settings/isos"
                                                        className="rto_link-2"
                                                    >
                                                        ISOs
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            )}

                            {SessionService.canRtoAdmin() && (
                                <div className="rto_div-block-27">
                                    <div
                                        className="rto_result-contract card border-bottom-0 border-right-0 border-left-0 card-primary"
                                        style={{
                                            borderTop: "3px solid #00aaff",
                                            // boxShadow:
                                            //     "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
                                        }}
                                    >
                                        <h3
                                            className="rto_contract-h3-preview-title"
                                            style={{
                                                fontWeight: 400,
                                            }}
                                        >
                                            <b>
                                                <span
                                                    className="fas fa-cogs"
                                                    style={{ color: "#00aaff" }}
                                                ></span>
                                            </b>
                                            &nbsp;Store Contracts
                                        </h3>
                                        <ul>
                                            <li>
                                                <a
                                                    href="/settings/esign/templates"
                                                    className="rto_link-2"
                                                >
                                                    Templates
                                                </a>
                                            </li>

                                            <li>
                                                <a
                                                    href="/settings/esign/dealer-documents"
                                                    className="rto_link-2"
                                                >
                                                    Executed Contracts
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Settings;
