import { forwardRef } from "react";
import TextField from "@mui/material/TextField";

const phoneInput = (props, ref) => {
	return (
		<TextField
			{...props}
			inputRef={ref}
			variant="standard"
			fullWidth
			name="phone"
			// InputLabelProps={{
			//     shrink: true,
			//     className: "Mui-form-label Mui-form-label-required",
			// }}
			InputProps={{
				className: "Mui-form-input",
				// style: { paddingLeft: "50px" },
			}}
			sx={{
				"& .MuiInputBase-input": {
					color: "rgba(0, 0, 0, 0.87) !important",
					fontFamily:
						'"Roboto", "Helvetica", "Arial", sans-serif !important',
				},
			}}
			// style={{ marginLeft: "-40px" }}
		/>
	);
};
export default forwardRef(phoneInput);
