import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
// import OnboardingTable from "../../components/backend/onboarding/OnboardingTable";
import OnboardingService from "../../service/onboarding/OnboardingService";
import IndexTable from "../../components/common/IndexTable";

function OnboardingView({ loading, setLoading }) {
	const pageTitle = "Onboarding | RTO Mobile";
	return (
		<>
			<Helmet>
				<title>{pageTitle}</title>
				<meta name="robots" content="noindex,nofollow"></meta>
				<meta property="og:title" content={pageTitle} />
				<meta name="twitter:title" content={pageTitle} />
			</Helmet>
			<div className="rto_form-sections">
				<div className="rto_content-div" style={{ maxWidth: "1600px" }}>
					<Breadcrumb
						listProps={{ className: "w-100 justify-content-end" }}
					>
						<Breadcrumb.Item
							linkAs={Link}
							linkProps={{ to: "/dashboard" }}
						>
							Dashboard
						</Breadcrumb.Item>
						<Breadcrumb.Item active>Onboarding</Breadcrumb.Item>
					</Breadcrumb>
					<div className="rto_title-div">
						<h1 className="rto_page-title">Onboarding</h1>
					</div>
					<div
						className="card card-outline card-primary"
						style={{
							borderTop: "3px solid #00aaff",
							boxShadow:
								"0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
						}}
					>
						<div className="card-body">
							{/* <OnboardingTable
								loading={loading}
								setLoading={setLoading}
							/> */}
							<IndexTable
								model={"onboarding"}
								loading={loading}
								setLoading={setLoading}
								service={OnboardingService}
								title="Onboarding"
								actionButtons={{
									updateLink: {
										path: "/onboarding-v2",
									},
									updateModal: {
										enabled: true,
										path: "/onboarding-v2/update",
									},
									create: {
										enabled: true,
										path: "onboarding/create",
									},
									delete: true,
								}}
								labels={[
									{
										id: "id",
										label: "ID",
									},
									{
										id: "struct_iso_name",
										label: "ISO",
										link: "/settings/isos",
									},
									{
										id: "struct_master_agent_name",
										label: "Master Agent",
										link: "/settings/master-agents",
									},
									{
										id: "store_review_status_name",
										label: "Review Status",
									},
									{
										id: "card_connect_application_status_name",
										label: "CCN App Status",
									},
									{
										id: "organization_name",
										label: "Organization Name",
									},
									{
										id: "company_name",
										label: "Legal Company Name",
									},
									{
										id: "store_address_1",
										label: "Store Address",
									},
									{
										id: "store_id",
										label: "Store ID",
									},
									{
										id: "owner_fullname",
										label: "Owner Name",
									},
									{
										id: "owner_phone",
										label: "Owner Phone",
									},
									{
										id: "owner_email",
										label: "Owner Email",
									},
									{
										id: "user_fullname_first",
										label: "User Name",
									},
									{
										id: "created_at",
										label: "Created At",
									},
								]}
								apiBase="/onboarding"
								successRedirectBase="onboarding-v2"
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default OnboardingView;
