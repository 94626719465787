import React, { useEffect, useRef, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "react-toastify";
import TopModal from "../../../common/TopModal";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import PaymentService from "../../../../service/payment/PaymentService";
import ZipService from "../../../../service/zip/ZipService";
import { Months, CreditCardYears } from "../../../../Constants";

const MySchema = z.object({
	card_first_name: z
		.string()
		.trim()
		.nonempty({ message: "First name cannot be blank!" })
		.max(255, {
			message: "First name cannot be longer than 255 characters!",
		}),
	card_last_name: z
		.string()
		.trim()
		.nonempty({ message: "Last name cannot be blank!" })
		.max(255, {
			message: "Last name cannot be longer than 255 characters!",
		}),
	card_month: z
		.string()
		.toUpperCase()
		.trim()
		.nonempty({ message: "Month cannot be blank!" })
		.max(255, {
			message: "Month cannot be longer than 255 characters!",
		}),
	card_year: z
		.string()
		.toUpperCase()
		.trim()
		.nonempty({ message: "Year cannot be blank!" })
		.max(255, {
			message: "Year cannot be longer than 255 characters!",
		})
		.refine(
			(value) => {
				return CreditCardYears.some((year) => year.value === value);
			},
			{
				message: "Year must be a valid year!",
			}
		),
	card_cvv: z
		.string()
		.toUpperCase()
		.trim()
		.nonempty({ message: "CVV cannot be blank!" })
		.max(4, { message: "CVV cannot be longer than 4 characters!" })
		.min(3, { message: "CVV must be at least 3 characters long!" })
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return !isNaN(intValue);
			},
			{
				message: "CVV must be a number!",
			}
		)
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return intValue >= 0;
			},
			{
				message: "CVV cannot be negative!",
			}
		)
		.refine(
			(value) => {
				return /^[0-9]+$/.test(value);
			},
			{
				message: "CVV must be a valid integer!",
			}
		),
	billing_address: z
		.string()
		.toUpperCase()
		.trim()
		.nonempty({ message: "Billing address cannot be blank!" })
		.max(255, {
			message: "Billing address cannot be longer than 255 characters!",
		})
		.refine(
			(value) => {
				return /^[A-Za-z0-9\s.,'#-]+$/.test(value);
			},
			{
				message: "Billing address cannot contain special characters!",
			}
		),
	zip: z
		.string()
		.toUpperCase()
		.trim()
		.nonempty({ message: "Zip code cannot be blank!" })
		.length(5, { message: "Zip code must contain 5 characters!" })
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return !isNaN(intValue);
			},
			{
				message: "Zip code must be a number!",
			}
		)
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return intValue >= 0;
			},
			{
				message: "Zip code cannot be negative!",
			}
		)
		.refine(
			(value) => {
				return /^[0-9]+$/.test(value);
			},
			{
				message: "Zip code must be a valid integer!",
			}
		),
	city: z
		.string()
		.toUpperCase()
		.trim()
		.nonempty({ message: "City cannot be blank!" })
		.max(255, { message: "City cannot be longer than 255 characters!" }),
	state: z
		.string()
		.toUpperCase()
		.trim()
		.nonempty({ message: "State cannot be blank!" })
		.max(255, { message: "State cannot be longer than 255 characters!" }),
	token: z.string().nonempty({ message: "Card Number cannot be blank!" }),
});

const ContractAddABPModal = ({ show, onHide, contractId, tokenizerURL }) => {
	const {
		register,
		handleSubmit,
		setValue,
		setError,
		watch,
		formState: { errors },
	} = useForm({
		resolver: zodResolver(MySchema),
	});

	const zip = watch("zip");
	const iframeUrl =
		tokenizerURL +
		"?css=input%7Bbackground-color%3Atransparent%3Bborder%3Anone%3Bborder-bottom%3A1px%20solid%20%239e9e9e%3Bborder-radius%3A0%3Boutline%3Anone%3Bheight%3A3rem%3Bwidth%3A100%25%3Bfont-size%3A16px%3Bmargin%3A0%200%208px%200%3Bpadding%3A0%3B-webkit-box-shadow%3Anone%3Bbox-shadow%3Anone%3B-webkit-box-sizing%3Acontent-box%3Bbox-sizing%3Acontent-box%3B-webkit-transition%3Aborder%20.3s%2C%20-webkit-box-shadow%20.3s%3Btransition%3Aborder%20.3s%2C-webkit-box-shadow%20.3s%3Btransition%3A%20box-shadow%20.3s%2Cborder%20.3s%3B%20transition%3A%20box-shadow%20.3s%2Cborder%20.3s%2C%20-webkit-box-shadow%20.3s%3B%7D.error%7Bborder-bottom%3A1px%20solid%20%23F44336%3B-webkit-box-shadow%3A0%201px%200%200%20%23F44336%3Bbox-shadow%3A0%201px%200%200%20%23F44336%3B%7D.valid%7Bborder-bottom%3A1px%20solid%20%234CAF50%3B-webkit-box-shadow%3A0%201px%200%200%20%234CAF50%3Bbox-shadow%3A0%201px%200%200%20%234CAF50%3B%7Dinput%3Afocus%7Bborder-bottom%3A1px%20solid%20%23337ab7%20%21important%3Bbox-shadow%3A%200%201px%200%200%20%23337ab7%20%21important%3B%7Dbody%7Bmargin%3A0%3B%7D&amp;invalidinputevent=true&amp;placeholder=xxxx%20xxxx%20xxxx%20xxxx";

	useEffect(() => {
		const fetchLocationData = async () => {
			try {
				const response = await ZipService.fetchLocationData(zip);
				if (response.ok) {
					const data = await response.json();
					setValue("city", data.places[0]["place name"]);
					setValue("state", data.places[0]["state abbreviation"]);
				} else {
					setValue("city", "");
					setValue("state", "");
				}
			} catch (error) {
				setValue("city", "");
				setValue("state", "");
			}
		};

		if (zip && zip.length === 5 && /^\d+$/.test(zip)) {
			fetchLocationData();
		} else {
			setValue("city", "");
			setValue("state", "");
		}
	}, [zip, setValue]);

	const [token, setToken] = useState("");
	const iframeRef = useRef(null);
	const [cardError, setCardError] = useState(null);
	const [btnLoading, setBtnLoading] = React.useState(false);

	const handleIframeMessage = useCallback(
		(event) => {
			console.log("Received iframe message:", event);
			try {
				const tokenData = JSON.parse(event.data);
				console.log("Parsed iframe message:", tokenData);
				if (tokenData.message) {
					// Set the token value to the form
					setToken(tokenData.message);
					console.log("Token ==> ", tokenData.message);
					setCardError(null);
					setError("token", {
						type: "manual",
						message: "",
					});
				} else if (tokenData.validationError) {
					// Set the token value to empty
					setToken("");
					// Set the card error
					console.log("Card error:", tokenData.validationError);
					setCardError("Card Number is invalid.");
				}
			} catch (error) {
				console.error("Failed to parse event data:", error);
			}
		},
		[setToken, setError]
	);

	useEffect(() => {
		setValue("token", token);
	}, [setValue, token]);

	useEffect(() => {
		// Add event listener for the iframe message
		window.addEventListener("message", handleIframeMessage);
		console.log("Added event listener for iframe message");

		return () => {
			// Clean up event listener
			window.removeEventListener("message", handleIframeMessage);
			console.log("Removed event listener for iframe message");
		};
	}, [handleIframeMessage]);

	const onSubmit = async (data) => {
		// Handle the form submission with validated data
		setBtnLoading(true);
		return await PaymentService.contractAddAbp({
			contract_id: contractId,
			billing_address: data.billing_address,
			card_cvv: data.card_cvv,
			card_first_name: data.card_first_name,
			card_last_name: data.card_last_name,
			card_month: data.card_month,
			card_year: data.card_year,
			city: data.city,
			state: data.state,
			token: data.token,
			zip: data.zip,
		})
			.then((response) => {
				if (response.status === 200 && response.data.isOk === true) {
					// show success message
					toast.info(`Payment added successfully!`, {
						position: "top-center",
						autoClose: 1000,
					});
					onHide();
				} else {
					console.log("Post Error", response.data);
					setCardError(response.data.msg);
				}
				setBtnLoading(false);
			})
			.catch((error) => {
				toast.error(`Error: ${error.response.data.error}`, {
					position: "top-center",
					autoClose: 1000,
				});
				setBtnLoading(false);
			});
	};

	return (
		<TopModal
			isVisible={show}
			body={
				<>
					<form id="make_payment_form" name="make-payment-form">
						<Box sx={{ flexGrow: 1 }}>
							<Grid container spacing={2}>
								<Grid
									item
									xs={12}
									sx={{
										fontSize: "11px",
										color: "#FF0000",
									}}
								>
									Before continuing, please notify customer
									that a $1.00 pre-authorization charge will
									be posted to verify this card is valid. This
									pre-authorization charge will be reversed in
									no longer than 2 business days.
								</Grid>
								<Grid
									item
									xs={6}
									style={{ marginTop: "0.5rem" }}
								>
									<TextField
										id="outlined-basic"
										label="First Name"
										variant="standard"
										InputLabelProps={{
											shrink: true,
										}}
										placeholder="First Name"
										fullWidth={true}
										{...register("card_first_name")}
										error={!!errors?.card_first_name}
										helperText={
											errors?.card_first_name?.message
										}
										inputProps={{
											style: {
												textTransform: "uppercase",
											},
											maxLength: 255,
										}}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									style={{ marginTop: "0.5rem" }}
								>
									<TextField
										id="outlined-basic"
										label="Last Name"
										variant="standard"
										InputLabelProps={{
											shrink: true,
										}}
										placeholder="First Name"
										fullWidth={true}
										{...register("card_last_name")}
										error={!!errors?.card_last_name}
										helperText={
											errors?.card_last_name?.message
										}
										inputProps={{
											style: {
												textTransform: "uppercase",
											},
											maxLength: 255,
										}}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									style={{ marginTop: "0.5rem" }}
								>
									<Typography
										align="left"
										style={{
											fontFamily:
												"'Roboto', 'Helvetica', 'Arial', sans-serif",
											fontSize: "1rem",
										}}
									>
										Card Number
									</Typography>
									<iframe
										ref={iframeRef}
										title="tokenframe"
										id="tokenframe"
										name="tokenframe"
										style={{
											width: "100%",
											maxHeight: "57px",
											transform: "translate(0, -1.2rem)",
											marginBottom: "-2.4rem",
										}}
										src={iframeUrl}
									></iframe>
									{(cardError || errors.token) && (
										<p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium Mui-required">
											{cardError || errors.token?.message}
										</p>
									)}
								</Grid>
								<Grid
									item
									xs={4}
									style={{ marginTop: "0.5rem" }}
								>
									<TextField
										select
										variant="standard"
										label="Month"
										id="card_month"
										name="card_month"
										fullWidth={true}
										InputLabelProps={{
											shrink: true,
										}}
										{...register("card_month")}
										error={!!errors?.card_month}
										helperText={errors?.card_month?.message}
										defaultValue=" "
									>
										<option value=" " disabled>
											Month...
										</option>
										{Months.map((option) => (
											<MenuItem
												key={option.value}
												value={option.value}
											>
												{option.label}
											</MenuItem>
										))}
									</TextField>
								</Grid>
								<Grid
									item
									xs={4}
									style={{ marginTop: "0.5rem" }}
								>
									<TextField
										select
										variant="standard"
										label="Year"
										id="card_year"
										name="card_year"
										fullWidth={true}
										InputLabelProps={{
											shrink: true,
										}}
										{...register("card_year")}
										error={!!errors?.card_year}
										helperText={errors?.card_year?.message}
										defaultValue=" "
									>
										<option value=" " disabled>
											Year...
										</option>
										{CreditCardYears.map((option) => (
											<MenuItem
												key={option.value}
												value={option.value}
											>
												{option.label}
											</MenuItem>
										))}
									</TextField>
								</Grid>
								<Grid
									item
									xs={4}
									style={{ marginTop: "0.5rem" }}
								>
									<TextField
										type="text"
										variant="standard"
										label="CVV"
										id="card_cvv"
										name="card_cvv"
										fullWidth={true}
										InputLabelProps={{
											shrink: true,
										}}
										inputProps={{
											style: {
												textTransform: "uppercase",
											},
											maxLength: 4,
											inputMode: "numeric",
											onInput: (e) => {
												e.target.value =
													e.target.value.replace(
														/[^0-9]/g,
														""
													);
											},
										}}
										{...register("card_cvv")}
										error={!!errors?.card_cvv}
										helperText={errors?.card_cvv?.message}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									style={{ marginTop: "0.5rem" }}
								>
									<TextField
										type="text"
										variant="standard"
										label="Billing Address"
										id="billing_address"
										name="billing_address"
										fullWidth={true}
										InputLabelProps={{
											shrink: true,
										}}
										inputProps={{
											style: {
												textTransform: "uppercase",
											},
											maxLength: 255,
										}}
										{...register("billing_address")}
										error={!!errors?.billing_address}
										helperText={
											errors?.billing_address?.message
										}
									/>
								</Grid>
								<Grid
									item
									xs={4}
									style={{ marginTop: "0.5rem" }}
								>
									<TextField
										type="text"
										variant="standard"
										label="Zip code"
										id="zip"
										name="zip"
										fullWidth
										InputLabelProps={{
											shrink: true,
										}}
										inputProps={{
											style: {
												textTransform: "uppercase",
											},
											maxLength: 5,
											inputMode: "numeric",
											onInput: (e) => {
												e.target.value =
													e.target.value.replace(
														/[^0-9]/g,
														""
													);
											},
										}}
										{...register("zip")}
										error={!!errors.zip}
										helperText={errors.zip?.message}
									/>
								</Grid>
								<Grid
									item
									xs={4}
									style={{ marginTop: "0.5rem" }}
								>
									<TextField
										type="text"
										variant="standard"
										label="City"
										id="city"
										name="city"
										fullWidth
										InputLabelProps={{
											shrink: true,
										}}
										inputProps={{
											style: {
												textTransform: "uppercase",
											},
											maxLength: 255,
											readOnly: true,
										}}
										{...register("city")}
										error={!!errors.city}
										helperText={errors.city?.message}
									/>
								</Grid>
								<Grid
									item
									xs={4}
									style={{ marginTop: "0.5rem" }}
								>
									<TextField
										type="text"
										variant="standard"
										label="State"
										id="state"
										name="state"
										fullWidth
										InputLabelProps={{
											shrink: true,
										}}
										inputProps={{
											style: {
												textTransform: "uppercase",
											},
											maxLength: 255,
											readOnly: true,
										}}
										{...register("state")}
										error={!!errors.state}
										helperText={errors.state?.message}
									/>
								</Grid>
							</Grid>
						</Box>
						<input
							type="hidden"
							id="token"
							className="form-control"
							name="token"
							maxLength=""
							autoComplete="off"
							value={token}
							{...register("token")}
						/>
					</form>
				</>
			}
			title={`Add Payment`}
			footer={
				<>
					<Button
						type="submit"
						variant="contained"
						size="small"
						onClick={handleSubmit(onSubmit)}
						disabled={btnLoading}
					>
						Submit
					</Button>
				</>
			}
			onClose={onHide}
			//loading={modalOnLoad}
		></TopModal>
	);
};

export default ContractAddABPModal;
