import React from "react";
import { Helmet } from "react-helmet";

function NotFound() {
    const pageTitle = "404 | Page not found";
    const pageImage = "https://www.rtomobile.com/images/rto-open_graph.png";

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="robots" content="noindex,nofollow"></meta>
                <meta property="og:title" content={pageTitle} />
                <meta property="og:image" content={pageImage} />
                <meta name="twitter:title" content={pageTitle} />
                <meta name="twitter:image" content={pageImage} />
            </Helmet>
            <div className="rto_utility-page-wrap text-center">
                <div className="utility-page-content">
                    <h2 className="h2-alt" style={{ paddingRight: "0" }}>
                        404
                    </h2>
                    <br />
                    <br />
                    <div>
                        <p>Page not found. </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NotFound;
