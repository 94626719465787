import React, { useCallback, useEffect, useRef, useState } from "react";
import TopModal from "../../../common/TopModal";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {
	FormControl,
	InputLabel,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import { format } from "date-fns";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { toast } from "react-toastify";

import PaymentService from "../../../../service/payment/PaymentService";
import { getPaymentImage } from "../../../../helpers/Utils";
import ZipService from "../../../../service/zip/ZipService";
import { CreditCardYears, Months } from "../../../../Constants";

const ContractMakePaymentModal = ({ show, onHide, contractId }) => {
	const [paymentData, setPaymentData] = useState({});
	const [loading, setLoading] = useState(false);
	const [paymentAmount, setPaymentAmount] = useState("0.00");
	const [selectedPaymentMethodId, setSelectedPaymentMethodId] =
		useState(null);
	const [cashBalance, setCashBalance] = useState("---");
	const [tenderValue, setTenderValue] = useState(null);
	const [cashBalanceErrorMessage, setCashBalanceErrorMessage] = useState(
		"Amount received must be greater than or equal to total"
	);
	const [addCardInfo, setAddCardInfo] = useState({
		first_name: "",
		last_name: "",
		month: " ",
		year: " ",
		cvv: "",
		billing_address: "",
		zip: "",
		city: "",
		state: "",
	});

	const [abpEnabled, setAbpEnabled] = useState(false);

	const [tokenizerUrl, setTokenizerUrl] = useState("");
	const [token, setToken] = useState("");
	const [cardError, setCardError] = useState(null);

	const iframeRef = useRef(null);
	const iframeUrl =
		tokenizerUrl +
		"?css=input%7Bbackground-color%3Atransparent%3Bborder%3Anone%3Bborder-bottom%3A1px%20solid%20%239e9e9e%3Bborder-radius%3A0%3Boutline%3Anone%3Bheight%3A3rem%3Bwidth%3A100%25%3Bfont-size%3A16px%3Bmargin%3A0%200%208px%200%3Bpadding%3A0%3B-webkit-box-shadow%3Anone%3Bbox-shadow%3Anone%3B-webkit-box-sizing%3Acontent-box%3Bbox-sizing%3Acontent-box%3B-webkit-transition%3Aborder%20.3s%2C%20-webkit-box-shadow%20.3s%3Btransition%3Aborder%20.3s%2C-webkit-box-shadow%20.3s%3Btransition%3A%20box-shadow%20.3s%2Cborder%20.3s%3B%20transition%3A%20box-shadow%20.3s%2Cborder%20.3s%2C%20-webkit-box-shadow%20.3s%3B%7D.error%7Bborder-bottom%3A1px%20solid%20%23F44336%3B-webkit-box-shadow%3A0%201px%200%200%20%23F44336%3Bbox-shadow%3A0%201px%200%200%20%23F44336%3B%7D.valid%7Bborder-bottom%3A1px%20solid%20%234CAF50%3B-webkit-box-shadow%3A0%201px%200%200%20%234CAF50%3Bbox-shadow%3A0%201px%200%200%20%234CAF50%3B%7Dinput%3Afocus%7Bborder-bottom%3A1px%20solid%20%23337ab7%20%21important%3Bbox-shadow%3A%200%201px%200%200%20%23337ab7%20%21important%3B%7Dbody%7Bmargin%3A0%3B%7D&amp;invalidinputevent=true&amp;placeholder=xxxx%20xxxx%20xxxx%20xxxx";

	const isCashButtonDisabled = () => {
		if (
			Number(paymentAmount) === 0 ||
			selectedPaymentMethodId === null ||
			loading
		) {
			return true;
		}
		if (selectedPaymentMethodId === "CASH") {
			if (
				tenderValue === null ||
				tenderValue === 0 ||
				tenderValue === "0.00" ||
				!tenderValue ||
				parseFloat(tenderValue) <
					parseFloat(paymentData?.payment?.total)
			) {
				return true;
			}
		} else if (selectedPaymentMethodId === "CC") {
			if (!addCardInfo?.cvv || addCardInfo?.cvv.length < 3 || cardError) {
				return true;
			} else {
				for (const value of Object.values(addCardInfo)) {
					// Check if the value is "", null, undefined,
					if (
						value === "" ||
						value === null ||
						value === undefined ||
						!value
					) {
						return true; // Early return false if any field is invalid
					}
				}
			}
		}
		return false;
	};

	const isButtonDisabled = isCashButtonDisabled();

	const contractPaymentGet = async () => {
		setLoading(true);
		return await PaymentService.contractPaymentGet(contractId)
			.then((response) => {
				if (response.status === 200 && response.data.isOk === true) {
					setPaymentData(response?.data?.data);
					setPaymentAmount(
						response?.data?.data?.billing?.amount_due_today
							? response?.data?.data?.billing?.amount_due_today
							: "0.00"
					);

					setTokenizerUrl(
						response?.data?.data?.payment_methods?.CC?.tokenizer_url
					);

					setLoading(false);
				}
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	};

	const handleIframeMessage = useCallback(
		(event) => {
			try {
				const tokenData = JSON.parse(event.data);
				console.log(tokenData);
				if (tokenData.message) {
					// Set the token value to the form
					setToken(tokenData.message);
					setCardError(null);
				} else if (tokenData.validationError) {
					// Set the token value to empty
					setToken("");
					// Set the card error
					setCardError("Card Number is invalid.");
				}
			} catch (error) {
				console.error("Failed to parse event data:", error);
			}
		},
		[setToken]
	);

	const handleMakePayment = async () => {
		if (
			selectedPaymentMethodId === null ||
			selectedPaymentMethodId === ""
		) {
			setLoading(false);

			toast.error(`"Please enter a valid payment amount"`, {
				position: "top-center",
				autoClose: 1000,
			});
		} else {
			setLoading(true);
			return await PaymentService.contractPaymentProcess({
				contract_id: contractId,
				payment_method_id: selectedPaymentMethodId,
				payment: {
					net: paymentAmount,
					tax: paymentData?.payment?.tax,
					fee: paymentData?.payment?.fee,
					total: paymentData?.payment?.total,
				},
			})
				.then((response) => {
					if (
						response.status === 200 &&
						response.data.isOk === true
					) {
						setLoading(false);

						onHide(true);
						toast.info(`Payment completed!`, {
							position: "top-center",
							autoClose: 1000,
						});
					} else {
						setLoading(false);

						toast.error(
							response?.data?.msg
								? response?.data?.msg
								: "Unknown Error!",
							{
								position: "top-center",
								autoClose: 1000,
							}
						);
					}
					setLoading(false);
				})
				.catch((error) => {
					toast.error(`Error: ${error.response.data.error}`, {
						position: "top-center",
						autoClose: 1000,
					});
					setLoading(false);
				});
		}
	};

	const handleAddCardAndMakePayment = async () => {
		// Handle the form submission with validated data
		setLoading(true);
		return await PaymentService.contractPaymentProcess({
			contract_id: contractId,
			payment_method_id: "CC",
			payment: {
				net: paymentAmount,
				tax: paymentData?.payment?.tax,
				fee: paymentData?.payment?.fee,
				total: paymentData?.payment?.total,
			},
			payment_method: {
				billing_address: addCardInfo?.billing_address,
				card_cvv: addCardInfo?.cvv,
				card_first_name: addCardInfo?.first_name,
				card_last_name: addCardInfo?.last_name,
				card_month: addCardInfo?.month,
				card_year: addCardInfo?.year,
				city: addCardInfo?.city,
				state: addCardInfo?.state,
				token: token,
				zip: addCardInfo?.zip,
				abp: abpEnabled ? 1 : 0,
			},
		})
			.then((response) => {
				if (response.status === 200 && response.data.isOk === true) {
					setLoading(false);
					onHide(true);
					toast.info(`Payment completed!`, {
						position: "top-center",
						autoClose: 1000,
					});
				} else {
					// console.log("Post Error", response.data);
					setCardError(response.data.msg);
					setLoading(false);
				}
				setLoading(false);
			})
			.catch((error) => {
				toast.error(`Error: ${error.response.data.error}`, {
					position: "top-center",
					autoClose: 1000,
				});
				setLoading(false);
			});
	};

	useEffect(() => {
		contractPaymentGet();
	}, []);

	useEffect(() => {
		// Add event listener for the iframe message
		window.addEventListener("message", handleIframeMessage);

		return () => {
			// Clean up event listener
			window.removeEventListener("message", handleIframeMessage);
		};
	}, [handleIframeMessage]);

	useEffect(() => {
		const fetchLocationData = async () => {
			try {
				const response = await ZipService.fetchLocationData(
					addCardInfo?.zip
				);
				if (response.ok) {
					const data = await response.json();
					if (data?.error) {
						setAddCardInfo((prevState) => ({
							...prevState,
							city: "",
						}));
						setAddCardInfo((prevState) => ({
							...prevState,
							state: "",
						}));
					} else {
						setAddCardInfo((prevState) => ({
							...prevState,
							city: data.places[0]["place name"],
						}));
						setAddCardInfo((prevState) => ({
							...prevState,
							state: data.places[0]["state abbreviation"],
						}));
					}
				} else {
					setAddCardInfo((prevState) => ({
						...prevState,
						city: "",
					}));
					setAddCardInfo((prevState) => ({
						...prevState,
						state: "",
					}));
				}
			} catch (error) {
				setAddCardInfo((prevState) => ({
					...prevState,
					city: "",
				}));
				setAddCardInfo((prevState) => ({
					...prevState,
					state: "",
				}));
			}
		};

		if (addCardInfo?.zip && addCardInfo?.zip.length === 5) {
			fetchLocationData();
		} else {
			setAddCardInfo((prevState) => ({
				...prevState,
				city: "",
			}));
			setAddCardInfo((prevState) => ({
				...prevState,
				state: "",
			}));
		}
	}, [addCardInfo?.zip]);

	const handleKeyDown = (event) => {
		// Check if the key pressed is the Enter key
		if (event.key === "Enter") {
			// Prevent the default action to avoid submitting a form if the input is part of one
			event.preventDefault();
			// Blur the input field
			event.target.blur();
		}
	};

	return (
		<TopModal
			isVisible={show}
			body={
				<>
					{
						<Grid container>
							<Grid
								item
								xs={12}
								md={4}
								fontSize={14}
								sx={{
									paddingRight: {
										xs: "0px",
										md: "16px",
									},
									paddingBottom: {
										xs: "16px",
										md: "0px",
									},
								}}
							>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
									}}
								>
									<div style={{ textAlign: "left" }}>
										Amount Due Today:
									</div>
									<div style={{ textAlign: "right" }}>
										$
										{paymentData?.billing?.amount_due_today
											? Number(
													paymentData?.billing
														?.amount_due_today
											  )
													.toFixed(2)
													.toLocaleString()
											: "0.00"}
									</div>
								</div>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
									}}
								>
									<div style={{ textAlign: "left" }}>
										Next Amount Due:
									</div>
									<div style={{ textAlign: "right" }}>
										$
										{paymentData?.billing?.next_amount_due
											? Number(
													paymentData?.billing
														?.next_amount_due
											  )
													.toFixed(2)
													.toLocaleString()
											: "0.00"}
									</div>
								</div>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
									}}
								>
									<div style={{ textAlign: "left" }}>
										Next Amount Due Date:
									</div>
									<div style={{ textAlign: "right" }}>
										{paymentData?.billing
											?.next_amount_due_date
											? format(
													new Date(
														paymentData?.billing?.next_amount_due_date
													),
													"MM/dd/yyyy"
											  )
											: "mm/dd/yyyy"}
									</div>
								</div>
							</Grid>

							<Grid
								item
								xs={12}
								md={8}
								// borderLeft="1px solid #a1a1a1"
								style={{ paddingLeft: "16px" }}
								sx={{
									borderLeft: {
										xs: "none",
										md: "1px solid #a1a1a1",
									},
									borderTop: {
										xs: "1px solid #a1a1a1",
										md: "none",
									},
									paddingLeft: {
										xs: "16px",
										md: "0px",
									},
									paddingTop: {
										xs: "16px",
										md: "0px",
									},
								}}
							>
								<Grid container>
									<Grid
										item
										md={10}
										xs={12}
										marginTop={1}
										// style={{ textAlign: "right" }}
										sx={{
											textAlign: {
												xs: "start",
												md: "end",
											},
											margin: {
												xs: "10px 0px",
												md: "0px",
											},
										}}
									>
										{!paymentAmount ||
										paymentAmount === "0.00" ||
										paymentAmount === 0.0 ? (
											<span style={{ color: "#d32f2f" }}>
												Payment Amount: $
											</span>
										) : (
											<span>Payment Amount: $</span>
										)}
									</Grid>
									<Grid
										item
										md={2}
										xs={12}
										paddingLeft={1}
										style={{
											textAlign: "right",
											marginBottom: 10,
										}}
									>
										<FormControl fullWidth>
											<TextField
												required
												error={
													!paymentAmount ||
													paymentAmount === "0.00" ||
													paymentAmount === 0.0
												}
												id="payment-amount"
												type="number"
												variant="standard"
												value={paymentAmount}
												inputProps={{
													style: {
														textAlign: "right",
													},
												}}
												onChange={(e) => {
													setPaymentAmount(
														e.target.value
													);
												}}
												onBlur={(e) => {
													setPaymentAmount(
														Number(e.target.value)
															.toFixed(2)
															.toLocaleString()
													);

													return PaymentService.contractPaymentCalculate(
														{
															contract_id:
																contractId,
															payment: {
																net: e.target
																	.value,
															},
														}
													)
														.then((response) => {
															if (
																response.status ===
																	200 &&
																response.data
																	.isOk ===
																	true
															) {
																let data =
																	response
																		?.data
																		?.data;

																setPaymentData(
																	(
																		prevState
																	) => ({
																		...prevState,
																		payment:
																			{
																				...prevState.payment,
																				fee: data
																					?.payment
																					?.fee
																					? data
																							?.payment
																							?.fee
																					: 0,
																				tax: data
																					?.payment
																					?.tax
																					? data
																							?.payment
																							?.tax
																					: 0,
																				total: data
																					?.payment
																					?.total
																					? data
																							?.payment
																							?.total
																					: 0,
																				tax_rate:
																					data
																						?.payment
																						?.tax_rate
																						? data
																								?.payment
																								?.tax_rate
																						: 0,
																			},
																	})
																);
															}
														})
														.catch((error) => {
															console.log(error);
														});
												}}
												onKeyDown={handleKeyDown}
											/>
										</FormControl>
									</Grid>
									<Grid
										item
										xs={6}
										md={10}
										sx={{
											textAlign: {
												xs: "start",
												md: "end",
											},
											margin: {
												xs: "10px 0px",
												md: "0px",
											},
										}}
									>
										Tax (
										{paymentData?.payment?.tax_rate
											? paymentData?.payment?.tax_rate
											: "0.00"}
										%):
										<br />
										Payment Fee:
										<br />
										Total:
									</Grid>
									<Grid
										item
										md={2}
										xs={6}
										sx={{
											textAlign: {
												xs: "end",
												md: "end",
											},
											margin: {
												xs: "10px 0px",
												md: "0px",
											},
										}}
									>
										$
										{paymentData?.payment?.tax
											? paymentData?.payment?.tax
											: "0.00"}{" "}
										<br />$
										{paymentData?.payment?.fee
											? paymentData?.payment?.fee
											: "0.00"}{" "}
										<br />$
										{paymentData?.payment?.total
											? paymentData?.payment?.total
											: "0.00"}
									</Grid>
									<Grid
										item
										xs={12}
										style={{ textAlign: "left" }}
									>
										<Typography
											gutterBottom
											component="div"
											style={{ fontWeight: "bold" }}
										></Typography>
										<FormControl
											fullWidth={true}
											size="small"
											error={
												selectedPaymentMethodId ===
													"" ||
												selectedPaymentMethodId === null
											}
										>
											<InputLabel
												disabled={loading}
												id="payment-method-label"
											>
												Payment Method
											</InputLabel>
											<Select
												labelId="payment-method-label"
												label="Payment Method"
												value={selectedPaymentMethodId}
												disabled={loading}
												onChange={(e) => {
													setSelectedPaymentMethodId(
														e.target.value
													);
													setAddCardInfo({
														first_name: "",
														last_name: "",
														month: " ",
														year: " ",
														cvv: "",
														billing_address: "",
														zip: "",
														city: "",
														state: "",
													});

													setTenderValue("");
												}}
											>
												{paymentData?.payment_methods &&
													Object.entries(
														paymentData?.payment_methods
													).map(
														([key, value]) =>
															value.id !==
																null && (
																<MenuItem
																	key={key}
																	value={
																		value.id
																	}
																>
																	<ListItemText>
																		{getPaymentImage(
																			value.account_type
																		)}
																		Ends
																		with{" "}
																		{
																			value.cc_last_four
																		}
																		{"   "}
																		<small>
																			{value.cc_expiration.replace(
																				/(\d{2})(\d{2})/,
																				"$1/$2"
																			)}
																		</small>
																	</ListItemText>{" "}
																</MenuItem>
															)
													)}

												<MenuItem
													key="CASH"
													value="CASH"
												>
													<ListItemText>
														<img
															src="/images/cash-payment.png?v1"
															class="rto_card-logo-sm"
															alt=""
														/>
														Cash Payment
													</ListItemText>
												</MenuItem>
												<MenuItem key="CC" value="CC">
													<ListItemText>
														<img
															src="/images/card_default.png"
															class="rto_card-logo-sm"
															alt=""
														/>
														Add Credit Card
													</ListItemText>
												</MenuItem>
											</Select>
										</FormControl>
									</Grid>
									{selectedPaymentMethodId === "CASH" && (
										<>
											<Grid
												item
												xs={10}
												marginTop={2}
												style={{
													textAlign: "right",
												}}
											>
												{!tenderValue ||
												parseFloat(tenderValue) <
													parseFloat(
														paymentData?.payment
															?.total
													) ? (
													<span
														style={{
															color: "#d32f2f",
														}}
													>
														Amount Tendered: $
													</span>
												) : (
													<span>
														Amount Tendered: $
													</span>
												)}
											</Grid>
											<Grid
												item
												xs={2}
												marginTop={1}
												paddingLeft={1}
												style={{
													textAlign: "right",
												}}
											>
												<TextField
													id="cash-balance"
													variant="standard"
													error={
														!tenderValue ||
														parseFloat(
															tenderValue
														) <
															parseFloat(
																paymentData
																	?.payment
																	?.total
															)
													}
													value={tenderValue}
													inputProps={{
														style: {
															textAlign: "right",
														},
													}}
													onChange={(e) => {
														setTenderValue(
															e.target.value
														);

														if (
															parseFloat(
																e.target.value
															) >=
															parseFloat(
																paymentData
																	?.payment
																	?.total
															)
														) {
															let balance =
																parseFloat(
																	e.target
																		.value
																) -
																paymentData
																	?.payment
																	?.total;
															setCashBalance(
																balance.toFixed(
																	2
																)
															);

															setCashBalanceErrorMessage(
																""
															);
														} else {
															setCashBalance(
																"---"
															);
															setCashBalanceErrorMessage(
																"Amount received must be greater than or equal to total"
															);
														}
													}}
													onBlur={(e) => {
														setTenderValue(
															Number(
																e.target.value
															)
																.toFixed(2)
																.toLocaleString()
														);
													}}
													onKeyDown={handleKeyDown}
												/>
											</Grid>
											{cashBalanceErrorMessage && (
												<p
													style={{
														textAlign: "end",
														width: "100%",
													}}
													className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium Mui-required"
												>
													{cashBalanceErrorMessage}
												</p>
											)}
											<Grid
												item
												xs={10}
												marginTop={1}
												style={{
													textAlign: "right",
												}}
											>
												Change Due: $
											</Grid>
											<Grid
												item
												xs={2}
												marginTop={1}
												style={{
													textAlign: "right",
												}}
											>
												{cashBalance}
											</Grid>
										</>
									)}
									{selectedPaymentMethodId === "CC" && (
										<Grid
											container
											spacing={2}
											marginTop={2}
										>
											<Grid
												item
												xs={12}
												style={{
													padding: "0px !important",
													margin: "0px !important",
													display: "flex",
													justifyContent: "start",
												}}
											>
												<FormControlLabel
													control={
														<Switch
															checked={abpEnabled}
															onChange={(e) => {
																setAbpEnabled(
																	e.target
																		.checked
																);
															}}
														/>
													}
													label="ABP Enabled"
												/>
											</Grid>
											<Grid item xs={6}>
												<TextField
													id="outlined-basic"
													label="First Name"
													variant="standard"
													InputLabelProps={{
														shrink: true,
													}}
													placeholder="First Name"
													fullWidth={true}
													inputProps={{
														style: {
															textTransform:
																"uppercase",
														},
														maxLength: 255,
													}}
													value={
														addCardInfo?.first_name
													}
													onChange={(e) => {
														setAddCardInfo(
															(prevState) => ({
																...prevState,
																first_name:
																	e.target
																		.value,
															})
														);
													}}
													error={
														!addCardInfo?.first_name
													}
												/>
											</Grid>
											<Grid item xs={6}>
												<TextField
													id="outlined-basic"
													label="Last Name"
													variant="standard"
													InputLabelProps={{
														shrink: true,
													}}
													placeholder="Last Name"
													fullWidth={true}
													inputProps={{
														style: {
															textTransform:
																"uppercase",
														},
														maxLength: 255,
													}}
													value={
														addCardInfo?.last_name
													}
													onChange={(e) => {
														setAddCardInfo(
															(prevState) => ({
																...prevState,
																last_name:
																	e.target
																		.value,
															})
														);
													}}
													error={
														!addCardInfo?.last_name
													}
												/>
											</Grid>

											{/* Card Number Start */}
											{tokenizerUrl ? (
												<Grid
													item
													xs={12}
													marginBottom={"20px"}
												>
													<Typography
														align="left"
														style={{
															fontFamily:
																"'Roboto', 'Helvetica', 'Arial', sans-serif",
															fontSize: "1rem",
														}}
													>
														Card Number
													</Typography>
													<iframe
														ref={iframeRef}
														title="tokenframe"
														id="tokenframe"
														name="tokenframe"
														style={{
															width: "100%",
															maxHeight: "57px",
															transform:
																"translate(0, -1.2rem)",
															marginBottom:
																"-2.4rem",
														}}
														src={iframeUrl}
													></iframe>
													{cardError && (
														<p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium Mui-required">
															{cardError}
														</p>
													)}
												</Grid>
											) : (
												<Grid item xs={12}>
													<p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium Mui-required">
														No token URL found.
														Please contact support.
													</p>
												</Grid>
											)}
											{/* Card Number End */}

											<Grid item xs={4}>
												<TextField
													select
													variant="standard"
													label="Month"
													id="card_month"
													name="card_month"
													fullWidth={true}
													InputLabelProps={{
														shrink: true,
													}}
													defaultValue=" "
													value={addCardInfo?.month}
													onChange={(e) => {
														setAddCardInfo(
															(prevState) => ({
																...prevState,
																month: e.target
																	.value,
															})
														);
													}}
													error={
														!addCardInfo?.month ||
														addCardInfo?.month ===
															" "
													}
												>
													<MenuItem
														disabled
														value=" "
													>
														Month...
													</MenuItem>
													{Months.map((option) => (
														<MenuItem
															key={option.value}
															value={option.value}
														>
															{option.label}
														</MenuItem>
													))}
												</TextField>
											</Grid>
											<Grid item xs={4}>
												<TextField
													select
													variant="standard"
													label="Year"
													id="card_year"
													name="card_year"
													fullWidth={true}
													InputLabelProps={{
														shrink: true,
													}}
													defaultValue=" "
													value={addCardInfo?.year}
													onChange={(e) => {
														setAddCardInfo(
															(prevState) => ({
																...prevState,
																year: e.target
																	.value,
															})
														);
													}}
													error={
														!addCardInfo?.year ||
														addCardInfo?.year ===
															" "
													}
												>
													<MenuItem
														disabled
														value=" "
													>
														Year...
													</MenuItem>
													{CreditCardYears.map(
														(option) => (
															<MenuItem
																key={
																	option.value
																}
																value={
																	option.value
																}
															>
																{option.label}
															</MenuItem>
														)
													)}
												</TextField>
											</Grid>
											<Grid item xs={4}>
												<TextField
													type="text"
													variant="standard"
													label="CVV"
													id="card_cvv"
													name="card_cvv"
													fullWidth={true}
													InputLabelProps={{
														shrink: true,
													}}
													inputProps={{
														style: {
															textTransform:
																"uppercase",
														},
														maxLength: 4,
														inputMode: "numeric",
														onInput: (e) => {
															e.target.value =
																e.target.value.replace(
																	/[^0-9]/g,
																	""
																);
														},
													}}
													value={addCardInfo?.cvv}
													onChange={(e) => {
														setAddCardInfo(
															(prevState) => ({
																...prevState,
																cvv: e.target
																	.value,
															})
														);
													}}
													error={
														!addCardInfo?.cvv ||
														addCardInfo?.cvv
															.length < 3
													}
													helperText={
														(!addCardInfo?.cvv &&
															"CVV must be at least 3 characters long!") ||
														(addCardInfo?.cvv
															.length < 3 &&
															"CVV must be at least 3 characters long!")
													}
												/>
											</Grid>
											<Grid item xs={12}>
												<TextField
													type="text"
													variant="standard"
													label="Billing Address"
													id="billing_address"
													name="billing_address"
													fullWidth={true}
													InputLabelProps={{
														shrink: true,
													}}
													inputProps={{
														style: {
															textTransform:
																"uppercase",
														},
														maxLength: 255,
													}}
													value={
														addCardInfo?.billing_address
													}
													onChange={(e) => {
														setAddCardInfo(
															(prevState) => ({
																...prevState,
																billing_address:
																	e.target
																		.value,
															})
														);
													}}
													error={
														!addCardInfo?.billing_address
													}
												/>
											</Grid>
											<Grid item xs={4}>
												<TextField
													type="text"
													variant="standard"
													label="Zip code"
													id="zip"
													name="zip"
													fullWidth
													InputLabelProps={{
														shrink: true,
													}}
													inputProps={{
														style: {
															textTransform:
																"uppercase",
														},
														maxLength: 5,
														inputMode: "numeric",
														onInput: (e) => {
															e.target.value =
																e.target.value.replace(
																	/[^0-9]/g,
																	""
																);
														},
													}}
													value={addCardInfo?.zip}
													onChange={(e) => {
														setAddCardInfo(
															(prevState) => ({
																...prevState,
																zip: e.target
																	.value,
															})
														);
													}}
													error={
														!addCardInfo?.zip ||
														addCardInfo?.zip
															?.length !== 5 ||
														!addCardInfo?.city ||
														!addCardInfo?.state
													}
													helperText={
														(!addCardInfo?.city &&
															"Zip code not found.") ||
														(!addCardInfo?.state &&
															"Zip code not found.")
													}
												/>
											</Grid>
											<Grid item xs={4}>
												<TextField
													type="text"
													variant="standard"
													label="City"
													id="city"
													name="city"
													fullWidth
													disabled
													InputLabelProps={{
														shrink: true,
													}}
													inputProps={{
														style: {
															textTransform:
																"uppercase",
														},
														maxLength: 255,
														readOnly: true,
													}}
													value={addCardInfo?.city}
												/>
											</Grid>
											<Grid item xs={4}>
												<TextField
													type="text"
													variant="standard"
													label="State"
													id="state"
													name="state"
													fullWidth
													disabled
													InputLabelProps={{
														shrink: true,
													}}
													inputProps={{
														style: {
															textTransform:
																"uppercase",
														},
														maxLength: 255,
														readOnly: true,
													}}
													value={addCardInfo?.state}
												/>
											</Grid>
										</Grid>
									)}
								</Grid>
							</Grid>
						</Grid>
					}
					{loading && (
						<div className="loading-backdrop">
							<CircularProgress />
						</div>
					)}
				</>
			}
			title={`Make Payment`}
			footer={
				<>
					<Button
						disabled={isButtonDisabled}
						type="submit"
						variant="contained"
						size="small"
						onClick={() => {
							selectedPaymentMethodId === "CC"
								? handleAddCardAndMakePayment()
								: handleMakePayment();
						}}
					>
						Submit
					</Button>
				</>
			}
			onClose={() => {
				onHide(false);
			}}
			//loading={modalOnLoad}
		></TopModal>
	);
};

export default ContractMakePaymentModal;
