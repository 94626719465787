import React from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Box } from "@mui/material";
import "./Resources.css";

function AppleInstallation({ loading, setLoading }) {
	return (
		<>
			<div className="rto_form-sections">
				<div className="rto_content-div" style={{ maxWidth: "1600px" }}>
					<Box
						display="flex"
						justifyContent="flex-end"
						backgroundColor="#f5f5f5"
						p={(0.5, 1)}
						borderRadius={1}
					>
						<Breadcrumbs aria-label="breadcrumb">
							<Link to="/dashboard" className="rto_link-2">
								Dashboard
							</Link>
							<Link to="/resources" className="rto_link-2">
								Resources
							</Link>
							<Typography>
								Apple Installation Instructions
							</Typography>
						</Breadcrumbs>
					</Box>

					<div
						className="rto_title-div"
						style={{ textAlign: "left" }}
					>
						<h1>
							RTO LOCK SOFTWARE INSTALLATION STEPS FOR APPLE
							iPHONES AND iPADS
						</h1>

						<Box
							sx={{
								display: "grid",
								bgcolor: "grey.100",
								color: "red",
								border: "1px solid grey.100",
								p: 1,
								borderRadius: 2,
							}}
						>
							<p>
								<b>Important Reminder:</b>
								<br /> Ensure that the device:
								<ul className="ul-decimal">
									<li>Is powered on,</li>
									<li>Has been freshly factory reset, and</li>
									<li>
										Is on the Welcome (or Hello) screen
										before taking the following steps.
									</li>
								</ul>
							</p>
						</Box>

						<p>
							<ul className="ul-decimal">
								<li>
									Login to{" "}
									<b>
										<a
											href="https://app.rtomobile.com/"
											target="_blank"
											rel="noreferrer"
										>
											RTO Mobile Portal
										</a>
									</b>{" "}
									and click on the <b>Menu</b> button
									<p>
										<img
											alt=""
											src="/images/resources/android/1.png"
										/>
									</p>
								</li>
								<li>
									From the <strong>Menu</strong>, choose click
									on the <strong>Device Provisioning</strong>{" "}
									selection
									<p>
										<img
											alt=""
											src="/images/resources/android/2.png"
										/>
									</p>
								</li>
								<li>
									Click{" "}
									<strong>
										Create New Device Provisioning Batch
									</strong>
									<p>
										<img
											alt=""
											src="/images/resources/android/3.png"
										/>
									</p>
								</li>
								<li>
									Click <strong>Continue Workorder</strong>
									<p>
										<img
											alt=""
											src="/images/resources/android/4.png"
										/>
									</p>
								</li>
								<li>
									Under Device Type, choose{" "}
									<strong>Apple iPhone</strong>, enter the{" "}
									<strong>IMEI</strong> and <strong>Serial Number</strong> then click on{" "}
									<strong>Add</strong>. The device details
									will show then click on the{" "}
									<strong>Continue</strong> button.
									<p>
										<img
											alt=""
											src="/images/resources/apple/5-1.png"
										/>
									</p>
									<p>
										The <strong>IMEI</strong> and{" "}
										<strong>Serial Number</strong> of the
										device can be obtained through{" "}
										<strong>Apple Configurator</strong>.
										Double click on the phone image and get
										the details from there.
									</p>
									<p>
										<img
											alt=""
											src="/images/resources/apple/5-2.png"
										/>
									</p>
								</li>
								<li>
									The first set of instructions will appear.
									Connect the device to WiFi and once it's on
									Data and Privacy, set the phone down.{" "}
									<span style={{ color: "red" }}>
										REMINDER:{" "}
										<u>
											DO NOT FORGET TO SELECT PREPARE THE
											DEVICE INSIDE APPLE CONFIGURATOR.
											THEN, WHEN THE DEVICE STARTS
											RESETTING, PLEASE CLICK ON CONTINUE.
										</u>
									</span>
									<p>
										<img
											alt=""
											src="/images/resources/apple/6.png"
										/>
									</p>
								</li>
								<li>
									Now, prepare the device inside{" "}
									<strong>Apple Configurator</strong> by
									clicking <strong>Next</strong> all the way
									through until the last button which is{" "}
									<strong>Prepare</strong>. Click on{" "}
									<strong>Prepare</strong> Wait for the phone
									screen to turn black, then click on{" "}
									<strong>Continue</strong> inside the RTO
									Mobile Portal.
									<p>
										<img
											alt=""
											src="/images/resources/apple/7-1.png"
										/>
									</p>
									<p>
										<img
											alt=""
											src="/images/resources/apple/7-2.png"
										/>
									</p>
								</li>
								<li>
									The RTO Mobile portal will show a{" "}
									<strong>
										Pending Initial Verification
									</strong>{" "}
									status. Wait for it to update to{" "}
									<strong>Passed Initial Verification</strong>{" "}
									then click the <strong>Continue</strong>{" "}
									button.
									<p>
										<img
											alt=""
											src="/images/resources/apple/8-1.png"
										/>
									</p>
									<p>
										<img
											alt=""
											src="/images/resources/apple/8-2.png"
										/>
									</p>
								</li>
								<li>
									The status in the RTO Mobile Portal will
									show <strong>Pending Enrollment</strong>.
									<p>
										<img
											alt=""
											src="/images/resources/apple/9.png"
										/>
									</p>
								</li>
								<li>
									When enrollment has completed, a green check
									mark will appear and the status will change
									to <strong>Device Enrolled</strong>. Then,
									click the <strong>Continue</strong> button.
									<p>
										<img
											alt=""
											src="/images/resources/apple/10.png"
										/>
									</p>
								</li>
								<li>
									After clicking on the{" "}
									<strong>Continue</strong> button, the{" "}
									<strong>
										Device Software Installation
									</strong>{" "}
									page will appear where a set of instructions
									will be given.
									<p>
										You must then immediately connect the
										device to WiFi.
									</p>
									<p>
										Continue by affirmatively answering
										prompts on the device (e.g. Accept,
										Continue, Agree, Next, etc.) all the way
										to the main screen where you see all the
										apps.{" "}
										<span style={{ color: "red" }}>
											(REMINDER: Please do not connect the
											device to WiFi until you get to this
											page) Doing so will cause the
											installation to fail.
										</span>
									</p>
									<p>
										<img
											alt=""
											src="/images/resources/apple/11.png"
										/>
									</p>
								</li>
								<li>
									Wait for the RTO Mobile Portal to show{" "}
									<strong>Software Installed</strong> with a
									green check mark. Then click on the{" "}
									<strong>Continue</strong> button.
									<p>
										<img
											alt=""
											src="/images/resources/apple/12.png"
										/>
									</p>
								</li>
								<li>
									Click the <strong>Click To Lock</strong>{" "}
									button to test the lock functionality. The
									device screen should say “LOST iPHONE” and
									the device should be locked. The device
									screen will provide a{" "}
									<strong>4 digit code</strong>. Enter the
									code on the portal, and click on the{" "}
									<strong>Submit Code</strong> button (click
									only once).
									<p>
										<img
											alt=""
											src="/images/resources/apple/13-1.png"
										/>
									</p>
									<p>
										<img
											alt=""
											src="/images/resources/apple/13-2.png"
										/>
									</p>
								</li>
								<li>
									The code you entered will then be submitted,
									and the portal will show{" "}
									<strong>Device Provisioned</strong>. Click
									<strong>Continue</strong> then{" "}
									<strong>Return to Workorders</strong> to go
									back to the Device Provisioning page where
									your provisioned devices will be listed
									(e.g. your inventory of devices ready for
									customer payment plans).
									<p>
										<img
											alt=""
											src="/images/resources/apple/14.png"
										/>
									</p>
								</li>
							</ul>
						</p>
					</div>

					<div>
						<div className="rto_results-container"></div>
					</div>
				</div>
			</div>
		</>
	);
}

export default AppleInstallation;
