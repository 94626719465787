import React, { useEffect, useState } from "react";
import TopModal from "../../../common/TopModal";
import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";
// import TextField from "@mui/material/TextField";
// import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
// import MenuItem from "@mui/material/MenuItem";
import ContractService from "../../../../service/contract/ContractService";
// import { DatePicker } from "../../../common/DatePicker";
// import { parseDateString } from "../../../../helpers/Utils";
import dayjs from "dayjs";

import CustomSelectComponent from "../../../formComponents/rtoDynamicForm/fields/CustomSelectComponent";

// MUI Date Picker Imports
import { format } from "date-fns";
import CustomDateInputComponent from "../../../formComponents/rtoDynamicForm/fields/CustomDateInputComponent";
import CustomInputComponent from "../../../formComponents/rtoDynamicForm/fields/CustomInputComponent";

const ContractTextToEditModal = ({
	show,
	onHide,
	setTextToEditData,
	textToEditData,
	contractId,
	selectOptions,
}) => {
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [disabled, setDisabled] = useState(false);
	const onUpdate = () => {
		let data = {};
		if (textToEditData?.type === "date") {
			const formattedDate = textToEditData?.value
				? format(new Date(textToEditData?.value), "yyyy-MM-dd")
				: "";
			data = {
				contract_id: contractId,
				update: {
					[textToEditData?.name]: formattedDate,
				},
			};
		} else {
			data = {
				contract_id: contractId,
				update: {
					[textToEditData?.name]: textToEditData?.value,
				},
			};
		}

		//const response = ContractService.update(data).then((response) => {
		ContractService.update(data).then((response) => {
			if (response.status === 200 && response.data.isOk === true) {
				onHide(true);
				setError(false);
				setErrorMessage("");
			} else {
				setError(true);
				setErrorMessage(response.data.errors[`${textToEditData.name}`]);
			}
		});
	};

	const handleInputChange = (fieldName, value, pIsValid) => {
		setTextToEditData({
			...textToEditData,
			value: value,
		});
		console.log("value -> ", value);
		if (textToEditData?.type === "email") {
			if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
				setError(true);
				setErrorMessage("Please enter a valid email address");
			} else {
				setError(false);
				setErrorMessage("");
			}
		} else if (textToEditData?.type === "phone") {
			if (!value.match(/^\d{10}$/i)) {
				setError(true);
				setErrorMessage("Please enter a valid phone number");
			} else {
				setError(false);
				setErrorMessage("");
			}
		} else if (textToEditData?.type === "ssn") {
			if (!value.match(/^\d{4}$/i)) {
				setError(true);
				setErrorMessage("SSN must be a 4-digit number.");
			} else {
				setError(false);
				setErrorMessage("");
			}
		} else if (textToEditData?.type === "number") {
			if (!value.toString().match(/^\d+$/)) {
				setError(true);
				setErrorMessage("Please enter a valid number.");
			} else {
				setError(false);
				setErrorMessage("");
			}
		} else if (textToEditData?.type === "decimal") {
			if (!value.toString().match(/^\d+(\.\d+)?$/)) {
				setError(true);
				setErrorMessage("Please enter a valid number.");
			} else {
				setError(false);
				setErrorMessage("");
			}
		} else if (textToEditData?.type === "text-required") {
			if (!value === "") {
				setError(true);
				setErrorMessage("Please enter a value.");
			} else {
				setError(false);
				setErrorMessage("");
			}
		}
	};

	useEffect(() => {
		isDisabled();
	}, [textToEditData]);

	const isDisabled = () => {
		if (textToEditData?.type === "date") {
			if (
				textToEditData?.value === "" ||
				textToEditData?.value === null
			) {
				setDisabled(true);
			} else {
				setDisabled(false);
			}
		}
	};

	return (
		<TopModal
			isVisible={show}
			body={
				<>
					{textToEditData?.type === "date" ? (
						// <DatePicker
						// 	value={parseDateString(textToEditData?.value)}
						// 	onChange={handleInputChange}
						// 	// minDate={new Date()}
						// />
						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<div
								className="custom-date-input-container"
								// style={{ width: "30%" }}
							>
								<CustomDateInputComponent
									value={dayjs(textToEditData?.value)}
									onChange={handleInputChange}
									maxDate={dayjs(new Date())}
								></CustomDateInputComponent>
							</div>
						</div>
					) : textToEditData?.type === "select" ? (
						<>
							<FormControl
								sx={{ m: 1, width: "80%" }}
								size="small"
							>
								{/* <InputLabel id="select-label">
									{textToEditData?.label}{" "}
								</InputLabel> */}

								<CustomSelectComponent
									name={"select-label"}
									label={textToEditData?.label}
									type={{
										options: textToEditData?.selectOptions,
									}}
									value={textToEditData?.value}
									onChange={(fieldName, value, pIsValid) =>
										handleInputChange(
											fieldName,
											value,
											pIsValid
										)
									}
									className={""}
									required={true}

									// style={fieldInfo?.style}
								/>
								{/* <Select
									labelId="select-label"
									label={textToEditData?.label}
									value={textToEditData?.value}
									onChange={handleInputChange}
								>
									{textToEditData?.selectOptions.map(
										(option) => (
											<MenuItem
												key={option.id}
												value={option.id}
											>
												{option.name}
											</MenuItem>
										)
									)}
								</Select> */}
							</FormControl>
						</>
					) : (
						// <TextField
						// 	id="outlined-basic"
						// 	label={textToEditData?.label}
						// 	variant="standard"
						// 	value={textToEditData?.value}
						// 	placeholder={textToEditData?.label}
						// 	fullWidth
						// 	onChange={handleInputChange}
						// 	error={error}
						// 	helperText={errorMessage}
						// 	InputLabelProps={{ shrink: true }}
						// 		/>
						<CustomInputComponent
							name={"last_name"}
							label={textToEditData?.label}
							value={textToEditData?.value}
							type={{ required: true }}
							InputLabelProps={{
								shrink: true,
							}}
							onChange={(fieldName, value, pIsValid) => {
								handleInputChange(fieldName, value, pIsValid);
							}}
							className={""}
							required={true}
							error={error}
							helperText={errorMessage}
						/>
					)}
				</>
			}
			title={`Edit ${textToEditData?.label}`}
			footer={
				<>
					<Button
						disabled={disabled}
						variant="contained"
						onClick={onUpdate}
					>
						{" "}
						Update{" "}
					</Button>
				</>
			}
			onClose={onHide}
			// loading={modalLoading}
		></TopModal>
	);
};

export default ContractTextToEditModal;
