import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Switch } from "@mui/material";
import { formatValue } from "react-currency-input-field";
import StoreProductService from "../../../service/store/StoreProductService";

function StoreProductsModelTable({
	modelId,
	model,
	activeTab,
	loading,
	setLoading,
}) {
	const [storeProducts, setStoreProducts] = useState([]);

	const fetchStoreProducts = async (store_id) => {
		try {
			// Set loading to true
			setLoading(true);

			const response = await StoreProductService.fetchByStoreId({
				storeId: store_id,
				page: 1,
				limit: 300,
				sort: "sku:asc",
			});
			// Filter out non-product properties before mapping
			const productEntries = Object.entries(response.data.data).filter(
				([key, value]) => !["isOk", "msg"].includes(key)
			);
			const productsWithChecked = productEntries.map(
				([key, product]) => ({
					...product,
					checked: product.status_id === 1, // Add a checked property based on status_id
				})
			);
			setStoreProducts(productsWithChecked);

			// Set loading to false
			setLoading(false);
		} catch (error) {
			console.error("Error fetching store products:", error);
		}
	};

	useEffect(() => {
		if (modelId !== undefined) {
			fetchStoreProducts(modelId);
		}
	}, [modelId]);

	return (
		<>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="Store Products">
					<TableHead>
						<TableRow>
							<TableCell>SKU</TableCell>
							<TableCell>Description</TableCell>
							<TableCell>Status</TableCell>
							<TableCell>Cost</TableCell>
							<TableCell>Rev Share</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{[...storeProducts]
							.sort((a, b) => a.sku - b.sku)
							.map((product, index) => (
								<TableRow key={index}>
									<TableCell
										className="new_sp"
										data-id={product.product_id}
									>
										{product.sku}
									</TableCell>
									<TableCell>
										{product.product_name}
									</TableCell>
									<TableCell
										style={{
											textAlign: "center",
										}}
									>
										<Switch
											name="store_status_checkbox"
											checked={product.checked}
											onChange={(event) => {
												const currentStatus =
													event.target.checked;
												const targetSku = product.sku;
												const is4004or4005 = [
													4004, 4005,
												].includes(targetSku);

												// Check if trying to enable either 4004 or 4005
												if (
													currentStatus &&
													is4004or4005
												) {
													const otherSku =
														targetSku === 4004
															? 4005
															: 4004;
													const otherProductIsActive =
														storeProducts.some(
															(p) =>
																p.sku ===
																	otherSku &&
																p.checked
														);

													if (otherProductIsActive) {
														alert(
															`SKU ${targetSku} cannot be enabled while SKU ${otherSku} is active.`
														);
														return; // Prevent the switch from being toggled
													}
												}

												// Update the checked state for this product
												const updatedProducts =
													storeProducts.map((p) =>
														p.id === product.id
															? {
																	...p,
																	checked:
																		currentStatus,
															  }
															: p
													);
												setStoreProducts(
													updatedProducts
												);

												const status_id = currentStatus
													? 1
													: 0;
												StoreProductService.update({
													storeProductId: product.id,
													status_id: status_id,
												}).then((response) => {
													// Optionally, refresh the products list to reflect the change
													fetchStoreProducts(modelId);
												});
											}}
										/>
									</TableCell>
									<TableCell>
										{formatValue({
											value: product.default_price
												? product.default_price.toString()
												: "0",
											groupSeparator: ",",
											decimalSeparator: ".",
											decimalScale: 2,
											prefix: "$",
										})}
									</TableCell>
									<TableCell>
										{formatValue({
											value: product.default_share
												? product.default_share.toString()
												: "0",
											groupSeparator: ",",
											decimalSeparator: ".",
											decimalScale: 2,
											suffix: "%",
										})}
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}

export default StoreProductsModelTable;
