import * as React from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./ContractsViewTable.css";

export default function ContractsViewTable({ columns, data, customToolbar }) {
	// Default Toolbar
	const DefaultToolBar = () => {
		return <React.Fragment></React.Fragment>;
	};

	const customTheme = createTheme({
		components: {
			MUIDataTableHeadCell: {
				styleOverrides: {
					root: {
						backgroundColor: "#eff3f7",
					},
					paper: {
						borderRadius: "10px",
						overFlow: "hidden",
					},
				},
			},
		},
	});

	const options = {
		customToolbar: customToolbar ? customToolbar : DefaultToolBar,
		filter: false,
		elevation: 0,
		responsive: "standard",
		fixedHeader: true,
		fixedFooter: true,
		selectableRows: "none",
		search: false,
		download: false, // Since you are adding a custom download button
		print: false,
		viewColumns: false,
		sort: false,
		pagination: false, // This line disables pagination and rows per page option
	};

	return (
		<ThemeProvider theme={customTheme}>
			<div className="parent">
				<MUIDataTable
					// title={"Payment History"}
					columns={columns}
					data={data}
					options={options}
				/>
			</div>
		</ThemeProvider>
	);
}
