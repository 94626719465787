import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html#Reminder
 */

const ReminderService = {
	/**
	 * Get list of reminder statuses
	 * @see /docs/api/index.html#jump-Reminder-StatusList
	 * @returns JSON
	 */
	getStatusList: () => {
		return ApiService.get("/reminder/status-list").catch((error) => {
			console.log(error);
		});
	},

	/**
	 * Get list of reminder types
	 * @see /docs/api/index.html#jump-Reminder-TypesList
	 * @returns JSON
	 */
	getTypesList: () => {
		return ApiService.get("/reminder/types-list").catch((error) => {
			console.log(error);
		});
	},

	/**
	 * View reminders
	 * @param array data pass serach filter values if needed
	 * @see /docs/api/index.html#jump-Reminder-Index
	 * @returns
	 */
	view: (data) => {
		return ApiService.post("/reminder/index", data).catch((error) => {
			console.log(error);
			return error;
		});
	},
	/**
	 * @deprecated
	 * @param int modelId
	 * @param string modelName
	 * @method POST
	 * @see /docs/api/index.html#jump-Reminder-FetchByModel
	 * @returns JSON
	 */
	/*fetchByIdModel: (data) => {
        return ApiService.post("/reminder/fetch-by-model", data).catch(
            (error) => {
                console.log(error);
                return error;
            }
        );
    },*/

	/**
	 * Data filter by ID
	 * @param {int} id
	 * @see /docs/api/index.html#jump-Reminder-FetchById
	 * @returns JSON
	 */
	fetchById: (id) => {
		return ApiService.get("/reminder/fetch-by-id", { id: id }).catch(
			(error) => {
				console.log(error);
			}
		);
	},

	/**
	 * @param int modelId
	 * @param string modelName
	 * @param string date
	 * @param string description
	 * @param int remindersTypeId
	 * @param int assignedTo
	 * @see /docs/api/index.html#jump-Reminder-Create
	 * @returns JSON
	 */
	create: (
		modelName,
		modelId,
		date,
		description,
		remindersTypeId,
		assignedTo
	) => {
		return ApiService.post("/reminder/create", {
			modelId: modelId,
			modelName: modelName,
			date: date,
			description: description,
			remindersTypeId: remindersTypeId,
			assignedTo: assignedTo,
		}).catch((error) => {
			console.log(error);
		});
	},

	/**
	 * Update service
	 * @param json data {reminderId: 1, description:Message, date:2021-01-01, assignedTo:1 remindersTypeId: 2}
	 * @see /docs/api/index.html#jump-Reminder-Update
	 * @returns
	 */
	update: (data) => {
		return ApiService.post("/reminder/update", data).catch((error) => {
			console.log(error);
		});
	},

	/**
	 * 
	 * @param {*} data
	 * @see /docs/api/index.html#jump-Reminder-Delete
	 * @returns
	 */
	delete: (data) => {
		return ApiService.post("/reminder/delete", {data : {id : data}}).catch((error) => {
			console.log(error);
		});
	},

	/**
	 *
	 * @param {int} reminderId
	 * @param {int} modelId
	 * @param {string} modelName
	 * @see /docs/api/index.html#jump-Reminder-apiNew_2Freminder_2Fmarkasdelete
	 * @returns
	 */
	markAsDelete: (reminderId, modelId, modelName) => {
		return ApiService.post("/reminder/mark-as-delete", {
			reminderId: reminderId,
			modelId: modelId,
			modelName: modelName,
		}).catch((error) => {
			console.log(error);
		});
	},

	/**
	 *
	 * @param {int} reminderId
	 * @param {int} modelId
	 * @param {string} modelName
	 * @see /docs/api/index.html#jump-Reminder-apiNew_2Freminder_2Fmarkasdone
	 * @returns
	 */
	markAsDone: (reminderId, modelId, modelName) => {
		return ApiService.post("/reminder/mark-as-done", {
			reminderId: reminderId,
			modelId: modelId,
			modelName: modelName,
		}).catch((error) => {
			console.log(error);
		});
	},

	/**
	 * @see /docs/api/index.html#jump-Reminder-GetUsersBySysRtoIsoAdminRole
	 * @returns
	 */
	getUsersBySysRtoIsoAdminRole: () => {
		return ApiService.get(
			"/reminder/get-users-by-sys-rto-iso-admin-role"
		).catch((error) => {
			console.log(error);
		});
	},
};

export default ReminderService;
