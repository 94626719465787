import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import Grid from "@mui/material/Grid";
import ReportCardBlock from "../../components/common/ReportCardBlock";
import SessionService from "../../service/SessionService";
import StoreReportFlagsService from "../../service/store/StoreReportFlagsService";

function Reports({ loading, setLoading }) {
	const [reportingFlags, setReportingFlags] = useState(null);

	let allowAllAdmins =
		SessionService.canSystemAdmin() ||
		SessionService.canRtoAdmin() ||
		SessionService.canIsoAdmin() ||
		SessionService.canMasterAgentAdmin() ||
		SessionService.canOrganizationAdmin() ||
		SessionService.canCompanyAdmin() ||
		SessionService.canStoreAdmin();
	let allowStoreSystemAdmin =
		SessionService.canSystemAdmin() || SessionService.canStoreAdmin();
	let productionEnv = process.env.REACT_APP_ENV === "production";
	// console.log('Environment: ', process.env.REACT_APP_ENV);

	const fetchData = async () => {
		try {
			const response = await StoreReportFlagsService.getReportingFlags({
				storeId: parseInt(SessionService.getUserStore()),
			});
			setReportingFlags(response?.data?.status);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, [reportingFlags]);

	const validateStoreFlags = (reportKey) => {
		if (reportingFlags === null) {
			return false; // Or handle the case when reportingFlags is null
		}
		let obj = JSON.parse(reportingFlags);
		return obj[reportKey] === 1;
	};

	return (
		<>
			<div className="rto_form-sections">
				<div className="rto_content-div rto_900">
					<Breadcrumb
						listProps={{ className: "w-100 justify-content-end" }}
					>
						<Breadcrumb.Item
							linkAs={Link}
							linkProps={{ to: "/dashboard" }}
						>
							Dashboard
						</Breadcrumb.Item>
						<Breadcrumb.Item active>Reports</Breadcrumb.Item>
					</Breadcrumb>

					<div className="rto_title-div">
						<h1 className="rto_page-title">Reports</h1>
					</div>

					<div>
						<div className="rto_results-container">
							<Grid container spacing={2}>
								{allowAllAdmins && (
									<>
										{validateStoreFlags(
											"portfolio-summary-report"
										) && (
											<ReportCardBlock
												headerText="Portfolio Summary Report"
												headerIcon="fas fa-chart-line"
												hrefNew=""
												hrefOld="/reports/dealer-portfolio-summary"
											/>
										)}
										{validateStoreFlags(
											"sales-tax-report"
										) && (
											<ReportCardBlock
												headerText="Sales Tax Report"
												headerIcon="fas fa-tags"
												hrefNew="/reports/dealer-sales-tax"
												hrefOld="/reports/dealer-sales-tax-v1"
											/>
										)}
									</>
								)}
								{validateStoreFlags("payments") && (
									<ReportCardBlock
										headerText="Payments"
										headerIcon="fas fa-search-dollar"
										hrefNew="/reports/dealer-payments"
										hrefOld="/reports/dealer-payments-v1"
									/>
								)}
								{validateStoreFlags("end-of-day-report") && (
									<ReportCardBlock
										headerText="End Of Day Report"
										headerIcon="fas fa-chart-line"
										hrefNew={
											productionEnv
												? ""
												: "/reports/dealer-end-of-day"
										}
										hrefOld="/reports/dealer-end-of-day-v1"
									/>
								)}

								{allowAllAdmins && (
									<>
										{validateStoreFlags(
											"portfolio-detailed-report"
										) && (
											<ReportCardBlock
												headerText="Portfolio Detailed Report"
												headerIcon="fas fa-dollar-sign"
												hrefNew={
													productionEnv
														? ""
														: "/reports/dealer-portfolio-detailed"
												}
												hrefOld="/reports/dealer-portfolio-detailed-v1"
											/>
										)}
										{validateStoreFlags(
											"defaulted-contracts"
										) && (
											<ReportCardBlock
												headerText="Defaulted Contracts"
												headerIcon="fas fa-chart-line"
												hrefNew={
													productionEnv
														? ""
														: "/reports/dealer-defaulted-contracts"
												}
												hrefOld="/reports/dealer-defaulted-contracts-v1"
											/>
										)}
									</>
								)}

								{allowStoreSystemAdmin && (
									<>
										{validateStoreFlags(
											"contract-revenue-outlook"
										) && (
											<ReportCardBlock
												headerText="Contract Revenue Outlook"
												headerIcon="fas fa-chart-line"
												hrefNew=""
												hrefOld="/reports/dealer-contract-revenue-outlook"
											/>
										)}
										{validateStoreFlags(
											"dealer-contract-reconciliation-report"
										) && (
											<ReportCardBlock
												headerText="Dealer Contract Reconciliation"
												headerIcon="fas fa-chart-line"
												hrefNew={
													productionEnv
														? ""
														: "/reports/dealer-contract-reconciliation"
												}
												hrefOld="/reports/dealer-contract-reconciliation-v1"
											/>
										)}
									</>
								)}
							</Grid>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Reports;
