import React from 'react';
import CustomInputNumberComponent from './CustomInputNumberComponent';
import ApiService from '../../../../service/ApiService';
import { toast } from 'react-toastify';

const CustomInputCCNAmountComponent = ({ name, label, value, onChange, structStoreId, profileId, token, ...props }) => {
    const handleBlur = async (event) => {
		const newValue = event.target.value.replace(/[^0-9.]/g, ''); // Allow only numbers and dot

        const isValid = newValue > 0; // Add your validation logic here if needed

        // Call the original onChange to update the amount field
        onChange(name, newValue, isValid);

        // If the amount is valid, call the API to calculate the total
        if (isValid) {
            try {
                const response = await ApiService.post('/rto-company-payments/store-payment-calculate-total', {
                    "data": {
                        "struct_store_id": structStoreId,
                        "profile_id": profileId,
                        "token": token,
                        "amount": newValue
                    }
                });

                const total = response.data.data.total;
				const total_label = 'Total: $' + response.data.data.total;

                // Update the total field using its field name
                onChange('total', total, true);
				onChange('total_label', total_label ? total_label : "Total: $0.00", true);
            } catch (error) {
                console.error('Failed to calculate total:', error);
                toast.error('Failed to calculate total');
            }
        } else {
			onChange('total', 0, false);
			onChange('total_label', "Total: $0.00", false);
		}
    };

    return (
        <CustomInputNumberComponent
            name={name}
            label={label}
            value={value}
            onChange={(fieldName, newValue, isValid) => onChange(fieldName, newValue, isValid)}
            onBlur={handleBlur}
            {...props}
        />
    );
};

export default CustomInputCCNAmountComponent;