import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "./rtoDynamicForm/Header";
import Breadcrumbs from "./rtoDynamicForm/Breadcrumbs";
import CustomDynamicFormsMUI from "./rtoDynamicForm/CustomDynamicFormsMUI";
import ModelActivities from "../ModelActivities";
import pluralize from "pluralize";
import TopModal from "../common/TopModal";
import ApiService from "../../service/ApiService";

function MultiStepDynamicForm({
	loading,
	setLoading,
	model,
	modelId,
	modelLabel,
	service,
	apiBase,
	successRedirectBase,
	scenario = "create",
	updateId: propUpdateId,
	display = "page",
	isOpen,
	onClose,
	fetchData,
	tabs,
}) {
	const { id: routeId } = useParams(); // get the ID from the route if available
	// const effectiveUpdateId = propUpdateId || routeId; // prefer UpdateId over routeId
	const effectiveUpdateId =
		display === "page" || scenario === "create"
			? routeId
			: propUpdateId || routeId;

	const [formData, setFormData] = useState(null);
	const [formDefinition, setFormDefinition] = useState(null);
	const formContentKey = formData ? effectiveUpdateId : "initial"; // Use a unique part of formData or a placeholder

	useEffect(() => {
		const fetchFormDefinition = async () => {
			// For "modal" scenario, only proceed if the form is being displayed
			if (display === "modal" && !isOpen) {
				return;
			}

			const endpoint =
				scenario === "update"
					? `${apiBase}/update?id=${effectiveUpdateId}`
					: scenario === "tokenized"
					? `${apiBase}/token?token=${effectiveUpdateId}`
					: `${apiBase}/create`;

			setLoading(true);
			try {
				const response = await ApiService.get(endpoint);
				const data = response.data.data;
				setFormDefinition(data);

				if (scenario === "update") {
					// let formDataValues = {};
					// for (const fieldName in data.fields) {
					// 	formDataValues[fieldName] =
					// 		data.fields[fieldName].value;
					// }
					// setFormData(formDataValues);
					let formDataValues = data.fields.reduce(
						(acc, stepFields, index) => {
							acc[`step${index}`] = {};
							Object.keys(stepFields).forEach((fieldName) => {
								acc[`step${index}`][fieldName] =
									stepFields[fieldName].value;
							});
							return acc;
						},
						{}
					);
					console.log("formDataValues", formDataValues);
					setFormData(formDataValues);
				}
			} catch (error) {
				console.error("Error fetching form definition:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchFormDefinition();
	}, [scenario, effectiveUpdateId, apiBase, setLoading, display, isOpen]);

	const pageTitle =
		scenario === "update"
			? //? model === "user"
			  //? `Update ${modelLabel}: ${formData?.username}`
			  //: `Update ${modelLabel}: ${formData?.name}`
			  `Update ${modelLabel}`
			: `Create New ${modelLabel}`;
	const successRedirect = `/${successRedirectBase}`;

	const breadcrumbs = [
		{ label: "Dashboard", to: "/dashboard" },
		{ label: "Settings", to: "/settings" },
		{ label: pluralize(modelLabel), to: `/${successRedirectBase}` },
		{ label: scenario === "update" ? "Update" : "Create" },
	];

	console.log("formDefinition", formDefinition);

	// const formContent = formDefinition && (
	// 	<CustomDynamicFormsMUI
	// 		key={formContentKey}
	// 		loading={loading}
	// 		setLoading={setLoading}
	// 		formDefinition={formDefinition}
	// 		showDebug={true}
	// 		apiBase={apiBase}
	// 		successRedirect={successRedirect}
	// 		isUpdateMode={scenario === "update"}
	// 		onFormSubmitSuccess={() => {
	// 			if (display === "modal") {
	// 				onClose(); // Close the modal on successful form submission
	// 				fetchData(); // Fetch data to refresh the list
	// 			}
	// 		}}
	// 		model={model}
	// 		modelLabel={modelLabel}
	// 		modelId={effectiveUpdateId}
	// 	/>
	// );
	const formContent =
		formDefinition &&
		formDefinition.fields.map((stepFields, index) => {
			const stepLayout =
				Array.isArray(formDefinition.layout) &&
				formDefinition.layout[index]
					? formDefinition.layout[index]
					: undefined;

			return (
				<CustomDynamicFormsMUI
					key={`step-${index}`}
					loading={loading}
					setLoading={setLoading}
					formDefinition={{
						...formDefinition,
						fields: stepFields,
						layout: stepLayout.items,
					}}
					showDebug={true}
					apiBase={apiBase}
					successRedirect={successRedirect}
					isUpdateMode={scenario === "update"}
					onFormSubmitSuccess={() => {
						if (display === "modal") {
							onClose(); // Close the modal on successful form submission
							fetchData(); // Fetch data to refresh the list
						}
					}}
					model={model}
					modelLabel={modelLabel}
					modelId={effectiveUpdateId}
				/>
			);
		});

	return display === "modal" ? (
		<TopModal
			title={pageTitle}
			body={formContent}
			isVisible={isOpen}
			onClose={onClose}
		/>
	) : (
		<>
			<Header pageTitle={pageTitle} />
			<div className="rto_form-sections">
				<div className="rto_content-div">
					<Breadcrumbs breadcrumbs={breadcrumbs} />
					<div className="rto_title-div">
						<h1 className="rto_page-title">{pageTitle}</h1>
					</div>
					{formContent}
					{/* TODO: Remove the model !== "struct_activity" condition & make dynamic */}
					{scenario === "update" &&
						formData &&
						model !== "struct_activity" && (
							<>
								<br />
								<ModelActivities
									redirectPath={`${successRedirectBase}/${effectiveUpdateId}`}
									modelId={effectiveUpdateId}
									model={model}
									loading={loading}
									setLoading={setLoading}
									tabs={tabs}
								/>
							</>
						)}
				</div>
			</div>
		</>
	);
}

export default MultiStepDynamicForm;
