import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html#RtoCompanyPayments
 */

const RtoCompanyPaymentsService = {
	/**
	 *
	 * @param {RtoCompanyPaymentsView} RtoCompanyPaymentsView
	 * @see /docs/api/index.html#jump-RtoCompanyPayments-View
	 * @returns
	 */
	view: (RtoCompanyPaymentsView) => {
		return ApiService.post(
			"/rto-company-payments/index",
			RtoCompanyPaymentsView
		).catch((error) => {
			console.log(error);
			return error;
		});
	},

	/**
	 * data filter by ID
	 * @param {int} id
	 * @see /docs/api/index.html#jump-RtoCompanyPayments-FetchById
	 * @returns
	 */
	fetchById: (id) => {
		return ApiService.get("/rto-company-payments/fetch-by-id", {
			id: id,
		}).catch((error) => {
			console.log(error);
		});
	},

	/**
	 * Update service
	 * @see /docs/api/index.html#jump-RtoCompanyPayments-Update
	 * @param json data {rtoCompanyPaymentsId : 1, name: 'xxxx', address_1: 'ADD1' ...}
	 * @returns
	 */
	update: (data) => {
		return ApiService.post("/rto-company-payments/update", data).catch(
			(error) => {
				console.log(error);
			}
		);
	},

	/**
	 *
	 * @param {RtoCompanyPaymentsCreate} RtoCompanyPaymentsCreate
	 * @see /docs/api/index.html#jump-RtoCompanyPayments-Create
	 * @returns
	 */
	create: (RtoCompanyPaymentsCreate) => {
		return ApiService.post(
			"/rto-company-payments/create",
			RtoCompanyPaymentsCreate
		).catch((error) => {
			console.log(error);
		});
	},

	/**
	 * Get store payment methods
	 * @param {Object} data - ID of the store
	 * @see /docs/api/index.html#jump-RtoCompanyPayments-GetStorePaymentMethods
	 * @returns
	 */
	getStorePaymentMethods: (data) => {
		return ApiService.post(
			"/rto-company-payments/store-payment-methods-get",
			data
		).catch((error) => {
			console.log(error);
		});
	},

	/**
	 * Add store payment method
	 * @param {Object} data - Payment method data
	 * @see /docs/api/index.html#jump-RtoCompanyPayments-AddStorePaymentMethod
	 * @returns
	 */
	addStorePaymentMethod: (data) => {
		return ApiService.post(
			"/rto-company-payments/store-payment-methods-add",
			data
		).catch((error) => {
			console.log(error);
		});
	},

	/**
	 * Delete store payment method
	 * @param {int} id - ID of the payment method to delete
	 * @see /docs/api/index.html#jump-RtoCompanyPayments-DeleteStorePaymentMethod
	 * @returns
	 */
	deleteStorePaymentMethod: (id) => {
		return ApiService.delete(
			`/rto-company-payments/store-payment-methods-delete/${id}`
		).catch((error) => {
			console.log(error);
		});
	},

	/**
	 * Calculate total of payments
	 * @param {Object} data - Data to calculate total
	 * @see /docs/api/index.html#jump-RtoCompanyPayments-CalculateTotal
	 * @returns
	 */
	calculateTotal: (data) => {
		return ApiService.post(
			"/rto-company-payments/store-payment-calculate-total",
			data
		).catch((error) => {
			console.log(error);
		});
	},

	/**
	 * Process store payment
	 * @param {Object} data - Payment processing data
	 * @see /docs/api/index.html#jump-RtoCompanyPayments-ProcessStorePayment
	 * @returns
	 */
	processStorePayment: (data) => {
		return ApiService.post(
			"/rto-company-payments/store-payment-process",
			data
		).catch((error) => {
			console.log(error);
		});
	},

	/**
	 * Change default payment method
	 * @param {Object} data - Payment method data
	 * @see /docs/api/index.html#jump-RtoCompanyPayments-ChangeDefaultPaymentMethod
	 * @returns
	 */
	changeDefaultPaymentMethod: (data) => {
		return ApiService.post(
			"/rto-company-payments/store-payment-method-change-default",
			data
		).catch((error) => {
			console.log(error);
		});
	},

	/**
	 * Delete payment method
	 * @param {Object} data - Payment method data
	 * @see /docs/api/index.html#jump-RtoCompanyPayments-DeletePaymentMethod
	 * @returns
	 */
	deletePaymentMethod: (data) => {
		return ApiService.post(
			"/rto-company-payments/store-payment-methods-delete",
			data
		).catch((error) => {
			console.log(error);
		});
	},

	/**
	 * Delete customer
	 * @param {Object} data - Payment method data
	 * @see /docs/api/index.html#jump-RtoCompanyPayments-DeleteCustomer
	 * @returns
	 */
	deleteCustomer: (data) => {
		return ApiService.post(
			"/rto-company-payments/store-payment-profile-delete",
			data
		).catch((error) => {
			console.log(error);
		});
	},

	/**
	 * Void payment
	 * @param {Object} data - Payment data
	 * @see /docs/api/index.html#jump-RtoCompanyPayments-VoidPayment
	 * @returns
	 */
	voidPayment: (data) => {
		return ApiService.post("/rto-company-payments/store-payment-void", data).catch((error) => {
			console.log(error);
		});
	},

	/**
	 * Refund payment
	 * @param {Object} data - Payment data
	 * @see /docs/api/index.html#jump-RtoCompanyPayments-RefundPayment
	 * @returns
	 */
	refundPayment: (data) => {
		return ApiService.post(
			"/rto-company-payments/store-payment-refund",
			data
		).catch((error) => {
			console.log(error);
		});
	},
};

export default RtoCompanyPaymentsService;
