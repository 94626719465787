import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html#Report
 */

const SalesTaxService = {
	/**
	 * View
	 * @param array data pass serach filter values if needed
	 * @see /docs/api/index.html#jump-Report-Payment
	 * @returns
	 */
	view: (data, param, signal) => {
		return ApiService.post(
			"/report/sales-tax/index",
			data,
			param,
			signal
		).catch((error) => {
			console.log(error);
			return error;
		});
	},
};

export default SalesTaxService;
