import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html
 */

const VendorModelsService = {
	/**
	 *
	 * @param {VendorModelsView} VendorModelsView
	 * @returns
	 */
	view: (VendorModelsView) => {
		return ApiService.post("/vendor-models/index", VendorModelsView).catch(
			(error) => {
				console.log(error);
				return error;
			}
		);
	},

	/**
	 * Delete by ID
	 * @param {int} id
	 * @see /docs/api/index.html#jump-Vendor-delete
	 * @returns
	 */
	delete: (id) => {
		return ApiService.get("/vendor-models/delete", { id: id }).catch(
			(error) => {
				console.log(error);
			}
		);
	},
};

export default VendorModelsService;
