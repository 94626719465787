import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Box } from "@mui/material";
// import IsoTable from "../../components/backend/iso/IsoTable";
import IsoService from "../../service/iso/IsoService";
import IndexTable from "../../components/common/IndexTable";

function Isos({ loading, setLoading }) {
	const pageTitle = "ISOs | RTO Mobile";

	return (
		<>
			<Helmet>
				<title>{pageTitle}</title>
				<meta name="robots" content="noindex,nofollow"></meta>
				<meta property="og:title" content={pageTitle} />
				<meta name="twitter:title" content={pageTitle} />
			</Helmet>
			<div className="rto_form-sections">
				<div className="rto_content-div" style={{ maxWidth: "1600px" }}>
					<Box
						display="flex"
						justifyContent="flex-end"
						backgroundColor="#f5f5f5"
						p={(0.5, 1)}
						borderRadius={1}
					>
						<Breadcrumbs aria-label="breadcrumb">
							<Link to="/dashboard" className="rto_link-2">
								Dashboard
							</Link>
							<Link to="/settings" className="rto_link-2">
								Settings
							</Link>
							<Typography>ISOs</Typography>
						</Breadcrumbs>
					</Box>
					<div className="rto_title-div">
						<h1 className="rto_page-title">ISOs</h1>
					</div>
					<p className="text-center">
						<Link
							to="/settings/isos/create"
							className="btn btn-primary"
						>
							Create
						</Link>
					</p>
					<div
						className="card card-outline card-primary"
						style={{
							borderTop: "3px solid #00aaff",
							boxShadow:
								"0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
						}}
					>
						<div className="card-body">
							{/* <IsoTable
								loading={loading}
								setLoading={setLoading}
							/> */}
							<IndexTable
								model={"struct_iso"}
								loading={loading}
								setLoading={setLoading}
								service={IsoService}
								title="Independent Sales Organizations"
								actionButtons={{
									updateLink: { path: "/settings/isos" },
									// updateModal: {
									// 	enabled: true,
									// 	path: "/settings/isos/update",
									// },
									// create: {
									// 	enabled: true,
									// 	path: "/settings/isos/create",
									// },
								}}
								labels={[
									{
										id: "id",
										label: "ID",
									},
									{
										id: "struct_iso_name",
										label: "ISO",
										link: "/settings/isos",
									},
									{
										id: "address_1",
										label: "Address 1",
									},
									{
										id: "address_2",
										label: "Address 2",
										initialDisplay: false,
									},
									{
										id: "city",
										label: "City",
									},
									{
										id: "state",
										label: "State",
									},
									{
										id: "zip",
										label: "Zip",
										initialDisplay: false,
									},
									{
										id: "phone",
										label: "Phone",
									},
									{
										id: "email",
										label: "Email",
									},
									{
										id: "status_name",
										label: "Status",
									},
									{
										id: "created",
										label: "Created At",
									},
									{
										id: "user_name",
										label: "Created By",
									},
								]}
								apiBase="/iso"
								successRedirectBase="settings/isos"
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Isos;
