import React from "react";
import RtoLogo from "../images/RTO-Mobile_Stacked_logo_bluewhite.png";

function Header() {
    return (
        <>
            <div className="lp_nav">
                <div className="lp_container-700">
                    <div className="brand-wrapper w-inline-block">
                        <a
                            href="https://www.rtomobile.com"
                            style={{ width: "fit-content" }}
                        >
                            <img
                                src={RtoLogo}
                                loading="lazy"
                                alt=""
                                className="lp_logo"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;
