import React from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Box } from "@mui/material";
import "./Resources.css";

function AndroidInstallation({ loading, setLoading }) {
	return (
		<>
			<div className="rto_form-sections">
				<div className="rto_content-div" style={{ maxWidth: "1600px" }}>
					<Box
						display="flex"
						justifyContent="flex-end"
						backgroundColor="#f5f5f5"
						p={(0.5, 1)}
						borderRadius={1}
					>
						<Breadcrumbs aria-label="breadcrumb">
							<Link to="/dashboard" className="rto_link-2">
								Dashboard
							</Link>
							<Link to="/resources" className="rto_link-2">
								Resources
							</Link>
							<Typography>
								Android Installation Instructions
							</Typography>
						</Breadcrumbs>
					</Box>

					<div
						className="rto_title-div"
						style={{ textAlign: "left" }}
					>
						<h1>
							RTO LOCK SOFTWARE INSTALLATION STEPS FOR ANDROID
							PHONES AND GALAXY TABLETS
						</h1>

						<Box
							sx={{
								display: "grid",
								bgcolor: "grey.100",
								color: "red",
								border: "1px solid grey.100",
								p: 1,
								borderRadius: 2,
							}}
						>
							<p>
								<b>Important Reminder:</b>
								<br /> Ensure that the device:
								<ul className="ul-decimal">
									<li>Is powered on,</li>
									<li>Has been freshly factory reset, and</li>
									<li>
										Is on the Welcome (or Hello) screen
										before taking the following steps.
									</li>
								</ul>
							</p>
						</Box>

						<p>
							<ul className="ul-decimal">
								<li>
									Login to{" "}
									<b>
										<a
											href="https://app.rtomobile.com/"
											target="_blank"
											rel="noreferrer"
										>
											RTO Mobile Portal
										</a>
									</b>{" "}
									and click on the <b>Menu</b> button
									<p>
										<img
											alt=""
											src="/images/resources/android/1.png"
										/>
									</p>
								</li>
								<li>
									From the <strong>Menu</strong>, choose click
									on the <strong>Device Provisioning</strong>{" "}
									selection
									<p>
										<img
											alt=""
											src="/images/resources/android/2.png"
										/>
									</p>
								</li>
								<li>
									Click{" "}
									<strong>
										Create New Device Provisioning Batch
									</strong>
									<p>
										<img
											alt=""
											src="/images/resources/android/3.png"
										/>
									</p>
								</li>
								<li>
									Click <strong>Continue Workorder</strong>
									<p>
										<img
											alt=""
											src="/images/resources/android/4.png"
										/>
									</p>
								</li>
								<li>
									Under Device Type, choose{" "}
									<strong>Android Phone</strong>, enter the{" "}
									<strong>IMEI</strong> then click on{" "}
									<strong>Add</strong>. The device details
									will show then click on the{" "}
									<strong>Continue</strong> button.
									<p>
										<img
											alt=""
											src="/images/resources/android/5.png"
										/>
									</p>
								</li>
								<li>
									The first set of instructions will appear.
									This is a reminder to power on the device
									and ensure it has been freshly factory
									reset.{" "}
									<strong>
										Proceeding out of order will cause the
										lock installation to fail
									</strong>
									.
								</li>
								<li>
									Click the <strong>Continue</strong> button.
									<p>
										<img
											alt=""
											src="/images/resources/android/7.png"
										/>
									</p>
								</li>
								<li>
									The RTO Mobile Portal will indicate the{" "}
									<strong>Enrollment Requested</strong> status
									then will indicate{" "}
									<strong>Pending Enrollment</strong> status.{" "}
									<span style={{ color: "red" }}>
										REMINDER:{" "}
										<u>DO NOT SELECT THE LANGUAGE YET</u>.
										The device should still be powered on
										and on the welcome or hello screen.
									</span>
									<p>
										<img
											alt=""
											src="/images/resources/android/8-1.png"
										/>
									</p>
									<p>
										<img
											alt=""
											src="/images/resources/android/8-2.png"
										/>
									</p>
								</li>
								<li>
									When enrollment has completed, a green check
									mark will appear and the status will change
									to <strong>Device Enrolled</strong>. Then,
									click the <strong>Continue</strong> button.
									<p>
										<img
											alt=""
											src="/images/resources/android/9.png"
										/>
									</p>
								</li>
								<li>
									After clicking on the{" "}
									<strong>Continue</strong> button, the{" "}
									<strong>
										Device Software Installation
									</strong>{" "}
									page will appear where a set of instructions
									will be given.
									<p>
										<img
											alt=""
											src="/images/resources/android/10.png"
										/>
									</p>
								</li>
								<li>
									You must then immediately connect the device
									to WiFi
								</li>
								<li>
									Continue by answering prompts affirmatively
									(e.g. Accept, Continue, Agree, etc.) all the
									way to the main screen where you see all the
									apps.{" "}
									<span style={{ color: "red" }}>
										(REMINDER: Please do not connect the
										device to WiFi until you get to this
										page) Doing so will cause the
										installation to fail.
									</span>
								</li>
								<li>
									Wait for the RTO Mobile Portal to show{" "}
									<strong>Software Installed</strong> and a
									green check mark. Then click on the{" "}
									<strong>Continue</strong>
									button.
									<p>
										<img
											alt=""
											src="/images/resources/android/13.png"
										/>
									</p>
								</li>
								<li>
									Click the <strong>Click To Lock</strong>{" "}
									button to test the lock functionality. The
									device screen should say “DEVICE BLOCKED”
									and the device should be locked. The device
									screen will provide a{" "}
									<strong>4 digit code</strong>. Enter the
									code on the portal, and click on the{" "}
									<strong>Submit Code</strong> button.
									<p>
										<img
											alt=""
											src="/images/resources/android/14-1.png"
										/>
									</p>
									<p>
										<img
											alt=""
											src="/images/resources/android/14-2.png"
										/>
									</p>
								</li>
								<li>
									Once the code has been submitted, the portal
									will show{" "}
									<strong>Device Provisioned</strong>. Click{" "}
									<strong>Continue</strong> then{" "}
									<strong>Return to Workorders</strong> to go
									back to your inventory.
									<p>
										<img
											alt=""
											src="/images/resources/android/15.png"
										/>
									</p>
								</li>
							</ul>
						</p>
					</div>

					<div>
						<div className="rto_results-container"></div>
					</div>
				</div>
			</div>
		</>
	);
}

export default AndroidInstallation;
