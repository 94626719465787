import { redirect } from "react-router-dom";
import LogoutService from "../../service/auth/LogoutService";

function Logout(setToken) {
    LogoutService.action()
        .then((response) => {
            localStorage.removeItem("token");
            localStorage.removeItem("userInfo");
            // console.log("Logged out!");
            setToken(null);
        })
        .catch((error) => {
            // console.log("Error: ", error);
        });
    return redirect("/");
}

export default Logout;
