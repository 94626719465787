import React, { useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Row, Col } from "react-bootstrap";
import { TextField } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
//import { DevTool } from "@hookform/devtools";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import ForgotPasswordService from "../../service/auth/ForgotPasswordService";
const schema = z.object({
	email: z
		.string()
		.trim()
		.nonempty({ message: "Email address cannot be blank!" })
		.min(5, { message: "Email address must be at least 5 characters!" })
		.max(255, {
			message: "Email address cannot be longer than 255 characters!",
		})
		.email({ message: "Email address must be a valid email address!" }),
	recaptcha: z
		.string({
			invalid_type_error: "Please complete the ReCAPTCHA!",
		})
		.trim()
		.nonempty({ message: "Please complete the ReCAPTCHA!" }),
});

function ForgotPasswordForm() {
	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
		setError,
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			email: "",
			recaptcha: "",
		},
		resolver: zodResolver(schema),
	});

	const recaptchaRef = useRef();

	const [formSubmitted, setFormSubmitted] = useState(false);

	const onSubmit = async (data) => {
		// console.log(data);

		const forgotData = {
			PasswordRequestForm: {
				email: data.email,
				reCaptcha: data.recaptcha,
				// recaptcha: data.recaptcha,
			},
		};

		// console.log(forgotData);

		/*const response = await axios
            .post(
                process.env.REACT_APP_API_BASE_URL + "/forgot-password",
                forgotData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )*/
		ForgotPasswordService.reset(forgotData)
			.then((response) => {
				// console.log(response);
				if (response.status === 200 && response.data.isOk === true) {
					// console.log("Success!");
					setFormSubmitted(true);
				} else {
					// console.log("Failed!");
					setError("api", {
						type: "server",
						message: response.data.message,
					});
				}
			})
			.catch((error) => {
				// console.log("Error!");
				// console.log(error);
				setError("api", {
					type: "server",
					message: error.response.data.message,
				});
			});
	};

	return (
		<>
			{formSubmitted ? (
				<div data-w-id="e1bc4a34-b07a-fec3-2d1d-9735d080f1d0">
					<div className="contact-container">
						<div className="center alert alert-success">
							Success! Your message has been recieved.
						</div>
						<p>
							If the provided email address exists in our records,
							a password reset link has been sent to the
							associated email address.
						</p>
						<a className="rto_blue-link" href="/">
							Back to login
						</a>
					</div>
				</div>
			) : (
				<form
					id="forgot_password_form"
					name="forgot-password-form"
					onSubmit={handleSubmit(onSubmit)}
					className="form-vertical"
				>
					<Row>
						<Col lg style={{ marginBottom: "2rem" }}>
							<TextField
								type="email"
								variant="standard"
								label="Email address"
								id="email"
								name="email"
								fullWidth
								InputLabelProps={{
									shrink: true,
									className:
										"Mui-form-label Mui-form-label-required",
								}}
								InputProps={{
									className: "Mui-form-input",
								}}
								inputProps={{
									maxLength: 255,
								}}
								{...register("email")}
								error={!!errors.email}
								helperText={errors.email?.message}
								autoFocus
							/>
						</Col>
					</Row>
					<Row>
						<Col lg style={{ marginBottom: "1rem" }}>
							<Controller
								name="recaptcha"
								control={control}
								defaultValue=""
								rules={{
									required: "Please complete the ReCAPTCHA!",
								}}
								render={({ field }) => (
									<ReCAPTCHA
										{...field}
										ref={recaptchaRef}
										sitekey={
											process.env
												.REACT_APP_G_RECAPTCHA_SITEKEY
										}
										id="recaptcha"
										onChange={(value) => {
											field.onChange(value); // updates react-hook-form
										}}
										className="g-recaptcha"
									/>
								)}
							/>
							{errors.recaptcha && (
								<p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium Mui-required">
									{errors.recaptcha.message}
								</p>
							)}
						</Col>
					</Row>
					{errors.api && (
						<Row>
							<Col lg style={{ marginBottom: "2rem" }}>
								<p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium Mui-required">
									{errors.api.message}
								</p>
							</Col>
						</Row>
					)}
					<div className="form-group">
						<button
							type="submit"
							className="rto_login-bttn rto_half-bttn w-button"
							name="login-button"
						>
							Request Reset
						</button>
					</div>
					{/* <DevTool control={control} /> */}
				</form>
			)}
		</>
	);
}

export default ForgotPasswordForm;
