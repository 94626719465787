import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Row, Col } from "react-bootstrap";
import LoginForm from "../../components/forms/LoginForm";
import AnnouncementAlert from "../../components/common/AnnouncementAlert";

function clearAllCookies() {
	const cookies = document.cookie.split(";");
	// console.log("clearAllCookies", cookies);
	for (let i = 0; i < cookies.length; i++) {
		const cookie = cookies[i];
		const eqPos = cookie.indexOf("=");
		const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
		document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
	}
}

function Login({ loading, setLoading }) {
	const pageTitle = "Login | RTO Mobile";
	const pageDescription =
		"Login to RTO Mobile - Your gateway to seamless contract management. Access your account, manage services, and stay connected on the go.";
	const pageImage = "https://www.rtomobile.com/images/rto-open_graph.png";

	useEffect(() => {
		const html = document.getElementsByTagName("html")[0];
		html.setAttribute("data-wf-page", "5fc816a3dc392e6ecca3f7a6");
		html.setAttribute("data-wf-site", "5fa41afe5cdaf5586f4cea1d");

		window.Webflow && window.Webflow.destroy();
		window.Webflow && window.Webflow.ready();
		window.Webflow && window.Webflow.require("ix2").init();
		document.dispatchEvent(new Event("readystatechange"));
		// Call the function to clear cookies when the login page is loaded
		clearAllCookies();
	});

	return (
		<>
			<Helmet>
				<title>{pageTitle}</title>
				<meta name="description" content={pageDescription} />
				<meta property="og:title" content={pageTitle} />
				<meta property="og:description" content={pageDescription} />
				<meta property="og:image" content={pageImage} />
				<meta name="twitter:title" content={pageTitle} />
				<meta name="twitter:description" content={pageDescription} />
				<meta name="twitter:image" content={pageImage} />
			</Helmet>
			<div
				className="site-login rto_form-sections"
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					padding: "0 1rem",
					backgroundColor: "#22557b",
					backgroundImage:
						"linear-gradient(45deg, #22557b, #25689a 79%)",
				}}
			>
				<div className="mt-32 md:mt-0" style={{ maxWidth: "100%" }}>
					<AnnouncementAlert />
				</div>
				<br />
				<div className="rto_modal">
					<div className="rto_title-div">
						<h1 className="rto_page-title">Login</h1>
					</div>

					<Row>
						<Col className="col-lg-12">
							<p>
								Please fill out the following fields to login:
							</p>
							<br />
							<LoginForm
								loading={loading}
								setLoading={setLoading}
							/>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
}

export default Login;
