import React, { useEffect, useState } from "react";
import TopModal from "../../../common/TopModal";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import ContractService from "../../../../service/contract/ContractService";

const ContractImagesModal = ({ show, onHide, contractId }) => {
	const [data, setData] = useState([]);

	useEffect(() => {
		if (contractId) {
			ContractService.getImages({ contract_id: contractId }).then(
				(res) => {
					setData(res.data.data);
				}
			);
		}
	}, [contractId]);

	return (
		<TopModal
			isVisible={show}
			body={
				<>
					<Box sx={{ flexGrow: 1 }}>
						<Grid container spacing={2}>
							<Grid item xs={4}>
								{data.front ? (
									<img src={data.front} alt="front" />
								) : (
									<AccountCircleIcon
										style={{
											height: "100%",
											width: "100%",
										}}
									></AccountCircleIcon>
								)}
							</Grid>
							<Grid item xs={4}>
								{data.front ? (
									<img src={data.back} alt="front" />
								) : (
									<AccountCircleIcon
										style={{
											height: "100%",
											width: "100%",
										}}
									></AccountCircleIcon>
								)}
							</Grid>
							<Grid item xs={4}>
								{data.front ? (
									<img src={data.customer} alt="front" />
								) : (
									<AccountCircleIcon
										style={{
											height: "100%",
											width: "100%",
										}}
									></AccountCircleIcon>
								)}
							</Grid>
							<Grid item xs={4}>
								I.D. Front
							</Grid>
							<Grid item xs={4}>
								I.D. Back
							</Grid>
							<Grid item xs={4}>
								Customer
							</Grid>
						</Grid>
					</Box>
				</>
			}
			title={`Customer Images`}
			onClose={onHide}
			//loading={modalOnLoad}
		></TopModal>
	);
};

export default ContractImagesModal;
