import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html#Report
 */

const DealerContractReconciliationService = {
  /**
   * View Dealer Contract Reconciliation report data
   * @param array data pass serach filter values if needed
   * @see /docs/api/index.html#jump-Report-DealerContractReconciliation
   * @returns
   */
  view: (data, param, signal) => {
    return ApiService.post(
      "/report/dealer-contract-reconciliation/index",
      data,
      param,
      signal
    ).catch((error) => {
      console.log(error);
      return error;
    });
  },
};

export default DealerContractReconciliationService;
