import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import Feedback from "react-bootstrap/Feedback";
import PhoneInput from "react-phone-number-input/react-hook-form";
import PhoneInputBs5 from "../common/PhoneInputBs5";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { DevTool } from "@hookform/devtools";
import axios from "axios";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button, FormSelect } from "react-bootstrap";
import IsoService from "../../service/iso/IsoService";
import SessionService from "../../service/SessionService";
import { toast } from "react-toastify";

const usNumberRegex = /^\+1\d{10}$/;
const allowedCountryRegex = usNumberRegex;

const schema = z.object({
    name: z
        .string()
        .trim()
        .nonempty({ message: "Name cannot be blank!" })
        .max(255, {
            message: "Name cannot be longer than 255 characters!",
        })
        .refine(
            (value) => {
                return /^[A-Za-z0-9\s.'&#-]+$/.test(value);
            },
            {
                message: "Name cannot contain special characters!",
            }
        ),
    email: z
        .string()
        .trim()
        .nonempty({ message: "Email address cannot be blank!" })
        .max(255, {
            message: "Email address cannot be longer than 255 characters!",
        })
        .email({ message: "Email address must be a valid email address!" }),
    phone: z
        .string({
            invalid_type_error: "Phone number cannot be blank!",
        })
        .trim()
        .nonempty({ message: "Phone number cannot be blank!" })
        .refine(
            (value) => {
                return isPossiblePhoneNumber(value);
            },
            {
                message: "Phone number is invalid!",
            }
        )
        .refine(
            (value) => {
                return allowedCountryRegex.test(value);
            },
            {
                message: "Phone number country is invalid!",
            }
        ),
    status_id: z
        .string()
        .trim()
        .refine(
            (value) => {
                if (!value) return true; // Allow it to be blank
                const intValue = parseInt(value);
                return !isNaN(intValue);
            },
            {
                message: "Status must be a number!",
            }
        )
        .refine(
            (value) => {
                if (!value) return true; // Allow it to be blank
                const intValue = parseInt(value);
                return intValue >= 0;
            },
            {
                message: "Status cannot be negative!",
            }
        )
        .refine(
            (value) => {
                return /^[0-9]*$/.test(value); // Allow it to be blank or valid integers
            },
            {
                message: "Status must be a valid integer!",
            }
        )
        .optional(),
    address_1: z
        .string()
        .trim()
        .nonempty({ message: "Address 1 cannot be blank!" })
        .max(255, {
            message: "Address 1 cannot be longer than 255 characters!",
        })
        .refine(
            (value) => {
                return /^[A-Za-z0-9\s.,'#-]+$/.test(value);
            },
            {
                message: "Address 1 cannot contain special characters!",
            }
        ),
    address_2: z
        .string()
        .trim()
        .max(255, {
            message: "Address 2 cannot be longer than 255 characters!",
        })
        .refine(
            (value) => {
                //if not empty, then validate
                if (value) {
                    return /^[A-Za-z0-9\s.'#-]+$/.test(value);
                }
                //if empty, consider it valid
                return true;
            },
            {
                message: "Address 2 cannot contain special characters!",
            }
        ),
    zip: z
        .string()
        .toUpperCase()
        .trim()
        .nonempty({ message: "Zip code cannot be blank!" })
        .length(5, { message: "Zip code must contain 5 characters!" })
        .refine(
            (value) => {
                const intValue = parseInt(value);
                return !isNaN(intValue);
            },
            {
                message: "Zip code must be a number!",
            }
        )
        .refine(
            (value) => {
                const intValue = parseInt(value);
                return intValue >= 0;
            },
            {
                message: "Zip code cannot be negative!",
            }
        )
        .refine(
            (value) => {
                return /^[0-9]+$/.test(value);
            },
            {
                message: "Zip code must be a valid integer!",
            }
        ),
    city: z
        .string()
        .toUpperCase()
        .trim()
        .nonempty({ message: "City cannot be blank!" })
        .max(255, { message: "City cannot be longer than 255 characters!" }),
    state: z
        .string()
        .toUpperCase()
        .trim()
        .nonempty({ message: "State cannot be blank!" })
        .max(255, { message: "State cannot be longer than 255 characters!" }),
});

function IsosForm({ isoData, onUpdateCallback, loading, setLoading }) {
    const {
        register,
        control,
        handleSubmit,
        formState: {
            errors,
            touchedFields,
            isSubmitting,
            isSubmitted,
            isValid,
            isDirty,
        },
        // getValues,
        setValue,
        watch,
        setError,
        reset,
    } = useForm({
        mode: "onBlur",
        defaultValues: {
            name: "",
            email: "",
            phone: "",
            address_1: "",
            address_2: "",
            zip: "",
            city: "",
            state: "",
            status_id: "",
        },
        resolver: zodResolver(schema),
    });

    const zip = watch("zip");
    const [isZipApiUpdated, setZipApiUpdated] = useState(false);

    const isFirstRender = useRef(true);
    const renderCount = useRef(0);

    useEffect(() => {
        renderCount.current = renderCount.current + 1;

        const fetchLocationData = async () => {
            try {
                const response = await axios.get(
                    // `https://api.zippopotam.us/us/${zip}`
                    `https://zip.row.net/zip/${zip}`
                );
                const { places } = response.data;
                if (places && places.length > 0) {
                    setValue("city", places[0]["place name"], {
                        shouldValidate: true,
                    });
                    setValue("state", places[0]["state abbreviation"], {
                        shouldValidate: true,
                    });
                } else {
                    setValue("city", "", { shouldValidate: true });
                    setValue("state", "", { shouldValidate: true });
                }
                setZipApiUpdated(true);
            } catch (error) {
                setValue("city", "", { shouldValidate: true });
                setValue("state", "", { shouldValidate: true });
            }
        };

        if (zip && zip.length === 5 && /^\d+$/.test(zip)) {
            if (isoData) {
                if (renderCount.current > 2) {
                    fetchLocationData();
                }
            } else {
                if (renderCount.current > 1) {
                    fetchLocationData();
                }
            }
        } else {
            setValue("city", "", { shouldValidate: true });
            setValue("state", "", { shouldValidate: true });
        }

        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
    }, [zip, setValue]);

    useEffect(() => {
        if (isoData) {
            setValue("name", isoData.name?.toString() ?? "");
            setValue("email", isoData.email_address?.toString() ?? "");
            setValue("phone", "+1" + isoData.phone?.toString() ?? "");
            setValue("address_1", isoData.address_1?.toString() ?? "");
            setValue("address_2", isoData.address_2?.toString() ?? "");
            setValue("zip", isoData.zip?.toString() ?? "");
            setValue("city", isoData.city?.toString() ?? "");
            setValue("state", isoData.state?.toString() ?? "");
            setValue("status_id", isoData.status_id?.toString() ?? "");
        }
    }, [isoData, setValue]);

    const navigate = useNavigate();

    const isEditMode = !!isoData;

    const [formSubmitted, setFormSubmitted] = useState(false);

    const onSubmit = async (data) => {
        // Set loading state
        setLoading(true);

        const isoData = {
            IsoCreate: {
                name: data.name,
                email_address: data.email,
                // phone: data.phone,
                // since US only, get phone last 10 digits
                phone: data.phone.substring(data.phone.length - 10),
                address_1: data.address_1,
                address_2: data.address_2,
                zip: data.zip,
                city: data.city,
                state: data.state,
                status_id: 1,
            },
        };

        const response = await IsoService.create(isoData)
            .then((response) => {
                if (response.status === 200 && response.data.isOk === true) {
                    // show success message
                    toast.success(
                        `ISO ${isoData.IsoCreate.name} created successfully!`,
                        {
                            position: "top-center",
                            autoClose: 1000,
                        }
                    );
                    // setFormSubmitted(true);
					SessionService.resetUserHierarchy();
                    navigate("/settings/isos");
                } else {
                    // console.log("Error!");
                    // show error messages
                    const errorMappings = {
                        name: "name",
                        email_address: "email",
                        phone: "phone",
                        address_1: "address_1",
                        address_2: "address_2",
                        zip: "zip",
                        city: "city",
                        state: "state",
                        status_id: "status_id",
                    };

                    Object.entries(response.data.message).forEach(
                        ([field, message]) => {
                            const errorType = errorMappings[field];
                            if (errorType) {
                                setError(errorType, {
                                    type: "server",
                                    message,
                                });
                            }
                        }
                    );
                }
            })
            .catch((error) => {
                // console.log(error);
                setError("api", {
                    type: "server",
                    message: error.response.data.error,
                });
            });

        // Set loading state
        setLoading(false);
    };

    const onUpdate = async (data) => {
        // Set loading state
        setLoading(true);

        const isoUpdateData = {
            isoId: isoData.id,
            id: isoData.id,
            name: data.name,
            email_address: data.email,
            phone: data.phone.substring(data.phone.length - 10),
            address_1: data.address_1,
            address_2: data.address_2,
            zip: data.zip,
            city: data.city,
            state: data.state,
            status_id: data.status_id,
        };

        const response = await IsoService.update(isoUpdateData)
            .then((response) => {
                if (response.status === 200 && response.data.isOk === true) {
                    // show success message
                    toast.info(
                        `ISO ${isoUpdateData.name} updated successfully!`,
                        {
                            position: "top-center",
                            autoClose: 1000,
                        }
                    );
                    // setFormSubmitted(true);
					SessionService.resetUserHierarchy();
                    onUpdateCallback(isoUpdateData);
                    reset();
                    setZipApiUpdated(false);
                    renderCount.current = 0;
                } else {
                    // console.log("Error!");
                    // show error messages
                    const errorMappings = {
                        name: "name",
                        email_address: "email",
                        phone: "phone",
                        address_1: "address_1",
                        address_2: "address_2",
                        zip: "zip",
                        city: "city",
                        state: "state",
                        status_id: "status_id",
                    };

                    Object.entries(response.data.message).forEach(
                        ([field, message]) => {
                            const errorType = errorMappings[field];
                            if (errorType) {
                                setError(errorType, {
                                    type: "server",
                                    message,
                                });
                            }
                        }
                    );
                }
            })
            .catch((error) => {
                // console.log(error);
                setError("api", {
                    type: "server",
                    message: error.response.data.error,
                });
            });

        // Set loading state
        setLoading(false);
    };

    return (
        <>
            {formSubmitted ? (
                <p className="paragraph-2">
                    <strong>SUCCESS! YOUR MESSAGE HAS BEEN RECEIVED.</strong>
                    <br />
                    WE WILL GET IN CONTACT WITH YOU SHORTLY.
                </p>
            ) : (
                <form
                    id="iso_form"
                    name="iso-form"
                    onSubmit={handleSubmit(onSubmit)}
                    // onSubmit={handleSubmit(
                    //     console.log("Validation errors:", errors)
                    // )}
                    className="form-vertical"
                >
                    <Row style={{ marginBottom: "0" }}>
                        <Col lg="6">
                            <FormGroup className="form-group">
                                <FormLabel htmlFor="name">
                                    Name <div className="has-star">*</div>
                                </FormLabel>
                                <FormControl
                                    type="text"
                                    maxLength="100"
                                    name="name"
                                    id="name"
                                    {...register("name")}
                                    isInvalid={
                                        (touchedFields.name &&
                                            !!errors["name"]) ||
                                        (!isoData &&
                                            isSubmitted &&
                                            !!errors["name"])
                                    }
                                    isValid={
                                        touchedFields.name && !errors["name"]
                                    }
                                />
                                <Feedback type="invalid">
                                    {errors["name"] && errors["name"].message}
                                </Feedback>
                            </FormGroup>

                            <FormGroup className="form-group">
                                <FormLabel htmlFor="email">
                                    Email address{" "}
                                    <div className="has-star">*</div>
                                </FormLabel>
                                <FormControl
                                    type="email"
                                    maxLength="100"
                                    name="email"
                                    id="email"
                                    {...register("email")}
                                    isInvalid={
                                        (touchedFields.email &&
                                            !!errors["email"]) ||
                                        (!isoData &&
                                            isSubmitted &&
                                            !!errors["email"])
                                    }
                                    isValid={
                                        (touchedFields.email &&
                                            !errors["email"]) ||
                                        (!isoData &&
                                            isSubmitted &&
                                            !errors["email"])
                                    }
                                />
                                <Feedback type="invalid">
                                    {errors["email"] && errors["email"].message}
                                </Feedback>
                            </FormGroup>

                            <FormGroup className="form-group">
                                <FormLabel htmlFor="phone">
                                    Phone number{" "}
                                    <div className="has-star">*</div>
                                </FormLabel>
                                <PhoneInput
                                    id="phone"
                                    name="phone"
                                    defaultCountry="US"
                                    // countries={["US", "CA", "PR", "MX"]}
                                    countries={["US"]}
                                    addInternationalOption={false}
                                    control={control}
                                    inputComponent={PhoneInputBs5}
                                    isInvalid={
                                        (touchedFields.phone &&
                                            !!errors["phone"]) ||
                                        (!isoData &&
                                            isSubmitted &&
                                            !!errors["phone"])
                                    }
                                    isValid={
                                        (touchedFields.phone &&
                                            !errors["phone"]) ||
                                        (!isoData &&
                                            isSubmitted &&
                                            !errors["phone"])
                                    }
                                />
                                {errors.phone && (
                                    <div className="is-invalid invalid-feedback">
                                        {errors.phone.message}
                                    </div>
                                )}
                            </FormGroup>

                            {isEditMode ? (
                                <>
                                    <FormGroup className="form-group">
                                        <FormLabel htmlFor="status_id">
                                            Status{" "}
                                            <div className="has-star">*</div>
                                        </FormLabel>
                                        <FormSelect
                                            name="status_id"
                                            id="status_id"
                                            {...register("status_id")}
                                            isInvalid={
                                                (touchedFields.status_id &&
                                                    !!errors["status_id"]) ||
                                                (!isoData &&
                                                    isSubmitted &&
                                                    !!errors["status_id"])
                                            }
                                            isValid={
                                                touchedFields.status_id &&
                                                !errors["status_id"]
                                            }
                                        >
                                            <option value="" disabled>
                                                Select status...
                                            </option>
                                            {
                                                // TODO: get status list from API
                                                //enabled = 1
                                                //disabled = 0
                                            }
                                            <option value="0">Disabled</option>
                                            <option value="1">Enabled</option>
                                        </FormSelect>
                                        <Feedback type="invalid">
                                            {errors["status_id"] &&
                                                errors["status_id"].message}
                                        </Feedback>
                                    </FormGroup>
                                </>
                            ) : (
                                <></>
                            )}
                        </Col>
                        <Col lg="6">
                            <FormGroup className="form-group">
                                <FormLabel htmlFor="address">
                                    Address 1 <div className="has-star">*</div>
                                </FormLabel>
                                <FormControl
                                    type="text"
                                    maxLength="100"
                                    name="address_1"
                                    id="address_1"
                                    {...register("address_1")}
                                    isInvalid={
                                        (touchedFields.address_1 &&
                                            !!errors["address_1"]) ||
                                        (!isoData &&
                                            isSubmitted &&
                                            !!errors["address_1"])
                                    }
                                    isValid={
                                        (touchedFields.address_1 &&
                                            !errors["address_1"]) ||
                                        (!isoData &&
                                            isSubmitted &&
                                            !errors["address_1"])
                                    }
                                />
                                <Feedback type="invalid">
                                    {errors["address_1"] &&
                                        errors["address_1"].message}
                                </Feedback>
                            </FormGroup>

                            <FormGroup className="form-group">
                                <FormLabel htmlFor="address_2">
                                    Address 2
                                </FormLabel>
                                <FormControl
                                    type="text"
                                    maxLength="100"
                                    name="address_2"
                                    id="address_2"
                                    {...register("address_2")}
                                    isInvalid={
                                        (touchedFields.address_2 &&
                                            !!errors["address_2"]) ||
                                        (!isoData &&
                                            isSubmitted &&
                                            !!errors["address_2"])
                                    }
                                    isValid={
                                        (touchedFields.address_2 &&
                                            !errors["address_2"]) ||
                                        (!isoData &&
                                            isSubmitted &&
                                            !errors["address_2"])
                                    }
                                />
                                <Feedback type="invalid">
                                    {errors["address_2"] &&
                                        errors["address_2"].message}
                                </Feedback>
                            </FormGroup>

                            <FormGroup className="form-group">
                                <FormLabel htmlFor="zip">
                                    Zip code <div className="has-star">*</div>
                                </FormLabel>
                                <FormControl
                                    type="text"
                                    maxLength="5"
                                    name="zip"
                                    id="zip"
                                    inputMode="numeric"
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(
                                            /[^0-9]/g,
                                            ""
                                        );
                                    }}
                                    {...register("zip")}
                                    isInvalid={
                                        (touchedFields.zip &&
                                            !!errors["zip"]) ||
                                        (!isoData &&
                                            isSubmitted &&
                                            !!errors["zip"])
                                    }
                                    isValid={
                                        (touchedFields.zip && !errors["zip"]) ||
                                        (!isoData &&
                                            isSubmitted &&
                                            !errors["zip"])
                                    }
                                />
                                <Feedback type="invalid">
                                    {errors["zip"] && errors["zip"].message}
                                </Feedback>
                            </FormGroup>

                            <FormGroup className="form-group">
                                <FormLabel htmlFor="city">
                                    City <div className="has-star">*</div>
                                </FormLabel>
                                <FormControl
                                    type="text"
                                    maxLength="255"
                                    name="city"
                                    id="city"
                                    readOnly
                                    {...register("city")}
                                    isInvalid={
                                        (touchedFields.city &&
                                            !!errors["city"]) ||
                                        (!isoData &&
                                            isSubmitted &&
                                            !!errors["city"]) ||
                                        (isZipApiUpdated && !!errors["city"])
                                    }
                                    isValid={
                                        (touchedFields.city &&
                                            !errors["city"]) ||
                                        (!isoData &&
                                            isSubmitted &&
                                            !errors["city"]) ||
                                        (isZipApiUpdated && !errors["city"])
                                    }
                                />
                                <Feedback type="invalid">
                                    {errors["city"] && errors["city"].message}
                                </Feedback>
                            </FormGroup>

                            <FormGroup className="form-group">
                                <FormLabel htmlFor="state">
                                    State <div className="has-star">*</div>
                                </FormLabel>
                                <FormControl
                                    type="text"
                                    maxLength="255"
                                    name="state"
                                    id="state"
                                    readOnly
                                    {...register("state")}
                                    isInvalid={
                                        (touchedFields.state &&
                                            !!errors["state"]) ||
                                        (!isoData &&
                                            isSubmitted &&
                                            !!errors["state"]) ||
                                        (isZipApiUpdated && !!errors["state"])
                                    }
                                    isValid={
                                        (touchedFields.state &&
                                            !errors["state"]) ||
                                        (!isoData &&
                                            isSubmitted &&
                                            !errors["state"]) ||
                                        (isZipApiUpdated && !errors["state"])
                                    }
                                />
                                <Feedback type="invalid">
                                    {errors["state"] && errors["state"].message}
                                </Feedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    {errors.api && (
                        <Row>
                            <Col lg style={{ marginBottom: "2rem" }}>
                                <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium Mui-required">
                                    {errors.api.message}
                                </p>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col className="d-flex justify-content-end align-items-center">
                            {isEditMode ? (
                                // If in "edit" mode, show "Update" button
                                <Button
                                    type="button"
                                    variant="primary"
                                    disabled={
                                        isSubmitting || !isValid || !isDirty
                                    }
                                    onClick={handleSubmit(onUpdate)}
                                    // onClick={handleSubmit(
                                    //     console.log(
                                    //         "Validation errors:",
                                    //         errors
                                    //     )
                                    // )}
                                >
                                    {isSubmitting ? (
                                        <>
                                            <span
                                                className="spinner-border spinner-border-sm mr-1"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    Save
                                </Button>
                            ) : (
                                // If in "create" mode, show "Submit" button
                                <Button
                                    type="submit"
                                    variant="success"
                                    disabled={isSubmitting || !isValid}
                                >
                                    {isSubmitting ? (
                                        <>
                                            <span
                                                className="spinner-border spinner-border-sm mr-1"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    Submit
                                </Button>
                            )}
                        </Col>
                    </Row>
                </form>
            )}
            <DevTool control={control} />
        </>
    );
}

export default IsosForm;
