import React, { useEffect, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

const CustomSwitchComponent = ({
	name,
	label,
	type,
	value,
	onChange,
	onBlur,
	className,
	...otherProps
}) => {
	const [internalValue, setInternalValue] = useState(
		value === "true" || value === true
	);
	const [isValid, setIsValid] = useState(true);
	const [errorMessage, setErrorMessage] = useState("");
	const [isTouched, setIsTouched] = useState(false);

	useEffect(() => {
		setInternalValue(value === "true" || value === true);
	}, [value]);

	useEffect(() => {
		validate();
	}, [value, isTouched]);

	const handleChange = (event) => {
		const newValue = event.target.checked;
		setInternalValue(newValue);
		onChange(name, newValue, true);
	};

	const validate = () => {
		const fieldInfo = getFieldInfo();
		if (fieldInfo.required && !internalValue) {
			setIsValid(false);
			setErrorMessage("This field is required.");
		} else {
			setIsValid(true);
			setErrorMessage("");
		}
	};

	const handleFocus = () => {
		setIsTouched(true);
	};

	const getFieldInfo = () => ({
		required: otherProps.required || false,
		readOnly: otherProps.readOnly || false,
		upperCase: otherProps.upperCase || false,
	});

	return (
		<div className={className}>
			<FormControl error={!isValid && isTouched} className="w-full">
				<FormControlLabel
					control={
						<Switch
							id={name}
							checked={internalValue}
							onChange={handleChange}
							onFocus={handleFocus}
							onBlur={onBlur}
							name={name}
							{...otherProps}
						/>
					}
					label={
						otherProps?.style?.textTransform
							? label.toUpperCase()
							: label
					}
				/>
				{!isValid && isTouched && (
					<FormHelperText>{errorMessage}</FormHelperText>
				)}
			</FormControl>
		</div>
	);
};

export default CustomSwitchComponent;
