import React from "react";
//import Alert from "@mui/material/Alert";
//import AlertTitle from "@mui/material/AlertTitle";
//import RocketLaunch from "@mui/icons-material/RocketLaunch";

function AnnouncementAlert() {
	return (
		<>
			{/* <Alert
				icon={<RocketLaunch fontSize="inherit" />}
				severity="info"
				style={{
					width: "500px",
					maxWidth: "100%",
					margin: "auto",
					borderRadius: "10px",
				}}
			>
				<AlertTitle style={{ fontWeight: 600 }}>
					We’re thrilled to unveil our revamped website!
				</AlertTitle>
				<a
					href="https://www.rtomobile.com"
					target="_blank"
					rel="noreferrer"
					style={{ marginBottom: "1rem" }}
				>
					Dive in and discover the new content, sleek design, and
					user-friendly interface!
				</a>
				<br />
				We want your feedback! Explore the site and share your feedback
				to us at{" "}
				<a href="mailto:info@rtomobile.com">info@rtomobile.com</a>.
			</Alert>
			<br /> */}
		</>
	);
}

export default AnnouncementAlert;
