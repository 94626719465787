import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import DealerContractReconciliationTable from "../../components/backend/reports/DealerContractReconciliationTable";

function DealerContractReconciliationReport({ loading, setLoading }) {
	const pageTitle =
		"Reports | Dealer Contract Reconciliation Report | RTO Mobile";
	return (
		<>
			<Helmet>
				<title>{pageTitle}</title>
				<meta name="robots" content="noindex,nofollow"></meta>
				<meta property="og:title" content={pageTitle} />
				<meta name="twitter:title" content={pageTitle} />
			</Helmet>
			<div className="rto_form-sections">
				<div className="rto_content-div" style={{ maxWidth: "1600px" }}>
					<Breadcrumb
						listProps={{ className: "w-100 justify-content-end" }}
					>
						<Breadcrumb.Item
							linkAs={Link}
							linkProps={{ to: "/dashboard" }}
						>
							Dashboard
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkAs={Link}
							linkProps={{ to: "/reports" }}
						>
							Reports
						</Breadcrumb.Item>

						<Breadcrumb.Item active>
							Dealer Contract Reconciliation Report
						</Breadcrumb.Item>
					</Breadcrumb>
					<div
						className="card card-outline card-primary"
						style={{
							borderTop: "3px solid #00aaff",
							boxShadow:
								"0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
						}}
					>
						<div className="card-body">
							<DealerContractReconciliationTable
								loading={loading}
								setLoading={setLoading}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default DealerContractReconciliationReport;
