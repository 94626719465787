import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html#jump-StoreProduct
 */

const StoreProductService = {

    /**
     * data filter by ID
     * @param {array} data {storeId : 1, page: 1, limit: 300}
	 * @see /docs/api/index.html#jump-StoreProducts-FetchByStoreId
     * @returns
     */
    fetchByStoreId: (data) => {
        return ApiService.post("/store-products/fetch-by-store-id", data).catch((error) => {
            console.log(error);
        });
    },

	/**
     * Update service
	 * @see /docs/api/index.html#jump-StoreProducts-Update
     * @param json data {storeProductId : 19200, status_id: 0}
     * @returns
     */
    update: (data) => {
        return ApiService.post("/store-products/update", data).catch((error) => {
            console.log(error);
        });
    },
};

export default StoreProductService;