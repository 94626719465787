import React from "react";
import Grid from "@mui/material/Grid";
import Item from "@mui/material/Grid";

export default function ReportCardBlock(props) {
	return (
		<Grid item xs={6}>
			<Item>
				<div
					className="rto_result-report-block card border-bottom-0 border-right-0 border-left-0 card-primary"
					style={{
						borderTop: "3px solid #00aaff",
						display: "flex",
					}}
				>
					<h3
						className="rto_contract-h3-preview-title"
						style={{
							fontWeight: 300,
						}}
					>
						<b>
							<span
								className={props.headerIcon}
								style={{
									color: "#00aaff",
								}}
							></span>
						</b>
						&nbsp;{props.headerText}
					</h3>
					<ul>
						{props.hrefNew.length > 0 && (
							<li>
								<a href={props.hrefNew} className="rto_link-2">
									View Report - New Version
								</a>
							</li>
						)}
						<li>
							<a href={props.hrefOld} className="rto_link-2">
								View Report - Old Version
							</a>
						</li>
					</ul>
				</div>
			</Item>
		</Grid>
	);
}
