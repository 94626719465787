import React from "react";
import { getImageSource } from "../../helpers/PaymentMethodImageHelper";
import { RiCheckboxCircleLine } from "react-icons/ri";


function PaymentMethod({ method, isSelected, onSelect }) {
	return (
		<div
			key={method.id}
			onClick={() => onSelect(method.id)}
			// onClick={() => method.acct_type === "ECHK" && onSelect(method.id)}
			className={`relative h-48 w-100 max-w-[320px] rounded-xl bg-gradient-to-r from-gray-500 to-gray-400 text-white overflow-y-auto cursor-pointer ${
				isSelected
					? "ring-4 ring-offset-1 ring-[#00ABF9] transition-all"
					: "transition-all"
			// ${method.acct_type !== "ECHK" ? "opacity-50 cursor-not-allowed" : ""}`}
			}`}
		>
			{isSelected && (
				<div className="absolute top-0 left-0 mt-2 ml-2 h-3 w-3 rounded-full bg-[#00ABF9] transition-all"></div>
			)}
			{method.default_acct === 'Y' && (
				<div className="absolute top-0 right-0 mt-[6rem] mr-[4rem] h-3 w-3 transition-all">
					<RiCheckboxCircleLine className="text-2xl text-[#00f930]" />
				</div>
			)}
			<div className="absolute top-4 w-full px-4 sm:top-4">
				<div className="flex justify-between">
					<div className="">
						<p className="font-light mb-0">Name</p>
						<p className="text-sm font-medium tracking-widest mb-0">
							{method.name}
						</p>
					</div>
					<img
						className="h-14 w-14 object-contain"
						src={getImageSource(method.acct_type)}
						alt={method.acct_type}
					/>
				</div>
				{/* {method.acct_type !== "ECHK" && (
					<div className="pt-1">
						<p className="font-light mb-0">Card Number</p>
						<p className="tracking-more-wider font-medium mb-0">
						    {method.token.replace(/(.{4})/g, '$1 ')}
						</p>
					</div>
				)} */}
				<div className="pt-1">
					<p className="font-light mb-0">
						{method.acct_type !== "ECHK" ? "Card" : "Account"}{" "}
						Number
					</p>
					<p className="tracking-more-wider font-medium mb-0">
						{`${method.token.slice(0, 2)}${method.token
							.slice(2, -4)
							.replace(/./g, "X")}${method.token.slice(-4)}`
							.replace(/(.{4})/g, "$1 ")
							.trim()}
					</p>
				</div>
				{/* {method.acct_type === "ECHK" && (
					<div className="pt-1">
						<p className="font-light mb-0">Account Number</p>
						<p className="text-sm tracking-more-wider font-medium mb-0">
						    {method.token.slice(0, 2) + " " + method.token.slice(2).replace(/(.{4})/g, '$1 ')}
						</p>
					</div>
				)} */}
				<div
					className={`${
						method.acct_type !== "ECHK" ? "pt-4 pr-6" : "pt-2"
					} sm:pt-6`}
				>
					<div className="flex justify-between">
						{/* <div className="">
							<p className="text-xs font-light mb-0">
								Valid From
							</p>
							<p className="text-base font-medium tracking-widest mb-0">
								11/15
							</p>
						</div> */}
						{method.expiry && (
							<div className="">
								<p className="text-xs font-light mb-0">
									Expiry
								</p>
								<p className="text-base font-medium tracking-widest mb-0">
									{method.expiry.slice(0, 2) +
										"/" +
										method.expiry.slice(2)}
								</p>
							</div>
						)}
						{method.cc_cvv && (
							<div className="">
								<p className="text-xs font-light mb-0">CVV</p>
								<p className="tracking-more-wider text-sm font-bold mb-0">
									{method.cc_cvv}
								</p>
							</div>
						)}
						{method.routing_number && (
							<>
								<div></div>
								<div className="">
									<p className="text-xs font-light mb-0">
										Routing Number
									</p>
									<p className="text-sm font-medium tracking-widest mb-0">
										{method.routing_number}
									</p>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default PaymentMethod;
