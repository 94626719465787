import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html
 */

const UserLogService = {
	/**
	 *
	 * @param {View} View
	 * @returns
	 */
	view: (data) => {
		return ApiService.post("/user-log/index", data).catch((error) => {
			console.log(error);
			return error;
		});
	},
};

export default UserLogService;
