import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html
 */

const ReferralProgramService = {

    /**
     * @see /docs/api/index.html#jump-ReferralProgram-Create
     * @param {ReferralProgramForm} ReferralProgramForm
     * @returns
     */
    create: ( ReferralProgramForm ) => {
        return ApiService.post("/referral-program/create", ReferralProgramForm).catch((error) => {
            console.log(error);
        });
    },
};

export default ReferralProgramService;
