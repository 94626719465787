import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html#Payments
 */

const PaymentService = {
	/**
	 *
	 * @param int contractId contracts.id value
	 * @see /docs/api/index.html#jump-Payments-PaymentContractHistoryGet
	 * @returns
	 */
	contractHistoryGet: (contractId) => {
		return ApiService.post("/payments/contract-history-get", {
			contract_id: contractId,
		}).catch((error) => {
			console.log(error);
			return error;
		});
	},

	contractHistoryVoid: (data) => {
		return ApiService.post("/payments/contract-history-void", data).catch(
			(error) => {
				console.log(error);
				return error;
			}
		);
	},

	contractHistoryVoidConfirm: (data) => {
		return ApiService.post(
			"/payments/contract-history-void-confirm",
			data
		).catch((error) => {
			console.log(error);
			return error;
		});
	},

	contractHistoryAdd: (data) => {
		return ApiService.post("/payments/contract-history-add", data).catch(
			(error) => {
				console.log(error);
				return error;
			}
		);
	},

	contractHistoryAddConfirm: (data) => {
		return ApiService.post(
			"/payments/contract-history-add-confirm",
			data
		).catch((error) => {
			console.log(error);
			return error;
		});
	},

	/**
	 * ABP remove
	 * @param {*} data
	 * @see /docs/api/index.html#jump-Contract-AbpRemove
	 * @returns
	 */
	contractRemoveAbp: (data) => {
		return ApiService.post("/payments/contract-remove-abp", data).catch(
			(error) => {
				console.log(error);
			}
		);
	},

	/**
	 * ABP add
	 * @param {*} data
	 * @see /docs/api/index.html#jump-Contract-AbpRemove
	 * @returns
	 */
	contractAddAbp: (data) => {
		return ApiService.post("/payments/contract-add-abp", data).catch(
			(error) => {
				console.log(error);
			}
		);
	},

	/**
	 * ContractEnableAbp
	 * @param {JSON} data
	 * @returns
	 */
	contractEnableAbp: (data) => {
		return ApiService.post("/payments/contract-enable-abp", data).catch(
			(error) => {
				console.log(error);
			}
		);
	},

	/**
	 * ContractDisableAbp
	 * @param {JSON} data
	 * @returns
	 */
	contractDisableAbp: (data) => {
		return ApiService.post("/payments/contract-disable-abp", data).catch(
			(error) => {
				console.log(error);
			}
		);
	},

	/**
	 * ContractAbpMakeDefault
	 * @param {JSON} data
	 * @returns
	 */
	ContractAbpMakeDefault: (data) => {
		return ApiService.post(
			"/payments/contract-abp-make-default",
			data
		).catch((error) => {
			console.log(error);
		});
	},

	/**
	 * Contract Payment Calculate
	 * @param {JSON} data
	 * @returns
	 */
	contractPaymentCalculate: (data) => {
		return ApiService.post(
			"/payments/contract-payment-calculate",
			data
		).catch((error) => {
			console.log(error);
			return error;
		});
	},

	/**
	 * Contract Payment Process
	 * @param {JSON} data
	 * @returns
	 */
	contractPaymentProcess: (data) => {
		return ApiService.post(
			"/payments/contract-payment-process",
			data
		).catch((error) => {
			console.log(error);
			return error;
		});
	},

	/**
	 * Contract Payment Get
	 * @param {JSON} contractId
	 * @returns
	 */
	contractPaymentGet: (contractId) => {
		return ApiService.post("/payments/contract-payment-get", {
			contract_id: contractId,
		}).catch((error) => {
			console.log(error);
			return error;
		});
	},
};

export default PaymentService;
