import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";

/**
 * @doc when we need to copy the content to another textbox
 * [
 *	'type' => 'input',
 *	'name' => 'address_1',
 *	'class' => 'col-md-12',
 *	'targetTextComponent' => 'address_2',  // Optional property to copy the content to another textbox
 * ],
 */

const CustomInputComponent = ({
	name,
	label,
	type,
	value,
	onChange,
	onBlur,
	className,
	targetTextComponent,
	...otherProps
}) => {
	const [internalValue, setInternalValue] = useState(value);
	const [isValid, setIsValid] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [isTouched, setIsTouched] = useState(false);

	useEffect(() => {
		// Update internalValue when the external value prop changes
		setInternalValue(value);
	}, [value]);

	useEffect(() => {
		// Validate whenever internalValue changes
		validate();
	}, [internalValue]);

	const handleChange = (newValue) => {
		setInternalValue(newValue);
		onChange(name, newValue, isValid);
	};

	const validate = () => {
		const fieldInfo = getFieldInfo();

		let trimmedValue = value;

		// if (fieldInfo.upperCase) {
		// 	trimmedValue = trimmedValue.toUpperCase();
		// }

		setInternalValue(trimmedValue);

		if (fieldInfo.readOnly) {
			setIsValid(true);
			setErrorMessage("");
			onChange(name, trimmedValue, true);
			return;
		}

		// Check minLength and maxLength only if the field is not empty
		if (trimmedValue !== "") {
			// Check minLength
			if (
				fieldInfo.minLength &&
				trimmedValue.length < fieldInfo.minLength
			) {
				setIsValid(false);
				setErrorMessage(
					`Minimum length is ${fieldInfo.minLength} characters.`
				);
				onChange(name, trimmedValue, false);
				return;
			}

			// Check maxLength
			if (
				fieldInfo.maxLength &&
				trimmedValue.length > fieldInfo.maxLength
			) {
				setIsValid(false);
				setErrorMessage(
					`Maximum length is ${fieldInfo.maxLength} characters.`
				);
				onChange(name, trimmedValue, false);
				return;
			}
		}

		// Check required
		if (fieldInfo.required && trimmedValue === "") {
			setIsValid(false);
			setErrorMessage("This field is required.");
			onChange(name, trimmedValue, false);

			if (targetTextComponent) {
				onChange(targetTextComponent, trimmedValue, true);
			}
			return;
		}

		setIsValid(true);
		setErrorMessage("");
		onChange(name, trimmedValue, true);

		if (targetTextComponent) {
			onChange(targetTextComponent, trimmedValue, true);
		}
	};

	const handleFocus = () => {
		setIsTouched(true);
		validate();
	};

	const getFieldInfo = () => {
		return {
			required: Boolean(type && type.required),
			minLength: type && type.minLength,
			maxLength: type && type.maxLength,
			readOnly: type && type.readOnly,
			upperCase: type && type.upperCase,
		};
	};

	return (
		<div className={`${className}`}>
			<TextField
				id={name}
				label={`${label}`}
				variant="standard"
				size="small"
				fullWidth
				type={type && type.type}
				value={internalValue}
				onChange={(e) => handleChange(e.target.value)}
				onFocus={handleFocus}
				error={!isValid && isTouched}
				helperText={!isValid && isTouched && errorMessage}
				className={getFieldInfo().readOnly ? "readonly-text" : ""}
				inputProps={{
					readOnly: getFieldInfo().readOnly,
					style: {
						textTransform: getFieldInfo().upperCase
							? "uppercase"
							: "none",
					},
				}}
				{...otherProps}
			/>
		</div>
	);
};

export default CustomInputComponent;
