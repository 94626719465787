import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import Feedback from "react-bootstrap/Feedback";
import PhoneInput from "react-phone-number-input/react-hook-form";
import PhoneInputBs5 from "../common/PhoneInputBs5";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { DevTool } from "@hookform/devtools";
import axios from "axios";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button, FormSelect } from "react-bootstrap";
import StoreService from "../../service/store/StoreService";
import SessionService from "../../service/SessionService";
import { FormControlLabel, Switch } from "@mui/material";
import Select from "react-select";
import CurrencyInput from "react-currency-input-field";
import { toast } from "react-toastify";

const usNumberRegex = /^\+1\d{10}$/;
const allowedCountryRegex = usNumberRegex;

const schema = z.object({
	struct_company_id: z
		.string()
		.toUpperCase()
		.trim()
		.nonempty({ message: "Company cannot be blank!" })
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return !isNaN(intValue);
			},
			{
				message: "Company must be a number!",
			}
		)
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return intValue >= 0;
			},
			{
				message: "Company cannot be negative!",
			}
		)
		.refine(
			(value) => {
				return /^[0-9]+$/.test(value);
			},
			{
				message: "Company must be a valid integer!",
			}
		),
	struct_stores_tag_id: z
		.string()
		.toUpperCase()
		.trim()
		.refine(
			(value) => {
				if (!value) return true; // Allow it to be blank
				const intValue = parseInt(value);
				return !isNaN(intValue);
			},
			{
				message: "Store Tag must be a number!",
			}
		)
		.refine(
			(value) => {
				if (!value) return true; // Allow it to be blank
				const intValue = parseInt(value);
				return intValue >= 0;
			},
			{
				message: "Store Tag cannot be negative!",
			}
		)
		.refine(
			(value) => {
				if (!value) return true; // Allow it to be blank
				return /^[0-9]+$/.test(value);
			},
			{
				message: "Store Tag must be a valid integer!",
			}
		)
		.optional(),
	name: z
		.string()
		.trim()
		.nonempty({ message: "Name cannot be blank!" })
		.max(255, {
			message: "Name cannot be longer than 255 characters!",
		}),
	email: z
		.string()
		.trim()
		.nonempty({ message: "Email address cannot be blank!" })
		.max(255, {
			message: "Email address cannot be longer than 255 characters!",
		})
		.email({ message: "Email address must be a valid email address!" }),
	phone: z
		.string({
			invalid_type_error: "Phone number cannot be blank!",
		})
		.trim()
		.nonempty({ message: "Phone number cannot be blank!" })
		.refine(
			(value) => {
				return isPossiblePhoneNumber(value);
			},
			{
				message: "Phone number is invalid!",
			}
		)
		.refine(
			(value) => {
				return allowedCountryRegex.test(value);
			},
			{
				message: "Phone number country is invalid!",
			}
		),
	account_manager: z
		.string()
		.trim()
		.max(255, {
			message: "Account Manager cannot be longer than 255 characters!",
		})
		.refine(
			(value) => {
				if (!value) return true; // Allow it to be blank
				return /^[A-Za-z0-9\s.'-]+$/.test(value);
			},
			{
				message: "Account Manager cannot contain special characters!",
			}
		)
		.optional(),
	// account_number: z
	//     .string()
	//     .trim()
	//     .nonempty({ message: "Account Number cannot be blank!" })
	//     .max(20, {
	//         message: "Account Number cannot be longer than 255 characters!",
	//     })
	//     .refine(
	//         (value) => {
	//             return /^[A-Za-z0-9\s.'-]+$/.test(value);
	//         },
	//         {
	//             message: "Account Number cannot contain special characters!",
	//         }
	//     ),
	default_sales_tax: z
		.string()
		.trim()
		.nonempty({ message: "Sales Tax cannot be blank!" })
		.max(255, {
			message: "Sales Tax cannot be longer than 255 characters!",
		})
		.refine(
			(value) => {
				const floatValue = parseFloat(value);
				return !isNaN(floatValue);
			},
			{
				message: "Sales Tax must be a number!",
			}
		)
		.refine(
			(value) => {
				const floatValue = parseFloat(value);
				return floatValue >= 0;
			},
			{
				message: "Sales Tax cannot be negative!",
			}
		)
		.refine(
			(value) => {
				// Regular expression to match decimal numbers with up to two decimal places
				return /^\d+(\.\d{1,2})?$/.test(value);
			},
			{
				message:
					"Sales Tax must be a valid decimal with up to two decimal places!",
			}
		),
	min_finance_factor: z
		.string()
		.trim()
		.nonempty({ message: "Minimum Finance Factor cannot be blank!" })
		.max(255, {
			message:
				"Minimum Finance Factor cannot be longer than 255 characters!",
		})
		.refine(
			(value) => {
				const floatValue = parseFloat(value);
				return !isNaN(floatValue);
			},
			{
				message: "Minimum Finance Factor must be a number!",
			}
		)
		.refine(
			(value) => {
				const floatValue = parseFloat(value);
				return floatValue >= 0;
			},
			{
				message: "Minimum Finance Factor cannot be negative!",
			}
		)
		.refine(
			(value) => {
				// Regular expression to match decimal numbers with up to two decimal places
				return /^\d+(\.\d{1,2})?$/.test(value);
			},
			{
				message:
					"Minimum Finance Factor must be a valid decimal with up to two decimal places!",
			}
		),
	default_finance_factor: z
		.string()
		.trim()
		.nonempty({ message: "Default Finance Factor cannot be blank!" })
		.max(255, {
			message:
				"Default Finance Factor cannot be longer than 255 characters!",
		})
		.refine(
			(value) => {
				const floatValue = parseFloat(value);
				return !isNaN(floatValue);
			},
			{
				message: "Default Finance Factor must be a number!",
			}
		)
		.refine(
			(value) => {
				const floatValue = parseFloat(value);
				return floatValue >= 0;
			},
			{
				message: "Default Finance Factor cannot be negative!",
			}
		)
		.refine(
			(value) => {
				// Regular expression to match decimal numbers with up to two decimal places
				return /^\d+(\.\d{1,2})?$/.test(value);
			},
			{
				message:
					"Default Finance Factor must be a valid decimal with up to two decimal places!",
			}
		),
	max_finance_factor: z
		.string()
		.trim()
		.nonempty({ message: "Maximum Finance Factor cannot be blank!" })
		.max(255, {
			message:
				"Maximum Finance Factor cannot be longer than 255 characters!",
		})
		.refine(
			(value) => {
				const floatValue = parseFloat(value);
				return !isNaN(floatValue);
			},
			{
				message: "Maximum Finance Factor must be a number!",
			}
		)
		.refine(
			(value) => {
				const floatValue = parseFloat(value);
				return floatValue >= 0;
			},
			{
				message: "Maximum Finance Factor cannot be negative!",
			}
		)
		.refine(
			(value) => {
				// Regular expression to match decimal numbers with up to two decimal places
				return /^\d+(\.\d{1,2})?$/.test(value);
			},
			{
				message:
					"Maximum Finance Factor must be a valid decimal with up to two decimal places!",
			}
		),
	default_down_payment_min: z
		.string()
		.trim()
		.nonempty({ message: "Minimum Down Payment cannot be blank!" })
		.max(255, {
			message:
				"Minimum Down Payment cannot be longer than 255 characters!",
		})
		.refine(
			(value) => {
				const floatValue = parseFloat(value);
				return !isNaN(floatValue);
			},
			{
				message: "Minimum Down Payment must be a number!",
			}
		)
		.refine(
			(value) => {
				const floatValue = parseFloat(value);
				return floatValue >= 0;
			},
			{
				message: "Minimum Down Payment cannot be negative!",
			}
		)
		.refine(
			(value) => {
				// Regular expression to match decimal numbers with up to two decimal places
				return /^\d+(\.\d{1,2})?$/.test(value);
			},
			{
				message:
					"Minimum Down Payment must be a valid decimal with up to two decimal places!",
			}
		),
	default_down_payment: z
		.string()
		.trim()
		.nonempty({ message: "Default Down Payment cannot be blank!" })
		.max(255, {
			message:
				"Default Down Payment cannot be longer than 255 characters!",
		})
		.refine(
			(value) => {
				const floatValue = parseFloat(value);
				return !isNaN(floatValue);
			},
			{
				message: "Default Down Payment must be a number!",
			}
		)
		.refine(
			(value) => {
				const floatValue = parseFloat(value);
				return floatValue >= 0;
			},
			{
				message: "Default Down Payment cannot be negative!",
			}
		)
		.refine(
			(value) => {
				// Regular expression to match decimal numbers with up to two decimal places
				return /^\d+(\.\d{1,2})?$/.test(value);
			},
			{
				message:
					"Default Down Payment must be a valid decimal with up to two decimal places!",
			}
		),
	default_down_payment_max: z
		.string()
		.trim()
		.nonempty({ message: "Maximum Down Payment cannot be blank!" })
		.max(255, {
			message:
				"Maximum Down Payment cannot be longer than 255 characters!",
		})
		.refine(
			(value) => {
				const floatValue = parseFloat(value);
				return !isNaN(floatValue);
			},
			{
				message: "Maximum Down Payment must be a number!",
			}
		)
		.refine(
			(value) => {
				const floatValue = parseFloat(value);
				return floatValue >= 0;
			},
			{
				message: "Maximum Down Payment cannot be negative!",
			}
		)
		.refine(
			(value) => {
				// Regular expression to match decimal numbers with up to two decimal places
				return /^\d+(\.\d{1,2})?$/.test(value);
			},
			{
				message:
					"Maximum Down Payment must be a valid decimal with up to two decimal places!",
			}
		),
	payment_types: z
		.array(
			z.object({
				value: z.string(),
				label: z.string(),
			})
		)
		.nonempty({ message: "Payment Types cannot be blank!" }),
	store_type_id: z
		.string()
		.toUpperCase()
		.trim()
		.nonempty({ message: "Store Type cannot be blank!" })
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return !isNaN(intValue);
			},
			{
				message: "Store Type must be a number!",
			}
		)
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return intValue >= 0;
			},
			{
				message: "Store Type cannot be negative!",
			}
		)
		.refine(
			(value) => {
				return /^[0-9]+$/.test(value);
			},
			{
				message: "Store Type must be a valid integer!",
			}
		),
	auto_contract_default_days: z
		.string()
		.trim()
		.nonempty({
			message: "Default Contract After x Days Past Due cannot be blank!",
		})
		.max(255, {
			message:
				"Default Contract After x Days Past Due cannot be longer than 255 characters!",
		})
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return !isNaN(intValue);
			},
			{
				message:
					"Default Contract After x Days Past Due must be a number!",
			}
		)
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return intValue >= 0;
			},
			{
				message:
					"Default Contract After x Days Past Due cannot be negative!",
			}
		)
		.refine(
			(value) => {
				return /^[0-9]+$/.test(value);
			},
			{
				message:
					"Default Contract After x Days Past Due must be a valid integer!",
			}
		),
	simple_calculator: z
		.string()
		.trim()
		.nonempty({ message: "Enable Simple Calculator cannot be blank!" })
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return !isNaN(intValue);
			},
			{
				message: "Enable Simple Calculator must be a number!",
			}
		)
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return intValue >= 0;
			},
			{
				message: "Enable Simple Calculator cannot be negative!",
			}
		)
		.refine(
			(value) => {
				return /^[0-9]+$/.test(value); // Only valid integers
			},
			{
				message: "Enable Simple Calculator must be a valid integer!",
			}
		),
	enable_abm_provisioning: z
		.string()
		.trim()
		.nonempty({ message: "Enable Pre Provisioning cannot be blank!" })
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return !isNaN(intValue);
			},
			{
				message: "Enable Pre Provisioning must be a number!",
			}
		)
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return intValue >= 0;
			},
			{
				message: "Enable Pre Provisioning cannot be negative!",
			}
		)
		.refine(
			(value) => {
				return /^[0-9]+$/.test(value); // Only valid integers
			},
			{
				message: "Enable Pre Provisioning must be a valid integer!",
			}
		),
	status_id: z
		.string()
		.trim()
		.refine(
			(value) => {
				if (!value) return true; // Allow it to be blank
				const intValue = parseInt(value);
				return !isNaN(intValue);
			},
			{
				message: "Status must be a number!",
			}
		)
		.refine(
			(value) => {
				if (!value) return true; // Allow it to be blank
				const intValue = parseInt(value);
				return intValue >= 0;
			},
			{
				message: "Status cannot be negative!",
			}
		)
		.refine(
			(value) => {
				return /^[0-9]*$/.test(value); // Allow it to be blank or valid integers
			},
			{
				message: "Status must be a valid integer!",
			}
		)
		.optional(),
	address_1: z
		.string()
		.trim()
		.nonempty({ message: "Address 1 cannot be blank!" })
		.max(255, {
			message: "Address 1 cannot be longer than 255 characters!",
		})
		.refine(
			(value) => {
				return /^[A-Za-z0-9\s.,'#-]+$/.test(value);
			},
			{
				message: "Address 1 cannot contain special characters!",
			}
		),
	address_2: z
		.string()
		.trim()
		.max(255, {
			message: "Address 2 cannot be longer than 255 characters!",
		})
		.refine(
			(value) => {
				//if not empty, then validate
				if (value) {
					return /^[A-Za-z0-9\s.'#-]+$/.test(value);
				}
				//if empty, consider it valid
				return true;
			},
			{
				message: "Address 2 cannot contain special characters!",
			}
		),
	zip: z
		.string()
		.toUpperCase()
		.trim()
		.nonempty({ message: "Zip code cannot be blank!" })
		.length(5, { message: "Zip code must contain 5 characters!" })
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return !isNaN(intValue);
			},
			{
				message: "Zip code must be a number!",
			}
		)
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return intValue >= 0;
			},
			{
				message: "Zip code cannot be negative!",
			}
		)
		.refine(
			(value) => {
				return /^[0-9]+$/.test(value);
			},
			{
				message: "Zip code must be a valid integer!",
			}
		),
	city: z
		.string()
		.toUpperCase()
		.trim()
		.nonempty({ message: "City cannot be blank!" })
		.max(255, { message: "City cannot be longer than 255 characters!" }),
	state: z
		.string()
		.toUpperCase()
		.trim()
		.nonempty({ message: "State cannot be blank!" })
		.max(255, { message: "State cannot be longer than 255 characters!" }),
	username: z
		.string()
		.trim()
		.max(255, {
			message:
				"CardConnect Username cannot be longer than 255 characters!",
		})
		.refine(
			(value) => {
				if (!value) return true; // Allow it to be blank
				return /^[A-Za-z0-9\s.'-]+$/.test(value);
			},
			{
				message:
					"CardConnect Username cannot contain special characters!",
			}
		)
		.optional(),
	password: z
		.string()
		.trim()
		.max(255, {
			message:
				"CardConnect Password cannot be longer than 255 characters!",
		})
		.optional(),
	card_connect_merchant_id: z
		.string()
		.trim()
		.max(12, {
			message:
				"CardConnect Merch ID cannot be longer than 12 characters!",
		})
		.refine(
			(value) => {
				if (!value) return true; // Allow it to be blank
				return /^[A-Za-z0-9\s.'-]+$/.test(value);
			},
			{
				message:
					"CardConnect Merch ID cannot contain special characters!",
			}
		)
		.optional(),
	// api_key: z
	//     .string()
	//     .trim()
	//     .nonempty({ message: "CardConnect API Key cannot be blank!" })
	//     .max(255, {
	//         message:
	//             "CardConnect API Key cannot be longer than 255 characters!",
	//     })
	//     .refine(
	//         (value) => {
	//             return /^[A-Za-z0-9\s.'-]+$/.test(value);
	//         },
	//         {
	//             message:
	//                 "CardConnect API Key cannot contain special characters!",
	//         }
	//     ),
	require_abp: z.boolean(),
	require_abp_no_lock: z.boolean(),
	require_device_lock: z.boolean(),
	require_id_verification: z.boolean(),
	// require_facebook_verification: z.boolean(),
	bank_name: z
		.string()
		.trim()
		.max(255, {
			message: "Bank Name cannot be longer than 255 characters!",
		})
		.optional(),
	bank_account_type_id: z
		.string()
		.trim()
		.refine(
			(value) => {
				if (!value) return true; // Allow it to be blank
				const intValue = parseInt(value);
				return !isNaN(intValue);
			},
			{
				message: "Bank Account Type must be a number!",
			}
		)
		.refine(
			(value) => {
				if (!value) return true; // Allow it to be blank
				const intValue = parseInt(value);
				return intValue >= 0;
			},
			{
				message: "Bank Account Type cannot be negative!",
			}
		)
		.refine(
			(value) => {
				if (!value) return true; // Allow it to be blank
				return /^[0-9]+$/.test(value); // Only valid integers
			},
			{
				message: "Bank Account Type must be a valid integer!",
			}
		)
		.optional(),
	bank_account_number: z
		.string()
		.trim()
		.max(16, {
			message: "Bank Account Number cannot be longer than 16 characters!",
		})
		.refine(
			(value) => {
				if (!value) return true; // Allow it to be blank
				return /^[A-Za-z0-9\s.'-]+$/.test(value);
			},
			{
				message:
					"Bank Account Number cannot contain special characters!",
			}
		)
		.optional(),
	bank_routing_number: z
		.string()
		.trim()
		.max(9, {
			message: "Bank Routing Number cannot be longer than 9 characters!",
		})
		.refine(
			(value) => {
				if (!value) return true; // Allow it to be blank
				return /^[A-Za-z0-9\s.'-]+$/.test(value);
			},
			{
				message:
					"Bank Routing Number cannot contain special characters!",
			}
		)
		.optional(),
	assigned_to: z
		.string()
		.trim()
		.refine(
			(value) => {
				if (!value) return true; // Allow it to be blank
				const intValue = parseInt(value);
				return !isNaN(intValue);
			},
			{
				message: "Assigned To must be a number!",
			}
		)
		.refine(
			(value) => {
				if (!value) return true; // Allow it to be blank
				const intValue = parseInt(value);
				return intValue >= 0;
			},
			{
				message: "Assigned To cannot be negative!",
			}
		)
		.refine(
			(value) => {
				return /^[0-9]*$/.test(value); // Allow it to be blank or valid integers
			},
			{
				message: "Assigned To must be a valid integer!",
			}
		)
		.optional(),
});

const updateSchema = schema.extend({
	max_contract_value: z
		.string()
		.trim()
		.nonempty({ message: "Maximum MSRP cannot be blank!" })
		.max(255, {
			message: "Maximum MSRP cannot be longer than 255 characters!",
		})
		.refine(
			(value) => {
				const floatValue = parseFloat(value);
				return !isNaN(floatValue);
			},
			{
				message: "Maximum MSRP must be a number!",
			}
		)
		.refine(
			(value) => {
				const floatValue = parseFloat(value);
				return floatValue >= 0;
			},
			{
				message: "Maximum MSRP cannot be negative!",
			}
		)
		.refine(
			(value) => {
				// Regular expression to match decimal numbers with up to two decimal places
				return /^\d+(\.\d{1,2})?$/.test(value);
			},
			{
				message:
					"Maximum MSRP must be a valid decimal with up to two decimal places!",
			}
		),
	max_refund_days: z
		.string()
		.trim()
		.nonempty({ message: "Max Refund Days cannot be blank!" })
		.max(255, {
			message: "Max Refund Days cannot be longer than 255 characters!",
		})
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return !isNaN(intValue);
			},
			{
				message: "Max Refund Days must be a number!",
			}
		)
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return intValue >= 0;
			},
			{
				message: "Max Refund Days cannot be negative!",
			}
		)
		.refine(
			(value) => {
				return /^[0-9]+$/.test(value);
			},
			{
				message: "Max Refund Days must be a valid integer!",
			}
		),
	billable: z
		.string()
		.trim()
		.nonempty({ message: "Billable cannot be blank!" })
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return !isNaN(intValue);
			},
			{
				message: "Billable must be a number!",
			}
		)
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return intValue >= 0;
			},
			{
				message: "Billable cannot be negative!",
			}
		)
		.refine(
			(value) => {
				return /^[0-9]+$/.test(value); // Only valid integers
			},
			{
				message: "Billable must be a valid integer!",
			}
		),
	esign_contracts_enabled: z
		.string()
		.trim()
		.nonempty({
			message: "E-sign Contracts cannot be blank!",
		})
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return !isNaN(intValue);
			},
			{
				message: "E-sign Contracts must be a number!",
			}
		)
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return intValue >= 0;
			},
			{
				message: "E-sign Contracts cannot be negative!",
			}
		)
		.refine(
			(value) => {
				return /^[0-9]+$/.test(value); // Only valid integers
			},
			{
				message: "E-sign Contracts must be a valid integer!",
			}
		),
	esign_contracts_required: z
		.string()
		.trim()
		.nonempty({
			message: "E-sign Contracts Required? cannot be blank!",
		})
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return !isNaN(intValue);
			},
			{
				message: "E-sign Contracts Required? must be a number!",
			}
		)
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return intValue >= 0;
			},
			{
				message: "E-sign Contracts Required? cannot be negative!",
			}
		)
		.refine(
			(value) => {
				return /^[0-9]+$/.test(value); // Only valid integers
			},
			{
				message: "E-sign Contracts Required? must be a valid integer!",
			}
		),
	allow_cash_down_payment: z.boolean(),
	new_contract_show_rental_factor: z
		.string()
		.trim()
		.nonempty({
			message: "New Contract - Show Rental Factor cannot be blank!",
		})
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return !isNaN(intValue);
			},
			{
				message: "New Contract - Show Rental Factor must be a number!",
			}
		)
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return intValue >= 0;
			},
			{
				message:
					"New Contract - Show Rental Factor cannot be negative!",
			}
		)
		.refine(
			(value) => {
				return /^[0-9]+$/.test(value); // Only valid integers
			},
			{
				message:
					"New Contract - Show Rental Factor must be a valid integer!",
			}
		),
	new_contract_show_total_value: z
		.string()
		.trim()
		.nonempty({
			message:
				"New Contract - Show Total Contract Value cannot be blank!",
		})
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return !isNaN(intValue);
			},
			{
				message:
					"New Contract - Show Total Contract Value must be a number!",
			}
		)
		.refine(
			(value) => {
				const intValue = parseInt(value);
				return intValue >= 0;
			},
			{
				message:
					"New Contract - Show Total Contract Value cannot be negative!",
			}
		)
		.refine(
			(value) => {
				return /^[0-9]+$/.test(value); // Only valid integers
			},
			{
				message:
					"New Contract - Show Total Contract Value must be a valid integer!",
			}
		),
	bolt_url: z
		.string()
		.trim()
		.max(255, {
			message:
				"CardConnect Bolt URL cannot be longer than 255 characters!",
		})
		.optional(),
	rest_url: z
		.string()
		.trim()
		.max(255, {
			message:
				"CardConnect Rest URL cannot be longer than 255 characters!",
		})
		.optional(),
	tokenizer_url: z
		.string()
		.trim()
		.max(255, {
			message:
				"CardConnect Tokenizer URL cannot be longer than 255 characters!",
		})
		.optional(),
	cau_enabled: z.boolean(),
	param_use_view_v3: z.boolean(),
});

const handleDoubleValueChange = (min, max, value, onChange) => {
	// If value is not defined, do nothing
	if (value === undefined) {
		onChange("");
		return;
	}
	// If the last character is a decimal point, allow it
	if (value.charAt(value.length - 1) === ".") {
		onChange(value);
	} else {
		// Check if the value ends with ".0"
		if (value.endsWith(".0")) {
			onChange(value);
		} else {
			// Check if the value is a valid number
			const parsedValue = parseFloat(value);
			if (isNaN(parsedValue)) {
				onChange("");
			} else {
				// Clamp the value between min and max
				const inRangeValue = Math.max(min, Math.min(max, parsedValue));
				onChange(String(inRangeValue));
			}
		}
	}
};

const handleDoubleBlur = (min, max, decimalsLimit, value, onChange) => {
	// Convert value to string
	const valueStr = String(value);
	// If the last character is a decimal point, remove it
	if (valueStr.charAt(valueStr.length - 1) === ".") {
		onChange(valueStr.slice(0, -1));
	}
	// Format the value to have two decimal places
	const floatValue = parseFloat(valueStr);
	if (!isNaN(floatValue)) {
		// Ensure the value is within the specified range
		const clampedValue = Math.max(min, Math.min(max, floatValue));
		onChange(clampedValue.toFixed(decimalsLimit));
	}
};

function StoresForm({ storeData, onUpdateCallback, loading, setLoading }) {
	const [storeTags, setStoreTags] = useState([]);
	const [storeTypes, setStoreTypes] = useState([]);
	const [storePaymentTypes, setStorePaymentTypes] = useState([]);

	const [selectedOptions, setSelectedOptions] = useState([
		{ value: "1", label: "Cash" },
		{ value: "2", label: "Credit Card" },
	]);

	const isEditMode = !!storeData;

	const {
		register,
		control,
		handleSubmit,
		formState: {
			errors,
			touchedFields,
			isSubmitting,
			isSubmitted,
			isValid,
			isDirty,
		},
		// getValues,
		setValue,
		watch,
		trigger,
		setError,
		reset,
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			struct_company_id: "",
			struct_stores_tag_id: "",
			name: "",
			email: "",
			phone: "",
			account_manager: "",
			// account_number: "",
			default_sales_tax: "8.25",
			max_contract_value: "2000.00",
			min_finance_factor: "1.00",
			default_finance_factor: "2.00",
			max_finance_factor: "5.00",
			default_down_payment_min: "0.00",
			default_down_payment: "20.00",
			default_down_payment_max: "100.00",
			max_refund_days: "0",
			payment_types: [
				{ value: "1", label: "Cash" },
				{ value: "2", label: "Credit Card" },
			],
			store_type_id: "1",
			auto_contract_default_days: "90",
			simple_calculator: "1",
			enable_abm_provisioning: "0",
			new_contract_show_rental_factor: "0",
			new_contract_show_total_value: "0",
			address_1: "",
			address_2: "",
			zip: "",
			city: "",
			state: "",
			username: "",
			password: "",
			card_connect_merchant_id: "",
			// api_key: "",
			bolt_url: "",
			rest_url: "",
			tokenizer_url: "",
			cau_enabled: false,
			param_use_view_v3: false,
			require_abp: true,
			require_abp_no_lock: true,
			require_device_lock: false,
			require_id_verification: false,
			// require_facebook_verification: false,
			allow_cash_down_payment: false,
			bank_name: "",
			bank_account_type_id: "",
			bank_account_number: "",
			bank_routing_number: "",
			billable: "1",
			esign_contracts_enabled: "0",
			esign_contracts_required: "0",
			status_id: "",
			asigned_to: "",
		},
		resolver: zodResolver(isEditMode ? updateSchema : schema),
	});

	const fetchStoreTags = async () => {
		try {
			const response = await StoreService.getTags();
			setStoreTags(response.data.data.query);
		} catch (error) {
			console.error("Error fetching store tags:", error);
		}
	};

	const fetchStoreTypes = async () => {
		try {
			const response = await StoreService.getTypes();
			setStoreTypes(response.data.data.query);
		} catch (error) {
			console.error("Error fetching store types:", error);
		}
	};

	const fetchStorePaymentTypes = async () => {
		try {
			const response = await StoreService.getPaymentTypes();
			setStorePaymentTypes(response.data.data.query);
		} catch (error) {
			console.error("Error fetching store payment types:", error);
		}
	};

	const fetchBankNameForRoutingNumber = async (routing_number) => {
		if (!routing_number) {
			setValue("bank_name", "", { shouldValidate: true });
			return;
		}
		try {
			const response = await StoreService.getBankNameForRoutingNumber(
				routing_number
			);
			if (response.data.isOk) {
				setValue("bank_name", response.data.data.query.customer_name, {
					shouldValidate: true,
				});
			} else {
				setValue("bank_name", "", { shouldValidate: true });
			}
		} catch (error) {
			console.error("Error fetching store bank name:", error);
		}
	};

	const zip = watch("zip");
	const [isZipApiUpdated, setZipApiUpdated] = useState(false);

	const isFirstRender = useRef(true);
	const renderCount = useRef(0);

	useEffect(() => {
		fetchStoreTags();
		fetchStoreTypes();
		fetchStorePaymentTypes();
	}, []);

	useEffect(() => {
		renderCount.current = renderCount.current + 1;

		const fetchLocationData = async () => {
			try {
				const response = await axios.get(
					// `https://api.zippopotam.us/us/${zip}`
					`https://zip.row.net/zip/${zip}`
				);
				const { places } = response.data;
				if (places && places.length > 0) {
					setValue("city", places[0]["place name"], {
						shouldValidate: true,
					});
					setValue("state", places[0]["state abbreviation"], {
						shouldValidate: true,
					});
				} else {
					setValue("city", "", { shouldValidate: true });
					setValue("state", "", { shouldValidate: true });
				}
				setZipApiUpdated(true);
			} catch (error) {
				setValue("city", "", { shouldValidate: true });
				setValue("state", "", { shouldValidate: true });
			}
		};

		if (zip && zip.length === 5 && /^\d+$/.test(zip)) {
			if (storeData) {
				if (renderCount.current > 2) {
					fetchLocationData();
				}
			} else {
				if (renderCount.current > 1) {
					fetchLocationData();
				}
			}
		} else {
			setValue("city", "", { shouldValidate: true });
			setValue("state", "", { shouldValidate: true });
		}

		if (isFirstRender.current) {
			isFirstRender.current = false;
			return;
		}
	}, [zip, setValue]);

	const bank_routing_number = watch("bank_routing_number");
	const [isBankRoutingNumberUpdated, setBankRoutingNumberUpdated] =
		useState(false);

	useEffect(() => {
		fetchBankNameForRoutingNumber(bank_routing_number);
	}, [bank_routing_number]);

	useEffect(() => {
		if (storeData !== undefined) {
			// console.log("storeData", storeData);
			setValue(
				"struct_company_id",
				storeData.company_id?.toString()
					? storeData.company_id?.toString()
					: storeData.struct_company_id?.toString() ?? ""
			);
			setValue(
				"struct_stores_tag_id",
				storeData.struct_stores_tag_id?.toString() ?? ""
			);
			setValue("name", storeData.name?.toString() ?? "");
			setValue("email", storeData.email?.toString() ?? "");
			setValue("phone", "+1" + storeData.phone?.toString() ?? "");
			setValue(
				"account_manager",
				storeData.account_manager?.toString() ?? ""
			);
			// setValue(
			//     "account_number",
			//     storeData.account_number?.toString() ?? ""
			// );
			setValue(
				"default_sales_tax",
				storeData.default_sales_tax?.toString() ?? ""
			);
			setValue(
				"max_contract_value",
				storeData.max_contract_value?.toString() ?? ""
			);
			setValue(
				"min_finance_factor",
				storeData.min_finance_factor?.toString() ?? ""
			);
			setValue(
				"default_finance_factor",
				storeData.default_finance_factor?.toString() ?? ""
			);
			setValue(
				"max_finance_factor",
				storeData.max_finance_factor?.toString() ?? ""
			);
			setValue(
				"default_down_payment_min",
				storeData.default_down_payment_min?.toString() ?? ""
			);
			setValue(
				"default_down_payment",
				storeData.default_down_payment?.toString() ?? ""
			);
			setValue(
				"default_down_payment_max",
				storeData.default_down_payment_max?.toString() ?? ""
			);
			setValue(
				"max_refund_days",
				storeData.max_refund_days?.toString() ?? ""
			);
			setValue(
				"payment_types",
				// storeData.payment_types?.toString() ?? ""
				// storeData.payment_types?.split(",").map((item) => {
				//     return { value: item, label: item };
				// }) ?? ""
				// get payment types, use storePaymentTypes to get the label
				storeData.payment_types?.split(",").map((item) => {
					const paymentType = storePaymentTypes.find(
						(storePaymentType) => {
							return String(storePaymentType.id) === String(item);
						}
					);

					return {
						value: item,
						label: paymentType ? paymentType.name : item,
					};
				}) ?? ""
			);
			setValue(
				"store_type_id",
				storeData.store_type_id?.toString() ?? ""
			);
			setValue(
				"auto_contract_default_days",
				storeData.auto_contract_default_days?.toString() ?? ""
			);
			setValue(
				"simple_calculator",
				storeData.simple_calculator?.toString() ?? ""
			);
			setValue(
				"enable_abm_provisioning",
				storeData.enable_abm_provisioning?.toString() ?? ""
			);
			setValue(
				"new_contract_show_rental_factor",
				storeData.new_contract_show_rental_factor?.toString() ?? ""
			);
			setValue(
				"new_contract_show_total_value",
				storeData.new_contract_show_total_value?.toString() ?? ""
			);
			setValue("address_1", storeData.address_1?.toString() ?? "");
			setValue("address_2", storeData.address_2?.toString() ?? "");
			setValue("zip", storeData.zip?.toString() ?? "");
			setValue("city", storeData.city?.toString() ?? "");
			setValue("state", storeData.state?.toString() ?? "");
			setValue("username", storeData.username?.toString() ?? "");
			setValue("password", storeData.password?.toString() ?? "");
			setValue(
				"card_connect_merchant_id",
				storeData.card_connect_merchant_id?.toString() ?? ""
			);
			// setValue("api_key", storeData.api_key?.toString() ?? "");
			setValue("bolt_url", storeData.bolt_url?.toString() ?? "");
			setValue("rest_url", storeData.rest_url?.toString() ?? "");
			setValue(
				"tokenizer_url",
				storeData.tokenizer_url?.toString() ?? ""
			);
			setValue("cau_enabled", storeData.cau_enabled === 1 ? true : false);
			setValue(
				"param_use_view_v3",
				storeData.param_use_view_v3 === 1 ? true : false
			);
			setValue("require_abp", storeData.require_abp === 1 ? true : false);
			setValue(
				"require_abp_no_lock",
				storeData.require_abp_no_lock === 1 ? true : false
			);
			setValue(
				"require_device_lock",
				storeData.require_device_lock === 1 ? true : false
			);
			setValue(
				"require_id_verification",
				storeData.require_id_verification === 1 ? true : false
			);
			// setValue(
			//     "require_facebook_verification",
			//     storeData.require_facebook_verification === "1" ? true : false
			// );
			setValue(
				"allow_cash_down_payment",
				storeData.allow_cash_down_payment === 1 ? true : false
			);
			setValue("bank_name", storeData.bank_name?.toString() ?? "");
			setValue(
				"bank_account_type_id",
				storeData.bank_account_type_id?.toString() ?? ""
			);
			setValue(
				"bank_account_number",
				storeData.bank_account_number?.toString() ?? ""
			);
			setValue(
				"bank_routing_number",
				storeData.bank_routing_number?.toString() ?? ""
			);
			setValue("billable", storeData.billable?.toString() ?? "1");
			setValue(
				"esign_contracts_enabled",
				storeData.esign_contracts_enabled?.toString() ?? "0"
			);
			setValue(
				"esign_contracts_required",
				storeData.esign_contracts_required?.toString() ?? "0"
			);
			setValue("status_id", storeData.status_id?.toString() ?? "");
			setValue("assigned_to", storeData.assigned_to?.toString() ?? "");
		}
	}, [storeData, storePaymentTypes]);

	const userHierarchy = SessionService.getUserHierarchy();

	const companies = userHierarchy.com_list ? userHierarchy.com_list : {};

	const navigate = useNavigate();

	const [formSubmitted, setFormSubmitted] = useState(false);

	const onSubmit = async (data) => {
		// Set loading state
		setLoading(true);

		const storeData = {
			StoreCreate: {
				struct_company_id: data.struct_company_id,
				struct_stores_tag_id: data.struct_stores_tag_id
					? data.struct_stores_tag_id
					: null,
				name: data.name,
				email_address: data.email,
				// phone: data.phone,
				// since US only, get phone last 10 digits
				phone: data.phone.substring(data.phone.length - 10),
				account_manager: data.account_manager,
				// account_number: data.account_number,
				default_sales_tax: data.default_sales_tax,
				min_finance_factor: data.min_finance_factor,
				default_finance_factor: data.default_finance_factor,
				max_finance_factor: data.max_finance_factor,
				default_down_payment_min: data.default_down_payment_min,
				default_down_payment: data.default_down_payment,
				default_down_payment_max: data.default_down_payment_max,
				// payment_types: data.payment_types,
				payment_types: data.payment_types
					.map((item) => item.value)
					.join(","),
				store_type_id: data.store_type_id,
				auto_contract_default_days: data.auto_contract_default_days,
				simple_calculator: data.simple_calculator,
				enable_abm_provisioning: data.enable_abm_provisioning,
				new_contract_show_rental_factor: 1,
				new_contract_show_total_value: 1,
				address_1: data.address_1,
				address_2: data.address_2,
				zip: data.zip,
				city: data.city,
				state: data.state,
				username: data.username,
				password: data.password,
				card_connect_merchant_id: data.card_connect_merchant_id,
				// api_key: data.api_key,
				bolt_url: "https://bolt.cardpointe.com/api/",
				rest_url: "https://fts.cardconnect.com/cardconnect/rest/",
				tokenizer_url:
					"https://fts.cardconnect.com/itoke/ajax-tokenizer.html",
				cau_enabled: 0,
				param_use_view_v3: 0,
				max_refund_days: 0,
				max_contract_value: 2000,
				esign_contracts_enabled: 0,
				esign_contracts_required: 0,
				require_abp: data.require_abp ? 1 : 0,
				require_abp_no_lock: data.require_abp_no_lock ? 1 : 0,
				require_device_lock: data.require_device_lock ? 1 : 0,
				require_id_verification: data.require_id_verification ? 1 : 0,
				// require_facebook_verification:
				//     data.require_facebook_verification ? 1 : 0,
				bank_name: data.bank_name,
				// bank_account_type_id: data.bank_account_type_id ?? 0,
				bank_account_number: data.bank_account_number,
				bank_routing_number: data.bank_routing_number,
				status_id: 1,
				assigned_to: data.assigned_to,
			},
		};

		//if bank_account_type_id is set, add it to storeData
		if (data.bank_account_type_id) {
			storeData.StoreCreate.bank_account_type_id =
				data.bank_account_type_id;
		} else {
			//remove bank_account_type_id from storeData
			delete storeData.StoreCreate.bank_account_type_id;
		}

		const response = await StoreService.create(storeData)
			.then((response) => {
				if (response.status === 200 && response.data.isOk === true) {
					// show success message
					toast.success(
						`Store ${storeData.StoreCreate.name} created successfully!`,
						{
							position: "top-center",
							autoClose: 1000,
						}
					);
					SessionService.resetUserHierarchy();
					// setFormSubmitted(true);
					navigate("/settings/stores");
				} else {
					// console.log("Error!");
					// show error messages
					const errorMappings = {
						// struct_iso_id: "struct_iso_id",
						// struct_master_agent_id: "struct_master_agent_id",
						struct_company_id: "struct_company_id",
						struct_stores_tag_id: "struct_stores_tag_id",
						name: "name",
						email: "email_address",
						phone: "phone",
						account_manager: "account_manager",
						// account_number: "account_number",
						default_sales_tax: "default_sales_tax",
						min_finance_factor: "min_finance_factor",
						default_finance_factor: "default_finance_factor",
						max_finance_factor: "max_finance_factor",
						default_down_payment_min: "default_down_payment_min",
						default_down_payment: "default_down_payment",
						default_down_payment_max: "default_down_payment_max",
						payment_types: "payment_types",
						store_type_id: "store_type_id",
						auto_contract_default_days:
							"auto_contract_default_days",
						simple_calculator: "simple_calculator",
						enable_abm_provisioning: "enable_abm_provisioning",
						address_1: "address_1",
						address_2: "address_2",
						zip: "zip",
						city: "city",
						state: "state",
						username: "username",
						password: "password",
						card_connect_merchant_id: "card_connect_merchant_id",
						// api_key: "api_key",
						require_abp: "require_abp",
						require_abp_no_lock: "require_abp_no_lock",
						require_device_lock: "require_device_lock",
						require_id_verification: "require_id_verification",
						// require_facebook_verification:
						//     "require_facebook_verification",
						bank_name: "bank_name",
						bank_account_type_id: "bank_account_type_id",
						bank_account_number: "bank_account_number",
						bank_routing_number: "bank_routing_number",
						status_id: "status_id",
					};

					Object.entries(response.data.message).forEach(
						([field, message]) => {
							const errorType = errorMappings[field];
							if (errorType) {
								setError(errorType, {
									type: "server",
									message,
								});
							}
						}
					);
				}
			})
			.catch((error) => {
				// console.log(error);
				setError("api", {
					type: "server",
					message: error.response.data.error,
				});
			});

		// Set loading state
		setLoading(false);
	};

	const onUpdate = async (data) => {
		// Set loading state
		setLoading(true);

		const storeUpdateData = {
			storeId: storeData.id,
			id: storeData.id,
			struct_company_id: data.struct_company_id,
			struct_stores_tag_id: data.struct_stores_tag_id
				? data.struct_stores_tag_id
				: null,
			name: data.name,
			email: data.email,
			phone: data.phone.substring(data.phone.length - 10),
			account_manager: data.account_manager,
			// account_number: data.account_number,
			default_sales_tax: data.default_sales_tax,
			max_contract_value: data.max_contract_value,
			min_finance_factor: data.min_finance_factor,
			default_finance_factor: data.default_finance_factor,
			max_finance_factor: data.max_finance_factor,
			default_down_payment_min: data.default_down_payment_min,
			default_down_payment: data.default_down_payment,
			default_down_payment_max: data.default_down_payment_max,
			max_refund_days: data.max_refund_days,
			// payment_types: data.payment_types,
			payment_types: data.payment_types
				.map((item) => item.value)
				.join(","),
			store_type_id: data.store_type_id,
			auto_contract_default_days: data.auto_contract_default_days,
			simple_calculator: data.simple_calculator,
			enable_abm_provisioning: data.enable_abm_provisioning,
			new_contract_show_rental_factor:
				data.new_contract_show_rental_factor,
			new_contract_show_total_value: data.new_contract_show_total_value,
			address_1: data.address_1,
			address_2: data.address_2,
			zip: data.zip,
			city: data.city,
			state: data.state,
			username: data.username,
			password: data.password,
			card_connect_merchant_id: data.card_connect_merchant_id,
			// api_key: data.api_key,
			bolt_url: data.bolt_url,
			rest_url: data.rest_url,
			tokenizer_url: data.tokenizer_url,
			cau_enabled: data.cau_enabled ? 1 : 0,
			param_use_view_v3: data.param_use_view_v3 ? 1 : 0,
			require_abp: data.require_abp ? 1 : 0,
			require_abp_no_lock: data.require_abp_no_lock ? 1 : 0,
			require_device_lock: data.require_device_lock ? 1 : 0,
			require_id_verification: data.require_id_verification ? 1 : 0,
			// require_facebook_verification: data.require_facebook_verification ? 1 : 0,
			allow_cash_down_payment: data.allow_cash_down_payment ? 1 : 0,
			bank_name: data.bank_name,
			// bank_account_type_id: data.bank_account_type_id ?? 0,
			bank_account_number: data.bank_account_number,
			bank_routing_number: data.bank_routing_number,
			billable: data.billable,
			esign_contracts_enabled: data.esign_contracts_enabled,
			esign_contracts_required: data.esign_contracts_required,
			status_id: data.status_id,
			assigned_to: data.assigned_to,
		};

		//if bank_account_type_id is set, add it to storeData
		if (data.bank_account_type_id) {
			storeUpdateData.bank_account_type_id = data.bank_account_type_id;
		} else {
			//remove bank_account_type_id from storeData
			delete storeUpdateData.bank_account_type_id;
		}

		const response = await StoreService.update(storeUpdateData)
			.then((response) => {
				if (response.status === 200 && response.data.isOk === true) {
					// show success message
					toast.info(
						`Store ${storeUpdateData.name} updated successfully!`,
						{
							position: "top-center",
							autoClose: 1000,
						}
					);
					// setFormSubmitted(true);
					SessionService.resetUserHierarchy();
					onUpdateCallback(storeUpdateData);
					reset();
					setZipApiUpdated(false);
					renderCount.current = 0;
				} else {
					// console.log("Error!");
					// show error messages
					const errorMappings = {
						struct_company_id: "struct_comany_id",
						struct_stores_tag_id: "struct_stores_tag_id",
						name: "name",
						email: "email",
						phone: "phone",
						account_manager: "account_manager",
						// account_number: "account_number",
						default_sales_tax: "default_sales_tax",
						max_contract_value: "max_contract_value",
						min_finance_factor: "min_finance_factor",
						default_finance_factor: "default_finance_factor",
						max_finance_factor: "max_finance_factor",
						default_down_payment_min: "default_down_payment_min",
						default_down_payment: "default_down_payment",
						default_down_payment_max: "default_down_payment_max",
						max_refund_days: "max_refund_days",
						payment_types: "payment_types",
						store_type_id: "store_type_id",
						auto_contract_default_days:
							"auto_contract_default_days",
						simple_calculator: "simple_calculator",
						enable_abm_provisioning: "enable_abm_provisioning",
						new_contract_show_rental_factor:
							"new_contract_show_rental_factor",
						new_contract_show_total_value:
							"new_contract_show_total_value",
						address_1: "address_1",
						address_2: "address_2",
						zip: "zip",
						city: "city",
						state: "state",
						username: "username",
						password: "password",
						card_connect_merchant_id: "card_connect_merchant_id",
						// api_key: "api_key",
						bolt_url: "bolt_url",
						rest_url: "rest_url",
						tokenizer_url: "tokenizer_url",
						cau_enabled: "cau_enabled",
						param_use_view_v3: "param_use_view_v3",
						require_abp: "require_abp",
						require_abp_no_lock: "require_abp_no_lock",
						require_device_lock: "require_device_lock",
						require_id_verification: "require_id_verification",
						// require_facebook_verification:
						//     "require_facebook_verification",
						allow_cash_down_payment: "allow_cash_down_payment",
						bank_name: "bank_name",
						bank_account_type_id: "bank_account_type_id",
						bank_account_number: "bank_account_number",
						bank_routing_number: "bank_routing_number",
						billable: "billable",
						esign_contracts_enabled: "esign_contracts_enabled",
						esign_contracts_required: "esign_contracts_required",
						status_id: "status_id",
					};

					/*Object.entries(response.data.message).forEach(
						([field, message]) => {
							const errorType = errorMappings[field];
							if (errorType) {
								setError(errorType, {
									type: "server",
									message,
								});
							}
						}
					);*/
					if (response.data.message instanceof Array) {
						// console.log("AAAAA", response.data.message);
						Object.entries(response.data.message).forEach(
							([field, message]) => {
								const errorType = errorMappings[field];

								if (errorType) {
									setError(errorType, {
										type: "server",
										message,
									});
								}
							}
						);
					} else {
						// console.log("XXXX", response.data.message);
						setError("api", {
							type: "server",
							message: response.data.message,
						});
					}
				}
			})
			.catch((error) => {
				// console.log(error);
				setError("api", {
					type: "server",
					message: error.response.data.error,
				});
			});

		// Set loading state
		setLoading(false);
	};

	return (
		<>
			{formSubmitted ? (
				<p className="paragraph-2">
					<strong>SUCCESS! YOUR MESSAGE HAS BEEN RECEIVED.</strong>
					<br />
					WE WILL GET IN CONTACT WITH YOU SHORTLY.
				</p>
			) : (
				<form
					id="store_form"
					name="store-form"
					onSubmit={handleSubmit(onSubmit)}
					// onSubmit={handleSubmit(
					//     console.log("Validation errors:", errors)
					// )}
					className="form-vertical"
				>
					{!isEditMode ? (
						<Row style={{ marginBottom: "0" }}>
							<Col lg="12">
								<FormGroup className="form-group">
									<FormLabel htmlFor="struct_company_id">
										Company{" "}
										<div className="has-star">*</div>
									</FormLabel>
									<FormSelect
										name="struct_company_id"
										id="struct_company_id"
										{...register("struct_company_id")}
										isInvalid={
											(touchedFields.struct_company_id &&
												!!errors[
													"struct_company_id"
												]) ||
											(!storeData &&
												isSubmitted &&
												!!errors["struct_company_id"])
										}
										isValid={
											touchedFields.struct_company_id &&
											!errors["struct_company_id"]
										}
									>
										<option value="" disabled>
											Select a Company...
										</option>
										{Object.entries(JSON.parse(companies))
											.sort((a, b) =>
												a[1].localeCompare(b[1])
											)
											.map(
												([
													company_id,
													company_name,
												]) => (
													<option
														value={company_id}
														key={company_id}
													>
														{company_name}
													</option>
												)
											)}
									</FormSelect>
									<Feedback type="invalid">
										{errors["struct_company_id"] &&
											errors["struct_company_id"].message}
									</Feedback>
								</FormGroup>
							</Col>
						</Row>
					) : (
						<></>
					)}

					{!isEditMode ? (
						<Row style={{ marginBottom: "0" }}>
							<Col lg="12">
								<FormGroup className="form-group">
									<FormLabel htmlFor="struct_stores_tag_id">
										Store Tag
									</FormLabel>
									<FormSelect
										name="struct_stores_tag_id"
										id="struct_stores_tag_id"
										{...register("struct_stores_tag_id")}
										isInvalid={
											(touchedFields.struct_stores_tag_id &&
												!!errors[
													"struct_stores_tag_id"
												]) ||
											(!storeData &&
												isSubmitted &&
												!!errors[
													"struct_stores_tag_id"
												])
										}
										isValid={
											touchedFields.struct_stores_tag_id &&
											!errors["struct_stores_tag_id"]
										}
									>
										<option value="" disabled>
											Select a Store Tag...
										</option>
										{storeTags.length > 0 &&
											storeTags.map((tag) => (
												<option
													value={tag.id}
													key={tag.id}
													selected={
														storeData &&
														tag.id.toString() ===
															storeData.struct_stores_tag_id
													}
												>
													{tag.name}
												</option>
											))}
									</FormSelect>
									<Feedback type="invalid">
										{errors["struct_stores_tag_id"] &&
											errors["struct_stores_tag_id"]
												.message}
									</Feedback>
								</FormGroup>
							</Col>
						</Row>
					) : (
						<></>
					)}

					<Row style={{ marginBottom: "0" }}>
						<Col lg="6">
							<FormGroup className="form-group">
								<FormLabel htmlFor="name">
									Name <div className="has-star">*</div>
								</FormLabel>
								<FormControl
									type="text"
									maxLength="100"
									name="name"
									id="name"
									{...register("name")}
									isInvalid={
										(touchedFields.name &&
											!!errors["name"]) ||
										(!storeData &&
											isSubmitted &&
											!!errors["name"])
									}
									isValid={
										touchedFields.name && !errors["name"]
									}
								/>
								<Feedback type="invalid">
									{errors["name"] && errors["name"].message}
								</Feedback>
							</FormGroup>

							<FormGroup className="form-group">
								<FormLabel htmlFor="email">
									Email address{" "}
									<div className="has-star">*</div>
								</FormLabel>
								<FormControl
									type="email"
									maxLength="100"
									name="email"
									id="email"
									{...register("email")}
									isInvalid={
										(touchedFields.email &&
											!!errors["email"]) ||
										(!storeData &&
											isSubmitted &&
											!!errors["email"])
									}
									isValid={
										(touchedFields.email &&
											!errors["email"]) ||
										(!storeData &&
											isSubmitted &&
											!errors["email"])
									}
								/>
								<Feedback type="invalid">
									{errors["email"] && errors["email"].message}
								</Feedback>
							</FormGroup>

							<FormGroup className="form-group">
								<FormLabel htmlFor="phone">
									Phone number{" "}
									<div className="has-star">*</div>
								</FormLabel>
								<PhoneInput
									id="phone"
									name="phone"
									defaultCountry="US"
									// countries={["US", "CA", "PR", "MX"]}
									countries={["US"]}
									addInternationalOption={false}
									control={control}
									inputComponent={PhoneInputBs5}
									isInvalid={
										(touchedFields.phone &&
											!!errors["phone"]) ||
										(!storeData &&
											isSubmitted &&
											!!errors["phone"])
									}
									isValid={
										(touchedFields.phone &&
											!errors["phone"]) ||
										(!storeData &&
											isSubmitted &&
											!errors["phone"])
									}
								/>
								{errors.phone && (
									<div className="is-invalid invalid-feedback">
										{errors.phone.message}
									</div>
								)}
							</FormGroup>

							<FormGroup className="form-group">
								<FormLabel htmlFor="account_manager">
									Account Manager
								</FormLabel>
								<FormControl
									type="text"
									maxLength="100"
									name="account_manager"
									id="account_manager"
									{...register("account_manager")}
									isInvalid={
										(touchedFields.account_manager &&
											!!errors["account_manager"]) ||
										(!storeData &&
											isSubmitted &&
											!!errors["account_manager"])
									}
									isValid={
										touchedFields.account_manager &&
										!errors["account_manager"]
									}
								/>
								<Feedback type="invalid">
									{errors["account_manager"] &&
										errors["account_manager"].message}
								</Feedback>
							</FormGroup>

							{/* <FormGroup className="form-group">
								<FormLabel htmlFor="account_number">
									Account Number{" "}
									<div className="has-star">*</div>
								</FormLabel>
								<FormControl
									type="text"
									maxLength="20"
									name="account_number"
									id="account_number"
									{...register("account_number")}
									isInvalid={
										(touchedFields.account_number &&
											!!errors["account_number"]) ||
										(!storeData &&
											isSubmitted &&
											!!errors["account_number"])
									}
									isValid={
										touchedFields.account_number &&
										!errors["account_number"]
									}
								/>
								<Feedback type="invalid">
									{errors["account_number"] &&
										errors["account_number"].message}
								</Feedback>
							</FormGroup> */}

							{isEditMode ? (
								<>
									<FormGroup className="form-group">
										<FormLabel htmlFor="struct_company_id">
											Company{" "}
											<div className="has-star">*</div>
										</FormLabel>
										<FormSelect
											name="struct_company_id"
											id="struct_company_id"
											{...register("struct_company_id")}
											isInvalid={
												(touchedFields.struct_company_id &&
													!!errors[
														"struct_company_id"
													]) ||
												(!storeData &&
													isSubmitted &&
													!!errors[
														"struct_company_id"
													])
											}
											isValid={
												touchedFields.struct_company_id &&
												!errors["struct_company_id"]
											}
										>
											<option value="" disabled>
												Select a Company...
											</option>
											{Object.entries(
												JSON.parse(companies)
											)
												.sort((a, b) =>
													a[1].localeCompare(b[1])
												)
												.map(
													([
														company_id,
														company_name,
													]) => (
														<option
															value={company_id}
															key={company_id}
															selected={
																storeData &&
																company_id.toString() ===
																	storeData.company_id
															}
														>
															{company_name}
														</option>
													)
												)}
										</FormSelect>
										<Feedback type="invalid">
											{errors["struct_company_id"] &&
												errors["struct_company_id"]
													.message}
										</Feedback>
									</FormGroup>
								</>
							) : (
								<></>
							)}

							{isEditMode ? ( //store tag
								<>
									<FormGroup className="form-group">
										<FormLabel htmlFor="struct_stores_tag_id">
											Store Tag
										</FormLabel>
										<FormSelect
											name="struct_stores_tag_id"
											id="struct_stores_tag_id"
											{...register(
												"struct_stores_tag_id"
											)}
											isInvalid={
												(touchedFields.struct_stores_tag_id &&
													!!errors[
														"struct_stores_tag_id"
													]) ||
												(!storeData &&
													isSubmitted &&
													!!errors[
														"struct_stores_tag_id"
													])
											}
											isValid={
												touchedFields.struct_stores_tag_id &&
												!errors["struct_stores_tag_id"]
											}
										>
											<option value="" disabled>
												Select a Store Tag...
											</option>
											{storeTags.length > 0 &&
												storeTags.map((tag) => (
													<option
														value={tag.id}
														key={tag.id}
														selected={
															storeData &&
															tag.id.toString() ===
																storeData.struct_stores_tag_id
														}
													>
														{tag.name}
													</option>
												))}
										</FormSelect>
										<Feedback type="invalid">
											{errors["struct_stores_tag_id"] &&
												errors["struct_stores_tag_id"]
													.message}
										</Feedback>
									</FormGroup>
								</>
							) : (
								<></>
							)}

							<FormGroup className="form-group">
								<FormLabel htmlFor="default_sales_tax">
									Sales Tax <div className="has-star">*</div>
								</FormLabel>
								<Controller
									name="default_sales_tax"
									control={control}
									defaultValue=""
									render={({ field }) => (
										<CurrencyInput
											id="default_sales_tax"
											// prefix="$"
											suffix="%"
											decimalsLimit={2}
											value={field.value}
											onValueChange={(value) =>
												handleDoubleValueChange(
													0, //min
													100, //max
													value,
													field.onChange
												)
											}
											onBlur={() => {
												handleDoubleBlur(
													0, //min
													100, //max
													2, //decimalsLimit
													field.value,
													field.onChange
												);
												trigger("default_sales_tax");
											}}
											// className={`form-control ${
											//     errors.default_sales_tax
											//         ? "is-invalid"
											//         : ""
											// }`}
											className={`form-control ${
												errors.default_sales_tax
													? "is-invalid"
													: ""
											} ${
												touchedFields.default_sales_tax &&
												!errors.default_sales_tax
													? "is-valid"
													: ""
											}`}
										/>
									)}
								/>
								<Feedback type="invalid">
									{errors["default_sales_tax"] &&
										errors["default_sales_tax"].message}
								</Feedback>
							</FormGroup>

							{isEditMode ? (
								<>
									<FormGroup className="form-group">
										<FormLabel htmlFor="max_contract_value">
											Maximum MSRP{" "}
											<div className="has-star">*</div>
										</FormLabel>
										<Controller
											name="max_contract_value"
											control={control}
											defaultValue=""
											render={({ field }) => (
												<CurrencyInput
													id="max_contract_value"
													prefix="$"
													// suffix="%"
													decimalsLimit={2}
													value={field.value}
													onValueChange={(value) =>
														handleDoubleValueChange(
															1, //min
															999999.99, //max
															value,
															field.onChange
														)
													}
													onBlur={() => {
														handleDoubleBlur(
															1, //min
															999999.99, //max
															2, //decimalsLimit
															field.value,
															field.onChange
														);
														trigger(
															"max_contract_value"
														);
													}}
													className={`form-control ${
														errors.max_contract_value
															? "is-invalid"
															: ""
													}`}
												/>
											)}
										/>
										<Feedback type="invalid">
											{errors["max_contract_value"] &&
												errors["max_contract_value"]
													.message}
										</Feedback>
									</FormGroup>
								</>
							) : (
								<></>
							)}

							<FormGroup className="form-group">
								<FormLabel htmlFor="min_finance_factor">
									Minimum Finance Factor{" "}
									<div className="has-star">*</div>
								</FormLabel>
								<Controller
									name="min_finance_factor"
									control={control}
									defaultValue=""
									render={({ field }) => (
										<CurrencyInput
											id="min_finance_factor"
											// prefix="$"
											// suffix="%"
											decimalsLimit={2}
											value={field.value}
											onValueChange={(value) =>
												handleDoubleValueChange(
													1, //min
													5, //max
													value,
													field.onChange
												)
											}
											onBlur={() => {
												handleDoubleBlur(
													1, //min
													5, //max
													2, //decimalsLimit
													field.value,
													field.onChange
												);
												trigger("min_finance_factor");
											}}
											className={`form-control ${
												errors.min_finance_factor
													? "is-invalid"
													: ""
											}`}
										/>
									)}
								/>
								<Feedback type="invalid">
									{errors["min_finance_factor"] &&
										errors["min_finance_factor"].message}
								</Feedback>
							</FormGroup>

							<FormGroup className="form-group">
								<FormLabel htmlFor="default_finance_factor">
									Default Finance Factor{" "}
									<div className="has-star">*</div>
								</FormLabel>
								<Controller
									name="default_finance_factor"
									control={control}
									defaultValue=""
									render={({ field }) => (
										<CurrencyInput
											id="default_finance_factor"
											// prefix="$"
											// suffix="%"
											decimalsLimit={2}
											value={field.value}
											onValueChange={(value) =>
												handleDoubleValueChange(
													1, //min
													5, //max
													value,
													field.onChange
												)
											}
											onBlur={() => {
												handleDoubleBlur(
													1, //min
													5, //max
													2, //decimalsLimit
													field.value,
													field.onChange
												);
												trigger(
													"default_finance_factor"
												);
											}}
											className={`form-control ${
												errors.default_finance_factor
													? "is-invalid"
													: ""
											}`}
										/>
									)}
								/>
								<Feedback type="invalid">
									{errors["default_finance_factor"] &&
										errors["default_finance_factor"]
											.message}
								</Feedback>
							</FormGroup>

							<FormGroup className="form-group">
								<FormLabel htmlFor="max_finance_factor">
									Maximum Finance Factor{" "}
									<div className="has-star">*</div>
								</FormLabel>
								<Controller
									name="max_finance_factor"
									control={control}
									defaultValue=""
									render={({ field }) => (
										<CurrencyInput
											id="max_finance_factor"
											// prefix="$"
											// suffix="%"
											decimalsLimit={2}
											value={field.value}
											onValueChange={(value) =>
												handleDoubleValueChange(
													1, //min
													5, //max
													value,
													field.onChange
												)
											}
											onBlur={() => {
												handleDoubleBlur(
													1, //min
													5, //max
													2, //decimalsLimit
													field.value,
													field.onChange
												);
												trigger("max_finance_factor");
											}}
											className={`form-control ${
												errors.max_finance_factor
													? "is-invalid"
													: ""
											}`}
										/>
									)}
								/>
								<Feedback type="invalid">
									{errors["max_finance_factor"] &&
										errors["max_finance_factor"].message}
								</Feedback>
							</FormGroup>

							<FormGroup className="form-group">
								<FormLabel htmlFor="default_down_payment_min">
									Minimum Down Payment{" "}
									<div className="has-star">*</div>
								</FormLabel>
								<Controller
									name="default_down_payment_min"
									control={control}
									defaultValue=""
									render={({ field }) => (
										<CurrencyInput
											id="default_down_payment_min"
											// prefix="$"
											suffix="%"
											decimalsLimit={2}
											value={field.value}
											onValueChange={(value) =>
												handleDoubleValueChange(
													0, //min
													100, //max
													value,
													field.onChange
												)
											}
											onBlur={() => {
												handleDoubleBlur(
													0, //min
													100, //max
													2, //decimalsLimit
													field.value,
													field.onChange
												);
												trigger(
													"default_down_payment_min"
												);
											}}
											className={`form-control ${
												errors.default_down_payment_min
													? "is-invalid"
													: ""
											}`}
										/>
									)}
								/>
								<Feedback type="invalid">
									{errors["default_down_payment_min"] &&
										errors["default_down_payment_min"]
											.message}
								</Feedback>
							</FormGroup>

							<FormGroup className="form-group">
								<FormLabel htmlFor="default_down_payment">
									Default Down Payment{" "}
									<div className="has-star">*</div>
								</FormLabel>
								<Controller
									name="default_down_payment"
									control={control}
									defaultValue=""
									render={({ field }) => (
										<CurrencyInput
											id="default_down_payment"
											// prefix="$"
											suffix="%"
											decimalsLimit={2}
											value={field.value}
											onValueChange={(value) =>
												handleDoubleValueChange(
													0, //min
													100, //max
													value,
													field.onChange
												)
											}
											onBlur={() => {
												handleDoubleBlur(
													0, //min
													100, //max
													2, //decimalsLimit
													field.value,
													field.onChange
												);
												trigger("default_down_payment");
											}}
											className={`form-control ${
												errors.default_down_payment
													? "is-invalid"
													: ""
											}`}
										/>
									)}
								/>
								<Feedback type="invalid">
									{errors["default_down_payment"] &&
										errors["default_down_payment"].message}
								</Feedback>
							</FormGroup>

							<FormGroup className="form-group">
								<FormLabel htmlFor="default_down_payment_max">
									Maximum Down Payment{" "}
									<div className="has-star">*</div>
								</FormLabel>
								<Controller
									name="default_down_payment_max"
									control={control}
									defaultValue=""
									render={({ field }) => (
										<CurrencyInput
											id="default_down_payment_max"
											// prefix="$"
											suffix="%"
											decimalsLimit={2}
											value={field.value}
											onValueChange={(value) =>
												handleDoubleValueChange(
													0, //min
													100, //max
													value,
													field.onChange
												)
											}
											onBlur={() => {
												handleDoubleBlur(
													0, //min
													100, //max
													2, //decimalsLimit
													field.value,
													field.onChange
												);
												trigger(
													"default_down_payment_max"
												);
											}}
											className={`form-control ${
												errors.default_down_payment_max
													? "is-invalid"
													: ""
											}`}
										/>
									)}
								/>
								<Feedback type="invalid">
									{errors["default_down_payment_max"] &&
										errors["default_down_payment_max"]
											.message}
								</Feedback>
							</FormGroup>

							{isEditMode ? (
								<FormGroup className="form-group">
									<FormLabel htmlFor="max_refund_days">
										Max Refund Days{" "}
										<div className="has-star">*</div>
									</FormLabel>
									<Controller
										name="max_refund_days"
										control={control}
										defaultValue=""
										render={({ field }) => (
											<CurrencyInput
												id="max_refund_days"
												// prefix="$"
												// suffix="%"
												decimalsLimit={0}
												value={field.value}
												onValueChange={(value) =>
													handleDoubleValueChange(
														0, //min
														365, //max
														value,
														field.onChange
													)
												}
												onBlur={() => {
													handleDoubleBlur(
														0, //min
														365, //max
														0, //decimalsLimit
														field.value,
														field.onChange
													);
													trigger("max_refund_days");
												}}
												className={`form-control ${
													errors.max_refund_days
														? "is-invalid"
														: ""
												}`}
											/>
										)}
									/>
									<Feedback type="invalid">
										{errors["max_refund_days"] &&
											errors["max_refund_days"].message}
									</Feedback>
								</FormGroup>
							) : (
								<></>
							)}

							<FormGroup className="form-group">
								<FormLabel htmlFor="payment_types">
									Payment Types{" "}
									<div className="has-star">*</div>
								</FormLabel>

								<Controller
									name="payment_types"
									control={control}
									defaultValue={selectedOptions}
									rules={{ required: true }} // Add your validation rules here
									render={({ field }) => (
										<Select
											{...field}
											options={storePaymentTypes.map(
												(item) => ({
													value: item.id,
													label: item.name,
												})
											)}
											isDisabled
											isMulti
											onChange={(option) =>
												field.onChange(option)
											}
										/>
									)}
								/>
								<Feedback type="invalid">
									{errors["payment_types"] &&
										errors["payment_types"].message}
								</Feedback>
							</FormGroup>

							<FormGroup className="form-group">
								<FormLabel htmlFor="store_type_id">
									Store Type <div className="has-star">*</div>
								</FormLabel>
								<FormSelect
									name="store_type_id"
									id="store_type_id"
									{...register("store_type_id")}
									isInvalid={
										(touchedFields.store_type_id &&
											!!errors["store_type_id"]) ||
										(!storeData &&
											isSubmitted &&
											!!errors["store_type_id"])
									}
									isValid={
										touchedFields.store_type_id &&
										!errors["store_type_id"]
									}
								>
									<option value="" disabled>
										Select a Store Type...
									</option>
									{storeTypes.length > 0 &&
										storeTypes.map((type) => (
											<option
												value={type.id}
												key={type.id}
												// selected={
												//     type.id.toString() ===
												//     storeData.store_type_id
												// }
											>
												{type.name}
											</option>
										))}
								</FormSelect>
								<Feedback type="invalid">
									{errors["store_type_id"] &&
										errors["store_type_id"].message}
								</Feedback>
							</FormGroup>

							<FormGroup className="form-group">
								<FormLabel htmlFor="auto_contract_default_days">
									Default Contract After x Days Past Due{" "}
									<div className="has-star">*</div>
								</FormLabel>
								<FormControl
									type="text"
									maxLength="3"
									name="auto_contract_default_days"
									id="auto_contract_default_days"
									{...register("auto_contract_default_days")}
									isInvalid={
										(touchedFields.auto_contract_default_days &&
											!!errors[
												"auto_contract_default_days"
											]) ||
										(!storeData &&
											isSubmitted &&
											!!errors[
												"auto_contract_default_days"
											])
									}
									isValid={
										touchedFields.auto_contract_default_days &&
										!errors["auto_contract_default_days"]
									}
								/>
								<Feedback type="invalid">
									{errors["auto_contract_default_days"] &&
										errors["auto_contract_default_days"]
											.message}
								</Feedback>
							</FormGroup>

							<FormGroup className="form-group">
								<FormLabel htmlFor="simple_calculator">
									Enable Simple Calculator{" "}
									<div className="has-star">*</div>
								</FormLabel>
								<FormSelect
									name="simple_calculator"
									id="simple_calculator"
									{...register("simple_calculator")}
									isInvalid={
										(touchedFields.simple_calculator &&
											!!errors["simple_calculator"]) ||
										(!storeData &&
											isSubmitted &&
											!!errors["simple_calculator"])
									}
									isValid={
										touchedFields.simple_calculator &&
										!errors["simple_calculator"]
									}
								>
									<option value="" disabled>
										Select...
									</option>
									{
										// TODO: get list from API
										//enabled = 1
										//disabled = 0
									}
									<option value="1">Yes</option>
									<option value="0">No</option>
								</FormSelect>
								<Feedback type="invalid">
									{errors["simple_calculator"] &&
										errors["simple_calculator"].message}
								</Feedback>
							</FormGroup>

							<FormGroup className="form-group">
								<FormLabel htmlFor="enable_abm_provisioning">
									Enable Pre Provisioning{" "}
									<div className="has-star">*</div>
								</FormLabel>
								<FormSelect
									name="enable_abm_provisioning"
									id="enable_abm_provisioning"
									{...register("enable_abm_provisioning")}
									isInvalid={
										(touchedFields.enable_abm_provisioning &&
											!!errors[
												"enable_abm_provisioning"
											]) ||
										(!storeData &&
											isSubmitted &&
											!!errors["enable_abm_provisioning"])
									}
									isValid={
										touchedFields.enable_abm_provisioning &&
										!errors["enable_abm_provisioning"]
									}
								>
									<option value="" disabled>
										Select...
									</option>
									{
										// TODO: get list from API
										//enabled = 1
										//disabled = 0
									}
									<option value="1">Yes</option>
									<option value="0">No</option>
								</FormSelect>
								<Feedback type="invalid">
									{errors["enable_abm_provisioning"] &&
										errors["enable_abm_provisioning"]
											.message}
								</Feedback>
							</FormGroup>

							{isEditMode ? (
								<FormGroup className="form-group">
									<FormLabel htmlFor="new_contract_show_rental_factor">
										New Contract - Show Rental Factor{" "}
										<div className="has-star">*</div>
									</FormLabel>
									<FormSelect
										name="new_contract_show_rental_factor"
										id="new_contract_show_rental_factor"
										{...register(
											"new_contract_show_rental_factor"
										)}
										isInvalid={
											(touchedFields.new_contract_show_rental_factor &&
												!!errors[
													"new_contract_show_rental_factor"
												]) ||
											(!storeData &&
												isSubmitted &&
												!!errors[
													"new_contract_show_rental_factor"
												])
										}
										isValid={
											touchedFields.new_contract_show_rental_factor &&
											!errors[
												"new_contract_show_rental_factor"
											]
										}
									>
										<option value="" disabled>
											Select...
										</option>
										{
											// TODO: get list from API
											//enabled = 1
											//disabled = 0
										}
										<option value="1">Yes</option>
										<option value="0">No</option>
									</FormSelect>
									<Feedback type="invalid">
										{errors[
											"new_contract_show_rental_factor"
										] &&
											errors[
												"new_contract_show_rental_factor"
											].message}
									</Feedback>
								</FormGroup>
							) : (
								<></>
							)}

							{isEditMode ? (
								<FormGroup className="form-group">
									<FormLabel htmlFor="new_contract_show_total_value">
										New Contract - Show Total Contract Value{" "}
										<div className="has-star">*</div>
									</FormLabel>
									<FormSelect
										name="new_contract_show_total_value"
										id="new_contract_show_total_value"
										{...register(
											"new_contract_show_total_value"
										)}
										isInvalid={
											(touchedFields.new_contract_show_total_value &&
												!!errors[
													"new_contract_show_total_value"
												]) ||
											(!storeData &&
												isSubmitted &&
												!!errors[
													"new_contract_show_total_value"
												])
										}
										isValid={
											touchedFields.new_contract_show_total_value &&
											!errors[
												"new_contract_show_total_value"
											]
										}
									>
										<option value="" disabled>
											Select...
										</option>
										{
											// TODO: get list from API
											//enabled = 1
											//disabled = 0
										}
										<option value="1">Yes</option>
										<option value="0">No</option>
									</FormSelect>
									<Feedback type="invalid">
										{errors[
											"new_contract_show_total_value"
										] &&
											errors[
												"new_contract_show_total_value"
											].message}
									</Feedback>
								</FormGroup>
							) : (
								<></>
							)}
						</Col>

						<Col lg="6">
							<FormGroup className="form-group">
								<FormLabel htmlFor="address">
									Address 1 <div className="has-star">*</div>
								</FormLabel>
								<FormControl
									type="text"
									maxLength="100"
									name="address_1"
									id="address_1"
									{...register("address_1")}
									isInvalid={
										(touchedFields.address_1 &&
											!!errors["address_1"]) ||
										(!storeData &&
											isSubmitted &&
											!!errors["address_1"])
									}
									isValid={
										(touchedFields.address_1 &&
											!errors["address_1"]) ||
										(!storeData &&
											isSubmitted &&
											!errors["address_1"])
									}
								/>
								<Feedback type="invalid">
									{errors["address_1"] &&
										errors["address_1"].message}
								</Feedback>
							</FormGroup>

							<FormGroup className="form-group">
								<FormLabel htmlFor="address_2">
									Address 2
								</FormLabel>
								<FormControl
									type="text"
									maxLength="100"
									name="address_2"
									id="address_2"
									{...register("address_2")}
									isInvalid={
										(touchedFields.address_2 &&
											!!errors["address_2"]) ||
										(!storeData &&
											isSubmitted &&
											!!errors["address_2"])
									}
									isValid={
										(touchedFields.address_2 &&
											!errors["address_2"]) ||
										(!storeData &&
											isSubmitted &&
											!errors["address_2"])
									}
								/>
								<Feedback type="invalid">
									{errors["address_2"] &&
										errors["address_2"].message}
								</Feedback>
							</FormGroup>

							<FormGroup className="form-group">
								<FormLabel htmlFor="zip">
									Zip code <div className="has-star">*</div>
								</FormLabel>
								<FormControl
									type="text"
									maxLength="5"
									name="zip"
									id="zip"
									inputMode="numeric"
									onInput={(e) => {
										e.target.value = e.target.value.replace(
											/[^0-9]/g,
											""
										);
									}}
									{...register("zip")}
									isInvalid={
										(touchedFields.zip &&
											!!errors["zip"]) ||
										(!storeData &&
											isSubmitted &&
											!!errors["zip"])
									}
									isValid={
										(touchedFields.zip && !errors["zip"]) ||
										(!storeData &&
											isSubmitted &&
											!errors["zip"])
									}
								/>
								<Feedback type="invalid">
									{errors["zip"] && errors["zip"].message}
								</Feedback>
							</FormGroup>

							<FormGroup className="form-group">
								<FormLabel htmlFor="city">
									City <div className="has-star">*</div>
								</FormLabel>
								<FormControl
									type="text"
									maxLength="255"
									name="city"
									id="city"
									readOnly
									{...register("city")}
									isInvalid={
										(touchedFields.city &&
											!!errors["city"]) ||
										(!storeData &&
											isSubmitted &&
											!!errors["city"]) ||
										(isZipApiUpdated && !!errors["city"])
									}
									isValid={
										(touchedFields.city &&
											!errors["city"]) ||
										(!storeData &&
											isSubmitted &&
											!errors["city"]) ||
										(isZipApiUpdated && !errors["city"])
									}
								/>
								<Feedback type="invalid">
									{errors["city"] && errors["city"].message}
								</Feedback>
							</FormGroup>

							<FormGroup className="form-group">
								<FormLabel htmlFor="state">
									State <div className="has-star">*</div>
								</FormLabel>
								<FormControl
									type="text"
									maxLength="255"
									name="state"
									id="state"
									readOnly
									{...register("state")}
									isInvalid={
										(touchedFields.state &&
											!!errors["state"]) ||
										(!storeData &&
											isSubmitted &&
											!!errors["state"]) ||
										(isZipApiUpdated && !!errors["state"])
									}
									isValid={
										(touchedFields.state &&
											!errors["state"]) ||
										(!storeData &&
											isSubmitted &&
											!errors["state"]) ||
										(isZipApiUpdated && !errors["state"])
									}
								/>
								<Feedback type="invalid">
									{errors["state"] && errors["state"].message}
								</Feedback>
							</FormGroup>

							<FormGroup className="form-group">
								<FormLabel htmlFor="username">
									CardConnect Username
								</FormLabel>
								<FormControl
									type="text"
									maxLength="100"
									name="username"
									id="username"
									{...register("username")}
									isInvalid={
										(touchedFields.username &&
											!!errors["username"]) ||
										(!storeData &&
											isSubmitted &&
											!!errors["username"])
									}
									isValid={
										touchedFields.username &&
										!errors["username"]
									}
								/>
								<Feedback type="invalid">
									{errors["username"] &&
										errors["username"].message}
								</Feedback>
							</FormGroup>

							<FormGroup className="form-group">
								<FormLabel htmlFor="password">
									CardConnect Password
								</FormLabel>
								<FormControl
									type="text"
									maxLength="100"
									name="password"
									id="password"
									{...register("password")}
									isInvalid={
										(touchedFields.password &&
											!!errors["password"]) ||
										(!storeData &&
											isSubmitted &&
											!!errors["password"])
									}
									isValid={
										touchedFields.password &&
										!errors["password"]
									}
								/>
								<Feedback type="invalid">
									{errors["password"] &&
										errors["password"].message}
								</Feedback>
							</FormGroup>

							<FormGroup className="form-group">
								<FormLabel htmlFor="card_connect_merchant_id">
									CardConnect Merch ID
								</FormLabel>
								<FormControl
									type="text"
									maxLength="12"
									name="card_connect_merchant_id"
									id="card_connect_merchant_id"
									{...register("card_connect_merchant_id")}
									isInvalid={
										(touchedFields.card_connect_merchant_id &&
											!!errors[
												"card_connect_merchant_id"
											]) ||
										(!storeData &&
											isSubmitted &&
											!!errors[
												"card_connect_merchant_id"
											])
									}
									isValid={
										touchedFields.card_connect_merchant_id &&
										!errors["card_connect_merchant_id"]
									}
								/>
								<Feedback type="invalid">
									{errors["card_connect_merchant_id"] &&
										errors["card_connect_merchant_id"]
											.message}
								</Feedback>
							</FormGroup>

							{/* <FormGroup className="form-group">
								<FormLabel htmlFor="api_key">
									CardConnect API Key{" "}
									<div className="has-star">*</div>
								</FormLabel>
								<FormControl
									type="text"
									maxLength="100"
									name="api_key"
									id="api_key"
									{...register("api_key")}
									isInvalid={
										(touchedFields.api_key &&
											!!errors["api_key"]) ||
										(!storeData &&
											isSubmitted &&
											!!errors["api_key"])
									}
									isValid={
										touchedFields.api_key &&
										!errors["api_key"]
									}
								/>
								<Feedback type="invalid">
									{errors["api_key"] &&
										errors["api_key"].message}
								</Feedback>
							</FormGroup> */}

							{isEditMode ? (
								<FormGroup className="form-group">
									<FormLabel htmlFor="bolt_url">
										CardConnect Bolt URL
									</FormLabel>
									<FormControl
										type="text"
										maxLength="255"
										name="bolt_url"
										id="bolt_url"
										{...register("bolt_url")}
										isInvalid={
											(touchedFields.bolt_url &&
												!!errors["bolt_url"]) ||
											(!storeData &&
												isSubmitted &&
												!!errors["bolt_url"])
										}
										isValid={
											touchedFields.bolt_url &&
											!errors["bolt_url"]
										}
									/>
									<Feedback type="invalid">
										{errors["bolt_url"] &&
											errors["bolt_url"].message}
									</Feedback>
								</FormGroup>
							) : (
								<></>
							)}

							{isEditMode ? (
								<FormGroup className="form-group">
									<FormLabel htmlFor="rest_url">
										CardConnect Rest URL
									</FormLabel>
									<FormControl
										type="text"
										maxLength="255"
										name="rest_url"
										id="rest_url"
										{...register("rest_url")}
										isInvalid={
											(touchedFields.rest_url &&
												!!errors["rest_url"]) ||
											(!storeData &&
												isSubmitted &&
												!!errors["rest_url"])
										}
										isValid={
											touchedFields.rest_url &&
											!errors["rest_url"]
										}
									/>
									<Feedback type="invalid">
										{errors["rest_url"] &&
											errors["rest_url"].message}
									</Feedback>
								</FormGroup>
							) : (
								<></>
							)}

							{isEditMode ? (
								<FormGroup className="form-group">
									<FormLabel htmlFor="tokenizer_url">
										CardConnect Tokenizer URL
									</FormLabel>
									<FormControl
										type="text"
										maxLength="255"
										name="tokenizer_url"
										id="tokenizer_url"
										{...register("tokenizer_url")}
										isInvalid={
											(touchedFields.tokenizer_url &&
												!!errors["tokenizer_url"]) ||
											(!storeData &&
												isSubmitted &&
												!!errors["tokenizer_url"])
										}
										isValid={
											touchedFields.tokenizer_url &&
											!errors["tokenizer_url"]
										}
									/>
									<Feedback type="invalid">
										{errors["tokenizer_url"] &&
											errors["tokenizer_url"].message}
									</Feedback>
								</FormGroup>
							) : (
								<></>
							)}

							{isEditMode ? (
								<FormGroup>
									<FormControlLabel
										control={
											<Switch
												name="param_use_view_v3"
												defaultChecked={
													storeData
														? storeData.param_use_view_v3 ===
														  0
															? false
															: true
														: true
												}
												{...register(
													"param_use_view_v3"
												)}
											/>
										}
										label="Enable V3 Contract View"
									/>
								</FormGroup>
							) : (
								<></>
							)}

							{isEditMode ? (
								<FormGroup>
									<FormControlLabel
										control={
											<Switch
												name="cau_enabled"
												defaultChecked={
													storeData
														? storeData.cau_enabled ===
														  0
															? false
															: true
														: true
												}
												{...register("cau_enabled")}
											/>
										}
										label="Enable Card Auto Updater"
									/>
								</FormGroup>
							) : (
								<></>
							)}

							<FormGroup>
								<FormControlLabel
									control={
										<Switch
											name="require_abp"
											defaultChecked={
												storeData
													? storeData.require_abp ===
													  0
														? false
														: true
													: true
											}
											{...register("require_abp")}
										/>
									}
									label="Require ABP on Lock Contracts"
								/>
							</FormGroup>

							<FormGroup>
								<FormControlLabel
									control={
										<Switch
											name="require_abp_no_lock"
											defaultChecked={
												storeData
													? storeData.require_abp_no_lock ===
													  0
														? false
														: true
													: true
											}
											{...register("require_abp_no_lock")}
										/>
									}
									label="Require ABP on No Lock Contracts"
								/>
							</FormGroup>

							<p className="text-sm text-danger text-muted-foreground mb-0">
								*ABP Requirement is void until store is fully
								boarded with CardConnect.
							</p>

							<FormGroup>
								<FormControlLabel
									control={
										<Switch
											name="require_device_lock"
											defaultChecked={
												storeData
													? storeData.require_device_lock ===
													  0
														? false
														: true
													: false
											}
											{...register("require_device_lock")}
										/>
									}
									label="Require Device Lock"
								/>
							</FormGroup>

							<FormGroup>
								<FormControlLabel
									control={
										<Switch
											name="require_id_verification"
											defaultChecked={
												storeData
													? storeData.require_id_verification ===
													  0
														? false
														: true
													: false
											}
											{...register(
												"require_id_verification"
											)}
										/>
									}
									label="Require ID Verification"
								/>
							</FormGroup>

							{/* <FormGroup>
								<FormControlLabel
									control={
										<Switch
											name="require_facebook_verification"
											defaultChecked={
												storeData
													? storeData.require_facebook_verification ===
													"0"
														? false
														: true
													: false
											}
											{...register(
												"require_facebook_verification"
											)}
										/>
									}
									label="Require Facebook Verification"
								/>
							</FormGroup> */}

							{isEditMode ? (
								<FormGroup>
									<FormControlLabel
										control={
											<Switch
												name="allow_cash_down_payment"
												defaultChecked={
													storeData
														? storeData.allow_cash_down_payment ===
														  0
															? false
															: true
														: false
												}
												{...register(
													"allow_cash_down_payment"
												)}
											/>
										}
										label="Allow Cash Down Payment"
									/>
								</FormGroup>
							) : (
								<></>
							)}

							<FormGroup className="form-group">
								<FormLabel htmlFor="bank_name">
									Bank Name
								</FormLabel>
								<FormControl
									type="text"
									maxLength="100"
									name="bank_name"
									id="bank_name"
									readOnly
									{...register("bank_name")}
									isInvalid={
										(touchedFields.bank_name &&
											!!errors["bank_name"]) ||
										(!storeData &&
											isSubmitted &&
											!!errors["bank_name"])
									}
									isValid={
										touchedFields.bank_name &&
										!errors["bank_name"]
									}
								/>
								<Feedback type="invalid">
									{errors["bank_name"] &&
										errors["bank_name"].message}
								</Feedback>
							</FormGroup>

							<FormGroup className="form-group">
								<FormLabel htmlFor="bank_account_type_id">
									Bank Account Type
								</FormLabel>
								<FormSelect
									name="bank_account_type_id"
									id="bank_account_type_id"
									{...register("bank_account_type_id")}
									isInvalid={
										(touchedFields.bank_account_type_id &&
											!!errors["bank_account_type_id"]) ||
										(!storeData &&
											isSubmitted &&
											!!errors["bank_account_type_id"])
									}
									isValid={
										touchedFields.bank_account_type_id &&
										!errors["bank_account_type_id"]
									}
								>
									<option value="" disabled>
										Select...
									</option>
									{
										// TODO: get list from API
										//enabled = 1
										//disabled = 0
									}
									<option value="1">CHECKING</option>
									<option value="2">SAVING</option>
								</FormSelect>
								<Feedback type="invalid">
									{errors["bank_account_type_id"] &&
										errors["bank_account_type_id"].message}
								</Feedback>
							</FormGroup>

							<FormGroup className="form-group">
								<FormLabel htmlFor="bank_account_number">
									Bank Account Number
								</FormLabel>
								<FormControl
									type="text"
									maxLength="16"
									name="bank_account_number"
									id="bank_account_number"
									{...register("bank_account_number")}
									isInvalid={
										(touchedFields.bank_account_number &&
											!!errors["bank_account_number"]) ||
										(!storeData &&
											isSubmitted &&
											!!errors["bank_account_number"])
									}
									isValid={
										touchedFields.bank_account_number &&
										!errors["bank_account_number"]
									}
								/>
								<Feedback type="invalid">
									{errors["bank_account_number"] &&
										errors["bank_account_number"].message}
								</Feedback>
							</FormGroup>

							<FormGroup className="form-group">
								<FormLabel htmlFor="bank_routing_number">
									Bank Routing Number
								</FormLabel>
								<FormControl
									type="text"
									maxLength="9"
									name="bank_routing_number"
									id="bank_routing_number"
									{...register("bank_routing_number")}
									isInvalid={
										(touchedFields.bank_routing_number &&
											!!errors["bank_routing_number"]) ||
										(!storeData &&
											isSubmitted &&
											!!errors["bank_routing_number"])
									}
									isValid={
										touchedFields.bank_routing_number &&
										!errors["bank_routing_number"]
									}
								/>
								<Feedback type="invalid">
									{errors["bank_routing_number"] &&
										errors["bank_routing_number"].message}
								</Feedback>
							</FormGroup>

							{isEditMode ? (
								<FormGroup className="form-group">
									<FormLabel htmlFor="billable">
										Billable
									</FormLabel>
									<FormSelect
										name="billable"
										id="billable"
										{...register("billable")}
										isInvalid={
											(touchedFields.billable &&
												!!errors["billable"]) ||
											(!storeData &&
												isSubmitted &&
												!!errors["billable"])
										}
										isValid={
											touchedFields.billable &&
											!errors["billable"]
										}
									>
										<option value="" disabled>
											Select...
										</option>
										{
											// TODO: get list from API
											//enabled = 1
											//disabled = 0
										}
										<option value="1">Yes</option>
										<option value="0">No</option>
									</FormSelect>
									<Feedback type="invalid">
										{errors["billable"] &&
											errors["billable"].message}
									</Feedback>
								</FormGroup>
							) : (
								<></>
							)}

							{isEditMode ? (
								<FormGroup className="form-group">
									<FormLabel htmlFor="esign_contracts_enabled">
										E-sign Contracts
									</FormLabel>
									<FormSelect
										name="esign_contracts_enabled"
										id="esign_contracts_enabled"
										{...register("esign_contracts_enabled")}
										isInvalid={
											(touchedFields.esign_contracts_enabled &&
												!!errors[
													"esign_contracts_enabled"
												]) ||
											(!storeData &&
												isSubmitted &&
												!!errors[
													"esign_contracts_enabled"
												])
										}
										isValid={
											touchedFields.esign_contracts_enabled &&
											!errors["esign_contracts_enabled"]
										}
									>
										<option value="" disabled>
											Select status...
										</option>
										{
											// TODO: get status list from API
											//enabled = 1
											//disabled = 0
										}
										<option value="0">Disabled</option>
										<option value="1">Enabled</option>
									</FormSelect>
									<Feedback type="invalid">
										{errors["esign_contracts_enabled"] &&
											errors["esign_contracts_enabled"]
												.message}
									</Feedback>
								</FormGroup>
							) : (
								<></>
							)}

							{isEditMode ? (
								<FormGroup className="form-group">
									<FormLabel htmlFor="esign_contracts_required">
										E-sign Contracts Required?
									</FormLabel>
									<FormSelect
										name="esign_contracts_required"
										id="esign_contracts_required"
										{...register(
											"esign_contracts_required"
										)}
										isInvalid={
											(touchedFields.esign_contracts_required &&
												!!errors[
													"esign_contracts_required"
												]) ||
											(!storeData &&
												isSubmitted &&
												!!errors[
													"esign_contracts_required"
												])
										}
										isValid={
											touchedFields.esign_contracts_required &&
											!errors["esign_contracts_required"]
										}
									>
										<option value="" disabled>
											Select...
										</option>
										{
											// TODO: get list from API
											//enabled = 1
											//disabled = 0
										}
										<option value="1">Yes</option>
										<option value="0">No</option>
									</FormSelect>
									<Feedback type="invalid">
										{errors["esign_contracts_required"] &&
											errors["esign_contracts_required"]
												.message}
									</Feedback>
								</FormGroup>
							) : (
								<></>
							)}

							{isEditMode ? (
								<>
									<FormGroup className="form-group">
										<FormLabel htmlFor="status_id">
											Status{" "}
											<div className="has-star">*</div>
										</FormLabel>
										<FormSelect
											name="status_id"
											id="status_id"
											{...register("status_id")}
											isInvalid={
												(touchedFields.status_id &&
													!!errors["status_id"]) ||
												(!storeData &&
													isSubmitted &&
													!!errors["status_id"])
											}
											isValid={
												touchedFields.status_id &&
												!errors["status_id"]
											}
											onChange={(e) => {
												setValue(
													"billable",
													e.target.value,
													{
														shouldDirty: true,
													}
												);
											}}
										>
											<option value="" disabled>
												Select status...
											</option>
											{
												// TODO: get status list from API
												//enabled = 1
												//disabled = 0
											}
											<option value="0">Disabled</option>
											<option value="1">Enabled</option>
										</FormSelect>
										<Feedback type="invalid">
											{errors["status_id"] &&
												errors["status_id"].message}
										</Feedback>
									</FormGroup>
								</>
							) : (
								<></>
							)}
						</Col>
					</Row>

					{errors.api && (
						<Row>
							<Col lg style={{ marginBottom: "2rem" }}>
								<p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium Mui-required">
									{errors.api.message}
								</p>
							</Col>
						</Row>
					)}
					<Row>
						<Col className="d-flex justify-content-end align-items-center">
							{isEditMode ? (
								// If in "edit" mode, show "Update" button
								<Button
									type="button"
									variant="primary"
									disabled={
										isSubmitting || !isValid || !isDirty
									}
									onClick={handleSubmit(onUpdate)}
									// onClick={handleSubmit(
									//     console.log(
									//         "Validation errors:",
									//         errors
									//     )
									// )}
								>
									{isSubmitting ? (
										<>
											<span
												className="spinner-border spinner-border-sm mr-1"
												role="status"
												aria-hidden="true"
											></span>
										</>
									) : (
										<></>
									)}
									Save
								</Button>
							) : (
								// If in "create" mode, show "Submit" button
								<Button
									type="submit"
									variant="success"
									disabled={isSubmitting || !isValid}
								>
									{isSubmitting ? (
										<>
											<span
												className="spinner-border spinner-border-sm mr-1"
												role="status"
												aria-hidden="true"
											></span>
										</>
									) : (
										<></>
									)}
									Submit
								</Button>
							)}
						</Col>
					</Row>
				</form>
			)}
			<DevTool control={control} />
		</>
	);
}

export default StoresForm;
