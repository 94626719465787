import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";

/**
 * @doc when we need to copy the content to another textbox
 * [
 *	'type' => 'email',
 *	'name' => 'email',
 *	'class' => 'col-md-12',
 *	'targetTextComponent' => 'username',  // Optional property to copy the content to another textbox
 * ],
 */
const CustomInputEmailComponent = ({
	name,
	label,
	type,
	value,
	onChange,
	onBlur,
	className,
	targetTextComponent,
	...otherProps
}) => {
	const [internalValue, setInternalValue] = useState(value);
	const [isValid, setIsValid] = useState(true);
	const [errorMessage, setErrorMessage] = useState("");
	const [isTouched, setIsTouched] = useState(false);

	useEffect(() => {
		setInternalValue(value);
		validateEmail(value);
	}, [value]);

	const handleChange = (event) => {
		const newValue = event.target.value;
		setInternalValue(newValue);
	};

	const handleBlur = () => {
		setIsTouched(true);
		validateEmail(internalValue);
	};

	const getFieldInfo = () => ({
		required: type?.required || false,
		readOnly: type?.readOnly || false,
		minLength: type?.minLength,
		maxLength: type?.maxLength,
		upperCase: type?.upperCase || false,
	});

	const validateEmail = (email) => {
		const fieldInfo = getFieldInfo();
		let isValidEmail = true;
		let message = "";
		const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

		if (fieldInfo.readOnly) {
			onChange(name, email, true);
			if (targetTextComponent) {
				onChange(targetTextComponent, email, true);
			}
			return;
		}

		if (fieldInfo.required && !emailRegex.test(email)) {
			isValidEmail = false;
			message = "Invalid email address.";
		}

		if (
			fieldInfo.required &&
			fieldInfo.minLength &&
			email.length < fieldInfo.minLength
		) {
			isValidEmail = false;
			message = `Minimum length is ${fieldInfo.minLength} characters.`;
		}

		if (
			fieldInfo.required &&
			fieldInfo.maxLength &&
			email.length > fieldInfo.maxLength
		) {
			isValidEmail = false;
			message = `Maximum length is ${fieldInfo.maxLength} characters.`;
		}

		setIsValid(isValidEmail);
		setErrorMessage(message);

		onChange(name, email, isValidEmail);
		if (targetTextComponent) {
			onChange(targetTextComponent, email, isValidEmail);
		}
	};

	return (
		<div className={`${className}`}>
			<TextField
				id={name}
				label={label}
				variant="standard"
				size="small"
				fullWidth
				type={type && type.type}
				value={internalValue}
				onChange={handleChange}
				onBlur={handleBlur}
				error={!isValid && isTouched}
				helperText={!isValid && isTouched ? errorMessage : ""}
				className={getFieldInfo().readOnly ? "readonly-text" : ""}
				inputProps={{
					readOnly: getFieldInfo().readOnly,
					style: {
						textTransform: getFieldInfo().upperCase
							? "uppercase"
							: "none",
					},
				}}
				{...otherProps}
			/>
		</div>
	);
};

export default CustomInputEmailComponent;
