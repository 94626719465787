import React, { useState, useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const CustomDateInputComponent = ({
	name,
	label,
	value,
	onChange,
	onBlur,
	className,
	required,
	maxDate,
	inputFormat,
	...otherProps
}) => {
	const formatValue = (dateValue) => {
		return dateValue ? dayjs(dateValue) : null;
	};

	const [internalValue, setInternalValue] = useState(formatValue(value));
	const [isValid, setIsValid] = useState(true); // Assume valid by default
	const [errorMessage, setErrorMessage] = useState("");
	const [isTouched, setIsTouched] = useState(false);

	useEffect(() => {
		setInternalValue(formatValue(value));
		// Validate the initial value
		validate(formatValue(value));
	}, [value]);

	const validate = (dateValue) => {
		let valid = true;
		let message = "";
		// Example validation: check if the date is required and present
		if (required && !dateValue) {
			valid = false;
			message = "This field is required.";
		}
		// Additional validations can be added here (e.g., checking against maxDate)
		setIsValid(valid);
		setErrorMessage(message);
		// Ensure to propagate the validation state
		if (onChange)
			onChange(
				name,
				dateValue ? dateValue.format("YYYY-MM-DD") : "",
				valid
			);
	};

	const handleOnChange = (newValue) => {
		setIsTouched(true);
		const formattedDate = newValue
			? dayjs(newValue).format("YYYY-MM-DD")
			: "";
		setInternalValue(newValue); // Store the Dayjs object as internal value
		validate(newValue); // Validate using the Dayjs object
	};

	const handleOnBlur = (e) => {
		setIsTouched(true);
		validate(internalValue);
		if (onBlur) onBlur(e);
	};

	let inputFormatValue = inputFormat || "MM/DD/YYYY";
	let viewsValue = ["year", "month", "day"];

	if (inputFormat === "YYYY") {
		inputFormatValue = "YYYY";
		viewsValue = ["year"];
	} else if (inputFormat === "MM/YYYY") {
		inputFormatValue = "MM/YYYY";
		viewsValue = ["month", "year"];
	}

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DatePicker
				className={className}
				sx={{ marginTop: "20px", width: "100% !important" }}
				label={label}
				value={internalValue}
				onChange={handleOnChange}
				onBlur={handleOnBlur}
				maxDate={maxDate ? dayjs(maxDate) : undefined}
				slotProps={{
					textField: {
						clearable: true,
						variant: "standard",
						error: !isValid && isTouched,
						helperText: !isValid && isTouched ? errorMessage : "",
					},
				}}
				views={viewsValue}
				inputFormat={inputFormatValue}
				{...otherProps}
			/>
		</LocalizationProvider>
	);
};

export default CustomDateInputComponent;
