import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html#Onboarding
 */

const OnboardingService = {
	/**
	 *
	 * @param {OnboardingView} OnboardingView
	 * @returns
	 */
	view: (OnboardingView) => {
		return ApiService.post("/onboarding/index", OnboardingView).catch(
			(error) => {
				console.log(error);
				return error;
			}
		);
	},

	/**
	 * Get list of bank account types
	 * @see /docs/api/index.html#jump-Onboarding-BankAccountTypes
	 * @returns JSON
	 */
	getBankAccountTypes: () => {
		return ApiService.get("/onboarding/bank-account-types").catch(
			(error) => {
				console.log(error);
			}
		);
	},

	/**
	 * User data filter by ID
	 * @param {int} id
	 * @returns
	 */
	fetchById: (id) => {
		return ApiService.get("/onboarding/update", { id: id }).catch(
			(error) => {
				console.log(error);
			}
		);
	},

	/**
	 * Delete by ID
	 * @param {int} id
	 * @see /docs/api/index.html#jump-Onboarding-delete
	 * @returns
	 */
	delete: (id) => {
		return ApiService.get("/onboarding/delete", { id: id }).catch(
			(error) => {
				console.log(error);
			}
		);
	},
};

export default OnboardingService;
