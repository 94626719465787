import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Breadcrumb } from "react-bootstrap";
import LeadService from "../../service/lead/LeadService";
import IndexTable from "../../components/common/IndexTable";

function Leads({ loading, setLoading }) {
	const pageTitle = "Leads | RTO Mobile";

	return (
		<>
			<Helmet>
				<title>{pageTitle}</title>
				<meta name="robots" content="noindex,nofollow"></meta>
				<meta property="og:title" content={pageTitle} />
				<meta name="twitter:title" content={pageTitle} />
			</Helmet>
			<div className="rto_form-sections">
				<div className="rto_content-div" style={{ maxWidth: "1600px" }}>
					<Breadcrumb
						listProps={{ className: "w-100 justify-content-end" }}
					>
						<Breadcrumb.Item
							linkAs={Link}
							linkProps={{ to: "/dashboard" }}
						>
							Dashboard
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkAs={Link}
							linkProps={{ to: "/admin" }}
						>
							Admin
						</Breadcrumb.Item>
						<Breadcrumb.Item active>Leads</Breadcrumb.Item>
					</Breadcrumb>
					<div className="rto_title-div">
						<h1 className="rto_page-title">Leads</h1>
					</div>
					<p className="text-center">
						<Link to="/leads/create" className="btn btn-primary">
							Create
						</Link>
					</p>
					<div
						className="card card-outline card-primary"
						style={{
							borderTop: "3px solid #00aaff",
							boxShadow:
								"0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
						}}
					>
						<div className="card-body">
							{/* <LeadsTable
								loading={loading}
								setLoading={setLoading}
							/> */}
							<IndexTable
								model={"leads"}
								loading={loading}
								setLoading={setLoading}
								service={LeadService}
								title="Leads"
								actionButtons={{
									updateLink: {
										path: "/leads",
									},
									// updateModal: {
									// 	enabled: true,
									// 	path: "/leads/update",
									// },
									// create: {
									// 	enabled: true,
									// 	path: "/leads/create",
									// },
								}}
								labels={[
									{
										id: "id",
										label: "ID",
									},
									{
										id: "leads_status_name",
										label: "Status",
									},
									{
										id: "fullname_first",
										label: "Assigned To",
									},
									{
										id: "referred_by",
										label: "Referred By",
									},
									{
										id: "company_name",
										label: "Company Name",
									},
									{
										id: "email_address",
										label: "Email Address",
									},
									{
										id: "phone_number",
										label: "Phone Number",
									},
									{
										id: "address",
										label: "Address",
									},
									{
										id: "city",
										label: "City",
									},
									{
										id: "state",
										label: "State",
									},
									{
										id: "zip",
										label: "Zip",
									},
									{
										id: "number_of_stores",
										label: "Number of Stores",
									},
									{
										id: "number_of_reminders",
										label: "Number of Reminders",
									},
									{
										id: "next_reminder_date",
										label: "Next Reminder Date",
									},
									{
										id: "last_note_date",
										label: "Last Note Date",
									},
									{
										id: "number_of_notes",
										label: "Number of Notes",
									},
									{
										id: "created_at",
										label: "Created At",
									},
									{
										id: "created_by",
										label: "Created By",
									},
								]}
								apiBase="/lead"
								successRedirectBase="leads"
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Leads;
