import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Breadcrumb } from "react-bootstrap";
// import UserTable from "../../components/backend/user/UserTable";
import { canUserActionAccess } from "../../helpers/Utils";
import UserService from "../../service/user/UserService";
import IndexTable from "../../components/common/IndexTable";

function Users({ loading, setLoading }) {
	const pageTitle = "Users | RTO Mobile";

	return (
		<>
			<Helmet>
				<title>{pageTitle}</title>
				<meta name="robots" content="noindex,nofollow"></meta>
				<meta property="og:title" content={pageTitle} />
				<meta name="twitter:title" content={pageTitle} />
			</Helmet>
			<div className="rto_form-sections">
				<div className="rto_content-div" style={{ maxWidth: "1600px" }}>
					<Breadcrumb
						listProps={{ className: "w-100 justify-content-end" }}
					>
						<Breadcrumb.Item
							linkAs={Link}
							linkProps={{ to: "/dashboard" }}
						>
							Dashboard
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkAs={Link}
							linkProps={{ to: "/settings" }}
						>
							Settings
						</Breadcrumb.Item>
						<Breadcrumb.Item active>Users</Breadcrumb.Item>
					</Breadcrumb>
					<div className="rto_title-div">
						<h1 className="rto_page-title">Users</h1>
					</div>
					<p className="text-center">
						{canUserActionAccess() && (
							<Link
								to="/settings/users/create"
								className="btn btn-primary"
							>
								Create
							</Link>
						)}
					</p>
					<div
						className="card card-outline card-primary"
						style={{
							borderTop: "3px solid #00aaff",
							boxShadow:
								"0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
						}}
					>
						<div className="card-body">
							{/* <UserTable
								loading={loading}
								setLoading={setLoading}
							/> */}
							<IndexTable
								model="user"
								loading={loading}
								setLoading={setLoading}
								service={UserService}
								title="Users"
								actionButtons={
									canUserActionAccess() && {
										updateLink: {
											path: "/settings/users",
										},
										// updateModal: {
										// 	enabled: true,
										// 	path: "/settings/users/update",
										// },
										// create: {
										// 	enabled: true,
										// 	path: "/settings/users/create",
										// },
									}
								}
								labels={[
									{
										id: "id",
										label: "ID",
									},
									{
										id: "struct_iso_name",
										label: "ISO",
										link: "/settings/isos",
									},
									{
										id: "struct_master_agent_name",
										label: "Master Agent",
										link: "/settings/master-agents",
									},
									{
										id: "struct_organization_name",
										label: "Organization",
										link: "/settings/organizations",
									},
									{
										id: "struct_company_name",
										label: "Company",
										link: "/settings/companies",
									},
									{
										id: "struct_store_name",
										label: "Store",
										link: "/settings/stores",
									},
									{
										id: "status_name",
										label: "Status",
									},
									{
										id: "username",
										label: "Username",
									},
									{
										id: "role_name",
										label: "Role",
									},
									{
										id: "email",
										label: "Email",
									},
									{
										id: "first_name",
										label: "First name",
									},
									{
										id: "last_name",
										label: "Last name",
									},
									{
										id: "phone",
										label: "Phone",
									},
									{
										id: "power_bot_status_name",
										label: "Power Bot",
									},
								]}
								apiBase={"/user"}
								successRedirectBase={"/settings/users"}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Users;
