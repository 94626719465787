import React, { useEffect } from "react";
import { Typography } from "@mui/material";

/**
 * @doc when we need to copy the content to another textbox
 * [
 *	"type" => "typography",
	"text" => 'Hello',
	"href" => "#",
	"class" => "col-md-12",
	"variant" => "body1",
 * ],
 * @ref https://mui.com/material-ui/react-typography/
 * @param {string} value
 * @param {string} text
 * @param {string} href
 * @param {string} hrefTarget
 * @param {string} variant - "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption" | "button" | "overline" | "srOnly" | "inherit"
 * @param {string} className
 * @param {json} style
 */

const CustomTypographyComponent = ({
	value,
	text,
	href,
	hrefTarget,
	variant,
	className,
	style,
	...otherProps
}) => {

	useEffect(() => {
		// Remount component when value changes
	}, [value]);

	return (
		<Typography
			className={className}
			variant={variant}
			style={style}
			{...otherProps}
		>
			{href && href.length > 0 ? (
				<a href={href} target={hrefTarget}>
					{value || text}
				</a>
			) : (
				<span>{value || text}</span>
			)}
		</Typography>
	);
};

export default CustomTypographyComponent;
