import React, { useEffect, useState, useRef } from "react";
import MUIDataTable from "mui-datatables";
import { toast } from "react-toastify";
import { FormSelect } from "react-bootstrap";
import { DateRangePicker } from "../../common/date-range-picker";
import SessionService from "../../../service/SessionService";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { NumericFormat } from "react-number-format";
import PortfolioDetailedService from "../../../service/report/PortfolioDetailedService";
// import Form from "react-bootstrap/Form";
import { Box, Typography } from "@mui/material";

const theme = createTheme({
	components: {
		MuiPopover: {
			styleOverrides: {
				paper: {
					padding: "1rem",
					maxWidth: "500px", // Set the width of the dialog window
					maxHeight: "500px", // Set the max height of the dialog window
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontWeight: "600",
					textTransform: "none",
					whiteSpace: "nowrap",
				},
			},
		},
	},
});

const DealerPortfolioDetailedTable = ({ loading, setLoading }) => {
	const [users, setUsers] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [rowCount, setRowCount] = useState(10);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [filterList, setFilterList] = useState({});
	const [sortList, setSortList] = useState("id:asc");
	const [tableState, setTableState] = useState({});
	const [dropdowns, setDropdowns] = useState({});
	const [searchText, setSearchText] = useState("");
	const [lastAction, setLastAction] = useState("");
	const [filters, setFilters] = useState({});
	const tableRef = useRef(null);
	const [isIsoColumnVisible, setIsIsoColumnVisible] = useState(true);
	const [isMasterAgentVisible, setIsMasterAgentVisible] = useState(true);
	const [isOrganizationVisible, setIsOrganizationVisible] = useState(true);
	const [isCompanyNameVisible, setIsCompanyNameVisible] = useState(true);
	const [isStoreNameVisible, setIsStoreNameVisible] = useState(true);
	// const [sums, setSums] = useState({});

	const getRowProps = (row, dataIndex, rowIndex) => {
		// Check if it's the last row
		if (rowIndex === users.length - 1) {
			return {
				style: { backgroundColor: "#f2f2f2" },
			};
		}
		return {};
	};

	const addSummaryObject = (data, sums) => {
		const summaryObject = {
			id: "",
			contract_number: "",
			account_manager: "",
			account_number: "",
			pos_invoice_number: "",
			imei: "",
			serial: "",
			manufacturer: "",
			model: "",
			contract_billing_status_name: "",
			contract_billing_status_id: "",
			orig_date: "",
			creation_date: "",
			customer_last_name: "",
			customer_first_name: "",
			customer_phone: "",
			customer_phone_2: "",
			device_model: "",
			device_manufacturer: "",
			merch_description: "",
			contract_cost: sums?.contract_cost,
			contract_length: "",
			rental_factor: sums?.rental_factor,
			down_payment_percent: sums?.down_payment_percent,
			abp_enabled_original: "",
			abp_enabled_current: "",
			contract_type: "",
			payment_frequency: "",
			last_payment_amount: sums?.last_payment_amount,
			last_payment_date: "",
			next_payment_amount: sums?.next_payment_amount,
			next_payment_date: "",
			retail_value: sums?.retail_value,
			original_contract_amount: sums?.original_contract_amount,
			down_payment: sums?.down_payment,
			total_rental_contract_value: sums?.total_rental_contract_value,
			invoices_count: "",
			payments_count: "",
			payments_collected_to_date: sums?.payments_collected_to_date,
			total_payment_fees: sums?.total_payment_fees,
			total_reschedule_fees: sums?.total_reschedule_fees,
			outstanding_balance_retail: sums?.outstanding_balance_retail,
			outstanding_balance_rental: sums?.outstanding_balance_rental,
			created: "",
			iso_id: "",
			iso_name: "",
			master_agent_id: "",
			master_agent_name: "",
			organization_id: "",
			organization_name: "",
			company_id: "",
			company_name: "",
			store_id: "",
			store_name: "",
		};
		// console.log(summaryObject);
		return [...data, summaryObject];
	};

	// Function to format the date in MM/DD/YYYY format
	const formatDate = (date) => {
		let day = date.getDate();
		let month = date.getMonth() + 1; // Months are 0-indexed
		let year = date.getFullYear();

		return `${month}/${day}/${year}`;
	};

	// Calculate the first day of the current month and today's date
	const today = new Date();
	const firstDayCurrentMonth = new Date(
		today.getFullYear(),
		today.getMonth(),
		1
	);

	// Format the date range string for the current month so far
	const initialDateRange = `${formatDate(
		firstDayCurrentMonth
	)} - ${formatDate(today)}`;

	// Initialize the dateRangeCreated state with the calculated date range
	const [dateRangeCreated, setDateRangeCreated] = useState(initialDateRange);

	const PaymentView = {
		page: currentPage, // <-- Set the default page
		limit: rowCount, // <-- Set the default row count
		payment_date: dateRangeCreated,
		sort: sortList,
		filter: {
			orig_date: dateRangeCreated,
		},
	};

	function isEmpty(obj) {
		return Object.keys(obj).length === 0 && obj.constructor === Object;
	}

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const response = await PortfolioDetailedService.view(
					PaymentView
				);

				// Display an error message if needed
				if (response.status !== 200) {
					// console.log("response", response);
					toast.error(`Error fetching Leads: ${response.message}`, {
						position: "top-center",
						autoClose: 1000,
					});
				}

				const users = response?.data?.data?.query
					? response?.data?.data?.query
					: [];
				const rowCount = parseInt(response?.data?.data.query?.length);
				const totalCount = parseInt(response?.data?.total?.count);
				const currentPage = parseInt(response?.data?.page);
				const dropdowns = response?.data?.dropdowns;

				setUsers(addSummaryObject(users, response?.data?.total?.sums));
				// addSummaryObject(users);
				// console.log(response?.data?.total?.sums);
				setTotalCount(totalCount);
				setCurrentPage(currentPage);
				setTotalPages(totalPages);
				setRowCount(rowCount);
				setRowsPerPage(rowsPerPage);
				setDropdowns(dropdowns);
			} catch (error) {
				console.error("Error fetching users:", error);
			}
			setLoading(false);
		};

		fetchData();
		//currentPage, totalPages, rowsPerPage
	}, []);

	const sendRequest = async (filterList, isFilterChange, dateRangeStr) => {
		try {
			const PaymentView = {
				page: tableState.page + 1,
				limit: tableState.rowsPerPage,
				payment_date: dateRangeCreated,
				filter: {},
				sort: sortList,
			};

			if (tableState.sortOrder.hasOwnProperty("name")) {
				let sortOrderDirection = tableState.sortOrder.direction
					? tableState.sortOrder.direction
					: "asc";
				PaymentView.sort = `${tableState.sortOrder.name}:${sortOrderDirection}`;
			} else {
				PaymentView.sort = "id:desc";
			}
			setSortList(PaymentView.sort);

			if (tableState.searchText) {
				if (isFilterChange) {
					PaymentView.filter = {};
					setSearchText("");
				} else {
					PaymentView.filter = tableState.searchText;
				}
			}

			if (isFilterChange) {
				let newFilterList = [];

				if (filterList) {
					newFilterList = filterList;
				} else {
					newFilterList = tableState.filterList;
				}

				//Filter
				if (newFilterList) {
					// Ensure both columns and tableState are provided and valid
					if (!columns || !tableState || !newFilterList) {
						console.error("Invalid columns or tableState");
					} else {
						// Loop through each column and set the filter in PaymentView
						columns.forEach((column, index) => {
							const filterValue = newFilterList[index][0];
							// console.log("the date stringggg", newFilterList);
							if (filterValue) {
								// console.log(column.name);
								if (dateRangeStr) {
									if (column.name === "orig_date") {
										PaymentView.filter[column.name] =
											dateRangeStr;
									} else {
										PaymentView.filter[column.name] =
											filterValue;
									}
								} else {
									PaymentView.filter[column.name] =
										filterValue;
								}
							} else if (dateRangeStr) {
								if (column.name === "orig_date") {
									PaymentView.filter[column.name] =
										dateRangeStr;
								}
							}
						});
					}

					setFilterList(PaymentView.filter);
				}
			}

			if (isFilterChange === false) {
				if (tableRef.current) {
					if (isEmpty(filters) === false) {
						tableRef.current.resetFilters();
					}
				}
			}

			setLoading(true);

			const response = await PortfolioDetailedService.view(PaymentView);
			const users = response?.data?.data?.query
				? response?.data?.data?.query
				: [];
			const rowCount = parseInt(response?.data?.data.query?.length);
			const totalCount = parseInt(response?.data?.total?.count);
			const currentPage = parseInt(response?.data?.page);
			const dropdowns = response?.data?.dropdowns;

			setUsers(addSummaryObject(users, response?.data?.total?.sums));
			// setSums(response?.data?.total?.sums);
			setTotalCount(totalCount);
			setCurrentPage(currentPage);
			setTotalPages(totalPages);
			setRowCount(rowCount);
			setRowsPerPage(tableState.rowsPerPage);
			setDropdowns(dropdowns);
			setLoading(false);
		} catch (error) {
			console.error("Error fetching users:", error);
		}
	};

	useEffect(() => {
		const handleClickOutside = async (event) => {
			if (lastAction === "search") {
				sendRequest(null, false, dateRangeCreated);
				setLastAction("");
				setFilters({});
			}
		};

		const handleEnterRelease = async (event) => {
			if (event.key === "Enter" && lastAction === "search") {
				sendRequest(null, false, dateRangeCreated);
				setLastAction("");
				setFilters({});
			}
			if (event.key === "Tab" && lastAction === "search") {
				sendRequest(null, false, dateRangeCreated);
				setLastAction("");
				setFilters({});
			}
		};

		// Add event listener
		document.addEventListener("click", handleClickOutside);
		document.addEventListener("keyup", handleEnterRelease);
		// Clean up
		return () => {
			document.removeEventListener("click", handleClickOutside);
			document.removeEventListener("keyup", handleEnterRelease);
		};
	}, [searchText, lastAction]);

	useEffect(() => {
		const handleClickOutside = async (event) => {
			if (lastAction === "filterChange") {
				sendRequest(null, true, dateRangeCreated);
				setLastAction("");
			}
		};

		const handleEnterRelease = async (event) => {
			if (event.key === "Enter" && lastAction === "filterChange") {
				sendRequest(null, true, dateRangeCreated);
				setLastAction("");
			}
			if (event.key === "Tab" && lastAction === "filterChange") {
				sendRequest(null, true, dateRangeCreated);
				setLastAction("");
			}
		};

		// Add event listener
		document.addEventListener("click", handleClickOutside);
		document.addEventListener("keyup", handleEnterRelease);
		// Clean up
		return () => {
			document.removeEventListener("click", handleClickOutside);
			document.removeEventListener("keyup", handleEnterRelease);
		};
	}, [filters, lastAction]);

	// let userHierarchy = SessionService.getUserHierarchy();
	// let stores = userHierarchy.sto_list ? userHierarchy.sto_list : {};

	const handleTableChange = async (action, newTableState) => {
		if (JSON.stringify(tableState) !== JSON.stringify(newTableState)) {
			setTableState(newTableState);
		}

		if (action === "search") {
			setSearchText(newTableState.searchText);
			setLastAction(action);
		}

		if (action === "filterChange") {
			// Assuming filters are structured as an object with column names as keys
			setFilters(
				newTableState.filterList.reduce((acc, filter, index) => {
					acc[newTableState.columns[index].name] = filter;
					return acc;
				}, {})
			);
			setLastAction(action);
		}

		if (
			action === "changePage" ||
			action === "changeRowsPerPage" ||
			action === "sort" ||
			action === "resetFilters"
		) {
			try {
				const PaymentView = {
					page: newTableState.page + 1,
					limit: newTableState.rowsPerPage,
					payment_date: dateRangeCreated,
					filter: {},
					sort: sortList,
				};

				if (newTableState.sortOrder.hasOwnProperty("name")) {
					let sortOrderDirection = newTableState.sortOrder.direction
						? newTableState.sortOrder.direction
						: "asc";
					PaymentView.sort = `${newTableState.sortOrder.name}:${sortOrderDirection}`;
				} else {
					PaymentView.sort = "id:desc";
				}
				setSortList(PaymentView.sort);

				if (newTableState.searchText) {
					if (newTableState.searchText.length > 2) {
						PaymentView.filter = newTableState.searchText;
					} else {
						return false;
					}
				}

				//Filter
				if (newTableState.filterList) {
					// Ensure both columns and tableState are provided and valid
					if (
						!columns ||
						!newTableState ||
						!newTableState.filterList
					) {
						console.error("Invalid columns or newTableState");
					} else {
						const newFilterList = newTableState.filterList;
						// Loop through each column and set the filter in PaymentView
						columns.forEach((column, index) => {
							const filterValue = newFilterList[index][0];
							// console.log("the date stringggg", newFilterList);
							if (filterValue) {
								// console.log(column.name);
								if (dateRangeCreated) {
									if (column.name === "orig_date") {
										PaymentView.filter[column.name] =
											dateRangeCreated;
									} else {
										PaymentView.filter[column.name] =
											filterValue;
									}
								} else {
									PaymentView.filter[column.name] =
										filterValue;
								}
							} else if (dateRangeCreated) {
								if (column.name === "orig_date") {
									PaymentView.filter[column.name] =
										dateRangeCreated;
								}
							}
						});
					}

					setFilterList(PaymentView.filter);
				}
				setTimeout(() => {
					setLoading(true);
				}, 5);
				const response = await PortfolioDetailedService.view(
					PaymentView
				);
				const users = response?.data?.data?.query
					? response?.data?.data?.query
					: [];
				const rowCount = parseInt(response?.data?.data.query?.length);
				const totalCount = parseInt(response?.data?.total?.count);
				const currentPage = parseInt(response?.data?.page);
				const dropdowns = response?.data?.dropdowns;

				setUsers(addSummaryObject(users, response?.data?.total?.sums)); // setSums(response?.data?.total?.sums);
				setTotalCount(totalCount);
				setCurrentPage(currentPage);
				setTotalPages(totalPages);
				setRowCount(rowCount);
				setRowsPerPage(newTableState.rowsPerPage);
				setDropdowns(dropdowns);
				setLoading(false);
			} catch (error) {
				console.error("Error fetching users:", error);
			}
		}
	};

	let allowIsoAdmin =
		SessionService.canSystemAdmin() ||
		SessionService.canRtoAdmin() ||
		SessionService.canIsoAdmin();
	let allowMasterAgentAdmin =
		allowIsoAdmin || SessionService.canMasterAgentAdmin();
	let allowOrganizationAdmin =
		allowMasterAgentAdmin || SessionService.canOrganizationAdmin();
	let allowCompanyAdmin =
		allowOrganizationAdmin || SessionService.canCompanyAdmin();
	let allowStoreAdmin = allowCompanyAdmin || SessionService.canStoreAdmin();

	const columns = [
		{
			name: "iso_name",
			label: "ISO",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: allowIsoAdmin === true ? true : false,
				display:
					allowIsoAdmin === true
						? isIsoColumnVisible
							? true
							: false
						: "excluded",
				filterType: "custom",
				filterOptions: {
					names: [], // You can provide filter options here if needed
					display: (filterList, onChange, index, column) => (
						<>
							<label
								className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 font-sans mb-1"
								style={{ fontWeight: 500 }}
							>
								ISO
							</label>
							<FormSelect
								name="iso_id"
								onChange={(event) => {
									if (event.target.value === "") {
										// Clear the filter for this column if the default option is selected
										filterList[index] = [];
									} else {
										// Set the filter value to the selected option
										filterList[index][0] =
											event.target.value;
									}
									onChange(filterList[index], index, column);
								}}
								className="form-select form-select-sm font-sans"
								value={filterList[index][0] || ""}
							>
								<option value="">All</option>
								{dropdowns?.iso_name?.map((dd) => (
									<option value={dd.name} key={dd.id}>
										{dd.name}
									</option>
								))}
							</FormSelect>
						</>
					),
				},
				customBodyRender: (value) => {
					return (
						<div className="whitespace-nowrap">
							{value ? value : ""}
						</div>
					);
				},
			},
		},
		{
			name: "master_agent_name",
			label: "Master Agent",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: allowMasterAgentAdmin === true ? true : false,
				display:
					allowMasterAgentAdmin === true
						? isMasterAgentVisible
							? true
							: false
						: "excluded",
				filterType: "custom",
				filterOptions: {
					names: [], // You can provide filter options here if needed
					display: (filterList, onChange, index, column) => (
						<>
							<label
								className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 font-sans mb-1"
								style={{ fontWeight: 500 }}
							>
								Master Agent
							</label>
							<FormSelect
								name="master_agent_name"
								onChange={(event) => {
									if (event.target.value === "") {
										// Clear the filter for this column if the default option is selected
										filterList[index] = [];
									} else {
										// Set the filter value to the selected option
										filterList[index][0] =
											event.target.value;
									}
									onChange(filterList[index], index, column);
								}}
								className="form-select form-select-sm font-sans"
								value={filterList[index][0] || ""}
							>
								<option value="">All</option>
								{dropdowns?.master_agent_name?.map((dd) => (
									<option value={dd.name} key={dd.id}>
										{dd.name}
									</option>
								))}
							</FormSelect>
						</>
					),
				},
				customBodyRender: (value) => {
					return (
						<div className="whitespace-nowrap">
							{value ? value : ""}
						</div>
					);
				},
			},
		},
		{
			name: "organization_name",
			label: "Organization",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: allowOrganizationAdmin === true ? true : false,
				display:
					allowOrganizationAdmin === true
						? isOrganizationVisible
							? true
							: false
						: "excluded",
				filterType: "custom",
				filterOptions: {
					names: [], // You can provide filter options here if needed
					display: (filterList, onChange, index, column) => (
						<>
							<label
								className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 font-sans mb-1"
								style={{ fontWeight: 500 }}
							>
								Organization
							</label>
							<FormSelect
								name="organization_name"
								onChange={(event) => {
									if (event.target.value === "") {
										// Clear the filter for this column if the default option is selected
										filterList[index] = [];
									} else {
										// Set the filter value to the selected option
										filterList[index][0] =
											event.target.value;
									}
									onChange(filterList[index], index, column);
								}}
								className="form-select form-select-sm font-sans"
								value={filterList[index][0] || ""}
							>
								<option value="">All</option>
								{dropdowns?.organization_name?.map((dd) => (
									<option value={dd.name} key={dd.id}>
										{dd.name}
									</option>
								))}
							</FormSelect>
						</>
					),
				},
				customBodyRender: (value) => {
					return (
						<div className="whitespace-nowrap">
							{value ? value : ""}
						</div>
					);
				},
			},
		},
		{
			name: "company_name",
			label: "Company",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: allowCompanyAdmin === true ? true : false,
				display:
					allowCompanyAdmin === true
						? isCompanyNameVisible
							? true
							: false
						: "excluded",
				filterType: "custom",
				filterOptions: {
					names: [], // You can provide filter options here if needed
					display: (filterList, onChange, index, column) => (
						<>
							<label
								className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 font-sans mb-1"
								style={{ fontWeight: 500 }}
							>
								Company
							</label>
							<FormSelect
								name="company_name"
								onChange={(event) => {
									if (event.target.value === "") {
										// Clear the filter for this column if the default option is selected
										filterList[index] = [];
									} else {
										// Set the filter value to the selected option
										filterList[index][0] =
											event.target.value;
									}
									onChange(filterList[index], index, column);
								}}
								className="form-select form-select-sm font-sans"
								value={filterList[index][0] || ""}
							>
								<option value="">All</option>
								{dropdowns?.company_name?.map((dd) => (
									<option value={dd.name} key={dd.id}>
										{dd.name}
									</option>
								))}
							</FormSelect>
						</>
					),
				},
				customBodyRender: (value) => {
					return (
						<div className="whitespace-nowrap">
							{value ? value : ""}
						</div>
					);
				},
			},
		},
		{
			name: "store_name",
			label: "Store",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: allowStoreAdmin === true ? true : false,
				display:
					allowStoreAdmin === true
						? isStoreNameVisible
							? true
							: false
						: "excluded",
				filterType: "custom",
				filterOptions: {
					names: [], // You can provide filter options here if needed
					display: (filterList, onChange, index, column) => (
						<>
							<label
								className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 font-sans mb-1"
								style={{ fontWeight: 500 }}
							>
								Store
							</label>
							<FormSelect
								name="store_name"
								onChange={(event) => {
									if (event.target.value === "") {
										// Clear the filter for this column if the default option is selected
										filterList[index] = [];
									} else {
										// Set the filter value to the selected option
										filterList[index][0] =
											event.target.value;
									}
									onChange(filterList[index], index, column);
								}}
								className="form-select form-select-sm font-sans"
								value={filterList[index][0] || ""}
							>
								<option value="">All</option>
								{dropdowns?.store_name?.map((dd) => (
									<option value={dd.name} key={dd.id}>
										{dd.name}
									</option>
								))}
							</FormSelect>
						</>
					),
				},
				customBodyRender: (value) => {
					return (
						<div className="whitespace-nowrap">
							{value ? value : ""}
						</div>
					);
				},
			},
		},
		{
			name: "account_manager",
			label: "Account Manager",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				customBodyRender: (value) => {
					return value;
				},
			},
		},
		{
			name: "account_number",
			label: "Account Number",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				customBodyRender: (value) => {
					return value;
				},
			},
		},
		{
			name: "contract_number",
			label: "Contract Number",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				customBodyRender: (value) => {
					return <a href={`/contracts/view/${value}`}>{value}</a>;
				},
			},
		},

		{
			name: "pos_invoice_number",
			label: "POS Invoice Number",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				customBodyRender: (value) => {
					return value;
				},
			},
		},
		{
			name: "imei",
			label: "IMEI",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				customBodyRender: (value) => {
					return value;
				},
			},
		},
		{
			name: "serial",
			label: "Serial Number",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				customBodyRender: (value) => {
					return value;
				},
			},
		},
		{
			name: "manufacturer",
			label: "Manufacturer",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				customBodyRender: (value) => {
					return value;
				},
			},
		},
		{
			name: "model",
			label: "Model",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				customBodyRender: (value) => {
					return value;
				},
			},
		},
		{
			name: "contract_billing_status_name",
			label: "Contract Billing Status Name",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: allowIsoAdmin === true ? true : false,
				display: allowIsoAdmin === true ? true : "excluded",
				filterType: "custom",
				filterOptions: {
					names: [], // You can provide filter options here if needed
					display: (filterList, onChange, index, column) => (
						<>
							<label
								className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 font-sans mb-1"
								style={{ fontWeight: 500 }}
							>
								Billing Status Name
							</label>
							<FormSelect
								name="contract_billing_status_name"
								onChange={(event) => {
									if (event.target.value === "") {
										// Clear the filter for this column if the default option is selected
										filterList[index] = [];
									} else {
										// Set the filter value to the selected option
										filterList[index][0] =
											event.target.value;
									}
									onChange(filterList[index], index, column);
								}}
								className="form-select form-select-sm font-sans"
								value={filterList[index][0] || ""}
							>
								<option value="">
									Select a status name...
								</option>
								{dropdowns?.contract_billing_status_name?.map(
									(dd) => (
										<option value={dd.name} key={dd.id}>
											{dd.name}
										</option>
									)
								)}
							</FormSelect>
						</>
					),
				},
				customBodyRender: (value) => {
					return (
						<div className="whitespace-nowrap">
							{value ? value : ""}
						</div>
					);
				},
			},
		},
		{
			name: "orig_date",
			label: "Origination Date",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: false, // Enable filtering for this column
				filterType: "custom", // Set filter type as custom
				filterOptions: {
					names: [], // You can provide filter options here if needed
					logic(payment_date, filters) {
						if (typeof filters !== "string") return false;
						let [startDate, endDate] = filters.split(" - ");
						if (!endDate) endDate = startDate;
						const createdDate = new Date(payment_date);
						startDate = new Date(startDate);
						endDate = new Date(endDate);
						return (
							createdDate >= startDate && createdDate <= endDate
						);
					},
					display: (filterList, onChange, index, column) => (
						<DateRangePicker
							onUpdate={(values) => {
								const formatDate = (date) => {
									if (!date) return ""; // return empty string if date is not valid
									const d = new Date(date);
									const month = d.getMonth() + 1; // getMonth() is zero-based
									const day = d.getDate();
									const year = d.getFullYear();
									return `${month}/${day}/${year}`;
								};

								const startDate = formatDate(values.range.from);
								const endDate = formatDate(values.range.to);
								const dateRangeStr = !endDate
									? startDate // if endDate is empty, just use startDate
									: startDate === endDate
									? startDate // if they are the same, use startDate
									: startDate + " - " + endDate; // otherwise, use both
								setDateRangeCreated(dateRangeStr);
								filterList[index][0] = dateRangeStr;
								onChange(filterList[index], index, column);
							}}
							label="Original Date"
							initialDateFrom={
								filterList[index][0]
									? filterList[index][0].split(" - ")[0]
									: ""
							}
							initialDateTo={
								filterList[index][0]
									? filterList[index][0].split(" - ")[1]
									: ""
							}
							align="end"
							locale="en-GB"
							showCompare={false}
						/>
					),
				},
				customBodyRender: (value) => {
					const date = new Date(value);
					const formattedDate = date.toLocaleDateString("en-US", {
						year: "numeric",
						month: "numeric",
						day: "numeric",
					});
					return (
						<div className="whitespace-nowrap">
							{value ? formattedDate : ""}
						</div>
					);
				},
			},
		},
		{
			name: "creation_date",
			label: "Creation Date",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: true, // Enable filtering for this column
				filterType: "custom", // Set filter type as custom
				filterOptions: {
					names: [], // You can provide filter options here if needed
					logic(payment_date, filters) {
						if (typeof filters !== "string") return false;
						let [startDate, endDate] = filters.split(" - ");
						if (!endDate) endDate = startDate;
						const createdDate = new Date(payment_date);
						startDate = new Date(startDate);
						endDate = new Date(endDate);
						return (
							createdDate >= startDate && createdDate <= endDate
						);
					},
					display: (filterList, onChange, index, column) => (
						<DateRangePicker
							onUpdate={(values) => {
								const formatDate = (date) => {
									if (!date) return ""; // return empty string if date is not valid
									const d = new Date(date);
									const month = d.getMonth() + 1; // getMonth() is zero-based
									const day = d.getDate();
									const year = d.getFullYear();
									return `${month}/${day}/${year}`;
								};

								const startDate = formatDate(values.range.from);
								const endDate = formatDate(values.range.to);
								const dateRangeStr = !endDate
									? startDate // if endDate is empty, just use startDate
									: startDate === endDate
									? startDate // if they are the same, use startDate
									: startDate + " - " + endDate; // otherwise, use both
								setDateRangeCreated(dateRangeStr);
								filterList[index][0] = dateRangeStr;
								onChange(filterList[index], index, column);
							}}
							label="Creation Date"
							initialDateFrom={
								filterList[index][0]
									? filterList[index][0].split(" - ")[0]
									: ""
							}
							initialDateTo={
								filterList[index][0]
									? filterList[index][0].split(" - ")[1]
									: ""
							}
							align="end"
							locale="en-GB"
							showCompare={false}
						/>
					),
				},
				customBodyRender: (value) => {
					const date = new Date(value);
					const formattedDate = date.toLocaleDateString("en-US", {
						year: "numeric",
						month: "numeric",
						day: "numeric",
					});
					return (
						<div className="whitespace-nowrap">
							{value ? formattedDate : ""}
						</div>
					);
				},
			},
		},
		{
			name: "customer_last_name",
			label: "Customer Last Name",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				customBodyRender: (value) => {
					return value;
				},
			},
		},
		{
			name: "customer_first_name",
			label: "Customer First Name",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				customBodyRender: (value) => {
					return value;
				},
			},
		},
		{
			name: "customer_phone",
			label: "Customer Phone",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				customBodyRender: (value) => {
					return value;
				},
			},
		},
		{
			name: "customer_phone_2",
			label: "Customer Phone 2",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				customBodyRender: (value) => {
					return value;
				},
			},
		},
		{
			name: "device_model",
			label: "Device Model",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				customBodyRender: (value) => {
					return value;
				},
			},
		},
		{
			name: "device_manufacturer",
			label: "Device Manufacturer",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				customBodyRender: (value) => {
					return value;
				},
			},
		},
		{
			name: "merch_description",
			label: "Merchandise Description",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				customBodyRender: (value) => {
					return value;
				},
			},
		},
		{
			name: "contract_cost",
			label: "Contract Cost",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: false,
				customBodyRender: (value, tableMeta) => {
					if (tableMeta.rowIndex === users.length - 1) {
						return (
							<strong>
								<div
									style={{
										display: "flex",
										justifyContent: "right",
									}}
								>
									<NumericFormat
										value={value}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
										decimalScale={2}
										fixedDecimalScale={true}
									/>
								</div>
							</strong>
						);
					}
					return (
						<div
							style={{ display: "flex", justifyContent: "right" }}
						>
							<NumericFormat
								value={value}
								displayType={"text"}
								thousandSeparator={true}
								prefix={"$"}
								decimalScale={2}
								fixedDecimalScale={true}
							/>
						</div>
					);
				},
			},
		},
		{
			name: "contract_length",
			label: "Contract Length",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				customBodyRender: (value) => {
					return value;
				},
			},
		},
		{
			name: "rental_factor",
			label: "Rental Factor",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				customBodyRender: (value, tableMeta) => {
					if (tableMeta.rowIndex === users.length - 1) {
						return (
							<strong>
								<div
									style={{
										display: "flex",
										justifyContent: "right",
									}}
								>
									{value}
								</div>
							</strong>
						);
					}
					return (
						<div
							style={{ display: "flex", justifyContent: "right" }}
						>
							{value}
						</div>
					);
				},
			},
		},
		{
			name: "down_payment_percent",
			label: "Down Payment Percent",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				customBodyRender: (value, tableMeta) => {
					if (tableMeta.rowIndex === users.length - 1) {
						return (
							<strong>
								<div
									style={{
										display: "flex",
										justifyContent: "right",
									}}
								>
									{value + "%"}
								</div>
							</strong>
						);
					}
					return (
						<div
							style={{ display: "flex", justifyContent: "right" }}
						>
							{value + "%"}
						</div>
					);
				},
			},
		},
		{
			name: "abp_enabled_original",
			label: "ABP Enabled Original",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				customBodyRender: (value) => {
					return value;
				},
			},
		},
		{
			name: "abp_enabled_current",
			label: "ABP Enabled Current",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				customBodyRender: (value) => {
					return value;
				},
			},
		},
		{
			name: "contract_type",
			label: "Contract Type",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				customBodyRender: (value) => {
					return value;
				},
			},
		},
		{
			name: "payment_frequency",
			label: "Payment Frequency",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				customBodyRender: (value) => {
					return value;
				},
			},
		},
		{
			name: "last_payment_amount",
			label: "Last Payment Amount",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: false,
				customBodyRender: (value, tableMeta) => {
					if (tableMeta.rowIndex === users.length - 1) {
						return (
							<strong>
								<div
									style={{
										display: "flex",
										justifyContent: "right",
									}}
								>
									<NumericFormat
										value={value}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
										decimalScale={2}
										fixedDecimalScale={true}
									/>
								</div>
							</strong>
						);
					}
					return (
						<div
							style={{ display: "flex", justifyContent: "right" }}
						>
							<NumericFormat
								value={value}
								displayType={"text"}
								thousandSeparator={true}
								prefix={"$"}
								decimalScale={2}
								fixedDecimalScale={true}
							/>
						</div>
					);
				},
			},
		},
		{
			name: "last_payment_date",
			label: "Last Payment Date",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: true, // Enable filtering for this column
				filterType: "custom", // Set filter type as custom
				filterOptions: {
					names: [], // You can provide filter options here if needed
					logic(payment_date, filters) {
						if (typeof filters !== "string") return false;
						let [startDate, endDate] = filters.split(" - ");
						if (!endDate) endDate = startDate;
						const createdDate = new Date(payment_date);
						startDate = new Date(startDate);
						endDate = new Date(endDate);
						return (
							createdDate >= startDate && createdDate <= endDate
						);
					},
					display: (filterList, onChange, index, column) => (
						<DateRangePicker
							onUpdate={(values) => {
								const formatDate = (date) => {
									if (!date) return ""; // return empty string if date is not valid
									const d = new Date(date);
									const month = d.getMonth() + 1; // getMonth() is zero-based
									const day = d.getDate();
									const year = d.getFullYear();
									return `${month}/${day}/${year}`;
								};

								const startDate = formatDate(values.range.from);
								const endDate = formatDate(values.range.to);
								const dateRangeStr = !endDate
									? startDate // if endDate is empty, just use startDate
									: startDate === endDate
									? startDate // if they are the same, use startDate
									: startDate + " - " + endDate; // otherwise, use both
								setDateRangeCreated(dateRangeStr);
								filterList[index][0] = dateRangeStr;
								onChange(filterList[index], index, column);
							}}
							label="Last Payment Date"
							initialDateFrom={
								filterList[index][0]
									? filterList[index][0].split(" - ")[0]
									: ""
							}
							initialDateTo={
								filterList[index][0]
									? filterList[index][0].split(" - ")[1]
									: ""
							}
							align="end"
							locale="en-GB"
							showCompare={false}
						/>
					),
				},
				customBodyRender: (value) => {
					const date = new Date(value);
					const formattedDate = date.toLocaleDateString("en-US", {
						year: "numeric",
						month: "numeric",
						day: "numeric",
					});
					return (
						<div className="whitespace-nowrap">
							{value ? formattedDate : ""}
						</div>
					);
				},
			},
		},
		{
			name: "next_payment_amount",
			label: "Next Payment Amount",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: false,
				customBodyRender: (value, tableMeta) => {
					if (tableMeta.rowIndex === users.length - 1) {
						return (
							<strong>
								<div
									style={{
										display: "flex",
										justifyContent: "right",
									}}
								>
									<NumericFormat
										value={value}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
										decimalScale={2}
										fixedDecimalScale={true}
									/>
								</div>
							</strong>
						);
					}
					return (
						<div
							style={{ display: "flex", justifyContent: "right" }}
						>
							<NumericFormat
								value={value}
								displayType={"text"}
								thousandSeparator={true}
								prefix={"$"}
								decimalScale={2}
								fixedDecimalScale={true}
							/>
						</div>
					);
				},
			},
		},
		{
			name: "next_payment_date",
			label: "Next Payment Date",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: true, // Enable filtering for this column
				filterType: "custom", // Set filter type as custom
				filterOptions: {
					names: [], // You can provide filter options here if needed
					logic(payment_date, filters) {
						if (typeof filters !== "string") return false;
						let [startDate, endDate] = filters.split(" - ");
						if (!endDate) endDate = startDate;
						const createdDate = new Date(payment_date);
						startDate = new Date(startDate);
						endDate = new Date(endDate);
						return (
							createdDate >= startDate && createdDate <= endDate
						);
					},
					display: (filterList, onChange, index, column) => (
						<DateRangePicker
							onUpdate={(values) => {
								const formatDate = (date) => {
									if (!date) return ""; // return empty string if date is not valid
									const d = new Date(date);
									const month = d.getMonth() + 1; // getMonth() is zero-based
									const day = d.getDate();
									const year = d.getFullYear();
									return `${month}/${day}/${year}`;
								};

								const startDate = formatDate(values.range.from);
								const endDate = formatDate(values.range.to);
								const dateRangeStr = !endDate
									? startDate // if endDate is empty, just use startDate
									: startDate === endDate
									? startDate // if they are the same, use startDate
									: startDate + " - " + endDate; // otherwise, use both
								setDateRangeCreated(dateRangeStr);
								filterList[index][0] = dateRangeStr;
								onChange(filterList[index], index, column);
							}}
							label="Next Payment Date"
							initialDateFrom={
								filterList[index][0]
									? filterList[index][0].split(" - ")[0]
									: ""
							}
							initialDateTo={
								filterList[index][0]
									? filterList[index][0].split(" - ")[1]
									: ""
							}
							align="end"
							locale="en-GB"
							showCompare={false}
						/>
					),
				},
				customBodyRender: (value) => {
					const date = new Date(value);
					const formattedDate = date.toLocaleDateString("en-US", {
						year: "numeric",
						month: "numeric",
						day: "numeric",
					});
					return (
						<div className="whitespace-nowrap">
							{value ? formattedDate : ""}
						</div>
					);
				},
			},
		},
		{
			name: "retail_value",
			label: "Retail Value",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: false,
				customBodyRender: (value, tableMeta) => {
					if (tableMeta.rowIndex === users.length - 1) {
						return (
							<strong>
								<div
									style={{
										display: "flex",
										justifyContent: "right",
									}}
								>
									<NumericFormat
										value={value}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
										decimalScale={2}
										fixedDecimalScale={true}
									/>
								</div>
							</strong>
						);
					}
					return (
						<div
							style={{ display: "flex", justifyContent: "right" }}
						>
							<NumericFormat
								value={value}
								displayType={"text"}
								thousandSeparator={true}
								prefix={"$"}
								decimalScale={2}
								fixedDecimalScale={true}
							/>
						</div>
					);
				},
			},
		},
		{
			name: "original_contract_amount",
			label: "Original Contract Amount",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: false,
				customBodyRender: (value, tableMeta) => {
					if (tableMeta.rowIndex === users.length - 1) {
						return (
							<strong>
								<div
									style={{
										display: "flex",
										justifyContent: "right",
									}}
								>
									<NumericFormat
										value={value}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
										decimalScale={2}
										fixedDecimalScale={true}
									/>
								</div>
							</strong>
						);
					}
					return (
						<div
							style={{ display: "flex", justifyContent: "right" }}
						>
							<NumericFormat
								value={value}
								displayType={"text"}
								thousandSeparator={true}
								prefix={"$"}
								decimalScale={2}
								fixedDecimalScale={true}
							/>
						</div>
					);
				},
			},
		},
		{
			name: "down_payment",
			label: "Down Payment",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: false,
				customBodyRender: (value, tableMeta) => {
					if (tableMeta.rowIndex === users.length - 1) {
						return (
							<strong>
								<div
									style={{
										display: "flex",
										justifyContent: "right",
									}}
								>
									<NumericFormat
										value={value}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
										decimalScale={2}
										fixedDecimalScale={true}
									/>
								</div>
							</strong>
						);
					}
					return (
						<div
							style={{ display: "flex", justifyContent: "right" }}
						>
							<NumericFormat
								value={value}
								displayType={"text"}
								thousandSeparator={true}
								prefix={"$"}
								decimalScale={2}
								fixedDecimalScale={true}
							/>
						</div>
					);
				},
			},
		},
		{
			name: "total_rental_contract_value",
			label: "Total Rental Contract Value",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: false,
				customBodyRender: (value, tableMeta) => {
					if (tableMeta.rowIndex === users.length - 1) {
						return (
							<strong>
								<div
									style={{
										display: "flex",
										justifyContent: "right",
									}}
								>
									<NumericFormat
										value={value}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
										decimalScale={2}
										fixedDecimalScale={true}
									/>
								</div>
							</strong>
						);
					}
					return (
						<div
							style={{ display: "flex", justifyContent: "right" }}
						>
							<NumericFormat
								value={value}
								displayType={"text"}
								thousandSeparator={true}
								prefix={"$"}
								decimalScale={2}
								fixedDecimalScale={true}
							/>
						</div>
					);
				},
			},
		},
		{
			name: "invoices_count",
			label: "Invoices Count",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				customBodyRender: (value) => {
					return value;
				},
			},
		},
		{
			name: "payments_count",
			label: "Payments Count",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				customBodyRender: (value) => {
					return value;
				},
			},
		},
		{
			name: "payments_collected_to_date",
			label: "Payments Collected to Date",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				customBodyRender: (value, tableMeta) => {
					if (tableMeta.rowIndex === users.length - 1) {
						return (
							<strong>
								<div
									style={{
										display: "flex",
										justifyContent: "right",
									}}
								>
									{value}
								</div>
							</strong>
						);
					}
					return (
						<div
							style={{ display: "flex", justifyContent: "right" }}
						>
							{value}
						</div>
					);
				},
			},
		},
		{
			name: "total_payment_fees",
			label: "Total Payment Fees",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: false,
				customBodyRender: (value, tableMeta) => {
					if (tableMeta.rowIndex === users.length - 1) {
						return (
							<strong>
								<div
									style={{
										display: "flex",
										justifyContent: "right",
									}}
								>
									<NumericFormat
										value={value}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
										decimalScale={2}
										fixedDecimalScale={true}
									/>
								</div>
							</strong>
						);
					}
					return (
						<div
							style={{ display: "flex", justifyContent: "right" }}
						>
							<NumericFormat
								value={value}
								displayType={"text"}
								thousandSeparator={true}
								prefix={"$"}
								decimalScale={2}
								fixedDecimalScale={true}
							/>
						</div>
					);
				},
			},
		},
		{
			name: "total_reschedule_fees",
			label: "Total Reschedule Fees",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: false,
				customBodyRender: (value, tableMeta) => {
					if (tableMeta.rowIndex === users.length - 1) {
						return (
							<strong>
								<div
									style={{
										display: "flex",
										justifyContent: "right",
									}}
								>
									<NumericFormat
										value={value}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
										decimalScale={2}
										fixedDecimalScale={true}
									/>
								</div>
							</strong>
						);
					}
					return (
						<div
							style={{ display: "flex", justifyContent: "right" }}
						>
							<NumericFormat
								value={value}
								displayType={"text"}
								thousandSeparator={true}
								prefix={"$"}
								decimalScale={2}
								fixedDecimalScale={true}
							/>
						</div>
					);
				},
			},
		},
		{
			name: "outstanding_balance_retail",
			label: "Outstanding Balance Retail",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: false,
				customBodyRender: (value, tableMeta) => {
					if (tableMeta.rowIndex === users.length - 1) {
						return (
							<strong>
								<div
									style={{
										display: "flex",
										justifyContent: "right",
									}}
								>
									<NumericFormat
										value={value}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
										decimalScale={2}
										fixedDecimalScale={true}
									/>
								</div>
							</strong>
						);
					}
					return (
						<div
							style={{ display: "flex", justifyContent: "right" }}
						>
							<NumericFormat
								value={value}
								displayType={"text"}
								thousandSeparator={true}
								prefix={"$"}
								decimalScale={2}
								fixedDecimalScale={true}
							/>
						</div>
					);
				},
			},
		},
		{
			name: "outstanding_balance_rental",
			label: "Outstanding Balance Rental",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: false,
				customBodyRender: (value, tableMeta) => {
					if (tableMeta.rowIndex === users.length - 1) {
						return (
							<strong>
								<div
									style={{
										display: "flex",
										justifyContent: "right",
									}}
								>
									<NumericFormat
										value={value}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
										decimalScale={2}
										fixedDecimalScale={true}
									/>
								</div>
							</strong>
						);
					}
					return (
						<div
							style={{ display: "flex", justifyContent: "right" }}
						>
							<NumericFormat
								value={value}
								displayType={"text"}
								thousandSeparator={true}
								prefix={"$"}
								decimalScale={2}
								fixedDecimalScale={true}
							/>
						</div>
					);
				},
			},
		},
		{
			name: "created",
			label: "Created Date",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: true, // Enable filtering for this column
				filterType: "custom", // Set filter type as custom
				filterOptions: {
					names: [], // You can provide filter options here if needed
					logic(payment_date, filters) {
						if (typeof filters !== "string") return false;
						let [startDate, endDate] = filters.split(" - ");
						if (!endDate) endDate = startDate;
						const createdDate = new Date(payment_date);
						startDate = new Date(startDate);
						endDate = new Date(endDate);
						return (
							createdDate >= startDate && createdDate <= endDate
						);
					},
					display: (filterList, onChange, index, column) => (
						<DateRangePicker
							onUpdate={(values) => {
								const formatDate = (date) => {
									if (!date) return ""; // return empty string if date is not valid
									const d = new Date(date);
									const month = d.getMonth() + 1; // getMonth() is zero-based
									const day = d.getDate();
									const year = d.getFullYear();
									return `${month}/${day}/${year}`;
								};

								const startDate = formatDate(values.range.from);
								const endDate = formatDate(values.range.to);
								const dateRangeStr = !endDate
									? startDate // if endDate is empty, just use startDate
									: startDate === endDate
									? startDate // if they are the same, use startDate
									: startDate + " - " + endDate; // otherwise, use both
								setDateRangeCreated(dateRangeStr);
								filterList[index][0] = dateRangeStr;
								onChange(filterList[index], index, column);
							}}
							label="Created Date"
							initialDateFrom={
								filterList[index][0]
									? filterList[index][0].split(" - ")[0]
									: ""
							}
							initialDateTo={
								filterList[index][0]
									? filterList[index][0].split(" - ")[1]
									: ""
							}
							align="end"
							locale="en-GB"
							showCompare={false}
						/>
					),
				},
				customBodyRender: (value) => {
					const date = new Date(value);
					const formattedDate = date.toLocaleDateString("en-US", {
						year: "numeric",
						month: "numeric",
						day: "numeric",
					});
					return (
						<div className="whitespace-nowrap">
							{value ? formattedDate : ""}
						</div>
					);
				},
			},
		},
	];

	const options = {
		customToolbar: () => {
			<p>I'm a toolbar</p>;
		},
		filter: true,
		filterType: "textField",
		searchText: searchText,
		onFilterChipClose: (removedFilterIndex, value, filterList) => {
			sendRequest(filterList, true, dateRangeCreated);
			setLastAction("");
		},
		onViewColumnsChange: (changedColumn, action) => {
			if (changedColumn === "iso_name") {
				setIsIsoColumnVisible(action === "add");
			} else if (changedColumn === "master_agent_name") {
				setIsMasterAgentVisible(action === "add");
			} else if (changedColumn === "organization_name") {
				setIsOrganizationVisible(action === "add");
			} else if (changedColumn === "company_name") {
				setIsCompanyNameVisible(action === "add");
			} else if (changedColumn === "store_name") {
				setIsStoreNameVisible(action === "add");
			}
		},
		setRowProps: getRowProps,
		serverSide: true,
		count: totalCount,
		page: currentPage - 1,
		rowsPerPage: rowsPerPage,
		rowsPerPageOptions: [
			10,
			25,
			50,
			100,
			{ label: "All", value: totalCount },
		],
		elevation: 0,
		responsive: "standard",
		fixedHeader: true,
		fixedFooter: true,
		// tableBodyHeight: "calc(100vh - 150px)",
		// customTableStyle: {
		//     maxHeight: "calc(100vh - 150px)",
		// },
		selectableRows: "none",
		onTableChange: handleTableChange,
		onDownload: (buildHead, buildBody, columns, data) => {
			// Fetch all data that matches the applied filters
			const fetchAllData = async () => {
				// Use the same parameters as in the handleTableChange function
				const PaymentView = {
					page: 1,
					limit: totalCount,
					payment_date: dateRangeCreated,
					filter: filterList,
					sort: sortList,
				};

				// Add filters based on the current table state
				if (tableState.filterList) {
					// Ensure both columns and tableState are provided and valid
					if (!columns || !tableState || !tableState.filterList) {
						console.error("Invalid columns or tableState");
					} else {
						// Loop through each column and set the filter in PaymentView
						const newFilterList = tableState.filterList;
						columns.forEach((column, index) => {
							const filterValue = newFilterList[index][0];
							// console.log("the date stringggg", newFilterList);
							if (filterValue) {
								// console.log(column.name);
								if (dateRangeCreated) {
									if (column.name === "orig_date") {
										PaymentView.filter[column.name] =
											dateRangeCreated;
									} else {
										PaymentView.filter[column.name] =
											filterValue;
									}
								} else {
									PaymentView.filter[column.name] =
										filterValue;
								}
							} else if (dateRangeCreated) {
								if (column.name === "orig_date") {
									PaymentView.filter[column.name] =
										dateRangeCreated;
								}
							}
						});
					}
				}

				const response = await PortfolioDetailedService.view(
					PaymentView
				);
				return response.data.data.query;
			};

			fetchAllData().then((allData) => {
				// Check if allData is defined
				if (!allData) {
					console.error("allData is undefined");
					return false;
				}

				// get checked columns
				const checkedColumns = tableState.columns.filter(
					(column) => column.display === "true"
				);

				// Transform the checked columns to the format required by buildHead
				const transformedColumns = checkedColumns.map((column) => ({
					...column,
				}));

				// remove first column, null empty
				// transformedColumns.shift();

				// Check if there are no columns selected
				if (transformedColumns.length === 0) {
					// Optionally display a message to the user
					toast.error("No columns selected for download", {
						position: "top-center",
						autoClose: 2000,
					});
					return false;
				}

				// Transform data to the required format
				const transformedData = allData
					.map((item, index) => {
						if (item) {
							return {
								index: index,
								data: transformedColumns.map(
									(column) => item[column.name]
								),
							};
						}
						return null;
					})
					.filter((item) => item !== null);

				// Generate CSV content
				const csvContent =
					buildHead(transformedColumns) + buildBody(transformedData);

				var fileDownload = require("js-file-download");
				fileDownload(csvContent, "export.csv", "text/csv");
			});

			// Return false to prevent the default CSV export
			return false;
		},
	};

	const CustomTitle = () => (
		<Box style={{ marginBottom: "10px" }}>
			<Typography variant="h6">
				Dealer Portfolio Detailed Report
			</Typography>
			<Typography
				variant="subtitle1"
				style={{
					marginTop: "5px",
					fontWeight: "500",
					fontSize: "14px",
					fontFamily:
						'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
				}}
			>
				Origination Date
			</Typography>
			<DateRangePicker
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "start",
					alignItems: "start",
				}}
				onUpdate={(values) => {
					const formatDate = (date) => {
						if (!date) return ""; // return empty string if date is not valid
						const d = new Date(date);
						const month = d.getMonth() + 1; // getMonth() is zero-based
						const day = d.getDate();
						const year = d.getFullYear();
						return `${month}/${day}/${year}`;
					};

					const startDate = formatDate(values.range.from);
					const endDate = formatDate(values.range.to);
					const dateRangeStr = !endDate
						? startDate // if endDate is empty, just use startDate
						: startDate === endDate
						? startDate // if they are the same, use startDate
						: startDate + " - " + endDate; // otherwise, use both

					setDateRangeCreated(dateRangeStr);

					sendRequest(null, true, dateRangeStr);

					// const currentFilters =
					// 	tableRef.current.state.filterList;
					// console.log("filterrrrr list", currentFilters);
					// const updatedFilters = [...currentFilters];
					// updatedFilters[] = [];
					// tableRef.current.setState({
					// 	...tableRef.current.state,
					// 	filterList: updatedFilters,
					// });

					// filterList[index][0] = dateRangeStr;
					// onChange(filterList[index], index, column);
				}}
				// label="Payment Date Range"
				// align="end"
				initialDateFrom={
					dateRangeCreated ? dateRangeCreated.split(" - ")[0] : ""
				}
				initialDateTo={
					dateRangeCreated ? dateRangeCreated.split(" - ")[1] : ""
				}
				locale="en-GB"
				showCompare={false}
			/>
		</Box>
	);

	return (
		<div>
			<ThemeProvider theme={theme}>
				<MUIDataTable
					title={<CustomTitle />}
					data={users}
					columns={columns}
					options={options}
					ref={tableRef}
				/>
			</ThemeProvider>
		</div>
	);
};

export default DealerPortfolioDetailedTable;
