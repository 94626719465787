import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Breadcrumb } from "react-bootstrap";
// import MasterAgentTable from "../../components/backend/masterAgent/MasterAgentTable";
import SessionService from "../../service/SessionService";
import MasterAgentService from "../../service/masterAgent/MasterAgentService";
import IndexTable from "../../components/common/IndexTable";

function MasterAgents({ loading, setLoading }) {
	const pageTitle = "Master Agents | RTO Mobile";

	return (
		<>
			<Helmet>
				<title>{pageTitle}</title>
				<meta name="robots" content="noindex,nofollow"></meta>
				<meta property="og:title" content={pageTitle} />
				<meta name="twitter:title" content={pageTitle} />
			</Helmet>
			<div className="rto_form-sections">
				<div className="rto_content-div" style={{ maxWidth: "1600px" }}>
					<Breadcrumb
						listProps={{ className: "w-100 justify-content-end" }}
					>
						<Breadcrumb.Item
							linkAs={Link}
							linkProps={{ to: "/dashboard" }}
						>
							Dashboard
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkAs={Link}
							linkProps={{ to: "/settings" }}
						>
							Settings
						</Breadcrumb.Item>

						<Breadcrumb.Item active>Master Agents</Breadcrumb.Item>
					</Breadcrumb>
					<div className="rto_title-div">
						<h1 className="rto_page-title">Master Agents</h1>
					</div>
					<p className="text-center">
						{SessionService.canRtoAdmin() && (
							<Link
								className="btn btn-primary"
								to="/settings/master-agents/create"
							>
								Create
							</Link>
						)}
					</p>
					<div
						className="card card-outline card-primary"
						style={{
							borderTop: "3px solid #00aaff",
							boxShadow:
								"0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
						}}
					>
						<div className="card-body">
							{/* <MasterAgentTable
								loading={loading}
								setLoading={setLoading}
							/> */}
							<IndexTable
								model={"struct_master_agent"}
								loading={loading}
								setLoading={setLoading}
								service={MasterAgentService}
								title="Master Agents"
								actionButtons={
									SessionService.canRtoAdmin() && {
										updateLink: {
											path: "/settings/master-agents",
										},
										// updateModal: {
										// 	enabled: true,
										// 	path: "/settings/master-agents/update",
										// },
										// create: {
										// 	enabled: true,
										// 	path: "/settings/master-agents/create",
										// },
									}
								}
								labels={[
									{
										id: "id",
										label: "ID",
									},
									{
										id: "struct_iso_name",
										label: "ISO",
										link: "/settings/isos",
									},
									{
										id: "struct_master_agent_name",
										label: "Master Agent",
										link: "/settings/master-agents",
									},
									{
										id: "address_1",
										label: "Address 1",
									},
									{
										id: "address_2",
										label: "Address 2",
										initialDisplay: false,
									},
									{
										id: "city",
										label: "City",
									},
									{
										id: "state",
										label: "State",
									},
									{
										id: "zip",
										label: "Zip",
										initialDisplay: false,
									},
									{
										id: "phone",
										label: "Phone",
									},
									{
										id: "email_address",
										label: "Email",
									},
									{
										id: "status_name",
										label: "Status",
									},
									{
										id: "created",
										label: "Created At",
									},
									{
										id: "user_name",
										label: "Created By",
									},
								]}
								apiBase="/master-agent"
								successRedirectBase="settings/master-agents"
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default MasterAgents;
