import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";

function Trainings() {
    return (
        <>
            <div className="rto_form-sections">
                <div className="rto_content-div rto_900">
                    <Breadcrumb
                        listProps={{ className: "w-100 justify-content-end" }}
                    >
                        <Breadcrumb.Item
                            linkAs={Link}
                            linkProps={{ to: "/dashboard" }}
                        >
                            Dashboard
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Trainings</Breadcrumb.Item>
                    </Breadcrumb>
                    <div class="rto_title-div">
                        <h1 class="rto_page-title">Training Videos</h1>
                        <p>
                            All courses are free of charge for stores that have
                            been issued RTO Mobile portal credentials.
                        </p>
                    </div>
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe
                            width="560"
                            height="315"
                            src="https://www.youtube.com/embed/videoseries?si=oaBD5-uR3O6gkODy&amp;list=PLA3WN5mMblUdtJSPFhZpUyxdik4HIfAUg"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            style={{ margin: "auto" }}
                            allowfullscreen
                        ></iframe>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Trainings;
