import React from "react";
// import { Link } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
    createGridStructLink,
    generateGridStructOptions,
} from "../../helpers/Utils";

export const createDropdownColumn = (
    tableColumns,
    name,
    label,
    linkPath = null,
    initialDisplay = true
) => {
    let dropdownData =
        (tableColumns && tableColumns[name] && tableColumns[name].data) || []; // Check if data_columns[name] is defined
    let config =
        (tableColumns && tableColumns[name] && tableColumns[name].filter) || ""; // Check if data_columns[name] is defined

    const shouldDisplay =
        tableColumns &&
        tableColumns[name] &&
        tableColumns.hasOwnProperty(name);

    return {
        name: config || "",
        label,
        options: {
            ...generateGridStructOptions(name, tableColumns, initialDisplay),
            filterType: "custom",
            filterOptions: {
                display: (filterList, onChange, index, column) => {
                    return (
                        shouldDisplay ? <>
                            <FormControl variant="standard">
                                <InputLabel>{label}</InputLabel>
                                <Select
                                    name={name}
                                    onChange={(event) => {
                                        if (event.target.value === "") {
                                            filterList[index] = [];
                                        } else {
                                            filterList[index][0] =
                                                event.target.value.toString();
                                        }
                                        onChange(
                                            filterList[index],
                                            index,
                                            column
                                        );
                                    }}
                                    value={filterList[index][0] || ""}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    {Array.isArray(dropdownData)
                                        ? dropdownData.map((dd) => (
                                              <MenuItem
                                                  value={dd.id}
                                                  key={dd.id}
                                              >
                                                  {dd.name}
                                              </MenuItem>
                                          ))
                                        : null}
                                </Select>
                            </FormControl>
                        </>
                     : 
                        false
                    );
                },
            },
            customBodyRender: (value, tableMeta, updateValue) => {
                const dropdown = dropdownData?.find((dd) => dd.id === value);
                const displayValue = dropdown ? dropdown.name : value;
                if (linkPath) {
                    // return (
                    // <Link to={`${linkPath}/${tableMeta.rowData[[tableMeta.columnIndex]]}`}>
                    // 	{displayValue || ""}
                    // </Link>
                    // );
                    return createGridStructLink(
                        `${linkPath}/${
                            tableMeta.rowData[[tableMeta.columnIndex]]
                        }`,
                        displayValue || ""
                    );
                } else {
                    return displayValue || "";
                }
            },
            customFilterListOptions: {
                render: (v) => {
                    if (v[0] && v[0].length > 0) {
                        const dropdown = dropdownData?.find(
                            (dd) => dd?.id?.toString() === v[0]
                        );
                        return dropdown
                            ? dropdown.name
                            : label + ": no matching records found";
                    }
                    return false;
                },
            },
        },
    };
};
