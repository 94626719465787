import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
// import ContractsTable from "../../components/backend/contracts/ContractsTable";
import ContractService from "../../service/contract/ContractService";
import IndexTable from "../../components/common/IndexTable";
import SessionService from "../../service/SessionService";

const userRoutes = SessionService.getUserRoutes() || {};
const redirectUrl = userRoutes["contract/view"] || "view"; // Fallback to default if not defined

function Contracts({ loading, setLoading }) {
	const pageTitle = "Contracts | RTO Mobile";
	return (
		<>
			<Helmet>
				<title>{pageTitle}</title>
				<meta name="robots" content="noindex,nofollow"></meta>
				<meta property="og:title" content={pageTitle} />
				<meta name="twitter:title" content={pageTitle} />
			</Helmet>
			<div className="rto_form-sections">
				<div className="rto_content-div" style={{ maxWidth: "1600px" }}>
					<Breadcrumb
						listProps={{ className: "w-100 justify-content-end" }}
					>
						<Breadcrumb.Item
							linkAs={Link}
							linkProps={{ to: "/dashboard" }}
						>
							Dashboard
						</Breadcrumb.Item>
						<Breadcrumb.Item active>Contracts</Breadcrumb.Item>
					</Breadcrumb>
					<div className="rto_title-div">
						<h1 className="rto_page-title">Contracts</h1>
					</div>
					<div
						className="card card-outline card-primary"
						style={{
							borderTop: "3px solid #00aaff",
							boxShadow:
								"0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
						}}
					>
						<div className="card-body">
							{/*<ContractsTable
								loading={loading}
								setLoading={setLoading}
							/>*/}
							<IndexTable
								loading={loading}
								setLoading={setLoading}
								service={ContractService}
								title="Contracts"
								actionButtons={
									redirectUrl === "view"
										? {
												viewHref: {
													path: redirectUrl,
												},
										  }
										: redirectUrl === "view-v3"
										? {
												viewLink: {
													path: redirectUrl,
												},
										  }
										: null
								}
								labels={[
									{
										id: "contract_id",
										label: "Contract #",
									},
									{
										id: "struct_iso_name",
										label: "ISO",
										link: "/settings/isos",
									},
									{
										id: "struct_master_agent_name",
										label: "Master Agent",
										link: "/settings/master-agents",
									},
									{
										id: "struct_organization_name",
										label: "Organization",
										link: "/settings/organizations",
									},
									{
										id: "struct_company_name",
										label: "Company",
										link: "/settings/companies",
									},
									{
										id: "struct_store_name",
										label: "Store",
										link: "/settings/stores",
									},
									{
										id: "billing_status_name",
										label: "Billing Status",
									},
									{
										id: "imei",
										label: "IMEI",
									},
									{
										id: "serial_number",
										label: "Serial #",
									},
									{
										id: "manufacturer_name",
										label: "Manufacturer",
									},
									{
										id: "model_name",
										label: "Model",
									},
									{
										id: "customer",
										label: "Customer",
									},
									{
										id: "lease_length",
										label: "Lease Length",
									},
									{
										id: "abp_original_status_name",
										label: "ABP Original Status",
									},
									{
										id: "abp_status_name",
										label: "ABP Status",
									},
									{
										id: "payment_due_date",
										label: "Payment Due Date",
									},
									{
										id: "user_name",
										label: "Username",
									},
									{
										id: "created",
										label: "Created",
									},
								]}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default Contracts;
