/**
 * fetch zipcode
 */

const ZipService = {
	/**
	 * fetchLocationData
	 * @param {int} zip
	 * @returns
	 */
	fetchLocationData: (zip) => {
		return fetch(`https://zip.row.net/zip/${zip}`).catch((error) => {
			console.log(error);
		});
	},
};

export default ZipService;
