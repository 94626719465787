import ApiService from "../ApiService";
import SessionService from "../SessionService";

const LogoutService = {
    action: () => {
        let userInfo = SessionService.getUserInfo();
        console.log("UserId -> ", userInfo.id);
        return ApiService.post("/auth/logout", { user_id: userInfo.id }).catch(
            (error) => {
                console.log(error);
            }
        );
    },
};

export default LogoutService;
