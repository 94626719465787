import axios from "axios";
import SessionService from "./SessionService";

/**
 * Service that is designed to encapsulate core logic for REST API calls
 */

const ApiService = {
  /**
   * Perform POST operation
   *
   * @param path String, endpoint path on the server
   * @param data String, request payload
   * @param params object, container for query string parameters (if exist)
   */
  post: (path, data, params = null, signal = null) => {
    const config = {
      ...ApiService.getRequestHeaderConfig("json"),
      signal: signal,
    };
    return axios.post(ApiService.buildUrl(path, params), data, config);
  },

  /**
   * Post form data contain file
   * @param path String, endpoint path on the server
   * @param data String, request payload
   * @param params object, container for query string parameters (if exist)
   * @returns
   */
  postFormData: (path, data, params = null) => {
    return axios.post(
      ApiService.buildUrl(path, params),
      data,
      ApiService.getRequestHeaderConfig("form-data")
    );
  },

  /**
   * Perform GET operation for given URL.
   *
   * @param path String, endpoint path on the server
   * @param params object, container for query string parameters (optional)
   * @return Promise
   */
  get: (path, params = null) => {
    return axios.get(
      ApiService.buildUrl(path, params),
      ApiService.getRequestHeaderConfig("json")
    );
  },

  /**
   * Build endpoint URL.
   *
   * @param path String, endpoint path on the server
   * @param params object, container for query string parameters
   * @returns String, endpoint URL
   */
  buildUrl: (path, params) => {
    let queryParams = process.env.REACT_APP_API_BASE_URL + path;

    if (params) {
      queryParams += "?";
      for (let attribute in params) {
        if (!params.hasOwnProperty(attribute)) {
          continue;
        }
        queryParams += `${attribute}=${params[attribute]}&`;
      }
    }
    return `${queryParams}`;
  },

  /**
   * Configure HTTP request headers.
   *
   * @return Object
   */
  getRequestHeaderConfig: (contentType = "json") => {
    const authToken = SessionService.getJWTToken();
    let contentTypeHeader = "application/json";

    if (contentType === "form-data") {
      contentTypeHeader = "multipart/form-data";
    }

    if (authToken) {
      return {
        headers: {
          "Content-Type": `${contentTypeHeader}`,
          Authorization: `${authToken}`,
        },
      };
    } else {
      return {
        headers: {
          "Content-Type": `${contentTypeHeader}`,
        },
      };
    }
  },
};

export default ApiService;
