import React from "react";
import { Card } from "react-bootstrap";
import SessionService from "../../service/SessionService";

const CardComponent = ({
	name,
	contractId,
	status,
	imei,
	serial,
	outstandingBalance,
	dueDate,
}) => {
	const userRoutes = SessionService.getUserRoutes() || {};
	const redirectUrl = userRoutes["contract/view"] || "view"; // Fallback to default if not defined

	return (
		<Card
			style={{ width: "100%", textAlign: "start" }}
			className="mb-2 search-card"
		>
			<Card.Body>
				<h3 className="card-title-search">{name}</h3>
				<Card.Text>
					<strong>Contract ID: </strong>
					{contractId}
					<br></br>
					<strong>Contract Status:</strong> {status}
					<br></br>
					<strong>IMEI:</strong> {imei}
					<br></br>
					<strong>Serial #:</strong> {serial}
					<br></br>
					<strong>Outstanding Contract Balance:</strong> $
					{outstandingBalance}
					<br></br>
					<strong>Due Date:</strong> {dueDate}
				</Card.Text>
				<Card.Link href={`/contracts/${redirectUrl}/${contractId}`}>
					View Contract
				</Card.Link>
			</Card.Body>
		</Card>
	);
};

export default CardComponent;
