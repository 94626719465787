import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html#jump-StoreReportFlagsService
 */

const StoreReportFlagsService = {
	/**
	 * data filter by ID
	 * @param {array} data {storeId : 1, page: 1, limit: 300}
	 * @see /docs/api/index.html#jump-StoreProducts-FetchByStoreId
	 * @returns
	 */
	fetchByStoreId: (data) => {
		return ApiService.post(
			"/store-report-flags/fetch-by-store-id",
			data
		).catch((error) => {
			console.log(error);
		});
	},

	/**
	 * Get reporting flag
	 * @param {array} data {storeId : 1}
	 */
	getReportingFlags: (data) => {
		return ApiService.post(
			"/store-report-flags/get-reporting-flags",
			data
		).catch((error) => {
			console.log(error);
		});
	},

	/**
	 * Update service
	 * @see /docs/api/index.html#jump-StoreReportFlags-Update
	 * @param json data {storeReportFlagId : 19200, statusId: 0}
	 * @returns
	 */
	update: (data) => {
		return ApiService.post("/store-report-flags/update", data).catch(
			(error) => {
				console.log(error);
			}
		);
	},
};

export default StoreReportFlagsService;
