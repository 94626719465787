import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html#Store
 */

const StoreService = {
	/**
	 * Get list of store types
	 * @see /docs/api/index.html#jump-Store-StoreTypes
	 * @returns JSON
	 */
	getTypes: () => {
		return ApiService.get("/store/store-types").catch((error) => {
			console.log(error);
		});
	},

	/**
	 * Get list of store tags
	 * @see /docs/api/index.html#jump-Store-StoreTags
	 * @returns JSON
	 */
	getTags: () => {
		return ApiService.get("/store/store-tags").catch((error) => {
			console.log(error);
		});
	},

	/**
	 * Get bank details from routing number
	 * @deprecated use /third-party/bank-routing-number-lookup
	 * @see /docs/api/index.html#jump-Store-GetBankNameForRoutingNumber
	 * @returns JSON
	 */
	getBankNameForRoutingNumber: (routingNumber) => {
		return ApiService.get("/store/get-bank-name-for-routing-number", {
			routingNumber: routingNumber,
		}).catch((error) => {
			console.log(error);
		});
	},

	/**
	 * Get payment types
	 * @see /docs/api/index.html#jump-Store-PaymentTypes
	 * @returns JSON
	 */
	getPaymentTypes: () => {
		return ApiService.get("/store/payment-types").catch((error) => {
			console.log(error);
		});
	},

	/**
	 *
	 * @param {StoreView} StoreView
	 * @see /docs/api/index.html#jump-Store-View
	 * @returns
	 */
	view: (StoreView) => {
		return ApiService.post("/store/index", StoreView).catch((error) => {
			console.log(error);
			return error;
		});
	},

	/**
	 * data filter by ID
	 * @param {int} id
	 * @see /docs/api/index.html#jump-Store-FetchById
	 * @returns
	 */
	fetchById: (id) => {
		return ApiService.get("/store/fetch-by-id", { id: id }).catch(
			(error) => {
				console.log(error);
			}
		);
	},

	/**
	 * Update service
	 * @see /docs/api/index.html#jump-Store-Update
	 * @param json data {storeId : 1, name: 'xxxx', address_1: 'ADD1' ...}
	 * @returns
	 */
	update: (data) => {
		return ApiService.post("/store/update", data).catch((error) => {
			console.log(error);
		});
	},

	/**
	 *
	 * @param {StoreCreate} StoreCreate
	 * @see /docs/api/index.html#jump-Store-Create
	 * @returns
	 */
	create: (StoreCreate) => {
		return ApiService.post("/store/create", StoreCreate).catch((error) => {
			console.log(error);
		});
	},
};

export default StoreService;
