import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Breadcrumb } from "react-bootstrap";
// import RemindersTable from "../../components/backend/reminders/RemindersTable";
import ReminderService from "../../service/reminder/ReminderService";
import IndexTable from "../../components/common/IndexTable";

function Reminders({ loading, setLoading }) {
	const pageTitle = "Reminders | RTO Mobile";

	return (
		<>
			<Helmet>
				<title>{pageTitle}</title>
				<meta name="robots" content="noindex,nofollow"></meta>
				<meta property="og:title" content={pageTitle} />
				<meta name="twitter:title" content={pageTitle} />
			</Helmet>
			<div className="rto_form-sections">
				<div className="rto_content-div" style={{ maxWidth: "1600px" }}>
					<Breadcrumb
						listProps={{ className: "w-100 justify-content-end" }}
					>
						<Breadcrumb.Item
							linkAs={Link}
							linkProps={{ to: "/dashboard" }}
						>
							Dashboard
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkAs={Link}
							linkProps={{ to: "/admin" }}
						>
							Admin
						</Breadcrumb.Item>
						<Breadcrumb.Item active>Reminders</Breadcrumb.Item>
					</Breadcrumb>
					<div className="rto_title-div">
						<h1 className="rto_page-title">Reminders</h1>
					</div>
					<div
						className="card card-outline card-primary"
						style={{
							borderTop: "3px solid #00aaff",
							boxShadow:
								"0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
						}}
					>
						<div className="card-body">
							{/* <RemindersTable
								loading={loading}
								setLoading={setLoading}
							/> */}
							<IndexTable
								model={"reminders"}
								loading={loading}
								setLoading={setLoading}
								service={ReminderService}
								title="Reminders"
								actionButtons={{
									markAsDone: true,
									updateLink: {
										path: "/reminders",
									},
									// updateModal: {
									// 	enabled: true,
									// 	path: "/reminders/update",
									// },
									// create: {
									// 	enabled: true,
									// 	path: "/reminders/create",
									// },
								}}
								labels={[
									{
										id: "id",
										label: "ID",
										initialDisplay: false,
									},
									{
										id: "reminders_type_name",
										label: "Type",
									},
									{
										id: "reminder_status_name",
										label: "Status",
									},
									{
										id: "assigned_to_name",
										label: "Assigned To",
									},
									{
										id: "date",
										label: "Due Date",
									},
									{
										id: "description",
										label: "Description",
									},
									{
										id: "created_at",
										label: "Created At",
									},
									{
										id: "model_id",
										excluded: true,
									},
									{
										id: "model",
										excluded: true,
									},
									{
										id: "model_name",
										label: "Name",
										excluded: true,
									},
									{
										id: "model_name",
										label: "Name",
										model_link_alt: true,
									},
									{
										id: "description",
										label: "Description",
									},
								]}
								apiBase="/reminder"
								successRedirectBase="reminders"
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Reminders;
