import React, { useEffect, useState } from "react";
import TopModal from "../../../common/TopModal";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Divider, Button as MuiButton, TextField } from "@mui/material";
import ContractService from "../../../../service/contract/ContractService";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
// import { getPaymentImage } from "../../../../helpers/Utils";
import { getPaymentImage } from "../../../../helpers/Utils";
import PaymentService from "../../../../service/payment/PaymentService";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";

const ContractCancelModal = ({
	isCancelVisible,
	closeCancelModal,
	contractId,
	setLoading,
}) => {
	const [modalLoading, setModalLoading] = useState(false); // Manage loading state internally
	const [cancelReason, setCancelReason] = useState("default");
	const [cancelReasonsData, setCancelReasonsData] = useState({});
	const [CancelModalOpen, setCancelModalOpen] = useState(false);
	const [step, setStep] = useState(1);

	const [value, setValue] = useState("0.00");
	const [displayValue, setDisplayValue] = useState("0.00");

	const [paymentData, setPaymentData] = useState([]);
	const [paymentInfo, setPaymentInfo] = useState({});
	const [selectedPayment, setSelectedPayment] = useState("CASH");
	const [radioChecked, setRadioChecked] = useState(false);

	const handleClose = () => {
		setCancelModalOpen(false);
		closeCancelModal();
		setCancelReason("default");
		setStep(1);
		setValue("0.00");
		setDisplayValue("0.00");
		setSelectedPayment("CASH");
		setRadioChecked(false);
	};

	const handleSubmit = async () => {
		// console.log({
		// 	contract_id: contractId,
		// 	cancel: {
		// 		reason: cancelReason,
		// 		payment: {
		// 			net: value,
		// 		},
		// 		check: paymentData?.check,
		// 		payment_method: selectedPayment,
		// 	},
		// });
		if (step === 2) {
			try {
				setLoading(true);
				setModalLoading(true);
				// const response = await ContractService.getCancelReasons({
				await ContractService.getCancelReasons({
					contract_id: contractId,
					cancel: {
						reason: cancelReason,
						payment: {
							net: value,
						},
						check: paymentData?.check,
						payment_method: selectedPayment,
					},
				});
				// console.log(response);
				setCancelModalOpen(true);
				setModalLoading(false);
				setLoading(false);
				handleClose();
			} catch (error) {
				setLoading(false);
				setModalLoading(false);
				console.error("Error fetching Contract:", error);
			}
		} else if (step === 3) {
			try {
				setLoading(true);
				setModalLoading(true);
				// const response = await ContractService.getCancelReasons({
				await ContractService.getCancelReasons({
					contract_id: contractId,
					cancel: {
						reason: cancelReason,
						check: paymentData?.check,
					},
				});
				// console.log(response);
				setCancelModalOpen(true);
				setModalLoading(false);
				setLoading(false);
				handleClose();
			} catch (error) {
				setLoading(false);
				setModalLoading(false);
				console.error("Error fetching Contract:", error);
			}
		}
	};

	useEffect(() => {
		// Format the initial value
		const formattedValue = parseFloat(value).toFixed(2);
		setValue(formattedValue);
		setDisplayValue(formattedValue);
	}, []);

	const handleBlur = () => {
		// Format the number with 2 decimal points on blur
		// If empty, set to '0.00'
		const formattedValue = value ? parseFloat(value).toFixed(2) : "0.00";
		setValue(formattedValue);
		setDisplayValue(formattedValue);
	};

	const handlePaymentMethodChange = (event) => {
		// setErrorMessage("");
		// setCashBalanceErrorMessage("");
		// setCashBalance("");

		if (
			/^\d*\.?\d*$/.test(event.target.value) ||
			event.target.value === ""
		) {
			// setPaymentAmount(event.target.value);
			return PaymentService.contractPaymentCalculate({
				contract_id: contractId,
				payment: { net: event.target.value },
			})
				.then((response) => {
					if (
						response.status === 200 &&
						response.data.isOk === true
					) {
						setPaymentInfo(response?.data?.data?.payment);
					}
				})
				.catch((error) => {
					console.log(error);
				});
			//recalculate amount
		}
	};

	const handleChange = (event) => {
		const newValue = event.target.value;

		// Allow only numbers with up to two decimal places
		if (newValue === "" || newValue.match(/^\d*\.?\d{0,2}$/)) {
			setValue(newValue);
			setDisplayValue(newValue);
			handlePaymentMethodChange(event);
		}
		handlePaymentMethodChange(event);
	};

	const handleFocus = () => {
		// Show the original value when the input is focused
		// If the value is '0.00', clear the field for user input
		if (value === "0.00") {
			setDisplayValue("");
		} else {
			setDisplayValue(value);
		}
	};

	const getCancelReasons = async () => {
		try {
			setLoading(true);
			setModalLoading(true);
			const response = await ContractService.getCancelReasons({
				contract_id: contractId,
			});
			setCancelReasonsData(response?.data?.data?.options);
			setCancelModalOpen(true);
			setModalLoading(false);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			setModalLoading(false);
			console.error("Error fetching Contract:", error);
		}
	};

	const getCancelReasonInfo = async (selectedCancelReason) => {
		try {
			setLoading(true);
			setModalLoading(true);
			const response = await ContractService.getCancelReasons({
				contract_id: contractId,
				cancel: {
					reason: selectedCancelReason,
				},
			});

			if (response?.data?.data?.cancel?.payment) {
				setPaymentData(response?.data?.data?.cancel);
				setPaymentInfo(response?.data?.data?.cancel?.payment);
				setValue(response?.data?.data?.cancel?.payment?.net);
				setDisplayValue(response?.data?.data?.cancel?.payment?.net);
				setStep(2);
			} else {
				setStep(3);
				console.log("there is no payment");
			}

			setModalLoading(false);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			setModalLoading(false);
			console.error("Error fetching Contract:", error);
		}
	};

	// useEffect hook to log to console when isVisible changes
	useEffect(() => {
		if (isCancelVisible) {
			getCancelReasons();
		}
	}, [isCancelVisible]);

	const handleRadioChage = (event) => {
		console.log(event.target.checked);
		setRadioChecked(event.target.checked);
	};

	// Define the body content for the modal
	const modalBody = (
		<>
			{/* Your body content here */}
			{
				<div style={{ padding: "0px 50px", color: "#21557a" }}>
					<FormControl
						// variant="standard"
						sx={{
							width: "100%",
						}}
					>
						<InputLabel id="demo-simple-select-autowidth-label">
							Cancellation Reason
						</InputLabel>
						<Select
							MenuProps={{
								PaperProps: { style: { width: "50%" } },
							}}
							labelId="demo-simple-select-autowidth-label"
							id="demo-simple-select-autowidth"
							value={cancelReason}
							onChange={(event) => {
								setCancelReason(event.target.value);
								getCancelReasonInfo(event.target.value);
							}}
							autoWidth
							label="Cancellation Reason"
							placeholder="Select Cancel Reason"
						>
							{Object?.keys(cancelReasonsData)?.map((key) => (
								<MenuItem key={key} value={key}>
									{cancelReasonsData[key]}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					{step === 2 && (
						<>
							<Box sx={{ flexGrow: 1 }} marginTop={2}>
								<Grid container spacing={1}>
									<Grid
										item
										xs={6}
										sx={{
											textAlign: "left",
										}}
									>
										Retail Value:
									</Grid>
									<Grid
										item
										xs={6}
										sx={{
											textAlign: "right",
										}}
									>
										${paymentData?.contract?.retail_value}
									</Grid>
									<Grid
										item
										xs={6}
										sx={{
											textAlign: "left",
										}}
									>
										Total Contract Value:
									</Grid>
									<Grid
										item
										xs={6}
										sx={{
											textAlign: "right",
										}}
									>
										$
										{
											paymentData?.contract
												?.total_contract_value
										}
									</Grid>
									<Grid
										item
										xs={6}
										sx={{
											textAlign: "left",
										}}
									>
										Payments Collected:
									</Grid>
									<Grid
										item
										xs={6}
										sx={{
											textAlign: "right",
										}}
									>
										${" "}
										{
											paymentData?.contract
												?.payments_collected
										}
									</Grid>
									<Grid
										item
										xs={6}
										sx={{
											textAlign: "left",
										}}
									>
										Outstanding Balance:
									</Grid>
									<Grid
										item
										xs={6}
										sx={{
											textAlign: "right",
										}}
									>
										$
										{
											paymentData?.contract
												?.outstanding_balance
										}
									</Grid>
									<Grid
										item
										xs={6}
										sx={{
											textAlign: "left",
											display: "flex",
											alignItems: "center",
										}}
									>
										Final Payment Amount Towards Balance:
									</Grid>
									<Grid
										item
										xs={6}
										sx={{
											textAlign: "right",
											display: "flex",
											justifyContent: "end",
										}}
									>
										<Box
											sx={{
												width: 500,
												maxWidth: "100%",
											}}
										>
											<TextField
												inputProps={{
													style: {
														textAlign: "right",
													},
												}}
												type="number"
												label=""
												value={displayValue}
												onChange={handleChange}
												onBlur={handleBlur}
												onFocus={handleFocus}
											/>
										</Box>
									</Grid>
								</Grid>
							</Box>
							<Box
								sx={{
									flexGrow: 1,
									background: "#F5F5F5",
									borderRadius: "10px",
									color: "#21557a",
									padding: "10px",
								}}
								marginTop={2}
							>
								<Grid
									container
									rowSpacing={2}
									columnSpacing={1}
								>
									<Grid
										item
										xs={6}
										sx={{
											textAlign: "left",
										}}
									>
										{`Tax [${paymentInfo?.tax_rate}%]:`}
									</Grid>
									<Grid
										item
										xs={6}
										sx={{
											textAlign: "right",
										}}
									>
										${paymentInfo?.tax}
									</Grid>
									<Grid
										item
										xs={6}
										sx={{
											textAlign: "left",
										}}
									>
										{"Payment Fee:"}
									</Grid>
									<Grid
										item
										xs={6}
										sx={{
											textAlign: "right",
										}}
									>
										${paymentInfo?.fee}
									</Grid>
									<Grid
										item
										xs={6}
										sx={{
											textAlign: "left",
										}}
									>
										{"Total Payment Amount:"}
									</Grid>
									<Grid
										item
										xs={6}
										sx={{
											textAlign: "right",
										}}
									>
										${paymentInfo?.total}
									</Grid>
								</Grid>
							</Box>
							<Box
								sx={{ flexGrow: 1, color: "#21557a" }}
								marginTop={2}
							>
								<Grid container spacing={1}>
									<Grid
										item
										xs={6}
										sx={{
											textAlign: "left",
										}}
									>
										Forgiven Balance:
									</Grid>
									<Grid
										item
										xs={6}
										sx={{
											textAlign: "right",
										}}
									>
										$
										{(
											Number(
												paymentData?.remaining_balance
											)
												.toFixed(2)
												.toLocaleString() -
											Number(paymentData?.payment?.net)
												.toFixed(2)
												.toLocaleString()
										)
											.toFixed(2)
											.toLocaleString()}
									</Grid>
								</Grid>
							</Box>
							<Divider
								sx={{
									borderColor: "#145d77",
									margin: "30px 0px",
									borderWidth: "1px 0px",
								}}
							/>
							<FormControl
								// variant="standard"
								sx={{
									width: "100%",
									color: "#21557a",
								}}
							>
								<InputLabel id="payment-method-label">
									Payment Method
								</InputLabel>
								<Select
									labelId="payment-method-label"
									id="payment-method-select"
									value={selectedPayment}
									MenuProps={{
										PaperProps: { style: { width: "50%" } },
									}}
									onChange={(event) => {
										setSelectedPayment(
											event?.target?.value
										);
									}}
									autoWidth
									label="Payment Method"
									placeholder="Select Payment Method"
								>
									{Object?.keys(
										paymentData?.payment_methods
									)?.map(
										(key) =>
											key !== "CC" && (
												<MenuItem key={key} value={key}>
													{paymentData
														?.payment_methods[key]
														?.id &&
														getPaymentImage(
															paymentData
																?.payment_methods[
																key
															]?.account_type
														)}
													{
														paymentData
															?.payment_methods[
															key
														]?.payment_name
													}
												</MenuItem>
											)
									)}
								</Select>
							</FormControl>
							<Divider
								sx={{
									borderColor: "#145d77",
									margin: "30px 0px",
									borderWidth: "1px 0px",
								}}
							/>
						</>
					)}
					{step === 3 && (
						<>
							<Grid container spacing={2} marginTop={2}>
								<Grid item xs={12}>
									<div>Please Confirm</div>
								</Grid>
								<Grid item xs={12}>
									<div style={{ color: "#a94442" }}>
										Cancel Contract - Device
										Damaged/Lost/Stolen
									</div>
								</Grid>
								<Grid item xs={12}>
									<div style={{ color: "#a94442" }}>
										This change can not be undone after
										submission.
									</div>
								</Grid>
							</Grid>

							<Divider
								sx={{
									borderColor: "#145d77",
									margin: "30px 0px",
									borderWidth: "1px 0px",
								}}
							/>

							<Grid container spacing={2}>
								<Grid item xs={12}>
									<div style={{ color: "#a94442" }}>
										The lock on the device will be set to{" "}
										<strong>locked</strong>
									</div>
								</Grid>
							</Grid>

							<Divider
								sx={{
									borderColor: "#145d77",
									margin: "30px 0px",
									borderWidth: "1px 0px",
								}}
							/>

							<FormControlLabel
								control={
									<Radio
										checked={radioChecked}
										onChange={handleRadioChage}
										// value={row}
										name="radio-button"
									/>
								}
								label="I confirm"
							/>
						</>
					)}
				</div>
			}

			{modalLoading && (
				<div className="loading-backdrop">
					<CircularProgress />
				</div>
			)}
		</>
	);

	// Define the header content for the modal
	const modalHeader = <>Contract Cancellation</>;

	// Define the footer content for the modal
	const modalFooter = (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				width: "100%",
			}}
		>
			<MuiButton
				color="error"
				variant="contained"
				onClick={handleClose}
				disabled={modalLoading}
			>
				Cancel
			</MuiButton>
			{step === 2 && (
				<MuiButton
					color="success"
					variant="contained"
					onClick={handleSubmit}
					disabled={modalLoading && radioChecked}
				>
					{"Submit & Complete >"}
				</MuiButton>
			)}
			{step === 3 && (
				<MuiButton
					color="success"
					variant="contained"
					onClick={handleSubmit}
					disabled={!radioChecked}
				>
					{"Submit & Complete >"}
				</MuiButton>
			)}
		</div>
	);

	return (
		<TopModal
			isVisible={CancelModalOpen}
			body={modalBody}
			footer={modalFooter}
			title={modalHeader}
			onClose={handleClose}
			loading={modalLoading}
		></TopModal>
	);
};

export default ContractCancelModal;
