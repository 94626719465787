"use client";

import React, { useState, useEffect, useRef } from "react";
import { Button } from "../../@rto/components/ui/button";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "../../@rto/components/ui/popover";
import { Calendar } from "../../@rto/components/ui/calendar";
import { DateInput } from "./date-input";
import { Label } from "../../@rto/components/ui/label";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "../../@rto/components/ui/select";
import { Switch } from "../../@rto/components/ui/switch";
import {
	ChevronUpIcon,
	ChevronDownIcon,
	CheckIcon,
	CalendarIcon,
} from "@radix-ui/react-icons";
import { cn } from "../../@rto/lib/utils";

const formatDate = (date, locale = "en-us") => {
	// return date.toLocaleDateString(locale, {
	//     month: "short",
	//     day: "numeric",
	//     year: "numeric",
	// });
	if (!date) return ""; // return empty string if date is null or undefined
	const month = (date.getMonth() + 1).toString();
	const day = date.getDate().toString();
	const year = date.getFullYear().toString();
	return `${month}/${day}/${year}`;
};

// Define presets
const PRESETS = [
	{ name: "today", label: "Today" },
	{ name: "yesterday", label: "Yesterday" },
	{ name: "last7", label: "Last 7 days" },
	{ name: "last14", label: "Last 14 days" },
	{ name: "last30", label: "Last 30 days" },
	{ name: "thisWeek", label: "This Week" },
	{ name: "lastWeek", label: "Last Week" },
	{ name: "thisMonth", label: "This Month" },
	{ name: "lastMonth", label: "Last Month" },
	{ name: "all", label: "All" },
];

/** The DateRangePicker component allows a user to select a range of dates */
export const DateRangePicker = ({
	// initialDateFrom = new Date(new Date().setHours(0, 0, 0, 0)),
	initialDateFrom,
	initialDateTo,
	initialCompareFrom,
	initialCompareTo,
	onUpdate,
	align = "end",
	locale = "en-US",
	showCompare = true,
	label,
	placeholder,
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const [range, setRange] = useState({
		// from: new Date(new Date(initialDateFrom).setHours(0, 0, 0, 0)),
		// to: initialDateTo
		//     ? new Date(new Date(initialDateTo).setHours(0, 0, 0, 0))
		//     : new Date(new Date(initialDateFrom).setHours(0, 0, 0, 0)),
		from: initialDateFrom ? new Date(initialDateFrom) : null,
		to: initialDateTo ? new Date(initialDateTo) : null,
	});
	const [rangeCompare, setRangeCompare] = useState(
		initialCompareFrom
			? {
					from: new Date(
						new Date(initialCompareFrom).setHours(0, 0, 0, 0)
					),
					to: initialCompareTo
						? new Date(
								new Date(initialCompareTo).setHours(0, 0, 0, 0)
						  )
						: new Date(
								new Date(initialCompareFrom).setHours(
									0,
									0,
									0,
									0
								)
						  ),
			  }
			: undefined
	);

	const handleCalendarSelect = (value) => {
		// console.log("valueeee", value);
		if (value?.from !== undefined && value?.to === undefined) {
			setRange({
				from: value?.from,
				to: value?.to,
			});
		} else if (value?.from !== undefined && value?.to !== undefined) {
			if (range?.to === undefined && range?.from === value?.from) {
				setRange({
					from: value?.from,
					to: value?.to,
				});
			} else if (value?.from !== range?.from) {
				setRange({
					from: value?.from,
					to: undefined,
				});
			} else if (value?.to !== range?.to) {
				setRange({
					from: value?.to,
					to: undefined,
				});
			}
		} else if (value === undefined) {
			setRange({
				from: range?.from,
				to: undefined,
			});
		}
	};

	// Refs to store the values of range and rangeCompare when the date picker is opened
	const openedRangeRef = useRef();
	const openedRangeCompareRef = useRef();

	const [selectedPreset, setSelectedPreset] = useState(undefined);

	const [isSmallScreen, setIsSmallScreen] = useState(
		typeof window !== "undefined" ? window.innerWidth < 960 : false
	);

	useEffect(() => {
		const handleResize = () => {
			setIsSmallScreen(window.innerWidth < 960);
		};

		window.addEventListener("resize", handleResize);

		// Clean up event listener on unmount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		setRange({
			from: initialDateFrom ? new Date(initialDateFrom) : null,
			to: initialDateTo ? new Date(initialDateTo) : null,
		});
	}, [initialDateFrom, initialDateTo]);

	const getPresetRange = (presetName) => {
		const preset = PRESETS.find(({ name }) => name === presetName);
		if (!preset)
			throw new Error(`Unknown date range preset: ${presetName}`);
		const from = new Date();
		const to = new Date();
		const first = from.getDate() - from.getDay();

		switch (preset.name) {
			case "today":
				from.setHours(0, 0, 0, 0);
				to.setHours(23, 59, 59, 999);
				break;
			case "yesterday":
				from.setDate(from.getDate() - 1);
				from.setHours(0, 0, 0, 0);
				to.setDate(to.getDate() - 1);
				to.setHours(23, 59, 59, 999);
				break;
			case "last7":
				from.setDate(from.getDate() - 6);
				from.setHours(0, 0, 0, 0);
				to.setHours(23, 59, 59, 999);
				break;
			case "last14":
				from.setDate(from.getDate() - 13);
				from.setHours(0, 0, 0, 0);
				to.setHours(23, 59, 59, 999);
				break;
			case "last30":
				from.setDate(from.getDate() - 29);
				from.setHours(0, 0, 0, 0);
				to.setHours(23, 59, 59, 999);
				break;
			case "thisWeek":
				from.setDate(first);
				from.setHours(0, 0, 0, 0);
				to.setHours(23, 59, 59, 999);
				break;
			case "lastWeek":
				from.setDate(from.getDate() - 7 - from.getDay());
				to.setDate(to.getDate() - to.getDay() - 1);
				from.setHours(0, 0, 0, 0);
				to.setHours(23, 59, 59, 999);
				break;
			case "thisMonth":
				from.setDate(1);
				from.setHours(0, 0, 0, 0);
				to.setHours(23, 59, 59, 999);
				break;
			case "lastMonth":
				from.setMonth(from.getMonth() - 1);
				from.setDate(1);
				from.setHours(0, 0, 0, 0);
				to.setDate(0);
				to.setHours(23, 59, 59, 999);
				break;
			case "all":
				from.setYear(2016);
				from.setMonth(1);
				from.setDate(1);
				from.setHours(0, 0, 0, 0);
				to.setHours(23, 59, 59, 999);
				break;
			default:
				break;
		}

		return { from, to };
	};

	const setPreset = (preset) => {
		const range = getPresetRange(preset);
		setRange(range);
		if (rangeCompare) {
			const rangeCompare = {
				from: range.from
					? new Date(
							range.from.getFullYear() - 1,
							range.from.getMonth(),
							range.from.getDate()
					  )
					: null,
				to: range.to
					? new Date(
							range.to.getFullYear() - 1,
							range.to.getMonth(),
							range.to.getDate()
					  )
					: // : undefined,
					  null,
			};
			setRangeCompare(rangeCompare);
		}
	};

	const checkPreset = () => {
		for (const preset of PRESETS) {
			const presetRange = getPresetRange(preset.name);

			// If range.from or range.to is null, continue to the next iteration
			if (!range.from || !range.to) continue;

			const normalizedRangeFrom = new Date(
				range.from.setHours(0, 0, 0, 0)
			);
			const normalizedPresetFrom = new Date(
				presetRange.from.setHours(0, 0, 0, 0)
			);

			const normalizedRangeTo = new Date(
				range.to?.setHours(0, 0, 0, 0) ?? 0
			);
			const normalizedPresetTo = new Date(
				presetRange.to?.setHours(0, 0, 0, 0) ?? 0
			);

			if (
				normalizedRangeFrom.getTime() ===
					normalizedPresetFrom.getTime() &&
				normalizedRangeTo.getTime() === normalizedPresetTo.getTime()
			) {
				setSelectedPreset(preset.name);
				return;
			}
		}

		setSelectedPreset(undefined);
	};

	// const resetValues = () => {
	//     setRange({
	//         from:
	//             typeof initialDateFrom === "string"
	//                 ? new Date(initialDateFrom)
	//                 : initialDateFrom,
	//         to: initialDateTo
	//             ? typeof initialDateTo === "string"
	//                 ? new Date(initialDateTo)
	//                 : initialDateTo
	//             : typeof initialDateFrom === "string"
	//             ? new Date(initialDateFrom)
	//             : initialDateFrom,
	//     });
	//     setRangeCompare(
	//         initialCompareFrom
	//             ? {
	//                   from:
	//                       typeof initialCompareFrom === "string"
	//                           ? new Date(initialCompareFrom)
	//                           : initialCompareFrom,
	//                   to: initialCompareTo
	//                       ? typeof initialCompareTo === "string"
	//                           ? new Date(initialCompareTo)
	//                           : initialCompareTo
	//                       : typeof initialCompareFrom === "string"
	//                       ? new Date(initialCompareFrom)
	//                       : initialCompareFrom,
	//               }
	//             : undefined
	//     );
	// };

	const resetValues = () => {
		const createDate = (date) => {
			if (typeof date === "string") {
				// Check if the date string is valid
				return isNaN(Date.parse(date)) ? null : new Date(date);
			}
			return date instanceof Date ? date : null;
		};

		const from = createDate(initialDateFrom);
		const to = createDate(initialDateTo) || from;
		const compareFrom = createDate(initialCompareFrom);
		const compareTo = createDate(initialCompareTo) || compareFrom;

		setRange({
			from: from,
			to: to,
		});
		setRangeCompare(
			compareFrom
				? {
						from: compareFrom,
						to: compareTo,
				  }
				: undefined
		);
	};

	useEffect(() => {
		checkPreset();
	}, [range]);

	const PresetButton = ({ preset, label, isSelected }) => (
		<Button
			className={
				cn(isSelected && "pointer-events-none") + " text-xs px-1 py-1"
			}
			style={{ height: "auto" }}
			variant="ghost"
			onClick={() => {
				setPreset(preset);
			}}
		>
			<>
				<span
					className={cn("pr-2 opacity-0", isSelected && "opacity-70")}
				>
					<CheckIcon width={18} height={18} />
				</span>
				{label}
			</>
		</Button>
	);

	// // Helper function to check if two date ranges are equal
	// const areRangesEqual = (a, b) => {
	//     if (!a || !b) return a === b; // If either is undefined, return true if both are undefined
	//     return (
	//         a.from.getTime() === b.from.getTime() &&
	//         (!a.to || !b.to || a.to.getTime() === b.to.getTime())
	//     );
	// };

	// Helper function to check if two date ranges are equal
	const areRangesEqual = (a, b) => {
		if (!a || !b) return a === b; // If either is undefined, return true if both are undefined

		// Check if 'from' dates are not null and are equal
		const fromEqual =
			a.from && b.from
				? a.from.getTime() === b.from.getTime()
				: a.from === b.from;

		// Check if 'to' dates are not null and are equal
		const toEqual =
			a.to && b.to ? a.to.getTime() === b.to.getTime() : a.to === b.to;

		return fromEqual && toEqual;
	};

	function areSameDate(date1, date2) {
		return (
			date1.getDate() === date2.getDate() &&
			date1.getMonth() === date2.getMonth() &&
			date1.getFullYear() === date2.getFullYear()
		);
	}

	useEffect(() => {
		if (isOpen) {
			openedRangeRef.current = range;
			openedRangeCompareRef.current = rangeCompare;
		}
	}, [isOpen]);

	return (
		<Popover
			modal={true}
			open={isOpen}
			onOpenChange={(open) => {
				if (!open) {
					resetValues();
				}
				setIsOpen(open);
			}}
		>
			{label && (
				<label
					className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 font-sans mb-1"
					style={{ fontWeight: 500 }}
				>
					{label}
				</label>
			)}
			<PopoverTrigger asChild>
				<Button
					size={"lg"}
					variant="outline"
					style={{ paddingLeft: ".6rem", paddingRight: ".6rem" }}
					className={
						(!range.from && !range.to
							? " text-muted-foreground"
							: "text-accent-foreground") +
						" font-sans font-normal justify-between overflow-hidden"
					}
				>
					<CalendarIcon className="mr-2 h-4 w-4" />
					<div className="text-right">
						<div className="py-1">
							<div style={{ whiteSpace: "nowrap" }}>
								{range.from &&
								range.to &&
								!areSameDate(range.from, range.to)
									? `${formatDate(
											range.from,
											locale
									  )} - ${formatDate(range.to, locale)}`
									: range.from
									? `${formatDate(range.from, locale)}`
									: placeholder
									? placeholder
									: "Pick a date range"}
							</div>
						</div>
						{rangeCompare != null && (
							<div className="opacity-60 text-xs -mt-1">
								<div>
									vs. {formatDate(rangeCompare.from, locale)}
									{rangeCompare.to != null
										? ` - ${formatDate(
												rangeCompare.to,
												locale
										  )}`
										: ""}
								</div>
							</div>
						)}
					</div>
					<div
						className="opacity-60 -mr-2 scale-125"
						style={{
							paddingLeft: "0",
						}}
					>
						{isOpen ? (
							<ChevronUpIcon width={24} />
						) : (
							<ChevronDownIcon width={24} />
						)}
					</div>
				</Button>
			</PopoverTrigger>
			<PopoverContent
				align={align}
				side="bottom"
				className="w-auto p-0 py-2 z-[9999999]"
			>
				<div className="flex py-2">
					<div className="flex">
						<div className="flex flex-col">
							<div className="flex flex-col lg:flex-row gap-2 px-3 justify-end items-center lg:items-start pb-4 pb-lg-0 lg:pb-0">
								<div className="flex items-center space-x-2 pr-4 py-1">
									{showCompare && (
										<>
											<Switch
												defaultChecked={Boolean(
													rangeCompare
												)}
												onCheckedChange={(checked) => {
													if (checked) {
														if (!range.to) {
															setRange({
																from: range.from,
																to: range.from,
															});
														}
														setRangeCompare({
															from: new Date(
																range.from.getFullYear(),
																range.from.getMonth(),
																range.from.getDate() -
																	365
															),
															to: range.to
																? new Date(
																		range.to.getFullYear() -
																			1,
																		range.to.getMonth(),
																		range.to.getDate()
																  )
																: new Date(
																		range.from.getFullYear() -
																			1,
																		range.from.getMonth(),
																		range.from.getDate()
																  ),
														});
													} else {
														setRangeCompare(
															undefined
														);
													}
												}}
												id="compare-mode"
											/>
											<Label htmlFor="compare-mode">
												Compare
											</Label>
										</>
									)}
								</div>
								<div className="flex flex-col gap-2">
									<div className="flex gap-2">
										<DateInput
											value={range.from}
											onChange={(date) => {
												const toDate =
													range.to == null ||
													date > range.to
														? date
														: range.to;
												setRange((prevRange) => ({
													...prevRange,
													from: date || null,
													to: toDate,
												}));
											}}
										/>
										<div className="py-1">-</div>
										<DateInput
											value={range.to}
											onChange={(date) => {
												const fromDate =
													date < range.from
														? date
														: range.from;
												setRange((prevRange) => ({
													...prevRange,
													from: fromDate,
													to: date,
												}));
											}}
										/>
									</div>
									{rangeCompare != null && (
										<div className="flex gap-2">
											<DateInput
												value={rangeCompare?.from}
												onChange={(date) => {
													if (rangeCompare) {
														const compareToDate =
															rangeCompare.to ==
																null ||
															date >
																rangeCompare.to
																? date
																: rangeCompare.to;
														setRangeCompare(
															(
																prevRangeCompare
															) => ({
																...prevRangeCompare,
																from: date,
																to: compareToDate,
															})
														);
													} else {
														setRangeCompare({
															from: date,
															to:
																date ??
																new Date(),
														});
													}
												}}
											/>
											<div className="py-1">-</div>
											<DateInput
												value={rangeCompare?.to}
												onChange={(date) => {
													if (
														rangeCompare &&
														rangeCompare.from
													) {
														const compareFromDate =
															date <
															rangeCompare.from
																? date
																: rangeCompare.from;
														setRangeCompare({
															...rangeCompare,
															from: compareFromDate,
															to: date,
														});
													}
												}}
											/>
										</div>
									)}
								</div>
							</div>
							{isSmallScreen && (
								<Select
									defaultValue={selectedPreset}
									onValueChange={(value) => {
										setPreset(value);
									}}
								>
									<SelectTrigger className="w-[180px] mx-auto mb-2">
										<SelectValue placeholder="Select..." />
									</SelectTrigger>
									<SelectContent className="z-[9999999]">
										{PRESETS.map((preset) => (
											<SelectItem
												key={preset.name}
												value={preset.name}
											>
												{preset.label}
											</SelectItem>
										))}
									</SelectContent>
								</Select>
							)}
							<div>
								<Calendar
									mode="range"
									onSelect={handleCalendarSelect}
									selected={range}
									numberOfMonths={isSmallScreen ? 1 : 2}
									defaultMonth={
										new Date(
											new Date().setMonth(
												new Date().getMonth() -
													(isSmallScreen ? 0 : 1)
											)
										)
									}
								/>
							</div>
						</div>
					</div>
					{!isSmallScreen && (
						<div className="flex flex-col items-end gap-1 pr-2">
							<div className="flex w-full flex-col items-end gap-1 pr-2">
								{PRESETS.map((preset) => (
									<PresetButton
										key={preset.name}
										preset={preset.name}
										label={preset.label}
										isSelected={
											selectedPreset === preset.name
										}
									/>
								))}
							</div>
						</div>
					)}
				</div>
				<div className="flex justify-end gap-2 py-2 pr-3">
					<Button
						onClick={() => {
							setIsOpen(false);
							resetValues();
						}}
						variant="ghost"
					>
						Cancel
					</Button>
					<Button
						onClick={() => {
							setIsOpen(false);
							if (range?.from != null && range?.to != null) {
								onUpdate?.({ range, rangeCompare });
							} else if (
								!areRangesEqual(
									range,
									openedRangeRef.current
								) ||
								!areRangesEqual(
									rangeCompare,
									openedRangeCompareRef.current
								)
							) {
								onUpdate?.({ range, rangeCompare });
							}
						}}
					>
						Update
					</Button>
				</div>
			</PopoverContent>
		</Popover>
	);
};

DateRangePicker.displayName = "DateRangePicker";
DateRangePicker.filePath =
	"libs/shared/ui-kit/src/lib/date-range-picker/date-range-picker.tsx";
