export const simplePaymentFormStep1Definition = {
	form: {
		state: false,
		class: "form-vertical",
	},
	fields: {
		struct_store_id: {
			type: "number",
			label: "RTO Store ID",
			value: "",
			integerOnly: true,
			minLength: 1,
			maxLength: 255,
			required: true,
			readOnly: false,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
		},
	},
	layout: [
		{
			type: "div",
			class: "row",
			items: [
				{
					type: "div",
					class: "col-12",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "number",
									name: "struct_store_id",
									class: "col-md-12",
								},
							],
						},
					],
				},
			],
		},
	],
	buttons: [
		{
			type: "submit",
			label: "Search",
			align: "right",
			color: "success",
		},
	],
};

export const simplePaymentFormStep2Definition = {
	form: {
		state: false,
		class: "form-vertical",
	},
	fields: {
		struct_store_id: {
			type: "hidden",
			label: "Store ID",
			value: "",
			required: true,
			readOnly: false,
			upperCase: true,
			style: {
				display: "none",
			},
		},
		profile_id: {
			type: "hidden",
			label: "Profile ID",
			value: "",
			minLength: 1,
			maxLength: 255,
			required: true,
			readOnly: false,
			upperCase: true,
			style: {
				display: "none",
			},
		},
		token: {
			type: "select",
			label: "Last Four & Token",
			value: "",
			minLength: 1,
			maxLength: 255,
			required: true,
			readOnly: false,
			upperCase: true,
			options: [],
			style: {
				textTransform: "uppercase",
			},
		},
		amount: {
			type: "ccn_amount",
			label: "Amount",
			value: "",
			decimalLimit: 2,
			integerOnly: false,
			prefix: "$",
			suffix: "",
			minLength: 1,
			maxLength: 255,
			required: true,
			readOnly: false,
			upperCase: true,
			minValue: 0.01,
			style: {
				textTransform: "uppercase",
			},
		},
		total: {
			type: "hidden",
			label: "Total",
			value: "",
			decimalLimit: 2,
			integerOnly: false,
			prefix: "$",
			suffix: "",
			minLength: 1,
			maxLength: 255,
			required: true,
			readOnly: false,
			upperCase: true,
			style: {
				display: "none",
			},
		},
		total_label: {
			type: "typography",
			text: "Total: $0.00",
			variant: "body1",
			className: "text-lg text-gray-500 font-medium mb-0",
			style: {
				backgroundColor: "#f3f4f6",
				color: "#6b7280",
				padding: "0.5rem",
				borderRadius: "0.25rem",
				fontFamily: "inter",
				fontSize: "20px",
				fontWeight: "500",
			},
		},
	},
	layout: [
		{
			type: "div",
			class: "row",
			items: [
				{
					type: "div",
					class: "col-12",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "input",
									name: "struct_store_id",
									class: "col-md-12",
								},
							],
						},
					],
				},
				{
					type: "div",
					class: "col-12",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "input",
									name: "profile_id",
									class: "col-md-12",
								},
							],
						},
					],
				},
				{
					type: "div",
					class: "col-12",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "select",
									name: "token",
									class: "col-md-12",
								},
							],
						},
					],
				},
				{
					type: "div",
					class: "col-12",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "ccn_amount",
									name: "amount",
									class: "col-md-12",
								},
							],
						},
					],
				},
				{
					type: "div",
					class: "col-12",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "number",
									name: "total",
									class: "col-md-12",
								},
							],
						},
					],
				},
				{
					type: "div",
					class: "col-12",
					items: [
						{
							type: "div",
							class: "mb-0",
							items: [
								{
									type: "typography",
									name: "total_label",
									class: "col-md-12",
								},
							],
						},
					],
				},
			],
		},
	],
	buttons: [
		{
			type: "submit",
			label: "Process Transaction",
			align: "right",
			color: "success",
		},
	],
};
