import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html
 */

const CompanyService = {
    /**
     *
     * @param {CompanyView} CompanyView
     * @returns
     */
    view: (CompanyView) => {
        return ApiService.post("/company/index", CompanyView).catch((error) => {
            console.log(error);
            return error;
        });
    },

    /**
     * Company data filter by ID
     * @param {int} id
     * @returns
     */
    fetchById: (id) => {
        return ApiService.get("/company/fetch-by-id", { id: id }).catch(
            (error) => {
                console.log(error);
            }
        );
    },

    /**
     *
     * @param {CompanyCreate} CompanyCreate
     * @returns
     */
    create: (CompanyCreate) => {
        return ApiService.post("/company/create", CompanyCreate).catch(
            (error) => {
                console.log(error);
            }
        );
    },

    /**
     * Update service
     * @param json data {companyId : 1, name: 'xxxx', address_1: 'ADD1' ...}
     * @returns
     */
    update: (data) => {
        return ApiService.post("/company/update", data).catch((error) => {
            console.log(error);
        });
    },

    /**
     *
     * @param {Integer} companyId
     * @returns
     */
    delete: (companyId) => {
        return ApiService.post("/company/delete", {
            companyId: companyId,
        }).catch((error) => {
            console.log(error);
        });
    },
};

export default CompanyService;
