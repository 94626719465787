import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html
 */

const HierarchyService = {
	/**
	 *
	 * @param model
	 * @param model_parent_id
	 * @returns
	 */
	get: (model, model_parent_id) => {
		return ApiService.post("/hierarchy/index", {
			model: model,
			model_parent_id: model_parent_id,
		}).catch((error) => {
			console.log(error);
			return error;
		});
	},
};

export default HierarchyService;
