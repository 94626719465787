import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import ScrollToTop from "../helpers/ScrollToTop";
import Header from "../components/Header";
import AuthHeader from "../components/AuthHeader";
import Footer from "../components/Footer";

import { TokenContext } from "../contexts/TokenContext";

// import { useRouteLoaderData } from "react-router-dom";

function MainLayout() {
    // const token = useRouteLoaderData("main");

    const { token } = useContext(TokenContext);

    //console.log("Token:", token);

    useEffect(() => {
        // Perform any additional actions when the token value changes
        //console.log("Token has changed:", token);
        // ... Other logic or side effects
    }, [token]);

    return (
        <>
            {!token && <Header />}
            {token && <AuthHeader />}
            <ScrollToTop />
            <div style={{ overflowX: "hidden" }}>
                <Outlet />
            </div>
            {token && <Footer />}
        </>
    );
}

export default MainLayout;
