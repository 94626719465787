import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html#Vendor
 */

const VendorService = {
	/**
	 *
	 * @param {VendorView} VendorView
	 * @see /docs/api/index.html#jump-Vendor-index
	 * @returns
	 */
	view: (VendorView) => {
		return ApiService.post("/vendor/index", VendorView).catch((error) => {
			console.log(error);
			return error;
		});
	},

	/**
	 * Delete by ID
	 * @param {int} id
	 * @see /docs/api/index.html#jump-Vendor-delete
	 * @returns
	 */
	delete: (id) => {
		return ApiService.get("/vendor/delete", { id: id }).catch((error) => {
			console.log(error);
		});
	},
};

export default VendorService;
