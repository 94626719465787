import { forwardRef } from "react";
import FormControl from "react-bootstrap/FormControl";

const phoneInput = (props, ref) => {
    return (
        <FormControl
            {...props}
            ref={ref}
            label="Phone number"
            variant="standard"
            name="phone"
            style={{
                marginLeft: "-56px",
                paddingLeft: "56px",
                position: "relative",
                left: "1rem",
                marginRight: "1rem",
            }}
        />
    );
};
export default forwardRef(phoneInput);
