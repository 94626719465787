import React, { useEffect, useState } from "react";

import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import "react-phone-number-input/style.css";
import PhoneInputMui from "../../../common/PhoneInputMui";
// import FormLabel from "react-bootstrap/FormLabel";
// import FormGroup from "react-bootstrap/FormGroup";

const CustomInputPhoneComponent = ({
	name,
	label,
	type,
	value,
	onChange,
	onBlur,
	className,
	required,
	...otherProps
}) => {
	const [internalValue, setInternalValue] = useState(value);
	const [isValid, setIsValid] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [isTouched, setIsTouched] = useState(false);

	useEffect(() => {
		validate();
	}, [value, isTouched]);

	const handleChange = (newValue) => {
		onChange(name, newValue, isValid);
	};

	const validate = () => {
		const validPhoneNumber = isValidPhoneNumber(value);
		const fieldInfo = getFieldInfo();

		if (fieldInfo.readOnly) {
			setIsValid(true);
			setErrorMessage("");
			onChange(name, value, true);
			return;
		}

		if (fieldInfo.required) {
			if (value.length === 0) {
				setIsValid(false);
				setErrorMessage("This field is required.");
			} else {
				if (validPhoneNumber) {
					setIsValid(true);
					setErrorMessage("");
					onChange(name, value, true);
				} else {
					setIsValid(false);
					setErrorMessage("Invalid Phone Number.");
					onChange(name, value, false);
				}
			}
		} else {
			if (value.length === 0) {
				setIsValid(true);
				setErrorMessage("");
			} else {
				if (validPhoneNumber) {
					setIsValid(true);
					setErrorMessage("");
					onChange(name, value, true);
				} else {
					setIsValid(false);
					setErrorMessage("Invalid Phone Number.");
				}
			}
		}
	};

	const handleFocus = () => {
		setIsTouched(true);
		validate();
	};

	const getFieldInfo = () => {
		return {
			required: Boolean(type && type.required),
			minLength: type && type.minLength,
			maxLength: type && type.maxLength,
			readOnly: type && type.readOnly,
			upperCase: type && type.upperCase,
		};
	};

	return (
		<>
			<div className={`${className}`}>
				<PhoneInput
					id={name}
					label={`${label}`}
					required={required}
					defaultCountry="US"
					countries={["US"]}
					addInternationalOption={false}
					displayInitialValueAsLocalNumber={true}
					international={false}
					inputComponent={PhoneInputMui}
					onChange={(e) => handleChange(e)}
					onFocus={handleFocus}
					value={value}
					error={!isValid && isTouched}
					// sx={{
					// 	color: "rgba(0, 0, 0, 0.87) !important", // This should change the text color of the input
					// 	fontFamily:
					// 		'"Roboto", "Helvetica", "Arial", sans-serif !important', // This should change the font family of the input
					// 	// Add any other styles you need here
					// }}
					{...otherProps}
				/>
				{isTouched && !isValid && (
					<p
						className={`MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall Mui-required ${
							otherProps.style.textTransform
								? otherProps.style.textTransform
								: ""
						}`}
					>
						{errorMessage}
					</p>
				)}
			</div>
		</>
	);
};

export default CustomInputPhoneComponent;
