import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Switch } from "@mui/material";
import StoreReportFlagsService from "../../../service/store/StoreReportFlagsService";
import SessionService from "../../../service/SessionService";

function StoreReportFlagsModelTable({
	modelId,
	model,
	activeTab,
	loading,
	setLoading,
}) {
	const [storeProducts, setStoreProducts] = useState([]);

	const fetchStoreReportFlags = async (store_id) => {
		try {
			// Set loading to true
			setLoading(true);

			const response = await StoreReportFlagsService.fetchByStoreId({
				storeId: store_id,
				page: 1,
				limit: 10,
				sort: "description:asc",
			});
			// Filter out non-product properties before mapping
			const productEntries = Object.entries(response.data.data).filter(
				([key, value]) => !["isOk", "msg"].includes(key)
			);
			const productsWithChecked = productEntries.map(
				([key, product]) => ({
					...product,
					checked: product.status_id === 1, // Add a checked property based on status_id
				})
			);
			setStoreProducts(productsWithChecked);

			// Set loading to false
			setLoading(false);
		} catch (error) {
			console.error("Error fetching store report flags:", error);
		}
	};

	useEffect(() => {
		if (modelId !== undefined) {
			fetchStoreReportFlags(modelId);
		}
	}, [modelId]);

	return (
		<>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="Store Report Flags">
					<TableHead>
						<TableRow>
							<TableCell style={{ textAlign: "left" }}>
								Report Name
							</TableCell>
							<TableCell>Status</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{[...storeProducts]
							.sort((a, b) => a.sku - b.sku)
							.map((product, index) => (
								<TableRow key={index}>
									<TableCell style={{ textAlign: "left" }}>
										{product.description}
									</TableCell>
									<TableCell
										style={{
											textAlign: "left",
										}}
									>
										<Switch
											name="store_status_checkbox"
											checked={product.checked}
											onChange={(event) => {
												const currentStatus =
													event.target.checked;
												const statusId = currentStatus
													? 1
													: 0;
												StoreReportFlagsService.update({
													storeReportFlagId:
														product.id,
													statusId: statusId,
												}).then((response) => {
													// Optionally, refresh the products list to reflect the change
													fetchStoreReportFlags(
														modelId
													);
												});
											}}
										/>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}

export default StoreReportFlagsModelTable;
