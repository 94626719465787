import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Breadcrumb } from "react-bootstrap";
import IndexTable from "../../components/common/IndexTable";
import RtoCompanyPaymentsService from "../../service/rtoCompanyPayments/RtoCompanyPaymentsService";

function RtoCompanyPayments({ loading, setLoading }) {
	const pageTitle = "Rto Company Payments | RTO Mobile";

	return (
		<>
			<Helmet>
				<title>{pageTitle}</title>
				<meta name="robots" content="noindex,nofollow"></meta>
				<meta property="og:title" content={pageTitle} />
				<meta name="twitter:title" content={pageTitle} />
			</Helmet>
			<div className="rto_form-sections">
				<div className="rto_content-div" style={{ maxWidth: "1600px" }}>
					<Breadcrumb
						listProps={{ className: "w-100 justify-content-end" }}
					>
						<Breadcrumb.Item
							linkAs={Link}
							linkProps={{ to: "/dashboard" }}
						>
							Dashboard
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkAs={Link}
							linkProps={{ to: "/admintools" }}
						>
							Admin Tools
						</Breadcrumb.Item>
						<Breadcrumb.Item active>
							Rto Company Payments
						</Breadcrumb.Item>
					</Breadcrumb>
					<div className="rto_title-div">
						<h1 className="rto_page-title">Rto Company Payments</h1>
					</div>
					<div
						className="card card-outline card-primary"
						style={{
							borderTop: "3px solid #00aaff",
							boxShadow:
								"0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
						}}
					>
						<div className="card-body">
							<IndexTable
								model={"rto-company-payments"}
								loading={loading}
								setLoading={setLoading}
								service={RtoCompanyPaymentsService}
								title="Rto Company Payments"
								actionButtons={
									{
										// updateLink: {
										// 	path: "/activities",
										// },
										// updateModal: {
										// 	enabled: true,
										// 	path: "/activities/update",
										// },
										// create: {
										// 	enabled: true,
										// 	path: "/activities/create",
										// },
									}
								}
								labels={[
									{
										id: "transaction_number",
										label: "Transaction#",
									},
									{
										id: "mid",
										label: "MID",
									},
									{
										id: "date",
										label: "Date",
									},
									{
										id: "method",
										label: "Method",
									},
									{
										id: "name",
										label: "Name",
									},
									{
										id: "brand",
										label: "Brand",
									},
									{
										id: "last_4",
										label: "Last 4",
									},
									{
										id: "amount",
										label: "Amount",
										currency: true,
									},
									{
										id: "auth_code",
										label: "Auth Code",
									},
									{
										id: "contracts_payment_status_name",
										label: "Status",
									},
								]}
								apiBase="/rto-company-payments"
								successRedirectBase="admintools/rto-company-payments"
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default RtoCompanyPayments;
