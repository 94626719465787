import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html
 */

const ImeiService = {
    /**
     * Get list of phone manufacturers
     * @see /docs/api/index.html#jump-Imei-ManufacturerList
     * @returns JSON
     */
    getManufacturerList: () => {
        return ApiService.get("/imei/manufacturer-list").catch((error) => {
            console.log(error);
        });
    },

    /**
     * Get list of integrations
     * @see /docs/api/index.html#jump-Imei-IntegrationList
     * @returns JSON
     */
    getIntegrationList: () => {
        return ApiService.get("/imei/integration-list").catch((error) => {
            console.log(error);
        });
    },

    /**
     *
     * @param {ImeiCreate} ImeiCreate
     * @returns
     */
    create: (ImeiCreate) => {
        return ApiService.post("/imei/create", ImeiCreate).catch((error) => {
            console.log(error);
        });
    },
};

export default ImeiService;
