import React, { useRef, useState } from "react";
import { useForm, Controller, set } from "react-hook-form";
import { Row, Col } from "react-bootstrap";
import { TextField } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import ActivateService from "../../service/auth/ActivateService";

const schema = z
	.object({
		password: z
			.string()
			.trim()
			.nonempty({ message: "Password cannot be blank!" })
			.max(100, {
				message: "Password cannot be longer than 100 characters!",
			}),
		password_repeat: z
			.string()
			.trim()
			.nonempty({ message: "Repeat Password cannot be blank!" })
			.max(100, {
				message:
					"Repeat Password cannot be longer than 100 characters!",
			}),
		// recaptcha: z
		//     .string({
		//         invalid_type_error: "Please complete the ReCAPTCHA!",
		//     })
		//     .trim()
		//     .nonempty({ message: "Please complete the ReCAPTCHA!" }),
	})
	.refine((data) => data.password === data.password_repeat, {
		message: "Passwords do not match!",
		path: ["password_repeat"],
	});

function ActivateAccountForm() {
	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
		setError,
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			// email: "",
			// recaptcha: "",
		},
		resolver: zodResolver(schema),
	});

	const recaptchaRef = useRef();

	const [formSubmitted, setFormSubmitted] = useState(false);

	const onSubmit = async (data) => {
		// console.log(data);

		// Get the token value from the URL
		const urlParams = new URLSearchParams(window.location.search);
		const token = urlParams.get("token");

		const activateData = {
			ActivateAccountForm: {
				token: token,
				new_password_hash: data.password,
				new_password_repeat_hash: data.password_repeat,
				// reCaptcha: data.recaptcha,
			},
		};

		// console.log(activateData);

		const response = ActivateService.activate(activateData)
			.then((response) => {
				// console.log(response);
				if (response.status === 200 && response.data.isOk === true) {
					// console.log("Success!");
					setFormSubmitted(true);
				} else {
					// console.log("Error!");
					setError("api", {
						type: "server",
						message: response.data.message,
					});
					setError("username", {
						type: "server",
						message: "",
					});
					setError("password", {
						type: "server",
						message: "",
					});
				}
			})
			.catch((error) => {
				// console.log(error);
				setError("api", {
					type: "server",
					message: error.response.data.error,
				});
				setError("username", {
					type: "server",
					message: "",
				});
				setError("password", {
					type: "server",
					message: "",
				});
			});
	};

	return (
		<>
			{formSubmitted ? (
				<div data-w-id="e1bc4a34-b07a-fec3-2d1d-9735d080f1d0">
					<div className="contact-container">
						<div className="center alert alert-success">
							Success!
						</div>
						<p>Your password has been successfully updated!</p>
						<a className="rto_blue-link" href="/">
							Back to login
						</a>
					</div>
				</div>
			) : (
				<form
					id="activate_account_form"
					name="activate-account-form"
					onSubmit={handleSubmit(onSubmit)}
					className="form-vertical"
				>
					<Row>
						<Col lg style={{ marginBottom: "2rem" }}>
							<TextField
								type="password"
								variant="standard"
								label="Password"
								id="password"
								name="password"
								fullWidth
								InputLabelProps={{
									shrink: true,
									className:
										"Mui-form-label Mui-form-label-required",
								}}
								InputProps={{
									className: "Mui-form-input",
								}}
								inputProps={{
									maxLength: 100,
								}}
								{...register("password")}
								error={!!errors.password}
								helperText={errors.password?.message}
								autoFocus
							/>
						</Col>
					</Row>
					<Row>
						<Col lg style={{ marginBottom: "2rem" }}>
							<TextField
								type="password"
								variant="standard"
								label="Repeat Password"
								id="password_repeat"
								name="password_repeat"
								fullWidth
								InputLabelProps={{
									shrink: true,
									className:
										"Mui-form-label Mui-form-label-required",
								}}
								InputProps={{
									className: "Mui-form-input",
								}}
								inputProps={{
									maxLength: 100,
								}}
								{...register("password_repeat")}
								error={!!errors.password_repeat}
								helperText={errors.password_repeat?.message}
							/>
							{(!errors.username || !errors.username.message) &&
								(!errors.password ||
									!errors.password.message) &&
								errors.api && (
									<p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium Mui-required">
										{errors.api.message}
									</p>
								)}
						</Col>
					</Row>
					{/* <Row>
                <Col lg style={{ marginBottom: "1rem" }}>
                    <Controller
                        name="recaptcha"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "Please complete the ReCAPTCHA!",
                        }}
                        render={({ field }) => (
                            <ReCAPTCHA
                                {...field}
                                ref={recaptchaRef}
                                sitekey={
                                    process.env
                                        .REACT_APP_G_RECAPTCHA_SITEKEY
                                }
                                id="recaptcha"
                                onChange={(value) => {
                                    field.onChange(value); // updates react-hook-form
                                }}
                                className="g-recaptcha"
                            />
                        )}
                    />
                    {errors.recaptcha && (
                        <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium Mui-required">
                            {errors.recaptcha.message}
                        </p>
                    )}
                </Col>
            </Row> */}
					<div className="form-group">
						<button
							type="submit"
							className="rto_login-bttn rto_half-bttn w-button"
							name="login-button"
						>
							Submit
						</button>
					</div>
					{/* <DevTool control={control} /> */}
				</form>
			)}
		</>
	);
}

export default ActivateAccountForm;
