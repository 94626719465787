import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./contractsViewTable/ContractsViewTable.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";
import { Button } from "@mui/material";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import PaymentIcon from "@mui/icons-material/Payment";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AddCardIcon from "@mui/icons-material/AddCard";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import ContractAddABPModal from "./modals/ContractAddABPModal";
import ContractMakePaymentModal from "./modals/ContractMakePaymentModal";
import PaymentService from "../../../service/payment/PaymentService";
import ContractService from "../../../service/contract/ContractService";
import { getPaymentImage, parseErrorObject } from "../../../helpers/Utils";

export default function ContractsAbpTable({
	contractDetails,
	loading,
	setLoading,
	refreshContract,
}) {
	const customTheme = createTheme({
		components: {
			MUIDataTableHeadCell: {
				styleOverrides: {
					root: {
						backgroundColor: "#eff3f7",
					},
					paper: {
						borderRadius: "10px",
						overFlow: "hidden",
					},
				},
			},
		},
	});

	const [data, setData] = useState([]);
	const [showContractAddABPModal, setShowContractAddABPModal] =
		useState(false);
	const [showContractMakePaymentModal, setShowContractMakePaymentModal] =
		useState(false);
	const [abpEnabled, setAbpEnabled] = React.useState(
		contractDetails.billing.abp_enabled === 1 ? true : false
	);
	const [tokenizerURL, setTokenizerURL] = useState("");

	const closeContractAddABPModal = () => {
		setShowContractAddABPModal(false);
	};

	const closeContractMakePaymentModal = (refresh) => {
		if (refresh) {
			setShowContractMakePaymentModal(false);
			refreshContract();
		} else {
			setShowContractMakePaymentModal(false);
		}
	};

	const fetchData = async () => {
		setLoading(true);

		try {
			const response = await ContractService.contractGetAbp(
				contractDetails.id
			);
			setData(response?.data?.data?.payment_methods || []);
			setTokenizerURL(response?.data?.data?.tokenizer_url || "");
		} catch (error) {
			console.error("Error fetching ABP data:", error);
		}
		// Set loading state
		setLoading(false);
	};

	useEffect(() => {
		fetchData();
	}, [setLoading]);

	const HeaderElements = () => (
		<>
			{" "}
			<FormControlLabel
				style={{ marginRight: "5rem" }}
				control={
					<Switch
						checked={abpEnabled}
						onChange={handleAbpEnableStatusChange}
					/>
				}
				label="ABP Enabled"
			/>
			<Button
				variant="contained"
				style={{ marginRight: "1rem" }}
				size="small"
				onClick={() => {
					setShowContractMakePaymentModal(true);
				}}
			>
				<PaymentIcon style={{ fontSize: "14px", marginRight: "4px" }} />
				Make Payment
			</Button>
			<Button
				variant="contained"
				size="small"
				onClick={() => setShowContractAddABPModal(true)}
			>
				<AddCardIcon style={{ fontSize: "14px", marginRight: "4px" }} />
				Add New Card
			</Button>
		</>
	);

	const handleAbpEnableStatusChange = async () => {
		if (abpEnabled) {
			return await PaymentService.contractDisableAbp({
				contract_id: contractDetails.id,
			}).then((response) => {
				if (response.status === 200 && response.data.isOk === true) {
					setAbpEnabled((prev) => !prev);
				} else {
					toast.error(
						`Error: ${parseErrorObject(response.data.errors)}`,
						{
							position: "top-center",
							autoClose: 1000,
						}
					);
				}
			});
		} else {
			return await PaymentService.contractEnableAbp({
				contract_id: contractDetails.id,
			}).then((response) => {
				if (response.status === 200 && response.data.isOk === true) {
					setAbpEnabled((prev) => !prev);
				} else {
					toast.error(
						`Error: ${parseErrorObject(response.data.errors)}`,
						{
							position: "top-center",
							autoClose: 1000,
						}
					);
				}
			});
		}
	};

	const handleMakeDefaultAction = async (paymentMethodId) => {
		return await PaymentService.ContractAbpMakeDefault({
			contract_id: contractDetails.id,
			payment_method_id: paymentMethodId,
		})
			.then((response) => {
				if (response.status === 200 && response.data.isOk === true) {
					// show success message
					toast.info(`Payment set as default!`, {
						position: "top-center",
						autoClose: 1000,
					});
					fetchData();
				}
			})
			.catch((error) => {
				toast.error(`Error: ${error.response.data.error}`, {
					position: "top-center",
					autoClose: 1000,
				});
			});
	};

	const handleDeleteAction = (paymentMethodId) => {
		confirmAlert({
			title: "Are you sure?",
			message: "You want to remove this payment?",
			buttons: [
				{
					label: "Yes",
					onClick: () => {
						deletePayment(paymentMethodId);
					},
				},
				{
					label: "No",
				},
			],
		});
	};

	const deletePayment = async (paymentMethodId) => {
		return await PaymentService.contractRemoveAbp({
			contract_id: contractDetails.id,
			payment_method_id: paymentMethodId,
		})
			.then((response) => {
				if (response.status === 200 && response.data.isOk === true) {
					// show success message
					toast.info(`Payment deleted successfully!`, {
						position: "top-center",
						autoClose: 1000,
					});
					fetchData();
				}
			})
			.catch((error) => {
				toast.error(`Error: ${error.response.data.error}`, {
					position: "top-center",
					autoClose: 1000,
				});
			});
	};

	const columns = [
		{
			name: "",
			options: {
				filter: false,
				sort: false,
				display: true,
				viewColumns: false,
				customBodyRenderLite: (dataIndex) => (
					<div
						style={{
							justifyContent: "space-around",
							textAlign: "left",
						}}
					>
						{data[dataIndex].default_acct === "N" && (
							<>
								<Tooltip title="Remove">
									<DeleteIcon
										variant="outlined"
										color="error"
										fontSize="small"
										onClick={() =>
											handleDeleteAction(
												data[dataIndex].id
											)
										}
										sx={{
											border: 1,
											borderRadius: "5px",
											marginRight: ".5rem",
										}}
										style={{ cursor: "pointer" }}
									></DeleteIcon>
								</Tooltip>

								<Tooltip title="Make Default Card">
									<CreditCardIcon
										variant="outlined"
										color="success"
										fontSize="small"
										onClick={() =>
											handleMakeDefaultAction(
												data[dataIndex].id
											)
										}
										sx={{
											border: 1,
											borderRadius: "5px",
											marginRight: ".5rem",
										}}
										style={{ cursor: "pointer" }}
									></CreditCardIcon>
								</Tooltip>
							</>
						)}
					</div>
				),
			},
		},
		{
			name: "name",
			label: "Name",
			options: {
				customBodyRenderLite: (dataIndex) => {
					return (
						<div style={{ whiteSpace: "nowrap" }}>
							{data[dataIndex].name}
						</div>
					);
				},
			},
		},
		{
			name: "cc_last_four",
			label: "Credit Card",
			options: {
				customBodyRenderLite: (dataIndex) => {
					return (
						<div style={{ whiteSpace: "nowrap" }}>
							{getPaymentImage(data[dataIndex].acct_type)} - Ends
							with {data[dataIndex].cc_last_four}
						</div>
					);
				},
			},
		},
		{
			name: "expiry",
			label: "Expiry",
			options: {
				customBodyRenderLite: (dataIndex) => {
					return (
						<div style={{ whiteSpace: "nowrap" }}>
							{data[dataIndex]?.expiry?.replace(
								/(\d{2})(\d{2})/,
								"$1/$2"
							)}
						</div>
					);
				},
			},
		},
		{
			name: "default_acct",
			label: "Default",
			options: {
				customBodyRenderLite: (dataIndex) => {
					return (
						<div style={{ whiteSpace: "nowrap" }}>
							{data[dataIndex].default_acct}
						</div>
					);
				},
			},
		},
	];

	const options = {
		customToolbar: () => <HeaderElements />,
		filter: false,
		elevation: 0,
		// responsive: "standard",
		fixedHeader: true,
		fixedFooter: true,
		selectableRows: "none",
		search: false,
		download: false, // Since you are adding a custom download button
		print: false,
		viewColumns: false,
		sort: false,
		pagination: false, // This line disables pagination and rows per page option
		responsive: "vertical", // or 'stacked' for horizontal scroll
	};

	const filteredData = data.filter((item) => item.id !== null);

	return (
		<>
			<ThemeProvider theme={customTheme}>
				<MUIDataTable
					title={"ABP Management"}
					columns={columns}
					data={filteredData}
					options={options}
				/>
			</ThemeProvider>

			{showContractAddABPModal && (
				<ContractAddABPModal
					show={showContractAddABPModal}
					onHide={closeContractAddABPModal}
					contractId={contractDetails.id}
					tokenizerURL={tokenizerURL}
				></ContractAddABPModal>
			)}

			{showContractMakePaymentModal && (
				<ContractMakePaymentModal
					show={showContractMakePaymentModal}
					onHide={closeContractMakePaymentModal}
					contractId={contractDetails.id}
				></ContractMakePaymentModal>
			)}
		</>
	);
}
