import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html#Report
 */

const PortfolioDetailedService = {
  /**
   * View PortfolioDetailed report data
   * @param array data pass serach filter values if needed
   * @see /docs/api/index.html#jump-Report-PortfolioDetailed
   * @returns
   */
  view: (data, param, signal) => {
    return ApiService.post(
      "/report/portfolio-detailed/index",
      data,
      param,
      signal
    ).catch((error) => {
      console.log(error);
      return error;
    });
  },
};

export default PortfolioDetailedService;
