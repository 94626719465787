import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html#VendorProducts
 */

const VendorProductsService = {
	/**
	 *
	 * @param {VendorProductsView} VendorProductsView
	 * @see /docs/api/index.html#jump-VendorProducts-index
	 * @returns
	 */
	view: (VendorProductsView) => {
		return ApiService.post(
			"/vendor-products/index",
			VendorProductsView
		).catch((error) => {
			console.log(error);
			return error;
		});
	},

	/**
	 * Delete by ID
	 * @param {int} id
	 * @see /docs/api/index.html#jump-VendorProducts-delete
	 * @returns
	 */
	delete: (id) => {
		return ApiService.get("/vendor-products/delete", { id: id }).catch(
			(error) => {
				console.log(error);
			}
		);
	},
};

export default VendorProductsService;
