import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

/**
 * USAGE:
 * "option_field" => [
				"type" => "multi-select",
				"label" => "Select Options",
				"value" => [],
				"options" => [
					["label" => "Option 1", "value" => "option1"],
					["label" => "Option 2", "value" => "option2"],
					["label" => "Option 3", "value" => "option3"],
				],
				"required" => true,
				"readOnly" => false,
				"upperCase" => true,
				"style" => [
					"textTransform" => "uppercase"
				]
			],
 */

const CustomMultiSelectComponent = ({
	name,
	label,
	type,
	value,
	options,
	onChange,
	onBlur,
	className,
	...otherProps
}) => {
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [isValid, setIsValid] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [isTouched, setIsTouched] = useState(false);

	useEffect(() => {
		const newDefaultValues = options.filter((option) =>
			value.includes(option.value)
		);
		setSelectedOptions(newDefaultValues);
	}, [value, options]);

	const getFieldInfo = () => {
		return {
			readOnly: (type && type?.readOnly) || false,
			required: (type && type?.required) || false,
		};
	};

	const handleSelectionChange = (event, newValue) => {
		setIsTouched(true);
		setSelectedOptions(newValue);
		validate();
	};

	const validate = () => {
		const fieldInfo = getFieldInfo();
		if (fieldInfo.readOnly) {
			setIsValid(true);
			setErrorMessage("");
			onChange(name, selectedOptions, true);
			return;
		}

		if (fieldInfo.required && selectedOptions.length === 0) {
			setIsValid(false);
			setErrorMessage("This field is required.");
			onChange(name, selectedOptions, false);
			return;
		}

		setIsValid(true);
		setErrorMessage("");
		onChange(name, selectedOptions, true);
	};

	return (
		<div className={`${className}`}>
			<Autocomplete
				multiple
				options={options}
				getOptionLabel={(option) => option.label}
				value={selectedOptions}
				onChange={handleSelectionChange}
				readOnly={getFieldInfo().readOnly}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="standard"
						label={label}
						error={!isValid && isTouched}
						helperText={!isValid && isTouched && errorMessage}
						className={
							getFieldInfo().readOnly ? "readonly-text" : ""
						}
					/>
				)}
				// Disable the popup indicator if readOnly is true
				popupIcon={getFieldInfo().readOnly ? null : undefined}
				{...otherProps}
			/>
		</div>
	);
};

export default CustomMultiSelectComponent;
