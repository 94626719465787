import React from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import CardComponent from "../../components/common/CardComponent";
import BluePlusIcon from "../../images/blue-plus.svg";
const SearchResults = () => {
	const location = useLocation();
	const { data } = location.state || {};

	/*useEffect(() => {
	// console.log("State Data: ", data.data.query);
}, []);*/

	const pageTitle = "Search Results | RTO Mobile";
	return (
		<div>
			<Helmet>
				<title>{pageTitle}</title>
				<meta name="robots" content="noindex,nofollow"></meta>
				<meta property="og:title" content={pageTitle} />
				<meta name="twitter:title" content={pageTitle} />
			</Helmet>
			<div className="rto_form-sections">
				<div className="rto_content-div rto_900">
					<Breadcrumb
						listProps={{ className: "w-100 justify-content-end" }}
					>
						<Breadcrumb.Item
							// linkAs={Link}
							// linkProps={{ to: "/dashboard" }}
							href="/dashboard"
						>
							Dashboard
						</Breadcrumb.Item>
						<Breadcrumb.Item active>Search Results</Breadcrumb.Item>
					</Breadcrumb>
					<div className="rto_title-div">
						<h1
							className="rto_page-title"
							style={{ marginBottom: "50px" }}
						>
							Search Results
						</h1>
						{/* <ChangePasswordForm
				loading={loading}
				setLoading={setLoading}
				></ChangePasswordForm> */}
						<div
							className="rto_options-container"
							style={{ marginBottom: "50px" }}
						>
							<a
								href="/contracts/create"
								className="rto_link-block w-inline-block search-results-block"
								style={{
									marginLeft: "auto",
									marginRight: "auto",
								}}
							>
								<div className="rto_div-block-4">
									<img
										src={BluePlusIcon}
										alt=""
										className="rto_image"
									/>
									<div className="rto_text-block-2">
										Create New Contract
									</div>
								</div>
							</a>
						</div>
						{data?.data?.query?.length > 0 ? (
							<Row className="g-4">
								{data.data.query.map((item) => (
									<Col xs={12} md={6} key={item.id}>
										<CardComponent
											name={item.customer_name}
											contractId={item.contract_id}
											status={item.contract_status}
											imei={item.imei}
											serial={item.serial}
											outstandingBalance={
												item.outstanding_balance_net
											}
											dueDate={
												item.payment_due_date
													? item.payment_due_date
													: "N/A"
											}
										/>
									</Col>
								))}
							</Row>
						) : (
							<div
								className="rto_title-div-2 not-found-text"
								style={{ width: "100%", textAlign: "start" }}
							>
								<h4>
									<b>No results found.</b>
								</h4>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SearchResults;
