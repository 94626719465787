import React from "react";
import { Helmet } from "react-helmet";
import { Breadcrumb } from "react-bootstrap";
import ChangePasswordForm from "../../components/forms/ChangePasswordForm";

const ChangePassword = ({ loading, setLoading }) => {
  const pageTitle = "Change Password | RTO Mobile";
  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="robots" content="noindex,nofollow"></meta>
        <meta property="og:title" content={pageTitle} />
        <meta name="twitter:title" content={pageTitle} />
      </Helmet>
      <div className="rto_form-sections">
        <div className="rto_content-div rto_900">
          <Breadcrumb listProps={{ className: "w-100 justify-content-end" }}>
            <Breadcrumb.Item
              // linkAs={Link}
              // linkProps={{ to: "/dashboard" }}
              href="/dashboard"
            >
              Dashboard
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Change Password</Breadcrumb.Item>
          </Breadcrumb>
          <div className="rto_title-div">
            <h1 className="rto_page-title" style={{ marginBottom: "50px" }}>
              Change Password
            </h1>
            <ChangePasswordForm loading={loading} setLoading={setLoading}></ChangePasswordForm>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
