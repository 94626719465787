import React from 'react';
import {Breadcrumb} from 'react-bootstrap';
import {Link} from 'react-router-dom';

const Breadcrumbs = ({breadcrumbs}) => {
    return (
        <Breadcrumb listProps={{className: "w-100 justify-content-end"}}>
            {breadcrumbs.map((breadcrumb, index) => (
                <Breadcrumb.Item
                    key={index}
                    linkAs={index < breadcrumbs.length - 1 ? Link : undefined}
                    linkProps={index < breadcrumbs.length - 1 ? {to: breadcrumb.to} : undefined}
                    active={index === breadcrumbs.length - 1}
                >
                    {breadcrumb.label}
                </Breadcrumb.Item>
            ))}
        </Breadcrumb>
    );
};

export default Breadcrumbs;
