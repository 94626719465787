// DateRangeColumn.jsx
import React from "react";
import { DateRangePicker } from "./date-range-picker-mui";
import { generateGridStructOptions } from "../../helpers/Utils";

export const createDateRangeColumn = (
	tableColumns,
	DataView,
	filters,
	setFilters,
	name,
	label,
	initialDisplay = true,
	displayTime = false
) => {
	return {
		name,
		label,
		options: {
			...generateGridStructOptions(name, tableColumns, initialDisplay),
			filterType: "custom",
			filterOptions: {
				names: [],
				logic(name, filters) {
					if (typeof filters !== "object") return false;
					const createdDate = new Date(name);
					const startDate = new Date(filters.from);
					const endDate = filters.to
						? new Date(filters.to)
						: startDate;
					return createdDate >= startDate && createdDate <= endDate;
				},
				display: (filterList, onChange, index, column) => (
					<DateRangePicker
						onUpdate={(values) => {
							const formatDate = (date) => {
								if (!date) return "";
								const d = new Date(date);
								const month = d.getMonth() + 1;
								const day = d.getDate();
								const year = d.getFullYear();
								return `${year}-${month}-${day}`;
							};
							const startDate = formatDate(values.range.from);
							const endDate = formatDate(values.range.to);
							const dateRangeObj = !endDate
								? { from: startDate, to: startDate }
								: { from: startDate, to: endDate };

							filterList[index][0] = dateRangeObj;

							DataView.filter = {
								...DataView.filter,
								ranges: {
									...DataView.filter.ranges,
									[`${name}`]: dateRangeObj,
								},
							};

							const updatedFilters = {
								...filters,
								ranges: {
									...filters.ranges,
									[`${name}`]: dateRangeObj,
								},
							};

							setFilters(updatedFilters);

							onChange(filterList[index], index, column);
						}}
						label={label}
						initialDateFrom={
							filterList[index][0]
								? filterList[index][0]["from"]
								: ""
						}
						initialDateTo={
							filterList[index][0]
								? filterList[index][0]["to"]
								: ""
						}
						align="end"
						locale="en-GB"
						showCompare={false}
					/>
				),
			},
			customBodyRender: (value) => {
				if (!value) return <div className="whitespace-nowrap"></div>;
				let [year, month, day] = value.split("-");
				let formattedDate = `${parseInt(month, 10)}/${parseInt(
					day,
					10
				)}/${year}`;

				if (displayTime === true) {
					// eslint-disable-next-line no-unused-vars
					let [date, time] = value.split(" ");
					formattedDate = `${formattedDate} ${time}`;
				}

				return <div className="whitespace-nowrap">{formattedDate}</div>;
			},
			customFilterListOptions: {
				render: (v) => {
					if (Array.isArray(v) && v.length > 0) {
						const dateRanges = v.map((dateRange) => {
							const formatDate = (dateString) => {
								const date = new Date(dateString);
								return `${(
									date.getMonth() + 1
								).toString()}/${date
									.getDate()
									.toString()}/${date.getFullYear()}`;
							};

							if (dateRange.from && dateRange.to) {
								const from = formatDate(dateRange.from);
								const to = formatDate(dateRange.to);

								if (from === to) {
									return from;
								}
								return `${from} - ${to}`;
							}
							return "";
						});
						return dateRanges.join(", ");
					}
					return false;
				},
			},
		},
	};
};
