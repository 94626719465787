import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html#RtoCompanyPaymentMethods
 */

const RtoCompanyPaymentMethodsService = {
	/**
	 *
	 * @param {RtoCompanyPaymentMethodsView} RtoCompanyPaymentMethodsView
	 * @see /docs/api/index.html#jump-RtoCompanyPaymentMethods-View
	 * @returns
	 */
	view: (RtoCompanyPaymentMethodsView) => {
		return ApiService.post("/rto-company-payment-methods/index", RtoCompanyPaymentMethodsView).catch((error) => {
			console.log(error);
			return error;
		});
	},

	/**
	 * data filter by ID
	 * @param {int} id
	 * @see /docs/api/index.html#jump-RtoCompanyPaymentMethods-FetchById
	 * @returns
	 */
	fetchById: (id) => {
		return ApiService.get("/rto-company-payment-methods/fetch-by-id", { id: id }).catch(
			(error) => {
				console.log(error);
			}
		);
	},

	/**
	 * Update service
	 * @see /docs/api/index.html#jump-RtoCompanyPaymentMethods-Update
	 * @param json data {rtoCompanyPaymentMethodsId : 1, name: 'xxxx', address_1: 'ADD1' ...}
	 * @returns
	 */
	update: (data) => {
		return ApiService.post("/rto-company-payment-methods/update", data).catch((error) => {
			console.log(error);
		});
	},

	/**
	 *
	 * @param {RtoCompanyPaymentMethodsCreate} RtoCompanyPaymentMethodsCreate
	 * @see /docs/api/index.html#jump-RtoCompanyPaymentMethods-Create
	 * @returns
	 */
	create: (RtoCompanyPaymentMethodsCreate) => {
		return ApiService.post("/rto-company-payment-methods/create", RtoCompanyPaymentMethodsCreate).catch((error) => {
			console.log(error);
		});
	},
};

export default RtoCompanyPaymentMethodsService;
