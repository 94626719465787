import { redirect } from "react-router-dom";
import SessionService from "../service/SessionService";

export function getAuthToken() {
    //const token = sessionStorage.getItem("token");
    //return token;
    return SessionService.getJWTToken();
}

export function tokenLoader() {
    return new Promise((resolve, reject) => {
        const token = getAuthToken();
        resolve(token);
    });
}

export function checkAuthLoader() {
    const token = getAuthToken();

    if (!token) {
		//@todo - check alternate react router redirect
		localStorage.setItem("referralUrl", document.location.href);
		//console.log(document.location.href);
        return redirect("/login");
    }

    return null;
}
