import React from "react";
import { useState } from "react";
import TopModal from "../../../common/TopModal";
//import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { z, ZodError } from "zod";

import ContractService from "../../../../service/contract/ContractService";
import CustomInputComponent from "../../../formComponents/rtoDynamicForm/fields/CustomInputComponent";

const MySchema = z.object({
	first_name: z
		.string()
		.trim()
		.nonempty({ message: "First name cannot be blank!" })
		.max(255, {
			message: "First name cannot be longer than 255 characters!",
		}),
	last_name: z
		.string()
		.trim()
		.nonempty({ message: "Last name cannot be blank!" })
		.max(255, {
			message: "Last name cannot be longer than 255 characters!",
		}),
});

const ContractNameEditModal = ({
	show,
	onHide,
	contractNameData,
	setContractNameData,
	contractId,
}) => {
	const [errors, setErrors] = useState({
		first_name: "",
		last_name: "",
	});
	//const [error, setError] = useState(false);

	const onUpdate = () => {
		const data = {
			contract_id: contractId,
			update: {
				first_name: contractNameData?.first_name,
				last_name: contractNameData?.last_name,
			},
		};
		//const response = ContractService.update(data).then((response) => {
		ContractService.update(data).then((response) => {
			if (response.status === 200 && response.data.isOk === true) {
				onHide(true);
			} else {
				setErrors(response.data.errors);
			}
		});
	};

	const handleInputChange =
		(fieldName, value, pIsValid, field) => (event) => {
			setContractNameData({
				...contractNameData,
				[field]: value,
			});

			try {
				const fieldSchema = MySchema.pick({
					[field]: MySchema.shape[field],
				});

				fieldSchema.parse({ [field]: value });

				setErrors({
					...errors,
					[field]: "",
				});
			} catch (error) {
				if (error instanceof ZodError) {
					// Handle ZodError
					const fieldError = error.errors.find(
						(err) => err.path[0] === field
					);
					if (fieldError) {
						setErrors({
							...errors,
							[field]: fieldError.message,
						});
					}
				}
			}
		};

	return (
		<TopModal
			isVisible={show}
			body={
				<>
					<Box sx={{ flexGrow: 1 }}>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								{/* <TextField
									id="outlined-basic"
									label="First Name"
									variant="standard"
									value={contractNameData?.first_name}
									InputLabelProps={{
										shrink: true,
									}}
									placeholder="First Name"
									fullWidth
									onChange={handleInputChange("first_name")}
									error={Boolean(errors.first_name)}
									helperText={errors.first_name}
								/> */}
								<CustomInputComponent
									name={"first_name"}
									label={"First Name"}
									value={contractNameData?.first_name}
									type={{ required: true }}
									onChange={(fieldName, value, pIsValid) => {
										handleInputChange(
											fieldName,
											value,
											pIsValid,
											"first_name"
										);
									}}
									InputLabelProps={{
										shrink: true,
									}}
									className={""}
									required={true}
									error={Boolean(errors.first_name)}
									helperText={errors.first_name}
								/>
							</Grid>
							<Grid item xs={6}>
								<CustomInputComponent
									name={"last_name"}
									label={"Last Name"}
									value={contractNameData?.last_name}
									type={{ required: true }}
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(fieldName, value, pIsValid) => {
										handleInputChange(
											fieldName,
											value,
											pIsValid,
											"last_name"
										);
									}}
									className={""}
									required={true}
									error={Boolean(errors.last_name)}
									helperText={errors.last_name}
								/>
								{/* <TextField
									id="outlined-basic"
									label="Last Name"
									variant="standard"
									value={contractNameData?.last_name}
									InputLabelProps={{
										shrink: true,
									}}
									placeholder="Last Name"
									fullWidth
									onChange={handleInputChange("last_name")}
									error={Boolean(errors.last_name)}
									helperText={errors.last_name}
								/> */}
							</Grid>
						</Grid>
					</Box>
				</>
			}
			title={`Edit Name`}
			footer={
				<>
					<Button variant="contained" onClick={onUpdate}>
						{" "}
						Update{" "}
					</Button>
				</>
			}
			onClose={onHide}
			// loading={modalLoading}
		></TopModal>
	);
};

export default ContractNameEditModal;
