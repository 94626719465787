import ApiService from "../ApiService";

const ChangePasswordService = {
  reset: (changeData) => {
    return ApiService.post("/auth/change-password", changeData).catch(
      (error) => {
        console.log(error);
      }
    );
    //@todo: for error , create a common alert box to show the errors
  },
};

export default ChangePasswordService;
