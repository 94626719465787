import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Breadcrumb } from "react-bootstrap";
// import ContactsTable from "../../components/backend/contacts/ContactsTable";
import ContactService from "../../service/contact/ContactService";
import IndexTable from "../../components/common/IndexTable";

function Contacts({ loading, setLoading }) {
	const pageTitle = "Contacts | RTO Mobile";

	return (
		<>
			<Helmet>
				<title>{pageTitle}</title>
				<meta name="robots" content="noindex,nofollow"></meta>
				<meta property="og:title" content={pageTitle} />
				<meta name="twitter:title" content={pageTitle} />
			</Helmet>
			<div className="rto_form-sections">
				<div className="rto_content-div" style={{ maxWidth: "1600px" }}>
					<Breadcrumb
						listProps={{ className: "w-100 justify-content-end" }}
					>
						<Breadcrumb.Item
							linkAs={Link}
							linkProps={{ to: "/dashboard" }}
						>
							Dashboard
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkAs={Link}
							linkProps={{ to: "/admin" }}
						>
							Admin
						</Breadcrumb.Item>
						<Breadcrumb.Item active>Contacts</Breadcrumb.Item>
					</Breadcrumb>
					<div className="rto_title-div">
						<h1 className="rto_page-title">Contacts</h1>
					</div>
					<div
						className="card card-outline card-primary"
						style={{
							borderTop: "3px solid #00aaff",
							boxShadow:
								"0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
						}}
					>
						<div className="card-body">
							{/* <ContactsTable
								loading={loading}
								setLoading={setLoading}
							/> */}
							<IndexTable
								model={"contacts"}
								loading={loading}
								setLoading={setLoading}
								service={ContactService}
								title="Contacts"
								actionButtons={{
									updateLink: {
										path: "/contacts",
									},
									// updateModal: {
									// 	enabled: true,
									// 	path: "/contacts/update",
									// },
									// create: {
									// 	enabled: true,
									// 	path: "/contacts/create",
									// },
								}}
								labels={[
									{
										id: "id",
										label: "ID",
									},
									{
										id: "struct_iso_id",
										label: "ISO ID",
										excluded: true,
									},
									{
										id: "struct_master_agent_id",
										label: "Master Agent ID",
										excluded: true,
									},
									{
										id: "struct_organization_id",
										label: "Organization ID",
										excluded: true,
									},
									{
										id: "struct_company_id",
										label: "Company ID",
										excluded: true,
									},
									{
										id: "struct_store_id",
										label: "Store ID",
										excluded: true,
									},
									{
										id: "leads_id",
										label: "Leads ID",
										excluded: true,
									},
									{
										id: "onboarding_id",
										label: "Onboarding ID",
										excluded: true,
									},
									{
										id: "model_name",
										label: "Name",
										excluded: true,
									},
									{
										id: "model_name",
										label: "Name",
										model_link: true,
									},
									{
										id: "first_name",
										label: "First Name",
									},
									{
										id: "last_name",
										label: "Last Name",
									},
									{
										id: "phone",
										label: "Phone",
									},
									{
										id: "email",
										label: "Email",
									},
									{
										id: "points_of_contact_name",
										label: "Title",
									},
									{
										id: "created_at",
										label: "Created At",
									},
									{
										id: "created_by_username",
										label: "Created By",
									},
								]}
								apiBase="/contact"
								successRedirectBase="contacts"
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Contacts;
