import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html
 */

const EventService = {
	/**
	 *
	 * @param {IsoView} IsoView
	 * @returns
	 */
	get: (contractId) => {
		return ApiService.post("/events/get", {
			contract_id: contractId,
		}).catch((error) => {
			console.log(error);
			return error;
		});
	},
};

export default EventService;
