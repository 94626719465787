import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";

const CustomSelectComponent = ({
	name,
	label,
	type,
	value,
	onChange,
	onBlur,
	className,
	...otherProps
}) => {
	const [internalValue, setInternalValue] = useState(
		value !== null && value !== undefined ? value : ""
	);
	const [isValid, setIsValid] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [isTouched, setIsTouched] = useState(false);

	useEffect(() => {
		setInternalValue(value !== null && value !== undefined ? value : "");
	}, [value]);

	useEffect(() => {
		validate();
	}, [internalValue]);

	const handleChange = (newValue) => {
		setInternalValue(newValue !== null && newValue !== undefined ? newValue : "");
		onChange(name, newValue, isValid);
	};

	const validate = () => {
		const fieldInfo = getFieldInfo();

		let trimmedValue = internalValue;

		if (trimmedValue.length === 0) {
			trimmedValue = ""; //handle 0 value
		}

		setInternalValue(trimmedValue);

		if (fieldInfo.readOnly) {
			setIsValid(true);
			setErrorMessage("");
			onChange(name, trimmedValue, true);
			return;
		}

		// Check required
		if (fieldInfo.required && trimmedValue === "") {
			setIsValid(false);
			setErrorMessage("This field is required.");
			onChange(name, trimmedValue, false);
			return;
		}

		setIsValid(true);
		setErrorMessage("");
		onChange(name, trimmedValue, true);
	};

	const handleFocus = () => {
		setIsTouched(true);
		validate();
	};

	const getFieldInfo = () => {
		return {
			required: Boolean(type && type.required),
			readOnly: type && type.readOnly,
			upperCase: type && type.upperCase,
			options: type && type.options,
		};
	};

	return (
		<div className={`${className}`}>
			<FormControl
				variant="standard"
				fullWidth
				error={!isValid && isTouched}
				size="small"
				sx={{ color: "rgba(0, 0, 0, 0.87)" }} // Adjust the color to match other fields
				{...otherProps}
			>
				<InputLabel
					id={`${name}-label`}
					shrink={internalValue.toString().length !== 0}
					className={`${
						otherProps.style?.textTransform
							? otherProps.style?.textTransform
							: ""
					}`}
				>
					{label}
				</InputLabel>
				<Select
					labelId={`${name}-label`}
					id={name}
					value={internalValue}
					onChange={(e) => handleChange(e.target.value)}
					label={label}
					readOnly={getFieldInfo().readOnly}
					onFocus={handleFocus}
					MenuProps={{
						PaperProps: {
							sx: {
								// This targets the dropdown items
								".MuiMenuItem-root": {
									color: "rgba(0, 0, 0, 0.87) !important", // This should change the text color of the dropdown items
									fontFamily:
										'"Roboto", "Helvetica", "Arial", sans-serif !important',
								},
							},
						},
					}}
					// Use the sx prop for more specific styling
					sx={{
						// This targets the text of the selected item
						".MuiSelect-select": {
							color: "rgba(0, 0, 0, 0.87) !important", // This should change the text color of the selected item
							fontFamily:
								'"Roboto", "Helvetica", "Arial", sans-serif !important',
						},
						// This targets the dropdown icon
						"& .MuiSvgIcon-root": {
							color: "rgba(0, 0, 0, 0.87) !important", // This changes the color of the dropdown icon
						},
						// This targets the input element directly for the text color
						"& .MuiInputBase-input": {
							color: "rgba(0, 0, 0, 0.87) !important", // This should change the text color of the input
							fontFamily:
								'"Roboto", "Helvetica", "Arial", sans-serif !important', // This should change the font family of the input
						},
					}}
					{...otherProps}
				>
					{Array.isArray(type?.options) &&
						type.options.map((option, index) => (
							<MenuItem
								key={`${option.id}-${index}`}
								value={option.id}
								className={`${
									otherProps.style?.textTransform
										? otherProps.style?.textTransform
										: ""
								}`}
								style={
									otherProps.style &&
									otherProps.style?.textTransform
										? {
												textTransform:
													otherProps.style
														?.textTransform,
										  }
										: {}
								}
							>
								{option.name}
							</MenuItem>
						))}
				</Select>
				{!isValid && isTouched && (
					<FormHelperText
						className={`${
							otherProps.style?.textTransform
								? otherProps.style?.textTransform
								: ""
						}`}
					>
						{errorMessage}
					</FormHelperText>
				)}
			</FormControl>
		</div>
	);
};

export default CustomSelectComponent;
