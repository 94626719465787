import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html
 */

const MasterAgentService = {
    /**
     *
     * @param {View} View
     * @returns
     */
    view: (View) => {
        return ApiService.post("/master-agent/index", View).catch((error) => {
            console.log(error);
            return error;
        });
    },

    /**
     * MasterAgent data filter by ID
     * @param {int} id
     * @returns
     */
    fetchById: (id) => {
        return ApiService.get("/master-agent/fetch-by-id", { id: id }).catch(
            (error) => {
                console.log(error);
            }
        );
    },

    /**
     *
     * @param {Create} Create
     * @returns
     */
    create: (Create) => {
        return ApiService.post("/master-agent/create", Create).catch(
            (error) => {
                console.log(error);
            }
        );
    },

    /**
     * Update service
     * @param json data {masterAgentId : 1, name: 'xxxx', address_1: 'ADD1' ...}
     * @returns
     */
    update: (data) => {
        return ApiService.postFormData("/master-agent/update", data).catch(
            (error) => {
                console.log(error);
            }
        );
    },

    /**
     *
     * @param {Integer} masterAgentId
     * @returns
     */
    delete: (masterAgentId) => {
        return ApiService.post("/master-agent/delete", {
            masterAgentId: masterAgentId,
        }).catch((error) => {
            console.log(error);
        });
    },
};

export default MasterAgentService;
