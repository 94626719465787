import React, { useState, useEffect } from "react";
import CustomInputNumberComponent from "./CustomInputNumberComponent";

const CustomMinMaxDefaultComponent = ({
	target_min,
	target_max,
	defaultName,
	defaultLabel,
	type,
	defaultValue,
	onChange,
	formState,
	...otherProps
}) => {
	const [def, setDef] = useState(defaultValue);
	const [errors, setErrors] = useState({});

	useEffect(() => {
		const valid = validateValue(def, target_min, target_max);
		onChange(defaultName, def, valid);
	}, [target_min, target_max, def]);

	useEffect(() => {
		setDef(defaultValue);
	}, [defaultValue]);

	const handleDefaultChange = (name, value) => {
		setDef(value);
		const valid = validateValue(value, target_min, target_max);
		onChange(name, value, valid);
	};

	const validateValue = (value, min, max) => {
		let error = {};

		if (value === "") {
			setErrors({});
			return true;
		}

		const numValue = parseFloat(value);
		const numMin = parseFloat(formState[target_min]?.value || 0);
		const numMax = parseFloat(formState[target_max]?.value || 99999);
		const numDefault = parseFloat(formState[defaultName]?.value || 0);

		if (isNaN(numValue)) {
			error.default = "Value must be a number.";
		} else {
			if (numMin > numMax) {
				error.default = "Minimum cannot be greater than the maximum.";
			} else if (numMax < numMin) {
				error.default = "Maximum cannot be less than the minimum.";
			} else if (numValue < numMin || numValue > numMax) {
				error.default = `Value must be between ${numMin} and ${numMax}.`;
			} else if (numMin > numDefault) {
				error.default = "Minimum cannot be greater than the default.";
			} else if (numMax < numDefault) {
				error.default = "Maximum cannot be less than the default.";
			}
		}

		if (JSON.stringify(errors) !== JSON.stringify(error)) {
			setErrors(error);
			console.log("Validation errors:", error);
		}

		return Object.keys(error).length === 0;
	};

	return (
		<CustomInputNumberComponent
			name={defaultName}
			label={defaultLabel}
			value={def}
			onChange={handleDefaultChange}
			error={!!errors.default}
			helperText={errors.default}
			type={{
				...type,
				suffix: type?.suffix,
				decimalLimit: (type && type?.decimalLimit) || 0,
			}}
			// Pass the type object with the suffix
			{...otherProps}
		/>
	);
};

export default CustomMinMaxDefaultComponent;
