import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import StoresForm from "../../components/forms/StoresForm";

function Stores({ loading, setLoading }) {
    const pageTitle = "Create New Store | RTO Mobile";

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="robots" content="noindex,nofollow"></meta>
                <meta property="og:title" content={pageTitle} />
                <meta name="twitter:title" content={pageTitle} />
            </Helmet>
            <div className="rto_form-sections">
                <div className="rto_content-div">
                    <Breadcrumb
                        listProps={{ className: "w-100 justify-content-end" }}
                    >
                        <Breadcrumb.Item
                            linkAs={Link}
                            linkProps={{ to: "/dashboard" }}
                        >
                            Dashboard
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkAs={Link}
                            linkProps={{ to: "/settings" }}
                        >
                            Settings
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkAs={Link}
                            linkProps={{ to: "/settings/stores" }}
                        >
                            Stores
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Create</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="rto_title-div">
                        <h1 className="rto_page-title">Create New Store</h1>
                    </div>
                    <StoresForm loading={loading} setLoading={setLoading} />
                </div>
            </div>
        </>
    );
}

export default Stores;
