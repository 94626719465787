import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html#VendorManufacturers
 */

const VendorManufacturersService = {
	/**
	 *
	 * @param {VendorManufacturersView} VendorManufacturersView
	 * @see /docs/api/index.html#jump-VendorManufacturers-index
	 * @returns
	 */
	view: (VendorManufacturersView) => {
		return ApiService.post(
			"/vendor-manufacturers/index",
			VendorManufacturersView
		).catch((error) => {
			console.log(error);
			return error;
		});
	},

	/**
	 * Delete by ID
	 * @param {int} id
	 * @see /docs/api/index.html#jump-VendorManufacturers-delete
	 * @returns
	 */
	delete: (id) => {
		return ApiService.get("/vendor-manufacturers/delete", { id: id }).catch(
			(error) => {
				console.log(error);
			}
		);
	},
};

export default VendorManufacturersService;
