import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html
 */

const OrganizationService = {
    /**
     *
     * @param {View} View
     * @returns
     */
    view: (View) => {
        return ApiService.post("/organization/index", View).catch((error) => {
            console.log(error);
            return error;
        });
    },

    /**
     * Organization data filter by ID
     * @param {int} id
     * @returns
     */
    fetchById: (id) => {
        return ApiService.get("/organization/fetch-by-id", { id: id }).catch(
            (error) => {
                console.log(error);
            }
        );
    },

    /**
     *
     * @param {Create} Create
     * @returns
     */
    create: (Create) => {
        return ApiService.post("/organization/create", Create).catch(
            (error) => {
                console.log(error);
            }
        );
    },

    /**
     * Update service
     * @param json data {organizationId : 1, name: 'xxxx', address_1: 'ADD1' ...}
     * @returns
     */
    update: (data) => {
        return ApiService.post("/organization/update", data).catch((error) => {
            console.log(error);
        });
    },

    /**
     *
     * @param {Integer} organizationId
     * @returns
     */
    delete: (organizationId) => {
        return ApiService.post("/organization/delete", {
            organizationId: organizationId,
        }).catch((error) => {
            console.log(error);
        });
    },
};

export default OrganizationService;
