import React, { useEffect, useState, useRef } from "react";
import MUIDataTable from "mui-datatables";
import { toast } from "react-toastify";
import { FormSelect } from "react-bootstrap";
import { DateRangePicker } from "../../common/date-range-picker";
import SessionService from "../../../service/SessionService";
// import PaymentService from "../../../service/report/PaymentService";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { NumericFormat } from "react-number-format";
import StoreEndOfDayService from "../../../service/report/StoreEndOfDayService";
// import Form from "react-bootstrap/Form";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";

const theme = createTheme({
	components: {
		MuiPopover: {
			styleOverrides: {
				paper: {
					padding: "1rem",
					maxWidth: "500px", // Set the width of the dialog window
					maxHeight: "500px", // Set the max height of the dialog window
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontWeight: "600",
					textTransform: "none",
					whiteSpace: "nowrap",
				},
			},
		},
	},
});

const StoreEndOfDayTable = ({ loading, setLoading }) => {
	const [users, setUsers] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [rowCount, setRowCount] = useState(10);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	// const [dateRangeCreated, setDateRangeCreated] = useState("");
	const [filterList, setFilterList] = useState({});
	const [sortList, setSortList] = useState("date:asc");
	const [tableState, setTableState] = useState({});
	const [dropdowns, setDropdowns] = useState({});
	const [searchText, setSearchText] = useState("");
	const [lastAction, setLastAction] = useState("");
	const [filters, setFilters] = useState({});
	const tableRef = useRef(null);
	const [isIsoColumnVisible, setIsIsoColumnVisible] = useState(true);
	const [isMasterAgentVisible, setIsMasterAgentVisible] = useState(true);
	const [isOrganizationVisible, setIsOrganizationVisible] = useState(true);
	const [isCompanyNameVisible, setIsCompanyNameVisible] = useState(true);
	const [isStoreNameVisible, setIsStoreNameVisible] = useState(true);
	const [combine, setCombine] = useState(1); // State to track the checkbox
	const [isAddressVisible, setIsAddressVisible] = useState(false);
	const [isCityVisible, setIsCityVisible] = useState(false);
	const [isStateVisible, setIsStateVisible] = useState(false);
	const [isZipVisible, setIsZipVisible] = useState(false);

	const getRowProps = (row, dataIndex, rowIndex) => {
		// Check if it's the last row
		if (rowIndex === users.length - 1) {
			return {
				style: { backgroundColor: "#f2f2f2" },
			};
		}
		return {};
	};

	const addSummaryObject = (data, sums) => {
		const summaryObject = {
			date: "",
			payment_type_id: "",
			payment_type_name: "",
			payment_group: "",
			merchant_id: "",
			batch_id: "",
			total_amount: sums?.total_amount,
			total_tax: sums?.total_tax,
			total_dealer_feee: sums?.total_dealer_feee,
			total_total: sums?.total_dealer_feee,
			events: "",
			iso_id: "",
			iso_name: "",
			master_agent_id: "",
			master_agent_name: "",
			organization_id: "",
			organization_name: "",
			company_id: "",
			company_name: "",
			store_id: "",
			store_name: "",
		};
		// console.log(summaryObject);
		return [...data, summaryObject];
	};
	// Function to format the date in MM/DD/YYYY format
	const formatDate = (date) => {
		let day = date.getDate();
		let month = date.getMonth() + 1; // Months are 0-indexed
		let year = date.getFullYear();

		return `${month}/${day}/${year}`;
	};

	// Calculate the first day of the current month and today's date
	const today = new Date();
	const firstDayCurrentMonth = new Date(
		today.getFullYear(),
		today.getMonth(),
		1
	);

	// Format the date range string for the current month so far
	const initialDateRange = `${formatDate(
		firstDayCurrentMonth
	)} - ${formatDate(today)}`;

	// Initialize the dateRangeCreated state with the calculated date range
	const [dateRangeCreated, setDateRangeCreated] = useState(initialDateRange);

	const PaymentView = {
		page: currentPage, // <-- Set the default page
		limit: rowCount, // <-- Set the default row count
		payment_date: dateRangeCreated,
		sort: sortList,
		filter: {
			date: dateRangeCreated,
		},
		combineAllPaymentTypeFlag: combine,
	};

	function isEmpty(obj) {
		return Object.keys(obj).length === 0 && obj.constructor === Object;
	}

	useEffect(() => {
		sendRequest(null, true, dateRangeCreated);
	}, [combine]);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const response = await StoreEndOfDayService.view(PaymentView);

				// Display an error message if needed
				if (response.status !== 200) {
					// console.log("response", response);
					toast.error(`Error fetching Leads: ${response.message}`, {
						position: "top-center",
						autoClose: 1000,
					});
				}

				const users = response?.data?.data?.query
					? response?.data?.data?.query
					: [];
				const rowCount = parseInt(response?.data?.data?.query?.length);
				const totalCount = parseInt(response.data.total?.count);
				const currentPage = parseInt(response.data.page);
				const dropdowns = response.data.dropdowns;

				setUsers(addSummaryObject(users, response?.data?.total?.sums));

				setTotalCount(totalCount);
				setCurrentPage(currentPage);
				setTotalPages(totalPages);
				setRowCount(rowCount);
				setRowsPerPage(rowsPerPage);
				setDropdowns(dropdowns);
			} catch (error) {
				console.error("Error fetching users:", error);
			}
			setLoading(false);
		};

		fetchData();
	}, []);

	const sendRequest = async (filterList, isFilterChange, dateRangeStr) => {
		try {
			const PaymentView = {
				page: tableState.page + 1,
				limit: tableState.rowsPerPage,
				payment_date: dateRangeCreated,
				filter: {},
				sort: sortList,
				combineAllPaymentTypeFlag: combine,
			};

			if (tableState.sortOrder.hasOwnProperty("name")) {
				let sortOrderDirection = tableState.sortOrder.direction
					? tableState.sortOrder.direction
					: "asc";
				PaymentView.sort = `${tableState.sortOrder.name}:${sortOrderDirection}`;
			} else {
				PaymentView.sort = "date:desc";
			}
			setSortList(PaymentView.sort);

			if (tableState.searchText) {
				if (isFilterChange) {
					PaymentView.filter = {};
					setSearchText("");
				} else {
					PaymentView.filter = tableState.searchText;
				}
			}

			if (isFilterChange) {
				let newFilterList = [];

				if (filterList) {
					newFilterList = filterList;
				} else {
					newFilterList = tableState.filterList;
				}

				//Filter
				if (newFilterList) {
					if (newFilterList[0][0]) {
						PaymentView.filter.iso_name = newFilterList[0][0];
					}

					if (newFilterList[1][0]) {
						PaymentView.filter.master_agent_name =
							newFilterList[1][0];
					}

					if (newFilterList[2][0]) {
						PaymentView.filter.organization_name =
							newFilterList[2][0];
					}

					if (newFilterList[3][0]) {
						PaymentView.filter.company_name = newFilterList[3][0];
					}

					if (newFilterList[4][0]) {
						PaymentView.filter.store_name = newFilterList[4][0];
					}

					if (newFilterList[5][0]) {
						if (dateRangeStr) {
							PaymentView.filter.date = dateRangeStr;
						} else {
							PaymentView.filter.date = newFilterList[5][0];
						}
					} else {
						PaymentView.filter.date = dateRangeStr;
						// console.log("sending requessssttt", PaymentView);
					}

					if (newFilterList[6][0]) {
						PaymentView.filter.merchant_id = newFilterList[6][0];
					}

					if (newFilterList[7][0]) {
						PaymentView.filter.batch_id = newFilterList[7][0];
					}

					if (newFilterList[8][0]) {
						PaymentView.filter.payment_type_name =
							newFilterList[8][0];
					}

					if (newFilterList[9][0]) {
						PaymentView.filter.total_amount = newFilterList[9][0];
					}

					if (newFilterList[10][0]) {
						PaymentView.filter.total_tax = newFilterList[10][0];
					}

					if (newFilterList[11][0]) {
						PaymentView.filter.total_dealer_feee =
							newFilterList[11][0];
					}

					if (newFilterList[12][0]) {
						PaymentView.filter.total_total = newFilterList[12][0];
					}

					setFilterList(PaymentView.filter);
				}
			}

			if (isFilterChange === false) {
				if (tableRef.current) {
					if (isEmpty(filters) === false) {
						tableRef.current.resetFilters();
					}
				}
			}

			setLoading(true);
			const response = await StoreEndOfDayService.view(PaymentView);
			const users = response?.data?.data?.query
				? response?.data?.data?.query
				: [];
			const rowCount = parseInt(response?.data?.data?.query?.length);
			const totalCount = parseInt(response.data.total?.count);
			const currentPage = parseInt(response.data.page);
			const dropdowns = response.data.dropdowns;

			setUsers(addSummaryObject(users, response?.data?.total?.sums));

			setTotalCount(totalCount);
			setCurrentPage(currentPage);
			setTotalPages(totalPages);
			setRowCount(rowCount);
			setRowsPerPage(tableState.rowsPerPage);
			setDropdowns(dropdowns);
			setLoading(false);
		} catch (error) {
			console.error("Error fetching users:", error);
		}
	};

	useEffect(() => {
		const handleClickOutside = async (event) => {
			if (lastAction === "search") {
				sendRequest(null, false, dateRangeCreated);
				setLastAction("");
				setFilters({});
			}
		};

		const handleEnterRelease = async (event) => {
			if (event.key === "Enter" && lastAction === "search") {
				sendRequest(null, false, dateRangeCreated);
				setLastAction("");
				setFilters({});
			}
			if (event.key === "Tab" && lastAction === "search") {
				sendRequest(null, false, dateRangeCreated);
				setLastAction("");
				setFilters({});
			}
		};

		// Add event listener
		document.addEventListener("click", handleClickOutside);
		document.addEventListener("keyup", handleEnterRelease);
		// Clean up
		return () => {
			document.removeEventListener("click", handleClickOutside);
			document.removeEventListener("keyup", handleEnterRelease);
		};
	}, [searchText, lastAction]);

	useEffect(() => {
		const handleClickOutside = async (event) => {
			if (lastAction === "filterChange") {
				sendRequest(null, true, dateRangeCreated);
				setLastAction("");
			}
		};

		const handleEnterRelease = async (event) => {
			if (event.key === "Enter" && lastAction === "filterChange") {
				sendRequest(null, true, dateRangeCreated);
				setLastAction("");
			}
			if (event.key === "Tab" && lastAction === "filterChange") {
				sendRequest(null, true, dateRangeCreated);
				setLastAction("");
			}
		};

		// Add event listener
		document.addEventListener("click", handleClickOutside);
		document.addEventListener("keyup", handleEnterRelease);
		// Clean up
		return () => {
			document.removeEventListener("click", handleClickOutside);
			document.removeEventListener("keyup", handleEnterRelease);
		};
	}, [filters, lastAction]);

	// let userHierarchy = SessionService.getUserHierarchy();
	// let stores = userHierarchy.sto_list ? userHierarchy.sto_list : {};

	const handleTableChange = async (action, newTableState) => {
		if (JSON.stringify(tableState) !== JSON.stringify(newTableState)) {
			setTableState(newTableState);
		}

		if (action === "search") {
			setSearchText(newTableState.searchText);
			setLastAction(action);
		}

		if (action === "filterChange") {
			// Assuming filters are structured as an object with column names as keys
			setFilters(
				newTableState.filterList.reduce((acc, filter, index) => {
					acc[newTableState.columns[index].name] = filter;
					return acc;
				}, {})
			);
			setLastAction(action);
		}

		if (
			action === "changePage" ||
			action === "changeRowsPerPage" ||
			action === "sort" ||
			action === "resetFilters"
		) {
			try {
				const PaymentView = {
					page: newTableState.page + 1,
					limit: newTableState.rowsPerPage,
					payment_date: dateRangeCreated,
					filter: {},
					sort: sortList,
					combineAllPaymentTypeFlag: combine,
				};

				if (newTableState.sortOrder.hasOwnProperty("name")) {
					let sortOrderDirection = newTableState.sortOrder.direction
						? newTableState.sortOrder.direction
						: "asc";
					PaymentView.sort = `${newTableState.sortOrder.name}:${sortOrderDirection}`;
				} else {
					PaymentView.sort = "date:desc";
				}
				setSortList(PaymentView.sort);

				if (newTableState.searchText) {
					if (newTableState.searchText.length > 2) {
						PaymentView.filter = newTableState.searchText;
					} else {
						return false;
					}
				}

				//Filter
				if (newTableState.filterList) {
					if (newTableState.filterList[0][0]) {
						PaymentView.filter.iso_name =
							newTableState.filterList[0][0];
					}

					if (newTableState.filterList[1][0]) {
						PaymentView.filter.master_agent_name =
							newTableState.filterList[1][0];
					}

					if (newTableState.filterList[2][0]) {
						PaymentView.filter.organization_name =
							newTableState.filterList[2][0];
					}

					if (newTableState.filterList[3][0]) {
						PaymentView.filter.company_name =
							newTableState.filterList[3][0];
					}

					if (newTableState.filterList[4][0]) {
						PaymentView.filter.store_name =
							newTableState.filterList[4][0];
					}

					if (newTableState.filterList[5][0]) {
						if (dateRangeCreated) {
							PaymentView.filter.date = dateRangeCreated;
						} else {
							PaymentView.filter.date =
								newTableState.filterList[5][0];
						}
					} else {
						PaymentView.filter.date = dateRangeCreated;
						// console.log("sending requessssttt", PaymentView);
					}

					if (newTableState.filterList[6][0]) {
						PaymentView.filter.merchant_id =
							newTableState.filterList[6][0];
					}

					if (newTableState.filterList[7][0]) {
						PaymentView.filter.batch_id =
							newTableState.filterList[7][0];
					}

					if (newTableState.filterList[8][0]) {
						PaymentView.filter.payment_type_name =
							newTableState.filterList[8][0];
					}

					if (newTableState.filterList[9][0]) {
						PaymentView.filter.total_amount =
							newTableState.filterList[9][0];
					}

					if (newTableState.filterList[10][0]) {
						PaymentView.filter.total_tax =
							newTableState.filterList[10][0];
					}

					if (newTableState.filterList[11][0]) {
						PaymentView.filter.total_dealer_feee =
							newTableState.filterList[11][0];
					}

					if (newTableState.filterList[12][0]) {
						PaymentView.filter.total_total =
							newTableState.filterList[12][0];
					}

					setFilterList(PaymentView.filter);
				}
				setLoading(true);
				const response = await StoreEndOfDayService.view(PaymentView);
				const users = response?.data?.data?.query
					? response?.data?.data?.query
					: [];
				const rowCount = parseInt(response?.data?.data?.query?.length);
				const totalCount = response.data.total?.count;
				const currentPage = parseInt(response.data.page);
				const dropdowns = response.data.dropdowns;

				setUsers(addSummaryObject(users, response?.data?.total?.sums));

				setTotalCount(totalCount);
				setCurrentPage(currentPage);
				setTotalPages(totalPages);
				setRowCount(rowCount);
				setRowsPerPage(newTableState.rowsPerPage);
				setDropdowns(dropdowns);
				setLoading(false);
			} catch (error) {
				console.error("Error fetching users:", error);
			}
		}
	};

	let allowIsoAdmin =
		SessionService.canSystemAdmin() ||
		SessionService.canRtoAdmin() ||
		SessionService.canIsoAdmin();
	let allowMasterAgentAdmin =
		allowIsoAdmin || SessionService.canMasterAgentAdmin();
	let allowOrganizationAdmin =
		allowMasterAgentAdmin || SessionService.canOrganizationAdmin();
	let allowCompanyAdmin =
		allowOrganizationAdmin || SessionService.canCompanyAdmin();
	let allowStoreAdmin = allowCompanyAdmin || SessionService.canStoreAdmin();

	const columns = [
		{
			name: "iso_name",
			label: "ISO",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: allowIsoAdmin === true ? true : false,
				display:
					allowIsoAdmin === true
						? isIsoColumnVisible
							? true
							: false
						: "excluded",
				filterType: "custom",
				customBodyRender: (value) => {
					return (
						<div className="whitespace-nowrap">
							{value ? value : ""}
						</div>
					);
				},
			},
		},
		{
			name: "master_agent_name",
			label: "Master Agent",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: allowMasterAgentAdmin === true ? true : false,
				display:
					allowMasterAgentAdmin === true
						? isMasterAgentVisible
							? true
							: false
						: "excluded",
				filterType: "custom",
				filterOptions: {
					names: [], // You can provide filter options here if needed
					display: (filterList, onChange, index, column) => (
						<>
							<label
								className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 font-sans mb-1"
								style={{ fontWeight: 500 }}
							>
								Master Agent
							</label>
							<FormSelect
								name="master_agent_name"
								onChange={(event) => {
									if (event.target.value === "") {
										// Clear the filter for this column if the default option is selected
										filterList[index] = [];
									} else {
										// Set the filter value to the selected option
										filterList[index][0] =
											event.target.value;
									}
									onChange(filterList[index], index, column);
								}}
								className="form-select form-select-sm font-sans"
								value={filterList[index][0] || ""}
							>
								<option value="">All</option>
								{dropdowns?.master_agent_name?.map((dd) => (
									<option value={dd.name} key={dd.id}>
										{dd.name}
									</option>
								))}
							</FormSelect>
						</>
					),
				},
				customBodyRender: (value) => {
					return (
						<div className="whitespace-nowrap">
							{value ? value : ""}
						</div>
					);
				},
			},
		},
		{
			name: "organization_name",
			label: "Organization",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: allowOrganizationAdmin === true ? true : false,
				display:
					allowOrganizationAdmin === true
						? isOrganizationVisible
							? true
							: false
						: "excluded",
				filterType: "custom",
				filterOptions: {
					names: [], // You can provide filter options here if needed
					display: (filterList, onChange, index, column) => (
						<>
							<label
								className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 font-sans mb-1"
								style={{ fontWeight: 500 }}
							>
								Organization
							</label>
							<FormSelect
								name="organization_name"
								onChange={(event) => {
									if (event.target.value === "") {
										// Clear the filter for this column if the default option is selected
										filterList[index] = [];
									} else {
										// Set the filter value to the selected option
										filterList[index][0] =
											event.target.value;
									}
									onChange(filterList[index], index, column);
								}}
								className="form-select form-select-sm font-sans"
								value={filterList[index][0] || ""}
							>
								<option value="">All</option>
								{dropdowns?.organization_name?.map((dd) => (
									<option value={dd.name} key={dd.id}>
										{dd.name}
									</option>
								))}
							</FormSelect>
						</>
					),
				},
				customBodyRender: (value) => {
					return (
						<div className="whitespace-nowrap">
							{value ? value : ""}
						</div>
					);
				},
			},
		},
		{
			name: "company_name",
			label: "Company",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: allowCompanyAdmin === true ? true : false,
				display:
					allowCompanyAdmin === true
						? isCompanyNameVisible
							? true
							: false
						: "excluded",
				filterType: "custom",
				filterOptions: {
					names: [], // You can provide filter options here if needed
					display: (filterList, onChange, index, column) => (
						<>
							<label
								className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 font-sans mb-1"
								style={{ fontWeight: 500 }}
							>
								Company
							</label>
							<FormSelect
								name="company_name"
								onChange={(event) => {
									if (event.target.value === "") {
										// Clear the filter for this column if the default option is selected
										filterList[index] = [];
									} else {
										// Set the filter value to the selected option
										filterList[index][0] =
											event.target.value;
									}
									onChange(filterList[index], index, column);
								}}
								className="form-select form-select-sm font-sans"
								value={filterList[index][0] || ""}
							>
								<option value="">All</option>
								{dropdowns?.company_name?.map((dd) => (
									<option value={dd.name} key={dd.id}>
										{dd.name}
									</option>
								))}
							</FormSelect>
						</>
					),
				},
				customBodyRender: (value) => {
					return (
						<div className="whitespace-nowrap">
							{value ? value : ""}
						</div>
					);
				},
			},
		},
		{
			name: "store_name",
			label: "Store",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: allowStoreAdmin === true ? true : false,
				display:
					allowStoreAdmin === true
						? isStoreNameVisible
							? true
							: false
						: "excluded",
				filterType: "custom",
				filterOptions: {
					names: [], // You can provide filter options here if needed
					display: (filterList, onChange, index, column) => (
						<>
							<label
								className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 font-sans mb-1"
								style={{ fontWeight: 500 }}
							>
								Store
							</label>
							<FormSelect
								name="store_name"
								onChange={(event) => {
									if (event.target.value === "") {
										// Clear the filter for this column if the default option is selected
										filterList[index] = [];
									} else {
										// Set the filter value to the selected option
										filterList[index][0] =
											event.target.value;
									}
									onChange(filterList[index], index, column);
								}}
								className="form-select form-select-sm font-sans"
								value={filterList[index][0] || ""}
							>
								<option value="">All</option>
								{dropdowns?.store_name?.map((dd) => (
									<option value={dd.name} key={dd.id}>
										{dd.name}
									</option>
								))}
							</FormSelect>
						</>
					),
				},
				customBodyRender: (value) => {
					return (
						<div className="whitespace-nowrap">
							{value ? value : ""}
						</div>
					);
				},
			},
		},
		{
			name: "date",
			label: "Date",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: false, // Enable filtering for this column
				filterType: "custom", // Set filter type as custom
				filterOptions: {
					names: [], // You can provide filter options here if needed
					logic(date, filters) {
						if (typeof filters !== "string") return false;
						let [startDate, endDate] = filters.split(" - ");
						if (!endDate) endDate = startDate;
						const createdDate = new Date(date);
						startDate = new Date(startDate);
						endDate = new Date(endDate);
						return (
							createdDate >= startDate && createdDate <= endDate
						);
					},
					display: (filterList, onChange, index, column) => (
						<DateRangePicker
							onUpdate={(values) => {
								const formatDate = (date) => {
									if (!date) return ""; // return empty string if date is not valid
									const d = new Date(date);
									const month = d.getMonth() + 1; // getMonth() is zero-based
									const day = d.getDate();
									const year = d.getFullYear();
									return `${month}/${day}/${year}`;
								};

								const startDate = formatDate(values.range.from);
								const endDate = formatDate(values.range.to);
								const dateRangeStr = !endDate
									? startDate // if endDate is empty, just use startDate
									: startDate === endDate
									? startDate // if they are the same, use startDate
									: startDate + " - " + endDate; // otherwise, use both
								setDateRangeCreated(dateRangeStr);
								filterList[index][0] = dateRangeStr;
								onChange(filterList[index], index, column);
							}}
							label="Date"
							initialDateFrom={
								filterList[index][0]
									? filterList[index][0].split(" - ")[0]
									: ""
							}
							initialDateTo={
								filterList[index][0]
									? filterList[index][0].split(" - ")[1]
									: ""
							}
							align="end"
							locale="en-GB"
							showCompare={false}
						/>
					),
				},
				customBodyRender: (value) => {
					const date = new Date(value);
					const formattedDate = date.toLocaleDateString("en-US", {
						year: "numeric",
						month: "numeric",
						day: "numeric",
					});
					return (
						<div className="whitespace-nowrap">
							{value ? formattedDate : ""}
						</div>
					);
				},
			},
		},
		{
			name: "merchant_id",
			label: "Merchant Id",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
			},
		},
		{
			name: "batch_id",
			label: "Batch Id",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
			},
		},
		{
			name: "payment_type_name",
			label: "Payment Type",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				customBodyRender: (value, tableMeta, updateValue) => {
					// Check the state and decide what to display
					// console.log(tableMeta);
					const displayValue =
						combine === 1 ? tableMeta.rowData[13] : value;
					return <div>{displayValue}</div>;
				},
			},
		},

		{
			name: "total_amount",
			label: "Amount",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: true,
				customBodyRender: (value, tableMeta) => {
					if (tableMeta.rowIndex === users.length - 1) {
						return (
							<strong>
								<div
									style={{
										display: "flex",
										justifyContent: "right",
									}}
								>
									<NumericFormat
										value={value}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
										decimalScale={2}
										fixedDecimalScale={true}
									/>
								</div>
							</strong>
						);
					}
					return (
						<div
							style={{ display: "flex", justifyContent: "right" }}
						>
							<NumericFormat
								value={value}
								displayType={"text"}
								thousandSeparator={true}
								prefix={"$"}
								decimalScale={2}
								fixedDecimalScale={true}
							/>
						</div>
					);
				},
			},
		},
		{
			name: "total_tax",
			label: "Sales Tax",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: true,
				customBodyRender: (value, tableMeta) => {
					if (tableMeta.rowIndex === users.length - 1) {
						return (
							<strong>
								<div
									style={{
										display: "flex",
										justifyContent: "right",
									}}
								>
									<NumericFormat
										value={value}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
										decimalScale={2}
										fixedDecimalScale={true}
									/>
								</div>
							</strong>
						);
					}
					return (
						<div
							style={{ display: "flex", justifyContent: "right" }}
						>
							<NumericFormat
								value={value}
								displayType={"text"}
								thousandSeparator={true}
								prefix={"$"}
								decimalScale={2}
								fixedDecimalScale={true}
							/>
						</div>
					);
				},
			},
		},
		{
			name: "total_dealer_feee",
			label: "Fee",
			options: {
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				filter: true,
				customBodyRender: (value, tableMeta) => {
					if (tableMeta.rowIndex === users.length - 1) {
						return (
							<strong>
								<div
									style={{
										display: "flex",
										justifyContent: "right",
									}}
								>
									<NumericFormat
										value={value}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
										decimalScale={2}
										fixedDecimalScale={true}
									/>
								</div>
							</strong>
						);
					}
					return (
						<div
							style={{ display: "flex", justifyContent: "right" }}
						>
							<NumericFormat
								value={value}
								displayType={"text"}
								thousandSeparator={true}
								prefix={"$"}
								decimalScale={2}
								fixedDecimalScale={true}
							/>
						</div>
					);
				},
			},
		},
		{
			name: "total_total",
			label: "Total",
			options: {
				filter: true,
				setCellHeaderProps: () => ({
					style: { backgroundColor: "#f2f2f2" },
				}),
				customBodyRender: (value, tableMeta) => {
					if (tableMeta.rowIndex === users.length - 1) {
						return (
							<strong>
								<div
									style={{
										display: "flex",
										justifyContent: "right",
									}}
								>
									<NumericFormat
										value={value}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
										decimalScale={2}
										fixedDecimalScale={true}
									/>
								</div>
							</strong>
						);
					}
					return (
						<div
							style={{ display: "flex", justifyContent: "right" }}
						>
							<NumericFormat
								value={value}
								displayType={"text"}
								thousandSeparator={true}
								prefix={"$"}
								decimalScale={2}
								fixedDecimalScale={true}
							/>
						</div>
					);
				},
			},
		},
		{
			name: "payment_group",
			label: "Payment Group",
			options: { display: false, filter: false },
		},
	];

	const options = {
		customToolbar: () => {
			<p>I'm a toolbar</p>;
		},
		filter: true,
		filterType: "textField",
		searchText: searchText,
		setRowProps: getRowProps,
		onFilterChipClose: (removedFilterIndex, value, filterList) => {
			sendRequest(filterList, true, dateRangeCreated);
			setLastAction("");
		},
		onViewColumnsChange: (changedColumn, action) => {
			if (changedColumn === "iso_name") {
				setIsIsoColumnVisible(action === "add");
			} else if (changedColumn === "master_agent_name") {
				setIsMasterAgentVisible(action === "add");
			} else if (changedColumn === "organization_name") {
				setIsOrganizationVisible(action === "add");
			} else if (changedColumn === "company_name") {
				setIsCompanyNameVisible(action === "add");
			} else if (changedColumn === "store_name") {
				setIsStoreNameVisible(action === "add");
			} else if (changedColumn === "zip") {
				setIsZipVisible(action === "add");
			} else if (changedColumn === "state") {
				setIsStateVisible(action === "add");
			} else if (changedColumn === "city") {
				setIsCityVisible(action === "add");
			} else if (changedColumn === "address") {
				setIsAddressVisible(action === "add");
			}
		},
		serverSide: true,
		count: totalCount,
		page: currentPage - 1,
		rowsPerPage: rowsPerPage,
		rowsPerPageOptions: [
			10,
			25,
			50,
			100,
			{ label: "All", value: totalCount },
		],
		elevation: 0,
		responsive: "standard",
		fixedHeader: true,
		fixedFooter: true,
		// tableBodyHeight: "calc(100vh - 150px)",
		// customTableStyle: {
		//     maxHeight: "calc(100vh - 150px)",
		// },
		selectableRows: "none",
		onTableChange: handleTableChange,
		onDownload: (buildHead, buildBody, columns, data) => {
			// Fetch all data that matches the applied filters
			const fetchAllData = async () => {
				// Use the same parameters as in the handleTableChange function
				const PaymentView = {
					page: 1,
					limit: totalCount,
					payment_date: dateRangeCreated,
					filter: filterList,
					sort: sortList,
					combineAllPaymentTypeFlag: combine,
				};

				// Add filters based on the current table state
				if (tableState.filterList) {
					if (tableState.filterList[0][0]) {
						PaymentView.filter.iso_name =
							tableState.filterList[0][0];
					}

					if (tableState.filterList[1][0]) {
						PaymentView.filter.master_agent_name =
							tableState.filterList[1][0];
					}

					if (tableState.filterList[2][0]) {
						PaymentView.filter.organization_name =
							tableState.filterList[2][0];
					}

					if (tableState.filterList[3][0]) {
						PaymentView.filter.company_name =
							tableState.filterList[3][0];
					}

					if (tableState.filterList[4][0]) {
						PaymentView.filter.store_name =
							tableState.filterList[4][0];
					}

					if (tableState.filterList[5][0]) {
						if (dateRangeCreated) {
							PaymentView.filter.date = dateRangeCreated;
						} else {
							PaymentView.filter.date =
								tableState.filterList[5][0];
						}
					} else {
						PaymentView.filter.date = dateRangeCreated;
						// console.log("sending requessssttt", PaymentView);
					}

					if (tableState.filterList[6][0]) {
						PaymentView.filter.merchant_id =
							tableState.filterList[6][0];
					}

					if (tableState.filterList[7][0]) {
						PaymentView.filter.batch_id =
							tableState.filterList[7][0];
					}

					if (tableState.filterList[8][0]) {
						PaymentView.filter.payment_type_name =
							tableState.filterList[8][0];
					}

					if (tableState.filterList[9][0]) {
						PaymentView.filter.total_amount =
							tableState.filterList[9][0];
					}

					if (tableState.filterList[10][0]) {
						PaymentView.filter.total_tax =
							tableState.filterList[10][0];
					}

					if (tableState.filterList[11][0]) {
						PaymentView.filter.total_dealer_feee =
							tableState.filterList[11][0];
					}

					if (tableState.filterList[12][0]) {
						PaymentView.filter.total_total =
							tableState.filterList[12][0];
					}
				}

				const response = await StoreEndOfDayService.view(PaymentView);
				return response.data.data.query;
			};

			fetchAllData().then((allData) => {
				// Check if allData is defined
				if (!allData) {
					console.error("allData is undefined");
					return false;
				}

				// get checked columns
				const checkedColumns = tableState.columns.filter(
					(column) => column.display === "true"
				);

				// Transform the checked columns to the format required by buildHead
				const transformedColumns = checkedColumns.map((column) => ({
					...column,
				}));

				// remove first column, null empty
				// transformedColumns.shift();

				// Check if there are no columns selected
				if (transformedColumns.length === 0) {
					// Optionally display a message to the user
					toast.error("No columns selected for download", {
						position: "top-center",
						autoClose: 2000,
					});
					return false;
				}

				// Transform data to the required format
				const transformedData = allData
					.map((item, index) => {
						if (item) {
							return {
								index: index,
								data: transformedColumns.map(
									(column) => item[column.name]
								),
							};
						}
						return null;
					})
					.filter((item) => item !== null);

				// Generate CSV content
				const csvContent =
					buildHead(transformedColumns) + buildBody(transformedData);

				var fileDownload = require("js-file-download");
				fileDownload(csvContent, "export.csv", "text/csv");
			});

			// Return false to prevent the default CSV export
			return false;
		},
	};

	const CustomTitle = () => (
		<>
			<Typography variant="h6">End Of Day Report</Typography>
			<Box>
				<Typography
					variant="subtitle1"
					style={{
						marginTop: "5px",
						fontWeight: "500",
						fontSize: "14px",
						fontFamily:
							'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
					}}
				>
					Contract Origination Date
				</Typography>
				<DateRangePicker
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "start",
						alignItems: "start",
					}}
					onUpdate={(values) => {
						const formatDate = (date) => {
							if (!date) return ""; // return empty string if date is not valid
							const d = new Date(date);
							const month = d.getMonth() + 1; // getMonth() is zero-based
							const day = d.getDate();
							const year = d.getFullYear();
							return `${month}/${day}/${year}`;
						};

						const startDate = formatDate(values.range.from);
						const endDate = formatDate(values.range.to);
						const dateRangeStr = !endDate
							? startDate // if endDate is empty, just use startDate
							: startDate === endDate
							? startDate // if they are the same, use startDate
							: startDate + " - " + endDate; // otherwise, use both

						setDateRangeCreated(dateRangeStr);

						sendRequest(null, true, dateRangeStr);

						const currentFilters =
							tableRef.current.state.filterList;
						// console.log("filterrrrr list", currentFilters);
						const updatedFilters = [...currentFilters];
						updatedFilters[5] = [];
						tableRef.current.setState({
							...tableRef.current.state,
							filterList: updatedFilters,
						});

						// filterList[index][0] = dateRangeStr;
						// onChange(filterList[index], index, column);
					}}
					// label="Payment Date Range"
					// align="end"
					initialDateFrom={
						dateRangeCreated ? dateRangeCreated.split(" - ")[0] : ""
					}
					initialDateTo={
						dateRangeCreated ? dateRangeCreated.split(" - ")[1] : ""
					}
					locale="en-GB"
					showCompare={false}
				/>
			</Box>
			<FormControlLabel
				control={
					<Checkbox
						color="primary"
						checked={combine === 1}
						onChange={(event) =>
							setCombine(event.target.checked ? 1 : 0)
						}
					/>
				}
				label="Combine Credit Card Payment Types"
			/>
		</>
	);

	return (
		<div>
			<ThemeProvider theme={theme}>
				<MUIDataTable
					title={<CustomTitle />}
					data={users}
					columns={columns}
					options={options}
					ref={tableRef}
				/>
			</ThemeProvider>
		</div>
	);
};

export default StoreEndOfDayTable;
