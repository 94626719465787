import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import MUIDataTable from "mui-datatables";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import ActivityService from "../../../service/activity/ActivityService";
import { toast } from "react-toastify";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { createDropdownColumn } from "../../common/DropdownColumn";
import { createDateRangeColumn } from "../../common/DateRangeColumn";
import { generateGridStructOptions } from "../../../helpers/Utils";
import {
	Button,
	FormControl,
	FormGroup,
	FormLabel,
	FormSelect,
} from "react-bootstrap";
import Feedback from "react-bootstrap/esm/Feedback";
import { DevTool } from "@hookform/devtools";
import { Button as DialogButton } from "../../../@rto/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "../../../@rto/components/ui/dialog";
import { FaPlus } from "react-icons/fa6";
import { getModelUrl } from "../../../helpers/ModelHelper";

const theme = createTheme({
	components: {
		MuiPopover: {
			styleOverrides: {
				paper: {
					padding: "1rem",
					maxWidth: "500px", // Set the width of the dialog window
					maxHeight: "500px", // Set the max height of the dialog window
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontWeight: "600",
					textTransform: "none",
					whiteSpace: "nowrap",
				},
			},
		},
	},
});

const schema = z.object({
	activities_type_id: z
		.string()
		.trim()
		.nonempty({ message: "Activity Type cannot be blank!" }),
	description: z
		.string()
		.trim()
		.nonempty({ message: "Description cannot be blank!" }),
});

const ActivitiesTable = ({
	modelId,
	model,
	activeTab,
	loading,
	setLoading,
}) => {
	const {
		register,
		control,
		handleSubmit,
		formState: {
			errors,
			touchedFields,
			isSubmitted,
			isSubmitting,
			isValid,
			isDirty,
		},
		setError,
		reset,
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			description: "",
			activities_type_id: "",
		},
		resolver: zodResolver(schema),
	});

	const [data, setData] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [rowCount, setRowCount] = useState(10);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [filterList, setFilterList] = useState({});
	const [sortList, setSortList] = useState("id:desc");
	const [tableState, setTableState] = useState({});
	const [tableColumns, setTableColumns] = useState([]);
	const [searchText, setSearchText] = useState("");
	const [lastAction, setLastAction] = useState("");
	const [filters, setFilters] = useState({});
	const [currentActivity, setCurrentActivity] = useState(null);
	const [activityTypes, setActivityTypes] = useState([]);
	const tableRef = useRef(null);
	const renderCount = useRef(0);

	const ActivityView = {
		page: currentPage, // <-- Set the default page
		limit: rowCount, // <-- Set the default row count
		sort: sortList,
		filter: filterList,
		modelId: modelId,
		modelName: model,
	};

	/* Is this page call via /activity page or via models like ISO, MA etc */
	let isModelIndexView = ActivityView.modelId !== undefined;
	//console.log("isModelIndexView", isModelIndexView);

	const fetchData = async () => {
		// Set loading state
		setLoading(true);

		try {
			if (isModelIndexView) {
				ActivityView.filter = {
					...ActivityView.filter,
					model_id: modelId,
					model: model,
					deleted_by_username: null,
				};
			}
			const response = await ActivityService.view(ActivityView);
			// Display an error message if needed
			if (response.status !== 200) {
				toast.error(`Error fetching Contacts: ${response.msg}`, {
					position: "top-center",
					autoClose: 1000,
				});
			}

			const data = response?.data?.data ? response?.data?.data : [];
			// const rowCount = parseInt(response?.data?.data?.length);
			const totalCount = parseInt(response?.data?.pagination?.count);
			const currentPage = parseInt(response?.data?.pagination?.page);
			const data_columns = response?.data?.columns;

			setData(data);
			setTotalCount(totalCount);
			setCurrentPage(currentPage);
			setTotalPages(totalPages);
			// setRowCount(10);
			setRowsPerPage(rowsPerPage);
			setTableColumns(data_columns);
		} catch (error) {
			console.error("Error fetching data:", error);
		}

		// Set loading state
		setLoading(false);
	};

	useEffect(() => {
		fetchData();
	}, [totalPages, setLoading]);

	const sendRequest = async (filterList, isFilterChange) => {
		//console.log("send request");
		try {
			ActivityView.page = tableState.page + 1;
			ActivityView.limit = tableState.rowsPerPage;
			ActivityView.filter = {
				...ActivityView.filter,
				ranges: filters.ranges,
			};
			ActivityView.sort = sortList;
			ActivityView.modelId = modelId;
			ActivityView.modelName = model;

			// if (tableState.sortOrder.hasOwnProperty("name")) {
			// 	let sortColumn = tableState.sortOrder.name;
			// 	if (
			// 		dropdownConfig && // Check if dropdownConfig is defined
			// 		Object.values(dropdownConfig).includes(sortColumn)
			// 	) {
			// 		sortColumn = Object.keys(dropdownConfig).find(
			// 			(key) => dropdownConfig[key] === sortColumn
			// 		);
			// 	}
			// 	ActivityView.sort = `${sortColumn}:${tableState.sortOrder.direction}`;
			// } else {
			// 	ActivityView.sort = ActivityView.sort || "id:desc";
			// }
			if (tableState.sortOrder.hasOwnProperty("name")) {
				let sortColumn = tableState.sortOrder.name;
				if (
					tableColumns && // Check if tableColumns is defined
					Object.values(tableColumns).includes(sortColumn)
				) {
					sortColumn = Object.keys(tableColumns).find(
						(key) => tableColumns[key].filter === sortColumn
					);
				}
				ActivityView.sort = `${sortColumn}:${tableState.sortOrder.direction}`;
			} else {
				ActivityView.sort = ActivityView.sort || "id:desc";
			}
			setSortList(ActivityView.sort);

			if (tableState.searchText) {
				if (isFilterChange) {
					ActivityView.filter = {
						...ActivityView.filter,
						all: {},
					};
					setSearchText("");
				} else {
					ActivityView.filter = {
						...ActivityView.filter,
						all: tableState.searchText,
					};
				}
			}

			if (isFilterChange) {
				let newFilterList = [];

				if (filterList) {
					newFilterList = filterList;
				} else {
					newFilterList = tableState.filterList;
					ActivityView.filter.ranges =
						ActivityView.filter.ranges || {}; // Preserve ActivityView.filter.ranges
				}

				if (!columns || !tableState || !newFilterList) {
					console.error("Invalid columns or newTableState");
				} else {
					// Clear the ActivityView.filter object
					ActivityView.filter = {
						ranges: ActivityView.filter.ranges, // Preserve ActivityView.filter.ranges
					};

					// Loop through each column and set the filter in ActivityView
					columns.forEach((column, index) => {
						// Skip the columns found in filters.ranges
						if (filters.ranges && column.name in filters.ranges) {
							// Skip this column
						} else {
							const filterValue = newFilterList[index][0];
							if (filterValue) {
								ActivityView.filter = {
									...ActivityView.filter,
									[column.name]: filterValue,
								};
							}
						}
					});

					setFilterList(ActivityView.filter);
				}
			}

			if (isFilterChange === false) {
				if (tableRef.current) {
					tableRef.current.resetFilters();
				}
			}

			setLoading(true);

			if (isModelIndexView) {
				ActivityView.filter = {
					...ActivityView.filter,
					model_id: modelId,
					model: model,
					deleted_by_username: null,
				};
			}
			const response = await ActivityService.view(ActivityView);
			const data = response?.data?.data ? response?.data?.data : [];
			//const rowCount = parseInt(response?.data?.data?.length);
			const totalCount = parseInt(response?.data?.pagination?.count);
			const currentPage = parseInt(response?.data?.pagination?.page);
			const data_columns = response?.data?.columns;

			setData(data);
			setTotalCount(totalCount);
			setCurrentPage(currentPage);
			setTotalPages(totalPages);
			// setRowCount(rowCount);
			setRowsPerPage(tableState.rowsPerPage);
			setTableColumns(data_columns);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error("Error fetching data:", error);
		}
	};

	useEffect(() => {
		const handleClickOutside = async (event) => {
			if (lastAction === "search") {
				sendRequest(null, false);
				setLastAction("");
			}
		};

		const handleEnterRelease = async (event) => {
			if (event.key === "Enter" && lastAction === "search") {
				sendRequest(null, false);
				setLastAction(""); // Reset the last action
			}
			if (event.key === "Tab" && lastAction === "search") {
				sendRequest(null, false);
				setLastAction("");
			}
		};

		// Add event listener
		document.addEventListener("click", handleClickOutside);
		document.addEventListener("keyup", handleEnterRelease);
		// Clean up
		return () => {
			document.removeEventListener("click", handleClickOutside);
			document.removeEventListener("keyup", handleEnterRelease);
		};
	}, [searchText, lastAction]);

	useEffect(() => {
		const handleClickOutside = async (event) => {
			if (lastAction === "filterChange") {
				sendRequest(null, true);
				setLastAction(""); // Reset the last action
			}
		};

		const handleEnterRelease = async (event) => {
			if (event.key === "Enter" && lastAction === "filterChange") {
				sendRequest(null, true);
				setLastAction(""); // Reset the last action
			}
			if (event.key === "Tab" && lastAction === "filterChange") {
				sendRequest(null, true);
				setLastAction(""); // Reset the last action
			}
		};

		// Add event listener
		document.addEventListener("click", handleClickOutside);
		document.addEventListener("keyup", handleEnterRelease);
		// Clean up
		return () => {
			document.removeEventListener("click", handleClickOutside);
			document.removeEventListener("keyup", handleEnterRelease);
		};
	}, [filters, lastAction]);

	const handleTableChange = async (action, newTableState) => {
		if (JSON.stringify(tableState) !== JSON.stringify(newTableState)) {
			setTableState(newTableState);
		}

		if (action === "search") {
			setSearchText(newTableState.searchText);
			setLastAction(action);
		}

		if (action === "filterChange") {
			// Assuming filters are structured as an object with column names as keys
			setFilters((prevFilters) => ({
				...newTableState.filterList.reduce((acc, filter, index) => {
					acc[newTableState.columns[index].name] = filter;
					return acc;
				}, {}),
				ranges: prevFilters.ranges, // Keep the ranges property
			}));
			setLastAction(action);
		}

		if (
			action === "changePage" ||
			action === "changeRowsPerPage" ||
			action === "sort" ||
			action === "resetFilters"
		) {
			try {
				ActivityView.page = newTableState.page + 1;
				ActivityView.limit = newTableState.rowsPerPage;
				ActivityView.filter = {
					...ActivityView.filter,
					ranges: ActivityView.filter.ranges,
				};
				ActivityView.sort = sortList;
				ActivityView.modelId = modelId;
				ActivityView.modelName = model;
				setRowCount(newTableState.rowsPerPage);
				// if (newTableState.sortOrder.hasOwnProperty("name")) {
				// 	let sortColumn = newTableState.sortOrder.name;
				// 	if (Object.values(dropdownConfig).includes(sortColumn)) {
				// 		sortColumn = Object.keys(dropdownConfig).find(
				// 			(key) => dropdownConfig[key] === sortColumn
				// 		);
				// 	}
				// 	ActivityView.sort = `${sortColumn}:${newTableState.sortOrder.direction}`;
				// } else {
				// 	ActivityView.sort = "id:desc";
				// }
				if (newTableState.sortOrder.hasOwnProperty("name")) {
					let sortColumn = newTableState.sortOrder.name;
					if (
						tableColumns &&
						Object.values(tableColumns).includes(sortColumn)
					) {
						sortColumn = Object.keys(tableColumns).find(
							(key) => tableColumns[key].filter === sortColumn
						);
					}
					ActivityView.sort = `${sortColumn}:${newTableState.sortOrder.direction}`;
				} else {
					ActivityView.sort = "id:desc";
				}
				setSortList(ActivityView.sort);

				if (newTableState.searchText) {
					if (newTableState.searchText.length > 2) {
						ActivityView.filter = {
							...ActivityView.filter,
							all: newTableState.searchText,
						};
					} else {
						return false;
					}
				}

				if (newTableState.filterList) {
					// Ensure both columns and tableState are provided and valid
					if (
						!columns ||
						!newTableState ||
						!newTableState.filterList
					) {
						console.error("Invalid columns or newTableState");
					} else {
						// Loop through each column and set the filter in ActivityView
						columns.forEach((column, index) => {
							const filterValue =
								newTableState.filterList[index][0];

							if (filterValue) {
								ActivityView.filter = {
									...ActivityView.filter,
									[column.name]: filterValue,
								};
							}
						});
					}
					setFilterList(ActivityView.filter);
				}

				if (action === "resetFilters") {
					ActivityView.filter = {};
					setFilterList({});
				}

				setLoading(true);

				if (isModelIndexView) {
					ActivityView.filter = {
						...ActivityView.filter,
						model_id: modelId,
						model: model,
						deleted_by_username: null,
					};
				}
				const response = await ActivityService.view(ActivityView);
				const data = response?.data?.data ? response?.data?.data : [];
				// const rowCount = parseInt(response?.data?.data?.length);
				const totalCount = parseInt(response?.data?.pagination?.count);
				const currentPage = parseInt(response?.data?.pagination?.page);

				setData(data);
				setTotalCount(totalCount);
				setCurrentPage(currentPage);
				setTotalPages(totalPages);
				// setRowCount(rowCount);
				setRowsPerPage(newTableState.rowsPerPage);
				setLoading(false);
			} catch (error) {
				setLoading(false);
				console.error("Error fetching data:", error);
			}
		}
	};

	const navigate = useNavigate();

	const [isOpen, setIsOpen] = useState(false);

	const fetchActivityTypes = async () => {
		try {
			const response = await ActivityService.getTypesList();
			setActivityTypes(response.data.data);
		} catch (error) {
			console.error("Error fetching types:", error);
		}
	};

	useEffect(() => {
		fetchActivityTypes();
		renderCount.current = renderCount.current + 1;

		if (activeTab === "activities") {
			fetchData();
		}
	}, [modelId, model, activeTab]);

	const handleClose = () => {
		// Reset the form when closing the modal
		reset();
		setIsOpen(false);
	};

	const handleOpen = () => setIsOpen(true);

	const handleAddAction = () => {
		setCurrentActivity(null); // Clear the current activity
		handleOpen(); // Show the modal
	};

	const HeaderElements = () => (
		<>
			{" "}
			<Button size="sm" onClick={handleAddAction}>
				<div className="d-flex align-items-center">
					<FaPlus /> Add Activity
				</div>
			</Button>
		</>
	);

	const submitActivity = async (data) => {
		if (currentActivity) {
			let activityId = parseInt(currentActivity.id);
			let modelId = parseInt(ActivityView.modelId);
			let modelName = ActivityView.modelName;
			let description = data.description;
			let activateTypeId = data.activities_type_id;

			return await ActivityService.update(
				activityId,
				modelId,
				modelName,
				activateTypeId,
				description
			)
				.then((response) => {
					if (
						response.status === 200 &&
						response.data.isOk === true
					) {
						// show success message
						handleClose();
						toast.info(`Activity updated successfully!`, {
							position: "top-center",
							autoClose: 1000,
						});
						fetchData();
						reset();
					}
				})
				.catch((error) => {
					setError("api", {
						type: "server",
						message: error.response.data.error,
					});
				});
		} else {
			const modelId = parseInt(ActivityView.modelId);
			const modelName = ActivityView.modelName;
			const activitiesTypeId = parseInt(data.activities_type_id);
			const description = data.description;

			return await ActivityService.create(
				modelId,
				modelName,
				activitiesTypeId,
				description
			)
				.then((response) => {
					if (
						response.status === 200 &&
						response.data.isOk === true
					) {
						// show success message
						handleClose();

						toast.info(`Activity created successfully!`, {
							position: "top-center",
							autoClose: 1000,
						});
						fetchData();
						reset();
						let path = getModelUrl(modelId, modelName);
						navigate(path);
					} else {
						// console.log("Error!");
						// show error messages
						const errorMappings = {
							modelId: "modelId",
							modelName: "modelName",
							description: "description",
							activities_type_id: "activities_type_id",
						};

						Object.entries(response.data.message).forEach(
							([field, message]) => {
								const errorType = errorMappings[field];
								if (errorType) {
									// setError(errorType, {
									//     type: "server",
									//     message,
									// });
								}
							}
						);
					}
				})
				.catch((error) => {
					// console.log(error);
					// setError("api", {
					//     type: "server",
					//     message: error.response.data.error,
					// });
				});
		}
	};

	const handleEditAction = (tableMeta) => {
		ActivityService.fetchById(tableMeta.rowData[1])
			.then((response) => {
				let activityData = response.data.data[0];
				// set activityData.activities_type_id to string
				activityData.activities_type_id =
					activityData.activities_type_id.toString();
				setCurrentActivity(activityData);
				handleOpen();
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const handleDeleteAction = (tableMeta) => {
		confirmAlert({
			title: "Are you sure?",
			message: "You want to delete this data?",
			buttons: [
				{
					label: "Yes",
					onClick: () => {
						deleteActivity(tableMeta.rowData[1]);
					},
				},
				{
					label: "No",
				},
			],
		});
	};

	const deleteActivity = async (activityId) => {
		let modelName = `${model}`;
		return await ActivityService.delete(activityId, modelId, modelName)
			.then((response) => {
				if (response.status === 200 && response.data.isOk === true) {
					// show success message
					handleClose();
					toast.info(`Activity deleted successfully!`, {
						position: "top-center",
						autoClose: 1000,
					});
					fetchData();
					reset();
				}
			})
			.catch((error) => {
				setError("api", {
					type: "server",
					message: error.response.data.error,
				});
			});
	};

	useEffect(() => {
		if (currentActivity) {
			reset({
				activities_type_id: currentActivity.activities_type_id,
				description: currentActivity.description,
			});
		} else {
			reset({
				activities_type_id: "",
				description: "",
			});
		}
	}, [currentActivity, reset]);

	const columns = [
		{
			name: "",
			options: {
				filter: false,
				sort: false,
				display: true,
				viewColumns: false,
				customBodyRender: (_, tableMeta) => (
					<div
						style={{
							display: "flex",
							justifyContent: "space-around",
						}}
					>
						{!isModelIndexView && (
							<Link to={`/activities/${tableMeta.rowData[1]}`}>
								<ModeEditIcon
									variant="outlined"
									color="primary"
									// onClick={() => handleButtonClick(tableMeta)}
									sx={{ border: 1, borderRadius: "5px" }}
								>
									Edit
								</ModeEditIcon>
							</Link>
						)}

						{isModelIndexView && (
							<ModeEditIcon
								variant="outlined"
								color="primary"
								onClick={() => handleEditAction(tableMeta)}
								sx={{
									border: 1,
									borderRadius: "5px",
									marginRight: ".5rem",
								}}
								style={{ cursor: "pointer" }}
							>
								Edit
							</ModeEditIcon>
						)}
						{isModelIndexView && (
							<DeleteIcon
								variant="outlined"
								color="error"
								onClick={() => handleDeleteAction(tableMeta)}
								sx={{
									border: 1,
									borderRadius: "5px",
									marginRight: ".5rem",
								}}
								style={{ cursor: "pointer" }}
							></DeleteIcon>
						)}
					</div>
				),
			},
		},
		{
			name: "id",
			label: "ID",
			options: generateGridStructOptions("id", tableColumns, false),
		},
		createDropdownColumn(tableColumns, "actities_type_name", "Type"),
		createDropdownColumn(
			tableColumns,
			"assigned_to_username",
			"Assigned To"
		),
		{
			name: "description",
			label: "Description",
			options: generateGridStructOptions("description", tableColumns),
		},
		createDateRangeColumn(
			tableColumns,
			ActivityView,
			filters,
			setFilters,
			"created_at",
			"Created At"
		),
	];

	const options = {
		customToolbar: () => <HeaderElements />,
		filter: true,
		filterType: "textField",
		searchText: searchText,
		onFilterChipClose: (removedFilterIndex, value, filterList) => {
			// Get the name of the removed filter
			const removedFilterName = columns[removedFilterIndex].name;
			// Check if the removed filter is in the ranges object
			if (
				filters.ranges &&
				filters.ranges.hasOwnProperty(removedFilterName)
			) {
				// Clear the range from ActivityView.filter.ranges
				if (ActivityView.filter.ranges) {
					delete ActivityView.filter.ranges[removedFilterName];
				}
				// Clear the range from filters.ranges
				delete filters.ranges[removedFilterName];
				// Update the state
				setFilters(filters);
			}
			sendRequest(filterList, true);
			setLastAction("");
		},
		serverSide: true,
		count: totalCount,
		page: currentPage - 1,
		rowsPerPage: rowsPerPage,
		rowsPerPageOptions: [
			10,
			25,
			50,
			100,
			{ label: "All", value: totalCount },
		],
		elevation: 0,
		responsive: "standard",
		fixedHeader: true,
		fixedFooter: true,
		selectableRows: "none",
		onTableChange: handleTableChange,
		onDownload: (buildHead, buildBody, columns, data) => {
			// Fetch all data that matches the applied filters
			const fetchAllData = async () => {
				// Use the same parameters as in the handleTableChange function
				ActivityView.page = 1;
				ActivityView.limit = totalCount;
				ActivityView.filter = filterList;
				ActivityView.sort = sortList;
				ActivityView.modelId = modelId;
				ActivityView.modelName = model;

				// Add filters based on the current table state
				if (tableState.filterList) {
					// Ensure both columns and tableState are provided and valid
					if (!columns || !tableState || !tableState.filterList) {
						console.error("Invalid columns or newTableState");
					} else {
						// Loop through each column and set the filter in ActivityView
						columns.forEach((column, index) => {
							const filterValue = tableState.filterList[index][0];

							if (filterValue) {
								ActivityView.filter = {
									...ActivityView.filter,
									[column.name]: filterValue,
								};
							}
						});
					}
				}

				if (isModelIndexView) {
					ActivityView.filter = {
						...ActivityView.filter,
						model_id: modelId,
						model: model,
						deleted_by_username: null,
					};
				}
				const response = await ActivityService.view(ActivityView);
				return response.data.data;
			};

			fetchAllData().then((allData) => {
				// Check if allData is defined
				if (!allData) {
					console.error("allData is undefined");
					return false;
				}

				// get checked columns
				const checkedColumns = tableState.columns.filter(
					(column) => column.display === "true"
				);

				// Transform the checked columns to the format required by buildHead
				const transformedColumns = checkedColumns.map((column) => ({
					...column,
				}));

				// remove first column, null empty
				transformedColumns.shift();

				// Check if there are no columns selected
				if (transformedColumns.length === 0) {
					// Optionally display a message to the user
					toast.error("No columns selected for download", {
						position: "top-center",
						autoClose: 2000,
					});
					return false;
				}

				// Transform data to the required format
				const transformedData = allData
					.map((item, index) => {
						if (item) {
							return {
								index: index,
								data: transformedColumns.map(
									(column) => item[column.name]
								),
							};
						}
						return null;
					})
					.filter((item) => item !== null);

				// Generate CSV content
				const csvContent =
					buildHead(transformedColumns) + buildBody(transformedData);

				var fileDownload = require("js-file-download");
				fileDownload(csvContent, "export.csv", "text/csv");
			});

			// Return false to prevent the default CSV export
			return false;
		},
	};

	return (
		<div>
			<ThemeProvider theme={theme}>
				<MUIDataTable
					title="Activities"
					data={data}
					columns={columns}
					options={options}
					ref={tableRef}
				/>
			</ThemeProvider>

			<Dialog modal={true} open={isOpen} onOpenChange={setIsOpen}>
				<DialogContent
					className="sm:max-w-md dialog-content"
					onPointerDownOutside={(e) => {
						e.preventDefault();
						// Select the dialog content
						const dialogContent =
							document.querySelector(".dialog-content");
						if (dialogContent) {
							// Remove any existing animationend event listener
							const onAnimationEnd = () => {
								dialogContent.style.animation = "none 0s";
							};
							dialogContent.removeEventListener(
								"animationend",
								onAnimationEnd
							);

							// Start a new animation
							dialogContent.style.animation = "popout 1s";
							dialogContent.style.animationFillMode = "forwards";

							// Remove the animation once it has finished
							dialogContent.addEventListener(
								"animationend",
								onAnimationEnd,
								{
									once: true,
								}
							);
						}
					}}
				>
					<DialogHeader>
						<DialogTitle>
							{currentActivity ? "Edit" : "Add"} Activity
						</DialogTitle>
						{/* <DialogDescription>
                            Anyone who has this link will be able to view this.
                        </DialogDescription> */}
					</DialogHeader>
					<div>
						<FormGroup className="form-group">
							<FormLabel htmlFor="activities_type_id">
								Activity Type <div className="has-star">*</div>
							</FormLabel>
							<FormSelect
								tabIndex="1"
								name="activities_type_id"
								id="activities_type_id"
								{...register("activities_type_id")}
								isInvalid={
									(touchedFields.activities_type_id &&
										!!errors["activities_type_id"]) ||
									(isSubmitted &&
										!!errors["activities_type_id"])
								}
							>
								<option value="" disabled>
									Select a Type...
								</option>
								{activityTypes.length > 0 &&
									activityTypes.map((activityTypes) => (
										<option
											value={activityTypes.id}
											key={activityTypes.id}
										>
											{activityTypes.name}
										</option>
									))}
							</FormSelect>
							<Feedback type="invalid">
								{errors["activities_type_id"] &&
									errors["activities_type_id"].message}
							</Feedback>
						</FormGroup>
						<FormControl
							autoFocus
							as="textarea"
							rows={5}
							{...register("description")}
							isInvalid={!!errors.description}
						/>
					</div>
					<DialogFooter>
						<DialogButton
							variant="outline"
							onClick={handleClose}
							className="mt-2 sm:mt-0"
						>
							Cancel
						</DialogButton>
						<DialogButton
							// prevent the dialog from closing
							onClick={(e) => {
								e.preventDefault();
								handleSubmit(submitActivity)();
							}}
							// onClick={handleSubmit(
							//     console.log("Validation errors:", errors)
							// )}
							disabled={
								currentActivity
									? isSubmitting || !isValid || !isDirty
									: isSubmitting || !isValid
							}
						>
							{isSubmitting && (
								<span className="spinner-border spinner-border-sm mr-1"></span>
							)}
							{currentActivity ? "Update" : "Save"}
						</DialogButton>
					</DialogFooter>
				</DialogContent>
			</Dialog>
			<DevTool control={control} />
		</div>
	);
};

export default ActivitiesTable;
