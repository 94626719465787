// Header.js

import React from 'react';
import { Helmet } from 'react-helmet';

const Header = ({ pageTitle }) => {
    return (
        <Helmet>
            <title>{pageTitle}</title>
            <meta name="robots" content="noindex,nofollow" />
            <meta property="og:title" content={pageTitle} />
            <meta name="twitter:title" content={pageTitle} />
        </Helmet>
    );
};

export default Header;
